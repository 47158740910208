let isDev = process.env.NODE_ENV === "development"
let useDev = process.env.REACT_APP_USE_DEV === "true"
// useDev = true
let useLocal = useDev && isDev 
// console.log("useLocal", process.env, isDev, useDev, useLocal )

export const DEFAULT_ORGANIZATION_COVER_PHOTO = "https://i.imgur.com/rYazWWq.png";

export const apiURL = !useLocal
    ? "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/knowledgeBase"
    : "http://127.0.0.1:5003/addy-ai-dev/us-central1/knowledgeBase";

export const AI_APP_API_URL = !useLocal
    ? "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/application"
    : "http://127.0.0.1:5003/addy-ai-dev/us-central1/application";

export const KNOWLEDGE_BASE_API_URL =  !useLocal
    ? "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/knowledgeBase"
    : "http://127.0.0.1:5003/addy-ai-dev/us-central1/knowledgeBase";

export const ORGANIZATION_API_URL = !useLocal
    ? "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/organization"
    : "http://127.0.0.1:5003/addy-ai-dev/us-central1/organization";
 
export const PROFILE_URL = !useLocal
    ? "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/api"
    : "http://127.0.0.1:5003/addy-ai-dev/us-central1/api";

export const CUSTOM_DOMAIN_A_RECORDS = ["185.199.108.153", "185.199.109.153", "185.199.110.153", "185.199.111.153"];

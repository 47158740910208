/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import reactCSS from "reactcss";
import { ChromePicker } from "react-color";
import { ButtonsTemplatePersonalization } from "./buttonsTemplatePersonalization";
import { ThreeDots } from "react-loader-spinner";
import { EmbedTemplatePersonalization } from "./embedTemplatePersonalization";
import { TextTemplatePersonalization } from "./textTemplatePersonalization";
import { WalletTemplatePersonalization } from "./walletTemplatePersonalization";
import { ButtonsSimpleTemplatePersonalization } from "./buttonsSimpleTemplatePersonalization";
import { ButtonsURLTemplatePersonalization } from "./buttonsURLTemplatePersonalization";
import { InputTemplatePersonalization } from "./inputTemplatePersonalization";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
// import logo from '../templates/topLogo.png' // relative path to image

export const BaseTemplatePersonalization = (props) => {
  async function firebaseGetImageURL(base64, uploadPath) {
    const storage = getStorage();
    const getImageDownloadURL = await getDownloadURL(ref(storage, uploadPath))
      .then((url) => {
        return url;
      })
      .catch((error) => {
        console.log(error);
        // Handle any errors
      });
    const file = await fetch(getImageDownloadURL);
    const blob = await file.blob();
    return blob;
  }

  async function loadImagesDefault() {
    if (props.selectedTemplate !== "") {
      var getFirebaseImageURL = undefined;
      console.log(props);
      if (props.logo === "") {
        if (props.selectedTemplate.includes("welcome")) {
          getFirebaseImageURL = await firebaseGetImageURL(null, `/default-logo/leftLogo.png`);
        } else {
          getFirebaseImageURL = await firebaseGetImageURL(null, `/default-logo/topLogo.png`);
          if (props.selectedTemplate.includes("connect")) {
            var getFirebaseImageURL1 = await firebaseGetImageURL(null, `/default-logo/leftLogo.png`);
            props.logoFunction(null, true, URL.createObjectURL(getFirebaseImageURL1));
            return;
          } else {
            if (props.selectedTemplate.includes("but")) {
              console.log(props.buttonsL);
              var getFirebaseImageURL2 = await firebaseGetImageURL(null, `/default-logo/leftLogo.png`);
              props.buttonsL.forEach((element, index) => {
                props.onButtonImageChange(null, index, true, URL.createObjectURL(getFirebaseImageURL2));
              });
            }
          }
        }
        setImage(URL.createObjectURL(getFirebaseImageURL));
        setFile(getFirebaseImageURL);
        props.imageFunction(null, true, URL.createObjectURL(getFirebaseImageURL));
      }
    }
  }

  useEffect(() => {
    //loadImagesDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTemplate]);

  const [file, setFile] = useState(null);
  const [image, setImage] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [buttonColor, setButtonColor] = useState("#0d1121");
  const fileTypes = ["JPG", "PNG", "GIF"];
  const handleChange = async (file) => {
    setUploadingImage(true);
    console.log(file);
    setImage(URL.createObjectURL(file));
    setFile(file);
    await props.imageFunction(file);
    setUploadingImage(false);
  };
  const styles = reactCSS({
    "default": {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${buttonColor.r}, ${buttonColor.g}, ${buttonColor.b}, ${buttonColor.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });
  return (
    <TemplatePersTemplateEditorModalRootRoot>
      <TemplatePersFlexRow>
        <TemplatePersText2>Select Template</TemplatePersText2>
        <TemplatePersLastWeek
          onClick={() => {
            props.setSelectedTemplate("");
          }}>
          Select Another Template
        </TemplatePersLastWeek>
      </TemplatePersFlexRow>
      <TemplatePersDivider src={`https://file.rendit.io/n/zZygIQuj6NOD9A2F3BPe.svg`} />
      <TemplatePersFlexRow1>
        <TemplatePersWhiteFlexColumn>
          <TemplatePersFlexColumn>
            <TemplatePersFlexColumn1>
              <TemplatePersText3>Selected Template Name</TemplatePersText3>
              <TemplatePersFlexRow2></TemplatePersFlexRow2>
            </TemplatePersFlexColumn1>
            <TemplatePersDivider src={`https://file.rendit.io/n/5lvXIhgj2f6I700wbxcf.svg`} />
          </TemplatePersFlexColumn>
          <TemplatePersFlexRow3>
            <TemplatePersScreen>
              <TemplatePersText4>Title</TemplatePersText4>
              <TemplatePersTextbox
                value={props.title}
                onChange={(e) => props.setTitle(e.target.value)}
                placeholder={`Placeholder`}></TemplatePersTextbox>
            </TemplatePersScreen>
          </TemplatePersFlexRow3>
          <TemplatePersFlexRow4>
            <TemplatePersScreen>
              <TemplatePersText4>Subtitle</TemplatePersText4>
              <TemplatePersTextbox
                value={props.subtitle}
                onChange={(e) => props.setSubtitle(e.target.value)}
                placeholder={`Placeholder`}></TemplatePersTextbox>
            </TemplatePersScreen>
          </TemplatePersFlexRow4>
          {props.buttons ? (
            props.externalURL ? (
              <ButtonsURLTemplatePersonalization
                condition={props.condition}
                onButtonImageChange={props.onButtonImageChange}
                setButtonsL={props.setButtonsL}
                buttons={props.buttonsL}
                setButtons={props.setButtons}
                numberOfButtons={props.numberOfButtons}
                addButton={props.addButton}
                removeButton={props.removeButton}></ButtonsURLTemplatePersonalization>
            ) : (
              <ButtonsTemplatePersonalization
                condition={props.condition}
                onButtonImageChange={props.onButtonImageChange}
                setButtonsL={props.setButtonsL}
                buttons={props.buttonsL}
                setButtons={props.setButtons}
                numberOfButtons={props.numberOfButtons}
                addButton={props.addButton}
                removeButton={props.removeButton}></ButtonsTemplatePersonalization>
            )
          ) : props.embed ? (
            <>
              <EmbedTemplatePersonalization
                embed={props.embedV}
                setEmbed={props.setEmbed}></EmbedTemplatePersonalization>
            </>
          ) : props.textB ? (
            <>
              <TextTemplatePersonalization
                editorState={props.editorState}
                onEditorStateChange={props.onEditorStateChange}></TextTemplatePersonalization>
            </>
          ) : props.walletC ? (
            <>
              <WalletTemplatePersonalization
                text={props.text}
                setText={props.setText}
                chain={props.chain}
                setChain={props.setChain}
                contractAddress={props.contractAddress}
                setContractAddress={props.setContractAddress}
                numberOfNeededTokens={props.numberOfNeededTokens}
                setNumberOfNeededTokens={props.setNumberOfNeededTokens}
                logoFunction={props.logoFunction}></WalletTemplatePersonalization>
            </>
          ) : props.input ? (
            <>
              <InputTemplatePersonalization
                formTitle={props.formTitle}
                setFormTitle={props.setFormTitle}
                setInputs={props.setInputs}
                inputs={props.inputs}
                addInput={props.addMoreInputs}
                addMoreDrop={props.addMoreDrop}
                removeInput={props.removeSpecificInput}
                removeDrop={props.removeDrop}></InputTemplatePersonalization>
            </>
          ) : (
            <>
              <TemplatePersFlexRow5>
                <TemplatePersScreen2>
                  <TemplatePersText4>Text</TemplatePersText4>
                  <TemplatePersTextbox2
                    value={props.text}
                    onChange={(e) => props.setText(e.target.value)}
                    placeholder={`Placeholder`}></TemplatePersTextbox2>
                </TemplatePersScreen2>
              </TemplatePersFlexRow5>
            </>
          )}
        </TemplatePersWhiteFlexColumn>
        <TemplatePersFlexColumn2>
          <TemplatePersTemplateScreens>{props.template}</TemplatePersTemplateScreens>
          <TemplatePersWhiteFlexColumn1>
            <TemplatePersFlexRow3>
              <TemplatePersScreen3>
                {props.input}
                <TemplatePersText4>{props.welcome ? "Upload Image on Left" : "Upload Image on Top"}</TemplatePersText4>
                <TemplatePersFlexRow7>
                  {uploadingImage ? (
                    "Uploading..."
                  ) : (
                    <FileUploader
                      classes="files"
                      style={{ border: "0px" }}
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    />
                  )}
                </TemplatePersFlexRow7>
                <TemplatePersParagraph>
                  SVG, PNG, JPG (Max 8mb), Preferred resolution
                  {"  "}
                  {props.welcome ? "950x950" : "1175x200"}
                </TemplatePersParagraph>
              </TemplatePersScreen3>
            </TemplatePersFlexRow3>

            {props.walletC ? (
              <>
                {props.backButton && (
                  <TemplatePersFlexRow4>
                    <TemplatePersScreen3 style={{ flexDirection: "row" }}>
                      <div>
                        <TemplatePersText10>Button Text</TemplatePersText10>
                        <TemplatePersTextbox5
                          value={props.buttonBack}
                          onChange={(e) => props.setButtonBack(e.target.value)}
                          placeholder="Next"></TemplatePersTextbox5>
                      </div>
                    </TemplatePersScreen3>
                  </TemplatePersFlexRow4>
                )}

                <TemplatePersFlexRow4>
                  <TemplatePersScreen3 style={{ flexDirection: "row" }}>
                    <div>
                      <TemplatePersText10>Button Color</TemplatePersText10>
                      <TemplatePersFlexRow7>
                        <TemplatePersLastWeek2
                          style={{
                            backgroundColor: props.buttonColor,
                            borderWidth: "1px",
                            color: props.buttonColor === "FFFFFF" ? "white" : "white",
                          }}
                          onClick={() => setDisplayColorPicker(!displayColorPicker)}>
                          Pick Color
                        </TemplatePersLastWeek2>
                        {displayColorPicker ? (
                          <div style={styles.popover}>
                            <div style={styles.cover} onClick={() => setDisplayColorPicker(!displayColorPicker)} />
                            <ChromePicker color={props.buttonColor} onChange={(e) => props.setButtonColor(e.hex)} />
                          </div>
                        ) : null}
                      </TemplatePersFlexRow7>
                    </div>
                    <div>
                      <TemplatePersText10>Button Text</TemplatePersText10>
                      <TemplatePersTextbox5
                        value={props.buttonWallet}
                        onChange={(e) => props.setButtonWallet(e.target.value)}
                        placeholder="Connect"></TemplatePersTextbox5>
                    </div>
                  </TemplatePersScreen3>
                </TemplatePersFlexRow4>
                <ButtonsSimpleTemplatePersonalization
                  onButtonImageChange={props.onButtonImageChange}
                  setButtonsL={props.setButtonsL}
                  buttons={props.buttonsL}
                  setButtons={props.setButtons}
                  numberOfButtons={props.numberOfButtons}
                  addButton={props.addButton}
                  removeButton={props.removeButton}></ButtonsSimpleTemplatePersonalization>
              </>
            ) : (
              <>
                <TemplatePersFlexRow4>
                  <TemplatePersScreen3 style={{ flexDirection: "row" }}>
                    <div>
                      <TemplatePersText10>Button Color</TemplatePersText10>
                      <TemplatePersFlexRow7>
                        <TemplatePersLastWeek2
                          style={{
                            backgroundColor: props.buttonColor,
                            borderWidth: "1px",
                            color: props.buttonColor === "FFFFFF" ? "white" : "white",
                          }}
                          onClick={() => setDisplayColorPicker(!displayColorPicker)}>
                          Pick Color
                        </TemplatePersLastWeek2>
                        {displayColorPicker ? (
                          <div style={styles.popover}>
                            <div style={styles.cover} onClick={() => setDisplayColorPicker(!displayColorPicker)} />
                            <ChromePicker color={props.buttonColor} onChange={(e) => props.setButtonColor(e.hex)} />
                          </div>
                        ) : null}
                      </TemplatePersFlexRow7>
                    </div>
                    <div>
                      <TemplatePersText10>Button Text</TemplatePersText10>
                      <TemplatePersTextbox5
                        value={props.buttonNext}
                        onChange={(e) => props.setButtonNext(e.target.value)}
                        placeholder="Next"></TemplatePersTextbox5>
                    </div>
                  </TemplatePersScreen3>
                </TemplatePersFlexRow4>
                {props.backButton && (
                  <TemplatePersFlexRow4>
                    <TemplatePersScreen3 style={{ flexDirection: "row" }}>
                      <div>
                        <TemplatePersText10>Button Text</TemplatePersText10>
                        <TemplatePersTextbox5
                          value={props.buttonBack}
                          onChange={(e) => props.setButtonBack(e.target.value)}
                          placeholder="Next"></TemplatePersTextbox5>
                      </div>
                    </TemplatePersScreen3>
                  </TemplatePersFlexRow4>
                )}
              </>
            )}
          </TemplatePersWhiteFlexColumn1>
        </TemplatePersFlexColumn2>
      </TemplatePersFlexRow1>
      <TemplatePersDivider src={`https://file.rendit.io/n/zZygIQuj6NOD9A2F3BPe.svg`} />
      <TemplatePersFlexRow11>
        <TemplatePersLastWeek3
          disabled={props.loadingSave}
          onClick={async () => {
            await props.storeTemplateInfo();
          }}>
          {!props.loadingSave ? (
            <>Save</>
          ) : (
            <>
              <span style={{ marginRight: "5px" }}> Saving </span>
              <ThreeDots color="#0E1221" height={20} width={50} />
            </>
          )}
        </TemplatePersLastWeek3>
      </TemplatePersFlexRow11>
    </TemplatePersTemplateEditorModalRootRoot>
  );
};

const TemplatePersDivider = styled.img`
  height: 1px;
  align-self: stretch;
`;
const TemplatePersText4 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
const TemplatePersFlexRow3 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 15px 15px 7px 15px;
`;
const TemplatePersScreen = styled.div`
  width: 332px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const TemplatePersTextbox = styled.input`
  height: 26px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const TemplatePersSetupPayments = styled.input`
  width: 100%;
  gap: 2px;
  display: inline-block;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  color: #c9d1ef;
  white-space: nowrap;
  outline-width: 0px;
  border-width: 0px;
  background: none;
  color: black;
  :: placeholder {
    color: #c9d1ef;
  }
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const TemplatePersFlexRow4 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 7px 15px;
`;
const TemplatePersScreen3 = styled.div`
  width: 369px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersFlexRow7 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersText10 = styled.button`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
`;
const TemplatePersTemplateEditorModalRootRoot = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 20px;
  padding: 22px 14px 14px 14px;
  border-width: 2px;
`;
const TemplatePersFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 0px 11px;
`;
const TemplatePersText2 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const TemplatePersLastWeek = styled.button`
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const TemplatePersFlexRow1 = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const TemplatePersWhiteFlexColumn = styled.div`
  width: 364px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #eaeeff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
`;
const TemplatePersFlexColumn = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 23px 15px 0px 15px;
`;
const TemplatePersFlexColumn1 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const TemplatePersText3 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;
const TemplatePersFlexRow2 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const TemplatePersText5 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 14px;
  white-space: nowrap;
`;
const TemplatePersFlexRow5 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 15px;
`;
const TemplatePersScreen2 = styled.div`
  width: 332px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersTextbox2 = styled.textarea`
  height: 240px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  :: placeholder {
    color: #c9d1ef;
  }
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const TemplatePersFlexColumn2 = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const TemplatePersTemplateScreens = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #858caa;
  overflow: hidden;
  border-radius: 20px;
  border-width: 2px;
  width: 400px;
  overflow: hidden;
  height: 275px;
  overflow-y: hidden;
`;
const TemplatePersWhiteFlexColumn1 = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  background-color: #eaeeff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
`;
const TemplatePersParagraph = styled.div`
  color: #858caa;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  white-space: pre-wrap;
`;
const TemplatePersLastWeek2 = styled.button`
  width: 150.5px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #0d1121;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const TemplatePersTextbox5 = styled.input`
  font-size: 12px;
  width: 180px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const TemplatePersFlexRow11 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersLastWeek3 = styled.button`
  width: 757px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #0d1121;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

import React from "react";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import styled from "styled-components";
import ProjectUtils from "../../services/project/ProjectUtils";
import { InfinitySpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


export const Projects = props => {
    const { pathname } = useLocation();
    const { organizationId } = useParams();
    const [projects, setProjects] = useState([]);
    const [loadProjects, setLoadProjects] = useState(true);
    const navigate = useNavigate();

    async function getOnboardings() {
        const auth = getAuth();
        setLoadProjects(true);
        auth.onAuthStateChanged((user) => {
            if (user) {

                ProjectUtils.callGetAllAppsByOrganizationIdAndUserId(user.uid.toString(), organizationId).then(p => {
                    setProjects(p);
                    setLoadProjects(false);
                });
            }
        });
    }

    useEffect(() => {
        getOnboardings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getOnboardings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    return (

        <FlexColumn>

            <FlexRowHead>

                <Link to={"/organization/" + organizationId + '/create-ai'}>
                    <CreateButtonRootRoot onClick={() => navigate("createOrganization")}>
                        <AddIconDiv>
                            {/* <AddIcon
                                        src="https://i.imgur.com/dB3Hmye.png"
                                    /> */}
                            <WorkflowText1Create>Create New Assistant</WorkflowText1Create>
                        </AddIconDiv>

                    </CreateButtonRootRoot>
                </Link>
            </FlexRowHead>

            <FlexRow1>
                {loadProjects
                    ?
                    <Loading>
                        <InfinitySpin
                            width='200'
                            height='200'
                            color="#0E1221"
                        />
                    </Loading>
                    :
                    projects.length > 0 && projects.map((project, index) => (
                        <Cardsprojects onClick={() => navigate(`/organization/${organizationId}/apps/${project.appID}`)}>
                            <FlexRow2>

                                <UserAvatar>
                                    <Pfp
                                        src={project.appType === "ai-email-assistant" ? "https://i.imgur.com/1JGtIoE.png"
                                            : "https://i.imgur.com/7zBajsN.png"}
                                    />
                                </UserAvatar>
                                <FlexColumn2>
                                    <Text2 title={project.appName}>{(project.appName).length > 40 ? `${(project.appName).substring(0, 40)}...` : (project.appName)}</Text2>
                                    <Text3 style={{
                                        marginTop: "4px"
                                    }}>{project.appTitle}</Text3>
                                </FlexColumn2>
                            </FlexRow2>
                        </Cardsprojects>
                    ))
                }
            </FlexRow1>
        </FlexColumn>
    )
}



const Loading = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
  background-color: transparent;
  border: 0px;
`;
const Text2 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;

const Text3 = styled.div`
  color: #495274;
  font-size: 12px;
  font-family: Poppins;
  white-space: nowrap;
`;

const FlexColumn2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Pfp = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UserAvatar = styled.div`
    width: 50px;
    height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 7px;
`;
const FlexRow2 = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;
const FlexColumn1 = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
const FlexColumn = styled.div`
  height: auto;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;

const FlexRow1 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  margin-bottom: 10px;
`;

const FlexRowHead = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  margin-bottom: 10px;
`;
const Cardsprojects = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
  width: 300px;
  height: 100px;
  cursor: pointer;
`;

const CreateButtonRootRoot = styled.div`
    cursor: pointer;
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #eaeeff;
    background-color: #745DDE;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 14px;
    border-width: 2px;
    `;

const AddIconDiv = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
`;

const AddIcon = styled.img`
    width: 20px;
    height: 20px;
`;

const WorkflowText1Create = styled.div`
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins;
    white-space: nowrap;
`;
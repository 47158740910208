
import styled from "styled-components";
const DeleteBody = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const DeleteModalsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
`;
const DeleteText1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const DeleteParagraph = styled.div`
  width: 376px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const DeleteFlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const DeleteDivider = styled.img`
  width: 376px;
  height: 1px;
`;
const DeleteLastWeek = styled.button`
  width: 344px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const DeleteLastWeek1 = styled.button`
  width: 344px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const MenuOptions3 = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  background-color: #f9faff;
  border-radius: 16px;
  padding: 15.2px 15.2px 15.2px 11.2px;
  cursor: pointer;
`;

const MenuOptionsSelected = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 15.2px 15.2px 15.2px 11.2px;
  border-width: 2.8px;
  cursor: pointer;
`;

const Text2 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;
const SectionTitle1 = styled.div`
  color: #495275;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const HeaderText = styled.div`
  color: #0d1121;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  white-space: nowrap;
`;

const Icons1 = styled.img`
  width: 24px;
  height: 24px;
`;
const PublishingCardsprojectsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 52px 14px;
  border-width: 2px;
`;
const PublishingBody = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PublishingText1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const PublishingText2 = styled.div`
  width: 376px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  text-align: center;
`;
const MenuOptions = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  padding: 17px 17px 17px 13px;
`;
const Icons = styled.img`
  width: 24px;
  height: 24px;
`;
const ScrollviewRootRoot = styled.div`
  height: 100vh;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f9faff;
`;
const Header = styled.div`
  width: 97%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 23px;
  padding-bottom: 23px;
  border-bottom: 1px solid #eaeeff;
  margin-bottom: 30px;
`;
const HeaderContainer = styled.div`
  padding-top: 50px;
  width: 97%;
  display: flex;
  flex-direction: column;
  justify-content: center;

`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
`;
const Board1 = styled.div`
  color: #485175;
  font-size: 14px;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: pre-wrap;
`;
const FlexColumn = styled.div`
  height: 100%;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 0px 15px;
`;

// export for common js all the variables

export {
    FlexColumn,
    FlexRow,
    Board1,
    Header,
    HeaderContainer,
    ScrollviewRootRoot,
    Icons,
    MenuOptions,
    MenuOptions3,
    MenuOptionsSelected,
    PublishingText2,
    PublishingText1,
    PublishingBody,
    PublishingCardsprojectsRootRoot,
    Icons1,
    HeaderText,
    SectionTitle1,
    Text2,
    DeleteLastWeek1,
    DeleteLastWeek,
    DeleteFlexColumn,
    DeleteDivider,
    DeleteParagraph,
    DeleteText1,
    DeleteModalsRootRoot,
    DeleteBody
};
import React, { useState } from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";

export const ButtonsURLTemplatePersonalization = props => {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [uploadingImage, setUploadingImage] = useState(false)
  return (
    <TemplatePersWhiteFlexColumn1>
      {props.buttons.map((btn, i) =>
      (
        <div key={i}>
          <Button6 onClick={() => props.removeButton(i)}>
            <FlexColumn4>
              <FlexRow10>
                <Text11>Button {i + 1}</Text11>
                {!props.condition &&
                  <RemoveButton>
                    <Icons
                      src={`https://file.rendit.io/n/4kxb1m9fT0cH7qneMzSf.svg`}
                    />
                  </RemoveButton>
                }
              </FlexRow10>
            </FlexColumn4>
          </Button6>
          <TemplatePersFlexRow3>
            <TemplatePersScreen3>
              <div>
                <TemplatePersText4>Upload Button Image</TemplatePersText4>
                <TemplatePersFlexRow7>

                  {uploadingImage
                    ?
                    "Uploading..."
                    :
                    <FileUploader classes="files" style={{ border: '0px' }} handleChange={async (e) => {
                      setUploadingImage(true);
                      await props.onButtonImageChange(e, i)
                      setTimeout(() => {
                      }, 1000);
                      setUploadingImage(false);
                    }} name="file" types={fileTypes} />
                  }                </TemplatePersFlexRow7>
                <TemplatePersParagraph>
                  SVG, PNG, JPG (Max 8mb), Preferred resolution
                  {"  "}
                  100X100
                </TemplatePersParagraph>
              </div>
            </TemplatePersScreen3>
          </TemplatePersFlexRow3>
          <TemplatePersFlexRow4 style={{ flexDirection: "column" }}>
            <TemplatePersScreen3 style={{ flexDirection: "row" }}>
              <div>
                <TemplatePersText10 >
                  Button Title
                </TemplatePersText10>
                <TemplatePersTextbox5
                  value={props.btn?.title} onChange={e => {
                    let newArr = [...props.buttons]; // copying the old datas array
                    newArr[i].title = e.target.value; // replace e.target.value with whatever you want to change it to
                    props.setButtonsL(newArr); // ??
                  }}
                  placeholder="example Title">
                </TemplatePersTextbox5>
              </div>
              <div>
                <TemplatePersText10>
                  Button Subtile
                </TemplatePersText10>
                <TemplatePersTextbox5
                  value={props.btn?.subtitle} onChange={e => {
                    let newArr = [...props.buttons]; // copying the old datas array
                    newArr[i].subtitle = e.target.value; // replace e.target.value with whatever you want to change it to
                    props.setButtonsL(newArr); // ??
                  }}
                  placeholder="example">
                </TemplatePersTextbox5>
              </div>
            </TemplatePersScreen3>

            <ColumnURL>
              <TemplatePersText10>
                Button URL
              </TemplatePersText10>
              <TemplatePersTextbox6
                value={props.btn?.url} onChange={e => {
                  let newArr = [...props.buttons]; // copying the old datas array
                  newArr[i].url = e.target.value; // replace e.target.value with whatever you want to change it to
                  props.setButtonsL(newArr); // ??
                }}
                placeholder="example">
              </TemplatePersTextbox6>
            </ColumnURL>
          </TemplatePersFlexRow4>
        </div>
      )
      )}
      {!props.condition &&
        <FlexRow4>
          <ButtonURL4 onClick={() => props.addButton()}>
            <FlexRow2>
              <RemoveButton>
                <Icons2 src={`undefined`} />
              </RemoveButton>
              <Text4>Add New Button</Text4>
            </FlexRow2>
            <Text22>you can add upto 4 buttons</Text22>
          </ButtonURL4>
        </FlexRow4>
      }
    </TemplatePersWhiteFlexColumn1>
  );
};

const FlexColumn4 = styled.div`
  width: 371px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 15px 7px 15px;
`;
const ColumnURL = styled.div`
  width: 100%;
`;
const FlexRow10 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
`;
const Text11 = styled.div`
  color: #485175;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;
const Icons = styled.img`
  width: 16px;
  height: 16px;
`;
const Button6 = styled.button`
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    opacity: 70%;
  }
`;
const Text4 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 14px;
  white-space: nowrap;
`;
const Text22 = styled.div`
  color: #858caa;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  white-space: nowrap;
`;
const FlexRow2 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const Icons2 = styled.img`
  width: 0;
  height: 0;
`;
const RemoveButton = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f6ff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 2px;
`;
const ButtonURL4 = styled.button`
  width: 369px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    opacity: 70%;
  }
`;
const FlexRow4 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 7px 15px;
`;
const TemplatePersText4 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
const TemplatePersFlexRow3 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 15px 15px 7px 15px;
`;
const TemplatePersFlexRow4 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 7px 15px;
`;
const TemplatePersScreen3 = styled.div`
  width: 369px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersFlexRow7 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersText10 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
`;
const TemplatePersWhiteFlexColumn1 = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  background-color: #eaeeff;
  border-radius: 20px;
`;
const TemplatePersParagraph = styled.div`
  color: #858caa;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  white-space: pre-wrap;
`;
const TemplatePersTextbox5 = styled.input`
  font-size: 12px;
  width: 160px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const TemplatePersTextbox6 = styled.input`
  font-size: 12px;
  width: 100%;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
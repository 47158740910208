/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import Modal from "react-modal";
import './createOrganizationStep2.css'
import './createOrganizationStep3.css'
import axios from "axios";
import { KNOWLEDGE_BASE_API_URL } from "../../globalVariables";
import { KNOWLEDGE_BASE_LABELS } from "../../globalConstants";
import { Dropdown } from "../../components/buttons/buttons.js";

// import styled 
import {
    PublishingCardsprojectsRootRoot,
    PublishingBody,
    PublishingText1,
    PublishingText2,
    RecommendedText,
    Text2,
    Body,
    Paragraph,
    FlexColumn,
    FlexColumnLittle,
    FlexRow3,
    Textbox,
    CardssetupWorkspaceRootRoot,
    FlexRow,
    ContentTypButton,
    ContentTypeTitle,
    ContentTypeDescription,
    FlexRow1,
    Icons,
    Text4,
    Text5,
    WhiteFlexRowNoCenter,
    UploadFileDiv,
    Text6,
    FeedBackText,
    Divider,
    LastWeek,
    LastWeekContain
} from "./createOrganizationStep3-styled.js";

import {
    BoldText,
    ActionButton,
    IconSmall
} from "./styled-modal.js"
//
// TITLE: Add Knowledge Base
//
// DESCRIPTION: add data to train your AI. You can add a website link or upload text files (pdf, txt, pptx).
//
// FEATURES: ENTER URL/ UPLOAD FILE/ GOOGLE DRIVE
//
//
export const CardsSetupOrganization3 = props => {
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.knowledgeBaseIDs]);

    const [contentTypeToUpload, setContentTypeToUpload] = useState("url");

    // For existing KB selection
    const [selectExistingKbModalOpen, setSelectExistingKbModalOpen] = useState(false);

    // For contentTypeToUpload = "url" and user hit's SCAN
    // opens when a user hits a SCAN button fn showFetchingLinks
    // closes from fn closeFetchingLinksModal, modal log in fn fetchingLinksPopUp
    const [fetchingLinksModalIsOpen, setfetchingLinksModalIsOpen] = useState(false);
    const [linkFetchingState, setLinkFetchingState] = useState(false);

    // For training 
    const [trainingModalIsOpen, setTrainingModalIsOpen] = useState(false);
    const [trainingState, setTrainingState] = useState(false);
    const [trainTitle, setTrainTitle] = useState("");

    // For UI and backend information
    const [tag, setTag] = useState("");
    const [numberOfPagesToScan, setNumberOfPagesToScan] = useState(10);

    const fileTypes = ["PDF", "TXT"]; // TODO: add support for PPTX
    const handleChange = async (file) => {
        if (!file) return;

        setTrainTitle(`File: ${file.name}`);
        if (file.type === "application/pdf") {
            // Read the content of the PDF file
            const reader = new FileReader();
            reader.onload = async (event) => {
                const content = event.target.result;
                const pdff = new Pdf2TextClass();

                showTrainView();
                pdff.pdfToText(content, null, (text) => {
                    let textContent = text.replaceAll(/[\t\n\s]{2,}/g, " ");
                    // Replace multiple tabs etc
                    createKnowledgeBaseFromTextFile(content, file.name, textContent, "pdf")
                });

            };
            reader.onerror = (error) => {
                hideTrainView();
                addFeedback("Error extracting text from PDF File", "file-feedback-text",
                    3000, "red");
            };
            reader.readAsDataURL(file);
        } else if (file.type === "text/plain") {

            // Assume the file is a text file
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = event.target.result;
                showTrainView();
                // Decode the file content as a UTF-8 string
                const text = new TextDecoder("utf-8").decode(content);
                createKnowledgeBaseFromTextFile(content, file.name, text, "txt")

            };
            reader.onerror = (error) => {
                hideTrainView();
                addFeedback("Error extracting text from TXT File", "file-feedback-text",
                    3000, "red");
            };
            reader.readAsArrayBuffer(file);
        } else {
            addFeedback("File must be a PDF or a TXT file", "file-feedback-text",
                3000, "red");
            return;
        }
    };

    // Called  when a user hits a SCAN button fn
    async function showFetchingLinks(url) {
        setfetchingLinksModalIsOpen(true);
        let kb = await scrapeUrlAndGetSitemapUrls(url);
        kb.url = url
        kb.allLinks = kb.allLinks.slice(0, 30) // only 30 in all links
        console.log('%ccrawlAndScrapeUrl:', 'background: yellow', ' knowledgeBaseObj: ', kb)
        setLinkFetchingState(kb);
    }

    function showTrainView() {
        setTrainingModalIsOpen(true);
        setTrainingState(true);
    }

    function hideTrainView() {
        setTrainingModalIsOpen(false);
        setTrainingState(false);
    }

    function onContentTypeToUploadClick(type, elementId) {
        setContentTypeToUpload(type);
        const clickedId = "content-type-clicked"
        const previouslyClicked = document.querySelectorAll(`.${clickedId}`);
        if (previouslyClicked && previouslyClicked.length) {
            for (const e of previouslyClicked) {
                e.style.borderColor = "rgba(0, 0, 0, 0.2)";
                e.style.backgroundColor = "rgba(0, 0, 0, 0.01)";
                e.classList.remove(clickedId);
            }
        }
        const targetElement = document.getElementById(elementId);

        if (targetElement) {
            targetElement.style.borderColor = "#745DDE";
            targetElement.style.backgroundColor = "#F0F5FF";
            targetElement.classList.add(clickedId);
        }

    }

    const createKnowledgeBaseFromTextFile = async (file, fileName, textContent, fileType) => {
        // Get file and upload
        const fileExists = props.knowledgeBaseIDs.some(obj =>
            obj.fileName === fileName)
        if (fileExists) {
            hideTrainView();
            addFeedback("File already added", "file-feedback-text",
                3000, "green");
            return;
        }
        // Find the text content in the link. If the text content
        // was found, add, then clear, if not, show error
        const knowledgeBaseID = await props.createFileKnowledgeBase(file, fileName, textContent, fileType);

        if (!knowledgeBaseID) {
            hideTrainView();
            addFeedback("Error creating knowledge base from file", "file-feedback-text",
                3000, "red");
            return;
        }
        // Knowledge base was created with ID
        // If the same knowledgebase ID exists in the state
        const knowledgeBaseExists = props.knowledgeBaseIDs.some(obj =>
            obj.id === knowledgeBaseID)
        if (knowledgeBaseExists) {
            hideTrainView();
            addFeedback("Knowledge base already uploaded", "file-feedback-text",
                3000, "green");
            return;
        }
        // Add to knowledge base IDs, {id: "String", url: "String"}
        const kbObject = {
            id: knowledgeBaseID,
            fileName: fileName,
            title: fileName,
        };
        hideTrainView();
        // setKnowledgeBases([...knowledgeBases, kbObject]);
        props.addKbToState(kbObject);
        addFeedback("File added successfully!!", "file-feedback-text",
            3000, "green");
    }

    const scrapeUrlAndGetSitemapUrls = async (URL) => {
        console.log('%cscrapeUrlAndGetSitemapUrls:', 'background: yellow', ' START');
        // Find the text content in the link. If the text content
        // was found, add, then clear, if not, show error
        const requestURL = `${KNOWLEDGE_BASE_API_URL}/knowledge-base/scrapeUrlAndGetSitemapUrls`;
        const requestBody = {
            url: URL,
            source: "url",
            fileContent: "n/a",
            fileType: "n/a",
            uid: props.uid,
            title: URL,
            organizationId: props.organizationId,
            numberOfPagesToScan: numberOfPagesToScan
        };
        const knowledgeBaseObj = await axios
            .post(requestURL, requestBody)
            .then(async (response) => {
                if (response.data.success) { return response.data.sitemapUrls; } return undefined;
            })
            .catch((error) => { console.error(error); return undefined; });
        if (!knowledgeBaseObj) {
            addFeedback("Error creating knowledge base from website", "url-feedback-text", 3000, "red");
        }
        else { addFeedback("Website scanned successfully!!", "url-feedback-text", 3000, "green"); }
        props.clearCurrentURLValue();
        return knowledgeBaseObj
    }

    const uploadFromScrapedUrlAndSitemap = async (event) => {
        let knowledgeBase = linkFetchingState;
        closeFetchingLinksModal()
        showTrainView()
        console.log('%cuploadFromScrapedUrlAndSitemap:', 'background: green', ' START')

        // Check if URL already in DB
        let title = knowledgeBase.url;
        const urlExists = props.existingKbs.some(obj => obj.url === title)
        console.log('!!!!!!!!!!!!!!!!!!!!!!!! START uploadFromScrapedUrlAndSitemap',
            title, props.existingKbs, props.existingKbs.map(obj => obj.url), props.existingKbs.map(obj => obj.title))
        if (urlExists) {
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!URL ALREADY EXISTS!!!!!!!!!!!!!!!!!!!!!!!!')
            title = title + '___' + knowledgeBase.linksText.length + '___' + Math.floor(Date.now() / 1000)
            // props.clearCurrentURLValue();
            // hideTrainView();
            // addFeedback("Website already added", "url-feedback-text", 3000, "green");
            // return;
        }

        const requestURL = `${KNOWLEDGE_BASE_API_URL}/knowledge-base/knowledge-base`;
        const requestBody = {
            url: knowledgeBase.url,
            source: "url",
            fileContent: "n/a",
            fileType: "n/a",
            uid: props.user.uid,
            title: title,
            organizationId: props.organizationId,
            scrapedKb: knowledgeBase,
            tag: tag
        }

        console.log('requestBody', requestBody)

        const knowledgeBaseID = await axios.post(requestURL, requestBody)
            .then(async (response) => {
                if (response.data.success) { return response.data.id; } return undefined;
            })
            .catch((error) => { console.error(error); return undefined; });

        console.log('%ccreateKnowledgeBaseFromURL:', 'background: green', ' REQUESTING knowledgeBaseID FROM: ', requestURL, ' WITH ORG_ID: ', props.organizationId)

        // In a panel show more links to scrape taken from the url and it's sitemap.
        console.log('%ccreateKnowledgeBaseFromURL:', 'background: green', ' REQUEST GOT BACK knowledgeBaseID: ', knowledgeBaseID)

        if (!knowledgeBaseID) {
            hideTrainView();
            addFeedback("Error creating knowledge base from website", "url-feedback-text", 3000, "red");
            return;
        }

        // Knowledge base was created with ID
        // If the same knowledgebase ID exists in the state
        const knowledgeBaseExists = props.knowledgeBaseIDs.some(obj =>
            obj.id === knowledgeBaseID)
        if (knowledgeBaseExists) {
            props.clearCurrentURLValue();
            hideTrainView();
            addFeedback("Knowledge base already uploaded", "url-feedback-text",
                3000, "green");
            return;
        }
        // Add to knowledge base IDs, {id: "String", url: "String"}
        const kbObject = {
            id: knowledgeBaseID,
            url: knowledgeBase.url,
            title: title,
        };
        hideTrainView();
        // setKnowledgeBases([...knowledgeBases, kbObject]);
        props.addKbToState(kbObject);
        addFeedback("Website scanned successfully!!", "url-feedback-text",
            3000, "green");
        props.clearCurrentURLValue();
    }

    const addFeedback = (text, idSelector, duration, color) => {
        const element = document.getElementById(idSelector);
        element.innerHTML = text;
        element.style.color = color;
        setTimeout(() => {
            // Set back to what it was
            element.style.color = "transparent";
            element.innerHTML = "";
        }, duration);
    }

    function trainingPopUp(title) {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Training AI with</PublishingText1>
                    <PublishingText2>{title}</PublishingText2>
                    <PublishingText2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <InfinitySpin
                                color="#0E1221"
                            />
                        </div>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        )
    }
    function removeAllFromScrapedKb(index) {
        let t = { ...linkFetchingState }
        t.linksText = []
        console.log('removeAllFromScrapedKb', t)
        setLinkFetchingState(t);
    }
    function removeFromScrapedKb(index) {
        // Create a new object instead of modifying the existing one
        let t = { ...linkFetchingState };
        t.linksText.splice(index, 1); // modified in place 
        // Update the state with the new object
        setLinkFetchingState(t);
    }
    function getTitle(title) {
        title = title.split('___')[0];
        title = title.replace(/https:\/\//g, "");
        title = title.replace(/http:\/\//g, "");
        return (title).length > 68 ? (title).slice(0, 28) + "..." : title
    }
    function getNumLinksFromTitle(title) {
        const regex = /___(\d+)___/;
        let match = title.match(regex);
        if (!match || match[1] === "0") return false;
        return match[1]
    }
    // Called from Modal id="fetchingLinks-modal" when isOpen={fetchingLinksModalIsOpen}
    function fetchingLinksPopUp() {
        // displays after user clicks continue on the fetching links modal
        // console.log("FETCHING LINKS POPUP LOGIC RUNNING", {linkFetchingState})
        console.log({ linkFetchingState }, linkFetchingState.text)
        // {"success": false, "text": false, "reason": error.message, "allLinks": []}
        // If we couldn't get the text then closeFetchingLinksModal
        if (linkFetchingState && !linkFetchingState.text) {
            console.log('linkFetchingState Exists but text is false');
            addFeedback("Error extracting text from URL", "url-feedback-text", 3000, "red");
            closeFetchingLinksModal()
        }
        let emptyArray = linkFetchingState && (!Array.isArray(linkFetchingState.linksText) || linkFetchingState.linksText.length === 0)
        console.log('emptyArray', emptyArray)
        if (emptyArray) {
            uploadFromScrapedUrlAndSitemap()
        }
        return (
            <PublishingCardsprojectsRootRoot style={{ paddingBottom: '24px' }}>
                {linkFetchingState ?
                    <div>
                        <div style={{ margin: '0px 10px', display: 'block' }}>
                            <FlexColumn style={{ marginTop: '-20px', marginBottom: '5px' }}>
                                {// Website scanned successfully!!, close model. 
                                }
                                <FlexRow1>
                                    <BoldText>
                                        Website Scanned Successfully
                                    </BoldText>
                                    <Text2 onClick={closeFetchingLinksModal} style={{ float: "right" }}>
                                        <Icons src={`https://i.imgur.com/RWLar5E.png`} /> <span>&nbsp;</span>
                                    </Text2>
                                </FlexRow1>
                                {// Instructions
                                }
                                <FlexRow1 style={{ marginTop: '-14px' }}>
                                    <Text5 style={{ marginBottom: '15px' }}>
                                        Please choose the pages on you website you want to include
                                    </Text5>
                                </FlexRow1>
                                {// Panel
                                }
                                <FlexColumn style={{ background: '#F5F7FF', borderRadius: '16px', border: '#EBEFFF 2px solid', padding: '25px' }}>
                                    {// Panel Header
                                    }
                                    <FlexRow style={{ padding: '0px 0px' }}>
                                        <BoldText style={{
                                            fontSize: "13px"
                                        }}>Included Pages</BoldText>
                                        {<button onClick={removeAllFromScrapedKb} style={{ color: 'red', borderRadius: '5px', paddingRight: '32px', fontSize: "13px" }}>Remove All</button>}
                                    </FlexRow>
                                    {// Panel Options Interative list of links
                                    }
                                    <div style={{
                                        padding: '24px', borderRadius: '8px', border: '2px #FAFBFF', gap: '24px', borderRadius: '12px',
                                        background: '#FAFBFF', boxShadow: '0px 0px 40px 0px #0000000D'
                                    }}>
                                        <FlexRow style={{ display: 'flex', margin: '0px 10px', paddingBottom: "10px" }}>
                                            <div style={{ flex: 5, fontSize: "12px" }}>Pages</div>
                                            <div style={{ flex: 2, fontSize: "12px" }}>Character Count</div>
                                            <div style={{ flex: 1, fontSize: "12px", color: 'rgba(1,1,1,0)' }}>Remove</div>
                                        </FlexRow>
                                        <hr style={{ borderTop: '0.2px solid rgba(0, 0, 0, 0.3)' }}></hr>
                                        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '300px', overflow: 'auto', borderRadius: '15px', }}>
                                            { // Map through the linksText array and display each link
                                                linkFetchingState.linksText.map((link, index) => (<div>
                                                    <FlexRow style={{ margin: '0px 10px', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px', gap: "40px" }} key={index}>
                                                        <FlexRow style={{ flex: 2, alignItems: 'flex-start', flexDirection: 'column' }}>
                                                            <Text6 style={{
                                                                flex: 1,
                                                                overflow: 'hidden',
                                                                padding: '6px',
                                                                borderRadius: '8px',
                                                                background: '#DBE9FF',
                                                                color: '#495275',
                                                                // fontWeight: 600,
                                                                marginRight: '10px',  // Space between textbox and character count
                                                                cursor: "pointer"
                                                            }} onClick={() => window.open(`https://${getTitle(link.link)}`, "_blank")} title={getTitle(link.link)}>{getTitle(link.link).length > 50 ? `${getTitle(link.link).substring(0, 50)}...` : getTitle(link.link)}
                                                            </Text6>
                                                            <div style={{ width: '100%' }}></div>
                                                        </FlexRow>
                                                        <Text6 style={{ fontSize: '11px', color: '#495275', marginRight: '12px' }}> {link.text.length} Characters </Text6>
                                                        <FlexRow style={{ margin: '0px 10px' }}>
                                                            { // <img style={{ color: 'red', width: '14px', height: '14px', marginTop:'12px',marginRight: '6px' }} src={`https://i.imgur.com/nSjYcVD.png`} alt="icon" />
                                                            }
                                                            <button className="horizontal-flex" onClick={() => { removeFromScrapedKb(index) }} style={{ fontSize: '12px', color: '#495275' }}>
                                                                <IconSmall src={`https://i.imgur.com/nSjYcVD.png`} />{" "} &nbsp;Remove</button>
                                                        </FlexRow>
                                                    </FlexRow>
                                                    <hr style={{ borderTop: '0.2px solid rgba(0, 0, 0, 0.3)' }}></hr>

                                                </div>
                                                ))}
                                        </div>
                                    </div>
                                </FlexColumn>
                            </FlexColumn>
                            <FlexRow style={{ paddingTop: '10px', justifyContent: 'flex-end' }}>
                                {/*<div> 
                <ActionButton  onClick={closeFetchingLinksModal} style={{marginLeft:'15px', backgroundColor:'gray'}}>Exit</ActionButton>
            </div>
            */}
                                <div style={{ paddingLeft: '140px' }}>
                                    <ActionButton onClick={uploadFromScrapedUrlAndSitemap} style={{ paddingLeft: '45px', paddingRight: '45px' }}>Continue</ActionButton>
                                </div>
                            </FlexRow>
                        </div>
                    </div>
                    :
                    <div>
                        <PublishingBody>
                            <PublishingText1>Fetching Links</PublishingText1>
                            <PublishingText2>{ }</PublishingText2>
                            <PublishingText2>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <InfinitySpin
                                        color="#0E1221"
                                    />
                                </div>
                            </PublishingText2>
                        </PublishingBody>
                    </div>
                }
            </PublishingCardsprojectsRootRoot>
        )
    }

    function selectFromExistingKbModal(props) {
        return (
            <PublishingCardsprojectsRootRoot>
                <div style={{
                    width: "700px",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    // alignItems: "center",
                }}>

                    <Text4>Select from your existing knowledge bases</Text4>
                    <Paragraph>Choose an item from your previously uploaded files to train your AI</Paragraph>
                    <br />
                    <WhiteFlexRowNoCenter>
                        <div className="overflow-thumb-hidden" style={{
                            height: "300px",
                            paddingLeft: "10px",
                            display: "flex",
                            flexDirection: "column"
                        }}>

                            {props.selectableExistingKBs && props.existingKbs.map((item, index) => (
                                <label key={index}>
                                    <input type="checkbox" value={item.title}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                // When checked, add item to knowledge base
                                                props.addKbToState(item);
                                            } else {
                                                // When unchecked, remove item from selectedItems array
                                                props.removeItemFromKnowledgeBaseIDs(item.id);
                                            }
                                        }}
                                    />
                                    &nbsp;&nbsp;&nbsp;{item.title}
                                </label>
                            ))}

                        </div>
                    </WhiteFlexRowNoCenter>

                </div>

                <Body>
                    <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
                    <div className="horizontal-flex flex-end w-full">
                        <LastWeek onClick={closeSelectExistingKbModal}>
                            Done
                        </LastWeek>
                    </div>

                </Body>
            </PublishingCardsprojectsRootRoot>)
    }

    function Pdf2TextClass() {
        var self = this;
        this.complete = 0;

        this.pdfToText = function (data, callbackPageDone, callbackAllDone) {
            console.assert(data instanceof ArrayBuffer || typeof data == 'string');
            // eslint-disable-next-line no-undef
            var loadingTask = pdfjsLib.getDocument(data);
            loadingTask.promise.then(function (pdf) {


                var total = pdf._pdfInfo.numPages;
                //callbackPageDone( 0, total );        
                var layers = {};
                for (let i = 1; i <= total; i++) {
                    pdf.getPage(i).then(function (page) {
                        var n = page.pageNumber;
                        page.getTextContent().then(function (textContent) {

                            //console.log(textContent.items[0]);0
                            if (null != textContent.items) {
                                var page_text = "";
                                var last_block = null;
                                for (var k = 0; k < textContent.items.length; k++) {
                                    var block = textContent.items[k];
                                    if (last_block != null && last_block.str[last_block.str.length - 1] != ' ') {
                                        if (block.x < last_block.x)
                                            page_text += "\r\n";
                                        else if (last_block.y != block.y && (last_block.str.match(/^(\s?[a-zA-Z])$|^(.+\s[a-zA-Z])$/) == null))
                                            page_text += ' ';
                                    }
                                    page_text += block.str;
                                    last_block = block;
                                }

                                textContent != null && console.log("page " + n + " finished."); //" content: \n" + page_text);
                                layers[n] = page_text + "\n\n";
                            }
                            ++self.complete;
                            //callbackPageDone( self.complete, total );
                            if (self.complete === total) {
                                window.setTimeout(function () {
                                    var full_text = "";
                                    var num_pages = Object.keys(layers).length;
                                    for (var j = 1; j <= num_pages; j++)
                                        full_text += layers[j];
                                    callbackAllDone(full_text);
                                }, 1000);
                            }
                        }); // end  of page.getTextContent().then
                    }); // end of page.then
                } // of for
            });
        }; // end of pdfToText()
    }; // end 
    const closeModal = () => {
        setTrainingModalIsOpen(false);
    }
    const closeFetchingLinksModal = () => {
        setfetchingLinksModalIsOpen(false);
        setLinkFetchingState(false);
    }
    const openSelectExistingKbModal = () => {
        setSelectExistingKbModalOpen(true);
    }
    const closeSelectExistingKbModal = () => {
        setSelectExistingKbModalOpen(false);
    }

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            border: "1px solid transparent",
        },
    };

    /**
     * #9E8FEF
     * #F87462
     * #60C6D2
     * #579CFF
     */

    return (
        <CardssetupWorkspaceRootRoot>
            <FlexRow>
                <FlexRow1>
                    <Text2 onClick={props.function1}>
                        {/* <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span> */}
                    </Text2>

                    <Text2 onClick={props.closeView}>
                        <Icons src={`https://i.imgur.com/RWLar5E.png`} /> <span>&nbsp;</span>
                    </Text2>
                    {/* <Paragraph1>
                        Step {props.step}/{props.totalSteps}
                    </Paragraph1> */}
                </FlexRow1>
                {/* <FlexRow2>
          {props.info.map((item, index) => {
            item.html === "text3" ?
              (
                <Text3>{item.text}</Text3>
              ) : (
                <Text1>{item.text}</Text1>
              )
            index < props.info.length - 1 ?
              <Line src={`https://file.rendit.io/n/ciQViPxUYf694yKxEyXY.svg`} />
              :
              <></>
          })}

        </FlexRow2> */}
            </FlexRow>
            <Body>
                <Text4>{props.text1}</Text4>
                <Paragraph>{props.text2}</Paragraph>

                <div className="hide-scrollbar" style={{
                    borderRadius: "15px",
                    paddingBottom: "20px",
                    paddingLeft: "5px",
                    paddingTop: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    overflowX: "scroll",
                    maxWidth: "700px",
                }}>
                    {/* <FlexRow>
                        <SubTitle>
                            Recommended Knowledge Base items
                        </SubTitle>
                    </FlexRow> */}

                    <FlexRow>
                        <RecommendedText>
                            Internal Training Materials
                        </RecommendedText>

                        <RecommendedText>
                            FAQs
                        </RecommendedText>

                        <RecommendedText>
                            Product Descriptions
                        </RecommendedText>

                        <RecommendedText>
                            Product Pricing
                        </RecommendedText>

                        <RecommendedText>
                            Return Policies
                        </RecommendedText>

                        <RecommendedText>
                            Customer Reviews
                        </RecommendedText>

                        <RecommendedText>
                            Case Studies
                        </RecommendedText>


                    </FlexRow>
                </div>

            </Body>


            <FlexColumnLittle>
                <Text5>Tag Knowledge Base:</Text5>
                <Dropdown chain={tag} setChain={setTag} options={KNOWLEDGE_BASE_LABELS} class="dropdown-full" ></Dropdown>
            </FlexColumnLittle>

            {/* Div contains type of knowledge items that can be added */}
            <FlexRow>
                <ContentTypButton id="click-url-button" style={{
                    borderColor: "#745DDE",
                    backgroundColor: "#F0F5FF",
                }} className="content-type-clicked" onClick={() => onContentTypeToUploadClick("url", "click-url-button")}>
                    <ContentTypeTitle>Enter URL</ContentTypeTitle>
                    <ContentTypeDescription>Addy will scan your site</ContentTypeDescription>
                </ContentTypButton>

                <ContentTypButton id="click-file-button" onClick={() => onContentTypeToUploadClick("file", "click-file-button")}>
                    <ContentTypeTitle>Upload File</ContentTypeTitle>
                    <ContentTypeDescription>Upload a .pdf or a .txt file</ContentTypeDescription>
                </ContentTypButton>

                <ContentTypButton id="click-gdrive-button" onClick={() => onContentTypeToUploadClick("gdrive", "click-gdrive-button")}>
                    <ContentTypeTitle>Google Drive</ContentTypeTitle>
                    <ContentTypeDescription>Connect your google drive</ContentTypeDescription>
                </ContentTypButton>
            </FlexRow>

            <div style={{ height: "10px", width: "100%" }}>

                {props.selectableExistingKBs && props.existingKbs && props.existingKbs.length > 0 &&
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "flex-end",
                    }}>

                        <p className="cursor-pointer" style={{
                            fontSize: "13px",
                            textDecoration: "underline"
                        }} onClick={openSelectExistingKbModal}>
                            Select from your previously uploaded files</p>

                    </div>}
            </div>

            {props.knowledgeBaseIDs && props.knowledgeBaseIDs.length > 0 && <FlexColumn>
                <div style={{
                    paddingBottom: "12px",
                    borderBottom: "0.5px solid rgba(0, 0, 0, 0.2)"
                }}>
                    {props.knowledgeBaseIDs.map((item, index) => {
                        console.log('props.knowledgeBaseIDs', props.knowledgeBaseIDs)
                        let numFiles = getNumLinksFromTitle(item.title)
                        return (
                            <div key={index} style={{
                                display: "flex",
                                width: "375px",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingTop: "5px",
                                paddingBottom: "5px"
                            }}>
                                <div>
                                    <Text4>{getTitle(item.title)} {numFiles && (`(Files: ${numFiles})`)}</Text4>
                                </div>

                                <div>
                                    <Text2 onClick={() => props.removeItemFromKnowledgeBaseIDs(item.id)}>
                                        <Icons src={`https://i.imgur.com/nSjYcVD.png`} /> <span>Delete</span>
                                    </Text2>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </FlexColumn>}


            <FlexColumn>
                {contentTypeToUpload === "url" &&
                    <>
                        <FlexColumnLittle>
                            <Text5>Maximum number of pages to scan:</Text5>
                            <Dropdown chain={numberOfPagesToScan} setChain={setNumberOfPagesToScan} options={[{ text: '1', value: 1 }, { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: '20', value: 20 }]} ></Dropdown>
                        </FlexColumnLittle>
                        <FlexColumnLittle>
                            <Text5>Paste URL:</Text5>
                            <FlexRow3>
                                <Textbox
                                    value={props.currentURLValue}
                                    onChange={props.onChange}
                                    placeholder={'https://example.com'}>
                                </Textbox>
                                <LastWeekContain disabled={!props.isCurrentURLValid}
                                    onClick={() => showFetchingLinks(props.currentURLValue)}>
                                    Scan
                                </LastWeekContain>
                            </FlexRow3>
                            <FeedBackText id="url-feedback-text">&nbsp;</FeedBackText>
                        </FlexColumnLittle>
                    </>
                }

                {contentTypeToUpload === "file" &&
                    <FlexColumnLittle>
                        {/* <Text5>Connect Google Drive:</Text5> */}
                        <UploadFileDiv>
                            <Text6>
                                <>
                                    <FileUploader classes="files" style={{ border: '1px', }} handleChange={handleChange} name="file" types={fileTypes} maxSize={5}
                                        onSizeError={() => {
                                            addFeedback("File size must be less than 5MB", "file-feedback-text",
                                                3000, "red");
                                        }} />
                                </>
                            </Text6>
                        </UploadFileDiv>
                        <FeedBackText id="file-feedback-text">&nbsp;</FeedBackText>
                    </FlexColumnLittle>}
                {contentTypeToUpload === "gdrive" &&
                    <FlexColumnLittle>
                        {/* <Text5>Click G:</Text5> */}

                        <p>Coming soon...</p>
                    </FlexColumnLittle>
                }
                {props.selectableExistingKBs && props.existingKbs && props.existingKbs.length > 0 &&
                    <Modal
                        id="training-modal"
                        isOpen={selectExistingKbModalOpen}
                        onRequestClose={closeSelectExistingKbModal}
                        style={modalStyle}
                        contentLabel="Select Existing Knowledge Base">
                        <div >
                            <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">
                                {selectFromExistingKbModal(props)}
                            </div>

                        </div>
                    </Modal>
                }
                <Body>
                    <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
                    <div className="horizontal-flex flex-end w-full">
                        <LastWeek onClick={props.createApplication}
                            disabled={!(props.knowledgeBaseIDs.length)}>
                            {props.buttonText}
                        </LastWeek>
                    </div>

                </Body>
            </FlexColumn>
            <Modal
                id="training-modal"
                isOpen={trainingModalIsOpen}
                onRequestClose={closeModal}
                style={modalStyle}
                contentLabel="Training">
                <div >
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">
                        {trainingState ? trainingPopUp(trainTitle) : null}
                    </div>

                </div>
            </Modal>
            <Modal
                isOpen={fetchingLinksModalIsOpen}
                onRequestClose={closeFetchingLinksModal}
                style={modalStyle}
                contentLabel="Fetching">
                <div style={{}} >
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">
                        {fetchingLinksPopUp()}
                    </div>

                </div>
            </Modal>
        </CardssetupWorkspaceRootRoot>
    );
};
import React from "react";
import styled from "styled-components";

import extensionIcon from "../../assets/images/extension_card_image.gif";
import { useNavigate } from "react-router-dom";

import {
  Text1,
  Text5,
  Wrapper,
  WhiteFlexRowNoCenter,
  CardsSetupWorkspaceRoot,
  Body,
  FlexRow,
  FlexRow1,
  Text2,
  Icons,
  Paragraph1,
  Text4,
  Paragraph,
  FlexColumn,
  FlexColumnLittle,
  Divider,
  LastWeek,
  SkipButton,
  ActionButton,
  ProfileImage
} from "./styles";

export const FinishOnboarding = (props) => {
  const getExtension = () => {
    window.open(
      "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
      "_blank"
    );
  };
  const navigate = useNavigate();
  return (
    <CardsSetupWorkspaceRoot> 
      <Body style={{borderRadius:'15px', background:'#E1EFFE', padding: '16px'}}>
        <Text1>Get the free browser extension!</Text1>
        <Paragraph style={{
            width: props.paragraphWidth ? props.paragraphWidth : "376px"
        }}>{props.subTitle ? props.subTitle : 'Our chrome extension brings the magic of AI customer service everywhere you interact with your customers.'}</Paragraph>
      </Body> 
      <FlexColumn style={{width: '100%'}}>
        <FlexCenterImg> 
          <Wrapper>
            <ProfileImage src={extensionIcon} /> 
          </Wrapper> 
        </FlexCenterImg>
        <Body>
          <Paragraph style={{textAlign: 'center'}}>Compose and reply to your customer emails in seconds.</Paragraph>
        </Body>
        <Body>
          <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
          <FlexRow> 
            {props.disableMaybeButton ? null : <SkipButton onClick={props.fwdBtn}>Maybe Later</SkipButton>}
            <ActionButton onClick={getExtension}>Add to Chrome</ActionButton> 
          </FlexRow> 
        </Body>
      </FlexColumn>
    </CardsSetupWorkspaceRoot>
  );
};

const FlexCenterImg = styled.div`
  width:100%;
  gap: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;
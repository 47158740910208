import styled from "styled-components";
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
`;
const FlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
const Icons = styled.img`
  width: 14px;
  height: 14px;
`;
const Text4 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
// const Text1 = styled.div`
//   color: #485175;
//   font-size: 10px;
//   font-weight: 500;
//   font-family: Poppins;
//   line-height: 14px;
//   white-space: nowrap;
// `;
// const Text3 = styled.div`
//   color: #c9d1ef;
//   font-size: 10px;
//   font-weight: 500;
//   font-family: Poppins;
//   line-height: 14px;
//   white-space: nowrap;
// `;
// const FlexRow2 = styled.div`
//   gap: 4px;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
//   border-style: solid;
//   border-color: #eaeeff;
//   border-radius: 8px;
//   padding: 9.2px 15.2px;
//   border-width: 0.8px;
// `;
// const Line = styled.img`
//   width: 16px;
//   height: 1px;
// `;

//

const PublishingCardsprojectsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 52px 14px;
  border-width: 2px;
`;
const PublishingBody = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PublishingText1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const PublishingText2 = styled.div`
  width: 500px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  text-align: center;
`;
const Paragraph1 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;

const SubTitle = styled.div`
  color: #485175;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;

const RecommendedText = styled.div`
  color: #485175;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 15px;
  white-space: nowrap;
  border: 0.5px solid rgba(0, 0, 0, 0.3)

`;

const Text2 = styled.button`
  color: #485175;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
`;
const Body = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
const Paragraph = styled.div`
  width: 100%;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;

const ItemTitleText = styled.div`
  width: 300px;
  color: #485175;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 20px;
`;

const FlexColumnLittle = styled.div`
  gap: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const FlexRow3 = styled.div`
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Textbox = styled.input`
  width: 70%;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 11px 15.2px;
  border-width: 2px;
`;
const CardssetupWorkspaceRootRoot = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
  min-width: fit-content;
  max-height: 80vh;
  overflow-y: auto;
`;
const ContentTypButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    gap: 2px;
    background-color: rgba(0, 0, 0, 0.01);
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 1px;
    margin-right: 30px;
    width: 220px;
    height: 150px;
    cursor: pointer;
`
const ContentTypeTitle = styled.p`
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    margin: 0px;
    font-family: Poppins;
    letter-spacing: 0.1px;
`;

const ContentTypeDescription = styled.p`
    font-size: 12px;
    color: #485175;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    line-height: 20px;
`

const FlexRow1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const WhiteText = styled.div`
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #858caa;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #eaeeff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const Text5 = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
const WhiteFlexRow = styled.div`
  width: 374px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-color: #d8e0ff;
  background-color: #eaeeff;
  overflow: hidden;
  border-radius: 16px;
  padding: 34px 0px 33px 0px;
  border-width: 1px;
`;

const WhiteFlexRowNoCenter = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  border-color: #d8e0ff;
  background-color: #eaeeff;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px;
  padding: 10px 5px 10px 5px;
  border-width: 1px;
`;

const UploadFileDiv = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-color: #d8e0ff;
//   background-color: #eaeeff;
  overflow: hidden;
  border-radius: 16px;
  border-width: 2px;
`

const Text6 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  text-align: center;
`;
const FeedBackText = styled.div`
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 10px;
`;
const Divider = styled.img`
  width: 376px;
  height: 1px;
`;
const LastWeek = styled.button`
  width: 200px;
  height: 14px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const LastWeekContain = styled.button`
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 7px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

// export everything
export {
  PublishingCardsprojectsRootRoot,
  PublishingBody,
  PublishingText1,
  PublishingText2,
  Paragraph1,
  SubTitle,
  RecommendedText,
  Text2,
  Body,
  Paragraph,
  ItemTitleText,
  FlexColumn,
  FlexColumnLittle,
  FlexRow3,
  Textbox,
  CardssetupWorkspaceRootRoot,
  FlexRow,
  ContentTypButton,
  ContentTypeTitle,
  ContentTypeDescription,
  FlexRow1,
  Icons,
  Text4,
  WhiteText,
  Text5,
  WhiteFlexRow,
  WhiteFlexRowNoCenter,
  UploadFileDiv,
  Text6,
  FeedBackText,
  Divider,
  LastWeek,
  LastWeekContain
};
import React, { useState } from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import { Dropdown } from '../buttons/buttons'

export const WalletTemplatePersonalization = props => {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [uploadingImage, setUploadingImage] = useState(false)
  const handleChange = async (file) => {
    setUploadingImage(true)
    await props.logoFunction(file);
    setUploadingImage(false)
  };
  return (
    <WalletCFrameRootRoot>
      <WalletCFlexRow2>
        <WalletCScreen>
          <WalletCText2>Text</WalletCText2>
          <WalletCTextbox
            value={props.text}
            onChange={e => props.setText(e.target.value)}
            placeholder={`99999`}>
          </WalletCTextbox>
        </WalletCScreen>
      </WalletCFlexRow2>
      <WalletCFlexRow2>
        <WalletCScreen>
          <WalletCText2>Logo Image</WalletCText2>
          {uploadingImage
            ?
            "Uploading..."
            :
            <FileUploader classes="files" style={{ border: '0px' }} handleChange={handleChange} name="file" types={fileTypes} />
          }
          <TemplatePersParagraph>
            SVG, PNG, JPG (Max 8mb), Preferred resolution
            {"  "}
            100X100
          </TemplatePersParagraph>
        </WalletCScreen>
      </WalletCFlexRow2>
      <WalletCFlexRow2>
        <WalletCScreen>
          <WalletCText2>Contract Address</WalletCText2>
          <WalletCTextbox
            value={props.contractAddress}
            onChange={e => props.setContractAddress(e.target.value)}
            placeholder={`0x000..`}>
          </WalletCTextbox>
        </WalletCScreen>
      </WalletCFlexRow2>
      <WalletCFlexRow2>
        <WalletCScreen>
          <WalletCText2>Number of Required Tokens</WalletCText2>
          <WalletCTextbox
            value={props.numberOfNeededTokens}
            onChange={e => props.setNumberOfNeededTokens(e.target.value)}
            placeholder={`99999`}>
          </WalletCTextbox>
        </WalletCScreen>
      </WalletCFlexRow2>
      <WalletCFlexRow2>
        <WalletCScreen>
          <WalletCText2>Blockchain</WalletCText2>
          <Dropdown chain={props.chain} setChain={props.setChain} options={[{ text: 'Ethereum', value: 'eth' }, { text: 'Polygon', value: 'pol' }, { text: 'Solana', value: 'sol' }]} ></Dropdown>
        </WalletCScreen>
      </WalletCFlexRow2>
    </WalletCFrameRootRoot>
  );
};


const TemplatePersParagraph = styled.div`
  color: #858caa;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  white-space: pre-wrap;
`;
const WalletCText2 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
const WalletCScreen = styled.div`
  width: 332px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const WalletCTextbox = styled.input`
  height: 26px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const WalletCFlexRow2 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 7px 15px;
`;
const WalletCFrameRootRoot = styled.div`
  width: 364px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #eaeeff;
  border-radius: 20px;
`;
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import OrganizationUtils from "../../services/organization/OrganizationUtils";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import axios from "axios";
import './overview.css'
import { KNOWLEDGE_BASE_API_URL, ORGANIZATION_API_URL } from "../../globalVariables";
import { CardsSetupOrganization3 } from "./createOrganizationStep3";


export const OrganizationKnowledgeBase = (props) => {
    const { organizationId } = useParams();

    // const [user, setUser] = useState(undefined); // User data object
    const user = props.user;
    const [addKbModalIsOpen, setAddKbModalIsOpen] = React.useState(false);
    const [currentURL, setCurrentURL] = useState("");
    const [validURL, setValidURL] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [knowledgeBases, setKnowledgeBases] = useState([]); // Array of objects
    const [knowledgeBaseIDs, setKnowledgeBaseIDs] = useState([]);


    useEffect(() => {

        getKnowledgeBasesForThisBusiness(organizationId, user.uid, user.email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);




    function showHTTPFeedbackToast() {
        document.getElementById("toast-http-feedback").style.setProperty("display", "block", "important");

        setTimeout(() => {
            document.getElementById("toast-http-feedback").style.setProperty("display", "none", "important");
        }, 5000);
    }

    function isValidUrl(str) {
        const pattern = /^https?:\/\/[\w.-]+\.[a-z]{2,}(\/[\w.-]+)*$/i;
        return pattern.test(str) && str.length >= 7;
    }

    const clearCurrentURLValue = () => {
        setCurrentURL("");
        setValidURL(false);
    };


    const closeAddKbModal = () => {
        setAddKbModalIsOpen(false);
    };

    function openAddKbModal() {
        setAddKbModalIsOpen(true);
    }

    async function hideRenderAddNewKnowledgeBase() {
        closeAddKbModal();
    }

    const handleURLTextChange = event => {
        const websiteURL = event.target.value;

        // Remove trailing /    
        const websiteURLNoTrail = websiteURL.endsWith('/') ? websiteURL.slice(0, -1) : websiteURL;

        setCurrentURL(websiteURL);
        // set if URL is valid
        if ((websiteURL).length > 7 && !((websiteURL).slice(0, 8).includes("http"))) {
            showHTTPFeedbackToast();
        }
        setValidURL(isValidUrl(websiteURLNoTrail));
    };

    const addKnowledgeBaseID = (kbObject) => {
        // KbObject coontains the kb id, and url identifier
        setKnowledgeBaseIDs([...knowledgeBaseIDs, kbObject]);
    };

    const removeItemFromKnowledgeBaseIDs = (itemID) => {
        // Match the item ID and remove it from knowledgebase ids
        setKnowledgeBaseIDs(knowledgeBaseIDs.filter((i) => i.id !== itemID));
    };

    async function createFileKnowledgeBase(file, fileName, textContent, fileType) {
        // PDF files are read as base64 so we can upload them to firebase
        const fileURL =
            fileType === "txt"
                ? "n/a"
                : await uploadBase64ToFirebaseAndGetImageURL(file, `userData/${user.uid}/files/${uuidv4()}`);
        const requestURL = `${KNOWLEDGE_BASE_API_URL}/knowledge-base/knowledge-base`;
        const requestBody = {
            url: fileURL,
            source: "file",
            fileContent: textContent, // string content in file
            uid: user.uid,
            fileType: fileType,
            title: fileName,
            organizationId: organizationId,
            scrapedKb: "n/a",
        };

        const createKb = await axios
            .post(requestURL, requestBody)
            .then(async (response) => {
                if (response.data.success) {
                    return response.data.id;
                }
                // no successful
                return undefined;
            })
            .catch((error) => {
                console.error(error);
                return undefined;
            });
        return createKb;
    }

    async function uploadBase64ToFirebaseAndGetImageURL(base64, uploadPath) {
        const storage = getStorage();
        const storageRef = ref(storage, uploadPath);
        const getImageDownloadURL = await uploadString(storageRef, base64, "data_url")
            .then((snapshot) => {
                return getDownloadURL(snapshot.ref);
            })
            .then((downloadURL) => {
                return downloadURL;
            })
            .catch((error) => {
                return "";
            });
        return getImageDownloadURL;
    }

    async function getKnowledgeBasesForThisBusiness(organizaitonId, userId, userEmail) {
        // return;
        OrganizationUtils.callGetKnowledgeBasesForOrg(organizaitonId, userId, userEmail)
            .then(async (response) => {
                if (response) {
                    setKnowledgeBases(response);
                    return;
                } else {
                    // No response
                    setKnowledgeBases([]);
                }

            })
            .catch((error) => {
                setKnowledgeBases([]);
                showErrorToast("Error: Failed to get Knowledge Bases", 6000);
            });
    }

    async function updateKnowledgeBase() {


        closeAddKbModal(); // Close modal
        // TODO: Smarter check for duplicates
        showSuccessToast("Updated successfully!!", 6000);

        getKnowledgeBasesForThisBusiness(organizationId, user.uid, user.email);
        setKnowledgeBaseIDs([])

        return;

    }

    async function updatedKnowledgeBaseField(orgId, knowledgeBaseId, userID, value) {
        setLoadingUpdate(true);

        const currentKnowledgeBases = knowledgeBases;
        const objIndex = currentKnowledgeBases.findIndex(obj => obj.id == knowledgeBaseId);
        currentKnowledgeBases[objIndex].autoUpdate = value.toString();
        await setKnowledgeBases(currentKnowledgeBases)

        const updateButtonElementID = `kb-aut-update-${knowledgeBaseId}`;
        const updateButtonElement = document.getElementById(updateButtonElementID);
        updateButtonElement.checked = value;

        OrganizationUtils.callUpdateKnowledgeBaseFields(orgId, knowledgeBaseId, userID, currentKnowledgeBases[objIndex])
            .then(async (response) => {
                if (response) {

                    showSuccessToast("Successfully updated!!", 5000);
                    setLoadingUpdate(false);
                }
            })
            .catch((error) => {
                showErrorToast("Error: Failed to update source", 6000);
                setLoadingUpdate(false);
            });


        return;
    }

    async function updateKnowledgeBaseCheck(orgId, knowledgeBaseId, userID) {

        const updateButtonElementID = `kb-update-btn-${knowledgeBaseId}`;
        const updateButtonElement = document.getElementById(updateButtonElementID);
        if (updateButtonElement) {
            updateButtonElement.innerHTML = "Updating...";
        }
        OrganizationUtils.callUpdateKnowledgeBaseCheck(orgId, knowledgeBaseId, userID)
            .then(async (response) => {
                if (response) {
                    // Set the new knowledge base
                    //   const newKnowledgeBases = currentKnowledgeBases.filter((item) => item.id !== knowledgeBaseId);
                    //   setKnowledgeBases(newKnowledgeBases);

                    const currentKnowledgeBases = knowledgeBases;

                    //Find index of specific object using findIndex method.    
                    const objIndex = currentKnowledgeBases.findIndex(obj => obj.id == knowledgeBaseId);
                    currentKnowledgeBases[objIndex].dateUpdated = response.data

                    await setKnowledgeBases(currentKnowledgeBases);
                    showSuccessToast("Successfully updated!!", 5000);

                    if (updateButtonElement) {
                        updateButtonElement.innerHTML = "Update";
                    }
                    return;
                }
            })
            .catch((error) => {
                showErrorToast("Error: Failed to update source", 6000);
                if (updateButtonElement) {
                    updateButtonElement.innerHTML = "Update";
                }
            });


        return;

    }

    function showSuccessToast(message, duration = 3000) {
        const element = document.getElementById("success-toast");
        if (!element) {
            return;
        }

        setTimeout(() => {
            // Set back to what it was

            element.style.setProperty("display", "block", "important");
            element.innerHTML = message;

            setTimeout(() => {
                element.style.setProperty("display", "none", "important");
                element.innerHTML = "";
            }, duration);


        }, 200);
    }

    function showErrorToast(message = "Error", duration = 3000) {
        const element = document.getElementById("error-toast");
        if (!element) return;

        element.style.setProperty("display", "block", "important");
        element.innerHTML = message;
        setTimeout(() => {
            // Set back to what it was
            element.style.setProperty("display", "none", "important");
            element.innerHTML = "";
        }, duration);
    }


    function renderAddNewKnowledgeBase() {
        return (
            <CardsSetupOrganization3
                user={user}
                text1={"Add Knowledge Base"}
                text2={"Personalize Addy to your brand by giving it facts about your business like:"}
                info={[
                    { html: "text1", text: "Org. Info" },
                    { html: "text3", text: "Proj. Info" },
                ]}
                createApplication={updateKnowledgeBase}
                buttonText={"Submit"}
                closeView={closeAddKbModal}
                function1={hideRenderAddNewKnowledgeBase}
                onChange={handleURLTextChange}
                knowledgeBaseIDs={knowledgeBaseIDs}
                addKbToState={addKnowledgeBaseID}
                removeItemFromKnowledgeBaseIDs={removeItemFromKnowledgeBaseIDs}
                createFileKnowledgeBase={createFileKnowledgeBase}
                selectableExistingKBs={false}
                existingKbs={knowledgeBases}
                kbsLoading={false}
                step={1}
                clearCurrentURLValue={clearCurrentURLValue}
                currentURLValue={currentURL}
                isCurrentURLValid={validURL}
                totalSteps={1}
                uid={user.uid}
                organizationId={organizationId}
            />
        );
    }

    const modalStyle2 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
            borderColor: "transparent",
        },
    };

    function deleteKnowledgeBase(orgId, knowledgeBaseId, userID) {
        const deleteButtonElementID = `kb-del-btn-${knowledgeBaseId}`;
        const deleteButtonElement = document.getElementById(deleteButtonElementID);
        if (deleteButtonElement) {
            deleteButtonElement.innerHTML = "Deleting...";
        }
        const currentKnowledgeBases = knowledgeBases;
        OrganizationUtils.callDeleteKnowledgeBaseFromOrganization(orgId, knowledgeBaseId, userID)
            .then(async (response) => {
                if (response) {
                    // Set the new knowledge base
                    const newKnowledgeBases = currentKnowledgeBases.filter((item) => item.id !== knowledgeBaseId);
                    setKnowledgeBases(newKnowledgeBases);
                    showSuccessToast("Successfully deleted!!", 5000);
                    return;
                }
                // Failed to delete
                showErrorToast("Error: Failed to delete source", 6000);
                if (deleteButtonElement) {
                    deleteButtonElement.innerHTML = "Delete";
                }
            })
            .catch((error) => {
                showErrorToast("Error: Failed to delete source", 6000);
                if (deleteButtonElement) {
                    deleteButtonElement.innerHTML = "Delete";
                }
            });
    }

    function knowledgeBaseList(items) {
        if (!(items && items.length)) return;
        const knowledgeBaseRender = [];
        const fileElement = () => <div className="horizontal-flex center-content center-opposite-flex"
            style={{
                width: "100px",
                backgroundColor: "#E2EDF9",
                borderRadius: "30px",
                padding: "6px",
            }}>
            <TextType style={{ color: "#4E92DF" }}>File</TextType>
        </div>

        const urlElement = () => <div className="horizontal-flex center-content center-opposite-flex"
            style={{
                width: "100px",
                backgroundColor: "#D0EAE7",
                borderRadius: "30px",
                padding: "6px",
            }}>
            <TextType style={{ color: "#209C8E" }}>Webpage</TextType>
        </div>
        let getTitle = (title) => {
            title = title.split('___')[0];
            title = title.replace(/https:\/\//g, "");
            title = title.replace(/http:\/\//g, "");
            return (title).length > 68 ? (title).slice(0, 28) + "..." : title
        }
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            // item.scrapedKb is a non empty array
            let linksLength = item?.scrapedKb?.linksText?.length
            linksLength = !!linksLength && linksLength;
            linksLength = !linksLength ? '' : ' (' + linksLength + ' Files)'
            // strip out '___'  from title. This was the appended timestamp for uniqueness
            let title = getTitle(item.title);
            // console.log({title, linksLength, kb:item.scrapedKb, len:item.scrapedKb.length > 0})
            knowledgeBaseRender.push(
                <tr>
                    <td className="table-data-1">{title}{linksLength}</td>
                    <td className="table-data-2">{item.ownerName ? item.ownerName : "Anonymous"}</td>
                    <td className="table-data-3">{item.source === "url" ? urlElement() : fileElement()}</td>
                    <td className="table-data-3">{item.dateUpdated ?
                        new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(item.dateUpdated)
                        :
                        new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(item.dateCreated)}
                    </td>
                    <td className="center-content center-opposite-flex"><Text2Delete onClick={() => updateKnowledgeBaseCheck(organizationId, item.kbID, user.uid)}>
                        <IconSmall src={`https://cdn.iconscout.com/icon/free/png-256/free-update-1779544-1513239.png`} /> <span id={`kb-update-btn-${item.kbID}`}>Update</span>
                    </Text2Delete></td>
                    <td className="center-content center-opposite-flex"><Text2Delete onClick={() => deleteKnowledgeBase(organizationId, item.kbID, user.uid)}>
                        <IconSmall src={`https://i.imgur.com/nSjYcVD.png`} /> <span id={`kb-del-btn-${item.kbID}`}>Delete</span>
                    </Text2Delete> </td>

                    <td className="center-content center-opposite-flex">
                        <input onChange={(e) => updatedKnowledgeBaseField(organizationId, item.kbID, user.uid, e.target.checked)} disabled={loadingUpdate} type="checkbox" id={`kb-aut-update-${item.kbID}`} checked={item.autoUpdate === "true" ? true : false} />
                    </td>
                </tr>
            )
        }
        return knowledgeBaseRender;

    }


    return (
        <ScrollviewRootRoot>
            <FlexColumn>

                <FlexRowHead>

                    <CreateButtonRootRoot onClick={() => openAddKbModal()}>
                        <AddIconDiv>
                            <AddIcon
                                src="https://i.imgur.com/GMWLL1s.png"
                            />
                            <WorkflowText1Create>Add Knowledge</WorkflowText1Create>
                        </AddIconDiv>

                    </CreateButtonRootRoot>
                </FlexRowHead>

                <br />

                <SectionTitle1>
                    &nbsp;Knowledge Base
                </SectionTitle1>

                <SectionContainer>

                    {
                        knowledgeBases && knowledgeBases.length > 0 ?

                            <div className="table-responsive w-full">

                                <table className="table" style={{
                                    width: "100%",
                                    minWidth: "700px",
                                }}>
                                    <thead>
                                        <tr>
                                            <th className="table-head">Item</th>
                                            <th className="table-head">Uploaded by</th>
                                            <th className="table-head">Type</th>
                                            <th className="table-head">Last update</th>
                                            <th className="table-head"></th>
                                            <th className="table-head"></th>
                                            <th className="table-head">Auto-update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {knowledgeBaseList(knowledgeBases)}

                                    </tbody>
                                </table>
                            </div>


                            :


                            <TextDescription>
                                You haven't added to your knowledge base yet
                            </TextDescription>

                    }


                </SectionContainer>

            </FlexColumn>

            <Modal
                id="add-knowledgebase-modal"
                isOpen={addKbModalIsOpen}
                onRequestClose={closeAddKbModal}
                shouldCloseOnOverlayClick={false}
                style={modalStyle2}
                contentLabel="Publish">
                <div>
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">{renderAddNewKnowledgeBase()}</div>
                </div>
            </Modal>

            <div
                id="toast-http-feedback"
                className="hidden flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400"
                style={{ zIndex: "100" }}
                role="alert">
                <div className="text-sm font-normal">URL must start with "http" or "https"</div>
            </div>

            <div
                id="success-toast"
                className="hidden flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-400"
                style={{ zIndex: "100" }}
                role="alert"></div>

            <div
                id="error-toast"
                className="hidden flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400"
                style={{ zIndex: "100" }}
                role="alert"></div>



        </ScrollviewRootRoot >
    );
};
const TextEmail = styled.div`
  color: #0d1121;
  font-size: 13px;
  font-family: Poppins;
  white-space: nowrap;
`;
const SectionTitle1 = styled.div`
  color: #495275;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const ScrollviewRootRoot = styled.div`
  height: 500px;
  width: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f9faff;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0px;
  width: 100%;
`;
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  border-radius: 10px;
  width: 95%;
  height: 500px;
  border-style: solid;
  border-color: #eaeeff;
  border-width: 2.8px;
  margin-top: 10px;
//   box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
`;

const CreateButtonRootRoot = styled.div`
    cursor: pointer;
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #eaeeff;
    background-color: #745DDE;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 14px;
    border-width: 2px;
    `;

const AddIconDiv = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    align-items: center;
`;

const AddIcon = styled.img`
    width: 16px;
    height: 16px;
`;

const WorkflowText1Create = styled.div`
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins;
    white-space: nowrap;
`;

const FlexRowHead = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  margin-bottom: 10px;
`;
const TextDescription = styled.div`
  width: 90%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  color: #485175;
  font-size: 13px;
  font-family: Poppins;
  line-height: 13px;
`;
const Text2Delete = styled.button`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 2px;
`;
const IconSmall = styled.img`
  width: 16px;
  height: 16px;
`;
const TextType = styled.div`
  width: fit-content;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  color: #485175;
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 13px;
`;
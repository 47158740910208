//
// Descriptions: Shows Tabbed overview of the organization
// 
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

import { Projects } from "./projects";
import { OrganizationMembers } from "./organizationMembers";
import { OrganizationKnowledgeBase } from "./knowledgeBase";
import OrganizationUtils from "../../services/organization/OrganizationUtils";

// Styles, Styled Components, Icons
import './overview.css'
import { FlexColumn, FlexRow, Board1, Header, HeaderContainer, ScrollviewRootRoot, Icons, MenuOptions, MenuOptions3, MenuOptionsSelected, PublishingText2, PublishingText1, PublishingBody, PublishingCardsprojectsRootRoot, Icons1, HeaderText, SectionTitle1, Text2, DeleteLastWeek1, DeleteLastWeek, DeleteFlexColumn, DeleteDivider, DeleteParagraph, DeleteText1, DeleteModalsRootRoot, DeleteBody } from "./overview-styled";
import knowledgeBaseIconOutline from '../../assets/icons/projects.png'
import knowledgeBaseIconFull from '../../assets/icons/projectsF.png'
import chromeIconOutline from '../../assets/icons/chrome_icon_outline.png'
import chromeIconFullBlack from '../../assets/icons/chrome_icon_full_black.png'
import chatbotIconOutline from '../../assets/icons/chatbot_outline.png'
import chatbotIconFull from '../../assets/icons/chatbot_full.png'
import membersIconOutline from '../../assets/icons/users_outline.png'
import membersIconFull from "../../assets/icons/users_full.png"

// Misc Imports
import minus from '../../assets/icons/minus.png'
import { InfinitySpin } from "react-loader-spinner";
import Popup from 'reactjs-popup';
import { FinishOnboarding } from "../onboarding/finishOnboarding";

export const Overview = props => {
    const [user, setUser] = useState(undefined); // User data object
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const [isOpenD, setIsOpenD] = useState(false);
    const [isOpenRemove, setIsOpenRemove] = useState(false);
    const [userToBeRemoved, setUserToBeRemoved] = useState();
    const closeModalD = () => setIsOpenD(false);

    const [publishState, setPublishState] = useState("delete");
    const [removeState, setRemoveState] = useState("idle");
    const [valueToBeDeleted, setValueToBeDeleted] = useState();
    const [organizationData, setOrganizationData] = useState({});
    const [currentView, setCurrentView] = useState("assistants"); // Default to show assistants view first

    const closeModalRemove = () => setIsOpenRemove(false);

    useEffect(() => {
        // Get user
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    async function getUser() {
        const auth = getAuth();
        auth.onAuthStateChanged(async (user) => {
            setUser(user);
            if (user) {
                // Get the organization Data
                OrganizationUtils.callGetOrganization(user.uid, organizationId, user.email)
                    .then(data => {
                        if (data) {
                            setOrganizationData(data);
                        }
                    })
            }
        })
    }

    async function deleteOrganization() {
        setPublishState("deleting");
        if (user === undefined) {
            const auth = getAuth();
            auth.onAuthStateChanged(async (user) => {
                setUser(user);
                if (user) {
                    OrganizationUtils.callDeleteOrganization(valueToBeDeleted, user.uid.toString()).then(p => {
                        if (p) {
                            setPublishState("delete");
                            navigate('/deletedOrganizationSuccess'); setIsOpenD(o => !o);
                        } else {
                            // TODO: Show error
                        }
                    });
                }
            })
        } else {
            if (user) {
                OrganizationUtils.callDeleteOrganization(valueToBeDeleted, user.uid.toString()).then(p => {
                    if (p) {
                        setPublishState("delete");
                        navigate('/deletedOrganizationSuccess');
                    } else {
                        // TODO: Show error
                    }
                });
            }

        }
    }

    async function removeMemberFromOrganization(member) {
        setRemoveState("removing");
        if (user === undefined) {
            const auth = getAuth();
            auth.onAuthStateChanged(async (user) => {
                setUser(user);
                if (user) {
                    OrganizationUtils.callRemovemember(user.uid, organizationId, member.email).then((success) => {
                        if (success) {
                            closeRemoveModalAndReset(member);
                            alert("Successfully removed " + member.email);
                        } else {
                            closeRemoveModalAndReset(member);
                            alert("Error removing " + member.email);
                        }
                    })

                }
            })
        } else {
            if (user) {
                OrganizationUtils.callRemovemember(user.uid, organizationId, member.email).then((success) => {
                    if (success) {
                        closeRemoveModalAndReset(member);
                        alert("Successfully removed " + member.email);
                    } else {
                        closeRemoveModalAndReset(member);
                        alert("Error removing " + member.email);
                    }
                })
            }

        }
    }

    function deletePopup() {
        return (

            <DeleteModalsRootRoot>
                <DeleteBody>
                    <DeleteText1>Delete Confirmation</DeleteText1>
                    <DeleteParagraph>
                        Danger Alert! <br />
                    </DeleteParagraph>
                </DeleteBody>
                <DeleteFlexColumn>
                    <DeleteBody>
                        <DeleteDivider
                            src={`https://file.rendit.io/n/ZJInA8DghzE2tMSvL197.svg`}
                        />
                        <DeleteLastWeek
                            onClick={() => { setIsOpenD(o => !o); setValueToBeDeleted() }}
                        >
                            Cancel
                        </DeleteLastWeek>
                        <DeleteLastWeek1
                            onClick={() => { deleteOrganization() }}
                        >
                            Delete
                        </DeleteLastWeek1>
                    </DeleteBody>
                </DeleteFlexColumn>
            </DeleteModalsRootRoot>

        )
    }

    function removeMemberPopup(member) {
        return (

            <DeleteModalsRootRoot>
                <DeleteBody>
                    <DeleteText1>Remove Member</DeleteText1>
                    <DeleteParagraph>
                        Remove {member.email ? member.email : ""} from this organization <br />
                    </DeleteParagraph>
                </DeleteBody>
                <DeleteFlexColumn>
                    <DeleteBody>
                        <DeleteDivider
                            src={`https://file.rendit.io/n/ZJInA8DghzE2tMSvL197.svg`}
                        />
                        <DeleteLastWeek
                            onClick={() => {
                                setIsOpenRemove(o => !o); setRemoveState("idle");
                                setUserToBeRemoved()
                            }}
                        >
                            Cancel
                        </DeleteLastWeek>
                        <DeleteLastWeek1
                            onClick={() => { removeMemberFromOrganization(member) }}
                        >
                            Remove
                        </DeleteLastWeek1>
                    </DeleteBody>
                </DeleteFlexColumn>
            </DeleteModalsRootRoot>

        )
    }

    function removingMember() {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Removing</PublishingText1>
                    <PublishingText2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <InfinitySpin
                                color="#0E1221"
                            />
                        </div>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        )
    }

    function deleting() {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Deleting</PublishingText1>
                    <PublishingText2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <InfinitySpin
                                color="#0E1221"
                            />
                        </div>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        )
    }

    function onDeleteMemberClick(member) {

        setUserToBeRemoved(member); // Set which member we want to remove
        setRemoveState("remove"); // Update remove state
        setIsOpenRemove(o => !o); // Open modal
    }

    function closeRemoveModalAndReset(member) {
        setUserToBeRemoved(); // Set member to remove to null
        setRemoveState("idle"); // Update remove state to idle
        closeModalRemove();
    }

    function setViewState(view) {
        if (view) setCurrentView(view);
    }

    return (
        <ScrollviewRootRoot>
            <HeaderContainer>
                <SectionTitle1>
                    Business
                </SectionTitle1>
                <HeaderText>
                    {organizationData["organizationName"] ? organizationData["organizationName"] : ""}
                </HeaderText>
                <Header>
                    <FlexRow>
                        {
                            currentView === "assistants" ? <MenuOptionsSelected onClick={() => setViewState("assistants")}>
                                <Icons1
                                    src={currentView === "assistants" ? chatbotIconFull : chatbotIconOutline}
                                />
                                <Text2>AI Support Assistants</Text2>
                            </MenuOptionsSelected> :
                                <MenuOptions3 onClick={() => setViewState("assistants")}>
                                    <Icons1
                                        src={currentView === "assistants" ? chatbotIconFull : chatbotIconOutline}
                                    />
                                    <Text2>AI Support Assistants</Text2>
                                </MenuOptions3>
                        }
                        {
                            currentView === "knowledgeBase" ? <MenuOptionsSelected onClick={() => setViewState("knowledgeBase")}>
                                <Icons1
                                    src={currentView === "knowledgeBase" ? knowledgeBaseIconFull : knowledgeBaseIconOutline}
                                />
                                <Text2>Knowledge Base</Text2>
                            </MenuOptionsSelected> : <MenuOptions3 onClick={() => setViewState("knowledgeBase")}>
                                <Icons1
                                    src={currentView === "knowledgeBase" ? knowledgeBaseIconFull : knowledgeBaseIconOutline}
                                />
                                <Text2>Knowledge Base</Text2>
                            </MenuOptions3>
                        }

                        {
                            currentView === "members" ? <MenuOptionsSelected onClick={() => setViewState("members")}>
                                <Icons1
                                    src={currentView === "members" ? membersIconFull : membersIconOutline}
                                />
                                <Text2>Members</Text2>
                            </MenuOptionsSelected> : <MenuOptions3 onClick={() => setViewState("members")}>
                                <Icons1
                                    src={currentView === "members" ? membersIconFull : membersIconOutline}
                                />
                                <Text2>Members</Text2>
                            </MenuOptions3>
                        }

                        {
                            currentView === "chrome" ? <MenuOptionsSelected onClick={() => setViewState("chrome")}>
                                <Icons1
                                    src={currentView === "chrome" ? chromeIconFullBlack : chromeIconOutline}
                                />
                                <Text2>Chrome Extension</Text2>
                            </MenuOptionsSelected> : <MenuOptions3 onClick={() => setViewState("chrome")}>
                                <Icons1
                                    src={currentView === "chrome" ? chromeIconFullBlack : chromeIconOutline}
                                />
                                <Text2>Chrome Extension</Text2>
                            </MenuOptions3>
                        }

                    </FlexRow>
                    <FlexRow>
                        <button onClick={() => { setIsOpenD(o => !o); setValueToBeDeleted(organizationId) }}>
                            <MenuOptions>
                                <Icons style={{ color: 'red' }} src={minus} />
                                <Board1 style={{ color: 'red' }}>
                                    Delete Business
                                </Board1>
                            </MenuOptions>
                        </button>



                    </FlexRow>
                </Header>
            </HeaderContainer>
            <div className="container-fluid flex">
                <FlexColumn >
                    <>
                        {
                            currentView === "assistants"
                                ?
                                <Projects></Projects>
                                :
                                <></>
                        }
                        {
                            currentView === "members"
                                ?
                                <OrganizationMembers
                                    organizationId={organizationId}
                                    deleteMember={onDeleteMemberClick} />
                                :
                                <></>
                        }
                        {
                            currentView === "knowledgeBase"
                                ?
                                <OrganizationKnowledgeBase
                                    organizationId={organizationId}
                                    user={props.user}
                                />
                                :
                                <></>
                        }
                        {
                            currentView === "chrome"
                                ?
                                <div style={{paddingBottom: "50px"}}>
                                    <FinishOnboarding
                                        disableMaybeButton={true}
                                    />
                                </div>
                                
                                :
                                <></>
                        }
                    </>
                </FlexColumn>
            </div>


            <Popup className={`whiteBackground`} open={isOpenD} closeOnDocumentClick onClose={closeModalD} modal nested >
                {publishState === "delete" ? deletePopup() : null}
                {publishState === "deleting" ? deleting() : null}
            </Popup>
            <Popup className={`whiteBackground`} open={isOpenRemove} closeOnDocumentClick onClose={closeModalRemove} modal nested >
                {removeState === "idle" ? null : null}
                {removeState === "remove" ? removeMemberPopup(userToBeRemoved) : null}
                {removeState === "removing" ? removingMember() : null}
            </Popup>
        </ScrollviewRootRoot>
    );
};
import styled from "styled-components";
const TextEmail = styled.div`
  color: #0d1121;
  font-size: 13px;
  font-family: Poppins;
  white-space: nowrap;
`;
const Text2Delete = styled.button`
  color: #485175;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
  align-items: center;
`;
const IconSmall = styled.img`
  width: 16px;
  height: 16px;
`;
const SectionTitle1 = styled.div`
  color: #495275;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const TextDescription = styled.div`
  width: 90%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  color: #485175;
  font-size: 13px;
  font-family: Poppins;
  line-height: 13px;
`;

const Text5 = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  color: #485175;
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 16px;
  white-space: nowrap;
`;

const FlexColumnLittle = styled.div`
  gap: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const FlexRow3 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const FeedBackText = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 10px;
`;
const LastWeekContain = styled.button`
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 8px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 7px;
  padding: 13.2px 10.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const Textbox = styled.input`
  width: 250px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: #f4f6ff;
  border-radius: 6px;
  padding: 6px 6px;
  border-width: 0.5px;
  font-size: 14px;
`;

const EmailDiv = styled.div`
font-size: 14px;

`
const DeleteModalsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
`;
const DeleteText1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const DeleteParagraph = styled.div`
  width: 376px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const DeleteFlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const DeleteDivider = styled.img`
  width: 376px;
  height: 1px;
`;
const DeleteLastWeek = styled.button`
  width: 344px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const DeleteLastWeek1 = styled.button`
  width: 344px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const MenuOptions3 = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 15.2px 15.2px 15.2px 11.2px;
  border-width: 2.8px;
  
`;
const Text2 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;
const Icons1 = styled.img`
  width: 24px;
  height: 24px;
`;
const PublishingCardsprojectsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 52px 14px;
  border-width: 2px;
`;
const PublishingBody = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PublishingText1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const PublishingText2 = styled.div`
  width: 376px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  text-align: center;
`;
const MenuOptions = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  padding: 17px 17px 17px 13px;
`;
const Icons = styled.img`
  width: 24px;
  height: 24px;
`;
const ScrollviewRootRoot = styled.div`
  height: 500px;
  width: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f9faff;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 23px 15px;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0px;
  width: 100%;
`;
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  border-radius: 10px;
  width: 95%;
  height: 500px;
  border-style: solid;
  border-color: #eaeeff;
  border-width: 2.8px;
  margin-top: 10px;
//   box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
`;
const Board1 = styled.div`
  color: #485175;
  font-size: 14px;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: pre-wrap;
`;

// Export everything
export {
  TextEmail,
  Text2Delete,
  IconSmall,
  SectionTitle1,
  TextDescription,
  Text5,
  FlexColumnLittle,
  FlexRow3,
  FeedBackText,
  LastWeekContain,
  Textbox,
  EmailDiv,
  DeleteModalsRootRoot,
  DeleteText1,
  DeleteParagraph,
  DeleteFlexColumn,
  DeleteDivider,
  DeleteLastWeek,
  DeleteLastWeek1,
  MenuOptions3,
  Text2,
  Icons1,
  PublishingCardsprojectsRootRoot,
  PublishingBody,
  PublishingText1,
  PublishingText2,
  MenuOptions,
  Icons,
  ScrollviewRootRoot,
  Header,
  FlexColumn,
  SectionContainer,
  Board1,
};
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";


export const QuickPrompts = (props) => {
    const [title, setTitle] = useState('');
    const [prompt, setPrompt] = useState('');

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const handlePromptChange = (e) => {
        setPrompt(e.target.value);
    }

    const addNewPrompt = () => {
        // TODO: Validate fields
        const quickPrompt = {
            id: uuidv4(),
            title: title,
            prompt: prompt,
        }
        props.addQuickPrompt(quickPrompt);
        setTitle("");
        setPrompt("");
    }

    return (
        <Container>
            <SectionTitle1 style={{
                marginBottom: "10px"
            }}>
                Add Quick Prompt
            </SectionTitle1>

            {/* <SectionTitle2>
                Give your customers quick answers to common queries
            </SectionTitle2> */}

            <div style={{
                marginBottom: "20px"
            }}>
                <Title>Title</Title>
                <InputOneLine className="no-border-input2" onChange={handleTitleChange} value={title}/>
            </div>

            <div style={{
                marginBottom: "10px"
            }}>
                <Title>Prompt</Title>
                <InputTwoLine className="no-border-input2" onChange={handlePromptChange} value={prompt}/>
            </div>

            <div className="horizontal-flex" style={{
                justifyContent: "flex-start",
            }}>
                <div className="horizontal-flex" style={{
                    width: "150px",
                    justifyContent: "flex-start"
                }}>
                    <LastWeek1 onClick={addNewPrompt} style={{
                        width: "150px"
                    }}>
                        Add Prompt <div style={{
                        display: props.addQuickPromptLoading ? "block" : "none"
                    }} id="updating-spinner" className="loader-new"></div>
                    </LastWeek1>
                </div>
            </div>

        </Container>
    )
}

// Styled Components 
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeeff;
`;

const Title = styled.h1`
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 13px;
  margin-bottom: 8px;
  ::after {
   content: " *";
   color: red;
  }
`;

const InputOneLine = styled.input`
  width: 100%;
  height: auto;
  line-height: 20px;
  padding: 10px;
  font-size: 13px;
  background-color: #EAEEFE;
`;

const InputTwoLine = styled.textarea`
   width: 100%;
   font-size: 13px;
   background-color: #EAEEFE;
   line-height: 20px;
   padding: 10px;
   height: ${4 * parseFloat(getComputedStyle(document.documentElement).fontSize)}px;
   max-height: ${4 * parseFloat(getComputedStyle(document.documentElement).fontSize)}px;
   overflow-y: auto;
`;

const SectionTitle1 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 12px;
`;

const SectionTitle2 = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 12px;
`;

const LastWeek1 = styled.button`
  width: 85px;
  gap: 5px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
import { faDiscord, faEthereum } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { twMerge } from 'tailwind-merge'
import { Link } from "react-router-dom";
import './buttons.css'
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import add from '../../assets/icons/add.png' // relative path to image 
import AuthUtils from "../../services/auth/AuthUtils";
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { apiURL } from "../../globalVariables";

export const Button = props => {
    return <button
        onClick={props.onClick}
        className={twMerge(`flex gap-2 items-center justify-center bg-slate-200 dark:bg-slate-700 py-[10px] px-[20px] font-semibold rounded-lg ${props.className}`)}
        disabled={props.disabled}>
        {props.children}
    </button>
}
export const ButtonComponent = props => {
    return <button
        onClick={props.onClick}
        className={`buttonComponent`}
        disabled={props.disabled}>
        {props.children}
    </button>
}

export const DiscordButton = props => {
    return <Button onClick={() => {window.open(`${apiURL}/routes/discord/auth/bot`, '', "width=400, height=400")}}
            className="bg-[#5865f2] dark:bg-[#5865f2] text-white">
        <FontAwesomeIcon icon={faDiscord} />
        Link Discord
    </Button>
}

export const WalletButton = props => {
    return <Button className="bg-purple-500 dark:bg-purple-500 text-white" onClick={props.onClick}>
        <FontAwesomeIcon icon={faEthereum} />
        Connect Wallet
    </Button>
}

export const ButtonSideMenu = props => {
    return (
        <SideButton onClick={props.click}>
            <StateidleTypedashboardRoot>
                <Icons src={props.icon} />
                <Text1>{props.text}</Text1>
            </StateidleTypedashboardRoot>
        </SideButton>
    );
};

export const ButtonSideMenuSelected = props => {
    return (
        <SideButton onClick={props.click}>
            <StateonHoverTypedashboardRoot>
                <Icons src={props.icon} />
                <Text1>{props.text}</Text1>
            </StateonHoverTypedashboardRoot>
        </SideButton>
    );
};

export const ButtonGrey = props => {
    return (
        <Link to={props.to} style={{ width: props.menuOpen ? '100%' : '52px' }}>
            <ButtonRoot onClick={(e) => console.log("ButtonRoot")}>
                <Icons src={props.icon} />
                <LastWeek>{props.text}</LastWeek>
            </ButtonRoot>
        </Link >
    );
};
export const ButtonGreyNoLink = props => {
    return (
        <ButtonRoot onClick={(e) => console.log("ButtonRoot")}>
            <Icons src={props.icon} />
            <LastWeek>{props.text}</LastWeek>
        </ButtonRoot>
    );
};
export const Dropdown = props => {
    return (
        <select value={props.chain} onChange={e => { console.log(e.target.value); props.setChain(e.target.value); }} className={`rounded-[8px] shadow-[0px_0px_40px_rgba(0,_0,_0,_0.05)] [border:1px_solid_#d9e0ff] box-border relative flex flex-row p-[8px] items-center justify-start gap-[4px] w-[150px] ${props.class}`}>
            {props.options.map((option, index) => (
                <option key={index} value={option.value}>{option.text}
                    <div>
                        <img
                            className="relative w-[12px] h-[12px] shrink-0 overflow-hidden"
                            alt=""
                            src={add}
                        /></div>
                </option>
            ))}
        </select>
    )
}


export const TwitterButton = props => {
    const [twitterLogin, setTwitterLogin] = useState(null)

    useEffect(() => {
        console.log("ola")
        AuthUtils.callGetTwitterLogin().then(setTwitterLogin)
    }, []);

    return <a href={twitterLogin}>
        <Button className="bg-[#1d9bf0] dark:bg-[#1d9bf0] text-white">
            <FontAwesomeIcon icon={faTwitter} />
            Connect Twitter
        </Button>
    </a>
}

const ButtonRoot = styled.button`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 15px;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: #f9faff;
  box-sizing: content-box;
  background-color: #eaeeff;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const SideButton = styled.button`
`;
const LastWeek = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;

const StateonHoverTypedashboardRoot = styled.div`
    gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 17px 13px;
    border-width: 1px;
    border-radius: 16px;
    border-style: solid;
    border-color: #eaeeff;
    background-color: #f9faff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  `;


const StateidleTypedashboardRoot = styled.div`
  width: 165px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 17px 13px;
  border-radius: 16px;
`;
const Icons = styled.img`
  width: 24px;
  height: 24px;
`;
const Text1 = styled.div`
  color: #485175;
  font-size: 14px;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;

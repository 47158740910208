import styled from "styled-components";

export const Text1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  width: 350px;
  border-radius: 20px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center; 
`;

export const ProfileImage = styled.img`
    border-radius: 15px;
    width: 100%;
    height: auto;
    object-fit: cover;
`;

export const Paragraph1 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
export const Text2 = styled.button`
  color: #485175;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
`;
export const Text5 = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
export const Body = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const Paragraph = styled.div`
  width: 376px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;

export const FlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const WhiteFlexRowNoCenter = styled.div`
  width: 374px;
  display: flex;
  flex-direction: column;
  border-style: dashed;
  border-color: #d8e0ff;
  background-color: #eaeeff;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px;
  padding: 10px 5px 10px 5px;
  border-width: 1px;
`;
export const FlexColumnLittle = styled.div`
  gap: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const CardsSetupWorkspaceRoot = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
`;
export const FlexRow1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction:row-reverse;
  justify-content: space-between;
  align-items: center;
`;
export const Icons = styled.img`
  width: 14px;
  height: 14px;
`;
export const Text4 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
export const WhiteText = styled.div`
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #858caa;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #eaeeff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
export const Divider = styled.img`
  width: 376px;
  height: 1px;
`;
export const LastWeek = styled.button`
  width: 344px;
  height: 14px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

export const SkipButton = styled.button`
width: 100px; /* 1/3 of the width of the other button (344px) */
padding: 13.2px 15.2px;
color: #485175;
font-size: 12px;
font-weight: 500;
font-family: Poppins;
line-height: 20px;
white-space: nowrap;
border-width: 0.8px;
border-radius: 8px;
border-style: solid;
border-color: #eaeeff;
box-sizing: content-box;
background-color: #f9faff; /* Gray background color */
cursor: pointer;
&:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1); /* Adjusted shadow color */
}
`;

export const ActionButton = styled.button`
  width: 200px;
  padding: 13.2px 15.2px;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-width: 0.8px;
  border-radius: 8px;
  border-style: solid;
  border-color: #eaeeff;
  box-sizing: content-box;
  background-color: #745dde;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
import OrganizationUtils from "../../services/organization/OrganizationUtils";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { ChatbotInterface } from "./chatbotInterface";
import { TextContent } from "./textContent";
import ProjectUtils from "../../services/project/ProjectUtils";

export const Settings = (props) => {
    const [newConfigState, setNewConfigState] = useState(props.config);

    
    useEffect(() => {
        setNewConfigState(props.config);
    }, [props.config]);

    function updateNewConfigState(key, value) {
        const newConfig = { ...newConfigState };  // make a copy of the state
        updateObjectPath(newConfig, key, value);  // mutate the copy
        setNewConfigState(newConfig);
        // Update chatbot preview
        props.updateChatbotPreview(newConfig);
    }

    async function updateAppConfig() {
        const loadingSpinner = document.getElementById("updating-spinner");
        if (loadingSpinner) loadingSpinner.style.display = "block";

        ProjectUtils.callUpdateAppInfo(props.user.uid, props.appId, "config", newConfigState)
            .then(async (response) => {
                if (response) {
                    props.showSuccessToast(`Updated Successfully!`, 6000);
                    if (loadingSpinner) loadingSpinner.style.display = "none";
                } else {
                    props.showErrorToast("Failed to update", 6000);
                    if (loadingSpinner) loadingSpinner.style.display = "none";
                }
                return;
            })
            .catch((error) => {
                props.showErrorToast("Failed to update", 6000);
                if (loadingSpinner) loadingSpinner.style.display = "none";
            });
    }

    function updateObjectPath(objectToModify, path, value) {
        let schema = objectToModify;  // a moving reference to internal objects within obj
        let pList = path.split('.');
        let len = pList.length;
        for (let i = 0; i < len - 1; i++) {
            let elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }

        schema[pList[len - 1]] = value;
    }

    return (
        <div style={{
            width: "100%"
        }}>

            <div style={{
                marginBottom: "30px",
                paddingBottom: "5px",
                borderBottom: "1px solid #eaeeff",
            }}>
                <HeaderText>
                    Settings
                </HeaderText>

                <SectionTitle1>
                    Customize the look and feel of your AI assistant
                </SectionTitle1>

            </div>

            <div style={{
                marginBottom: "30px"
            }}>
                <TextContent config={props.config} newConfigState={newConfigState}
                    updateNewConfigState={updateNewConfigState}/>
            </div>

            <div div style={{
                marginBottom: "30px"
            }}>
                <ChatbotInterface config={props.config} newConfigState={newConfigState}
                    updateNewConfigState={updateNewConfigState}/>
            </div>

            <div style={{
                paddingTop: "20px"
            }}>
                <LastWeek1 onClick={updateAppConfig}>
                    Save changes <div style={{
                        display: "none"
                    }} id="updating-spinner" className="loader-new">

                    </div>
                </LastWeek1>
            </div>

        </div>
    )
}

const HeaderText = styled.div`
  color: #0d1121;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  white-space: nowrap;
`;

const LastWeek1 = styled.button`
  width: 150px;
  gap: 5px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const SectionTitle1 = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 5px;
  margin-top: 4px;
`;

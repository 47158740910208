import React, { useEffect, useState } from "react";
import { renderToString } from 'react-dom/server';
import { v4 as uuidv4 } from "uuid";

export const ChatbotPreview = (props) => {

    const [config, setConfig] = useState({});
    const CHAT_ID = uuidv4(); // Chat ID for this session for inferencing
    let chatbotAPI = "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/businessInference/infer";

    useEffect(() => {
        // Get user
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setConfig(props.realtimeConfig);
    }, [props.realtimeConfig]);


    function renderAutoPrompts(autoPrompts) {
        if (!(autoPrompts && autoPrompts)) return;

        return (
            <div className="auto-prompts-container" style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                width: "100%",
                justifyContent: "flex-end"
            }}>
                {autoPrompts.map((item, index) => (
                    <div style={{
                        width: "fit-content",
                        borderRadius: "10px",
                        borderColor: "rgba(0, 0, 0, 0.3)",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        padding: "5px",
                        cursor: "pointer"
                      }}>
                          <p style={{
                              fontSize: "14px",
                              minWidth: "fit-content",
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              wordWrap: "break-word" // or use overflowWrap: "break-word"
                          }}>{item.prompt}</p>
                      </div>
                ))}
            </div>

        )
    }

    function botMessage(data) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                marginBottom: "25px"
            }}>
                {/* Bot profile photo */}
                <div style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    backgroundColor: config.primaryColor || "#745DDE",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: 'center',
                    alignItems: "center"
                }}>
                    <img src="https://i.imgur.com/xoP7CyF.png" alt="A.I."
                        width="24" height="24"/>
                </div>

                {/* Bot Message Div */}
                <div style={{
                    backgroundColor: "#E9ECF0",
                    maxWidth: "85%",
                    width: "fit-content",
                    padding: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    minWidth: "80px"
                }}>
                    <p style={{
                        lineHeight: "20px",
                        color: "rgba(0, 0, 0, 0.7)",
                        fontSize: "14px"
                    }}>{data.message}</p>
                </div>
            </div>
        )
    }

    function addMessageToChat(message, chatHistory) {
        let messageElem = userMessage({
            "message": message,
        });
        messageElem = renderToString(messageElem);
        const el = document.createElement("div");
        el.innerHTML = messageElem;
   
        chatHistory.append(el);
    }



    function getChatResponseFromServer(message, messageInput) {
        const hostName = window.location.host;
        const chatHistory = document.getElementById("chat-history")

        addMessageToChat(message, chatHistory);
            messageInput.value = "";

            let thinkingElem = botMessage({
                "message": "thinking",
            })

            thinkingElem = renderToString(thinkingElem);
            const thinkingEl = document.createElement("div");
            thinkingEl.innerHTML = thinkingElem;
            
            setTimeout(() => {
                chatHistory.append(thinkingEl);
                chatHistory.scrollTop = chatHistory.scrollHeight;
            }, 400);

        fetch(`${chatbotAPI}/qa?user_query=${message}&publicId=${props.data.publicId}&host=${hostName}&chatId=${CHAT_ID}`)
                .then(response => response.json())
                .then(data => {
                    thinkingEl.style.display = "none";
                    console.log(data);
                    if (data.response) {
                        createBotMessageElement(data.response, chatHistory);
                    } else {
                        createBotMessageElement("Sorry, I could not understand your question", chatHistory);
                    }
                    chatHistory.scrollTop = chatHistory.scrollHeight;
                }).catch((error) => {
                    console.error(error);
                    thinkingEl.style.display = "none";
                    createBotMessageElement("Oops... I had a glitch :( My engineers are working on it", chatHistory);
                })
    }

    function onChatClick() {
        const messageInput = document.getElementById("message-input");
        if (messageInput.value) {
            getChatResponseFromServer(messageInput.value, messageInput);
        }
    }

    function createBotMessageElement(message, chatHistory) {
        let messageElem = botMessage({
            "message": message
        });
        messageElem = renderToString(messageElem);
        const el = document.createElement("div");
        el.innerHTML = messageElem;

        chatHistory.append(el);
        chatHistory.scrollTop = chatHistory.scrollHeight;
    }

    function userMessage(data) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                marginBottom: "25px"
            }}>
                {/* Bot profile photo */}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src="https://i.imgur.com/WjAIvVp.png" alt="You"
                        width="35" height="35" style={{
                            borderRadius: "50%",
                        }}/>
                </div>

                {/* Bot Message Div */}
                <div style={{
                    backgroundColor: config.primaryColor || "#745DDE",
                    width: "fit-content",
                    padding: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    minWidth: "80px"
                }}>
                    <p style={{
                        lineHeight: "20px",
                        color: "rgba(255, 255, 255, 1)",
                        fontSize: "14px"
                    }}>{data.message}</p>
                </div>
            </div>
        )
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            height: "456px",
            maxWidth: "90%",
            minWidth: "360px",
            borderRadius: "20px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}>
            <div>
                {/* Header */}
                <div style={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: config.primaryColor || "#745DDE",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}>

                    <p style={{
                        color: "white",
                        fontWeight: 'bold'
                    }}>{config.chatbotName}</p>

                </div>

                {/* Chat Div */}
                <div style={{
                    paddingTop: "20px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    height: "350px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>

                    <div id="chat-history" style={{
                        width: "100%",
                        overflowY: "auto",
                        flexGrow: 1,
                    }}>
                        <div>
                            {botMessage({
                                message: config.welcomeMessage || "Hello 👋",
                            })}
                        </div>

                        <div>
                            {userMessage({
                                message: "Who are you?"
                            })}
                        </div>

                        <div>
                            {botMessage({
                                message: "I am a virtual assistant here to help answer your questions"
                            })}
                        </div>

                    </div>

                    <div>
                        {renderAutoPrompts(config.quickPrompts)}
                    </div>

                </div>

                {/* Chat buttons div */}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    // backgroundColor: "#EBEFFF"

                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        borderRadius: "10px",
                        backgroundColor: "#F1F4F6",
                        // paddingLeft: "10px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingRight: "5px"
                    }}>

                        <div style={{
                            flex: 1,
                        }}>
                            <input id="message-input" className="no-border-input" style={{
                                width: "100%",
                                borderRadius: "10px",
                                fontSize: "14px",
                                height: "35px",
                                paddingRight: "5px",
                                backgroundColor: "transparent"
                            }} type="text" placeholder={`${config.inputPlaceholder}`}></input>
                        </div>

                        <div style={{
                            // width: "24px",
                        }}>
                            <div onClick={() => onChatClick()} style={{
                                backgroundColor: config.primaryColor || "#745DDE",
                                height: "35px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                padding: "5px",
                                cursor: "pointer"
                            }}>
                                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m13.7612096 12.010246-10.76006891-1.0841632-.00114067-6.85217554c-.00009872-.59300388.48054588-1.07380853 1.07354976-1.07390725.16675147-.00002775.33121768.038783.48036473.11335653l15.85230779 7.92615386c.5303988.2651994.7453854.9101592.480186 1.440558-.1038883.2077764-.2723589.376257-.4801292.4801576l-15.84996765 7.9261566c-.5303831.2652308-1.1753556.0502824-1.4405864-.4801007-.07454342-.1490647-.11336284-.3134359-.11338495-.4801003l-.00090941-6.8526417z" fill="white" />
                                </svg>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}
import React from "react";
import styled from "styled-components";

export const InputTemplatePersonalization = props => {
  const options = [{ value: 'text', text: 'text' }, { value: 'dropdown', text: 'dropdown' }, { value: 'twitter', text: 'twitter' }, { value: 'discord', text: 'discord' }];
  return (
    <TemplatePersWhiteFlexColumn1>
      <FlexColumn4>
        <TemplatePersText10>Form Title (for analytics)</TemplatePersText10>
        <TemplatePersTextbox6
          value={props.formTitle}
          onChange={e => props.setFormTitle(e.target.value)}
          placeholder={`Form Contributor #1`} >
        </TemplatePersTextbox6>
      </FlexColumn4>
      {props.inputs.map((inp, i) =>
      (
        <div key={i}>
          <Div6>
            <FlexColumn4>
              <FlexRow10>
                <Text11>Input {i + 1} Type
                  <select onChange={e => {
                    let newArr = [...props.inputs]; // copying the old datas array
                    newArr[i].type = e.target.value; // replace e.target.value with whatever you want to change it to
                    props.setInputs(newArr); // ??
                  }}
                    className="rounded-[8px] shadow-[0px_0px_40px_rgba(0,_0,_0,_0.05)] [border:1px_solid_#d9e0ff] box-border relative flex flex-row p-[8px] items-center justify-start gap-[4px] w-[150px]">
                    {options.map((option, index) => (
                      <option key={index} defaultValue={inp.type === option.value ? true : false} selected={inp.type === option.value ? true : false} value={option.value}> {option.text}</option>
                    ))}
                  </select>
                </Text11>
                <Button6 onClick={() => props.removeInput(i)}>
                  <RemoveButton>
                    <Icons
                      src={`https://file.rendit.io/n/4kxb1m9fT0cH7qneMzSf.svg`}
                    />
                  </RemoveButton>
                </Button6>
              </FlexRow10>
            </FlexColumn4>
          </Div6>
          <TemplatePersFlexRow4 style={{ flexDirection: "row" }}>
            <div style={{ width: (inp.type !== 'twitter' && inp.type !== 'discord') ? 'auto' : '100%' }}>
              <TemplatePersText10 >
                Input Title
              </TemplatePersText10>
              <TemplatePersTextbox5
                value={inp?.title} onChange={e => {
                  let newArr = [...props.inputs]; // copying the old datas array
                  newArr[i].title = e.target.value; // replace e.target.value with whatever you want to change it to
                  props.setInputs(newArr); // ??
                }}
                placeholder="example Title">
              </TemplatePersTextbox5>
            </div>
            {(inp.type !== 'twitter' && inp.type !== 'discord') && (
              <div>
                <TemplatePersText10 >
                  Input Value to be stored
                </TemplatePersText10>
                <TemplatePersTextbox5
                  value={inp?.value} onChange={e => {
                    let newArr = [...props.inputs]; // copying the old datas array
                    newArr[i].value = e.target.value; // replace e.target.value with whatever you want to change it to
                    props.setInputs(newArr); // ??
                  }}
                  placeholder="email">
                </TemplatePersTextbox5>
              </div>
            )}
          </TemplatePersFlexRow4>
          {inp?.type === "dropdown" && (
            <TemplatePersFlexRow4 >
              <div>
                <TemplatePersText10 >
                  Dropdown Options
                </TemplatePersText10>
                <div style={{ heigth: '100px', width: '320px' }}>
                  {inp.options?.map((option, index) => (
                    <div key={index} style={{ flexDirection: "row", display: 'flex', alignItems: 'center' }}>
                      <TemplatePersTextbox5
                        value={option} onChange={e => {
                          let newArr = [...props.inputs]; // copying the old datas array
                          newArr[i].options[index] = e.target.value; // replace e.target.value with whatever you want to change it to
                          props.setInputs(newArr); // ??
                        }}
                        placeholder="email">
                      </TemplatePersTextbox5>

                      <Button6 onClick={() => props.removeDrop(i, index)}>
                        <RemoveButton>
                          <Icons
                            src={`https://file.rendit.io/n/4kxb1m9fT0cH7qneMzSf.svg`}
                          />
                        </RemoveButton>
                      </Button6>
                    </div>
                  ))}
                  <ButtonURL5 onClick={() => props.addMoreDrop(i)}>
                    <FlexRow2>
                      <RemoveButton>
                        <Icons2 src={`undefined`} />
                      </RemoveButton>
                      <Text4>Add new dropdown Option</Text4>
                    </FlexRow2>
                  </ButtonURL5>
                </div>
              </div>
            </TemplatePersFlexRow4>
          )}
        </div>
      )
      )}
      <FlexRow4>
        <ButtonURL4 onClick={() => props.addInput()}>
          <FlexRow2>
            <RemoveButton>
              <Icons2 src={`undefined`} />
            </RemoveButton>
            <Text4>Add New Input</Text4>
          </FlexRow2>
          <Text22>you can add upto 4 inputs</Text22>
        </ButtonURL4>
      </FlexRow4>
    </TemplatePersWhiteFlexColumn1>
  );
};

const FlexColumn4 = styled.div`
      width: 371px;
      gap: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px 15px 7px 15px;
      `;
const FlexRow10 = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-self: stretch;
      align-items: center;
      `;
const Text11 = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;
      color: #485175;
      font-size: 14px;
      font-weight: 600;
      font-family: Poppins;
      line-height: 19.6px;
      white-space: nowrap;
      `;
const Icons = styled.img`
      width: 16px;
      height: 16px;
      `;
const Button6 = styled.button`
      gap: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px;
      border-width: 0px;
      background: none;
      box-sizing: content-box;
      cursor: pointer;
      &: hover {
        opacity: 70%;
  }
      `;
const Div6 = styled.div`
      gap: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px;
      border-width: 0px;
      background: none;
      box-sizing: content-box;
      `;
const Text4 = styled.div`
      color: #485175;
      font-size: 12px;
      font-weight: 600;
      font-family: Poppins;
      line-height: 14px;
      white-space: nowrap;
      `;
const Text22 = styled.div`
      color: #858caa;
      font-size: 10px;
      font-weight: 500;
      font-family: Poppins;
      line-height: 14px;
      white-space: nowrap;
      `;
const FlexRow2 = styled.div`
      gap: 4px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      `;
const Icons2 = styled.img`
      width: 0;
      height: 0;
      `;
const RemoveButton = styled.div`
      gap: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f4f6ff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      padding: 2px;
      `;
const ButtonURL4 = styled.button`
      width: 369px;
      gap: 8px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding: 0px;
      border-width: 0px;
      background: none;
      box-sizing: content-box;
      cursor: pointer;
      &: hover {
        opacity: 70%;
  }
      `;
const ButtonURL5 = styled.button`
      width: 369px;
      gap: 8px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      border-width: 0px;
      background: none;
      box-sizing: content-box;
      cursor: pointer;
      &: hover {
        opacity: 70%;
  }
      `;
const FlexRow4 = styled.div`
      gap: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-self: stretch;
      align-items: flex-start;
      padding: 7px 15px;
      `;
const TemplatePersFlexRow4 = styled.div`
      gap: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-self: stretch;
      align-items: flex-start;
      padding: 7px 15px;
      `;
const TemplatePersText10 = styled.div`
      color: #485175;
      font-size: 12px;
      font-weight: 500;
      font-family: Poppins;
      line-height: 20px;
      white-space: nowrap;
      padding: 0px;
      border-width: 0px;
      background: none;
      box-sizing: content-box;
      cursor: pointer;
      `;
const TemplatePersWhiteFlexColumn1 = styled.div`
      gap: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-self: stretch;
      align-items: flex-start;
      background-color: #eaeeff;
      border-radius: 20px;
      `;
const TemplatePersTextbox6 = styled.input`
            font-size: 12px;
            width: 320px;
            gap: 2px;
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            justify-content: flex-start;
            align-self: stretch;
            align-items: center;
            border-style: solid;
            border-color: #eaeeff;
            background-color: #f4f6ff;
            border-radius: 8px;
            padding: 13.2px 15.2px;
            border-width: 0.8px;
            `;
const TemplatePersTextbox5 = styled.input`
      font-size: 12px;
      width: 160px;
      gap: 2px;
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      justify-content: flex-start;
      align-self: stretch;
      align-items: center;
      border-style: solid;
      border-color: #eaeeff;
      background-color: #f4f6ff;
      border-radius: 8px;
      padding: 13.2px 15.2px;
      border-width: 0.8px;
      `;
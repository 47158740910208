import React from "react";
import styled from "styled-components";

export const ButtonsSimpleTemplatePersonalization = props => {
  return (
    <TemplatePersWhiteFlexColumn1>

      {props.buttons.map((btn, i) =>
      (
        <div key={i}>
          <Button6 onClick={() => props.removeButton(i)}>
            <FlexColumn4>
              <FlexRow10>
                <Text11>Button {i + 1}</Text11>
                <RemoveButton>
                  <Icons
                    src={`https://file.rendit.io/n/4kxb1m9fT0cH7qneMzSf.svg`}
                  />
                </RemoveButton>
              </FlexRow10>
            </FlexColumn4>
          </Button6>
          <TemplatePersFlexRow4 style={{ flexDirection: "column" }}>
            <TemplatePersScreen3 style={{ flexDirection: "row" }}>
              <div>
                <TemplatePersText10 >
                  Button Title
                </TemplatePersText10>
                <TemplatePersTextbox5
                  value={props.buttons[i].title} onChange={e => {
                    let newArr = [...props.buttons]; // copying the old datas array
                    newArr[i].title = e.target.value; // replace e.target.value with whatever you want to change it to
                    props.setButtonsL(newArr); // ??
                  }}
                  placeholder="example Title">
                </TemplatePersTextbox5>
              </div>
              <div>
                <TemplatePersText10>
                  Button URL
                </TemplatePersText10>
                <TemplatePersTextbox5
                  value={props.buttons[i].url} onChange={e => {
                    let newArr = [...props.buttons]; // copying the old datas array
                    newArr[i].url = e.target.value; // replace e.target.value with whatever you want to change it to
                    props.setButtonsL(newArr); // ??
                  }}
                  placeholder="example">
                </TemplatePersTextbox5>
              </div>
            </TemplatePersScreen3>
          </TemplatePersFlexRow4>
        </div>
      )
      )}
      <FlexRow4>
        <ButtonURL4 onClick={() => props.addButton()}>
          <FlexRow2>
            <RemoveButton>
              <Icons2 src={`undefined`} />
            </RemoveButton>
            <Text4>Add New Button</Text4>
          </FlexRow2>
          <Text22>you can add upto 4 buttons</Text22>
        </ButtonURL4>
      </FlexRow4>
    </TemplatePersWhiteFlexColumn1>
  );
};

const FlexColumn4 = styled.div`
  width: 371px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 15px 7px 15px;
`;
const FlexRow10 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
`;
const Text11 = styled.div`
  color: #485175;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;
const Icons = styled.img`
  width: 16px;
  height: 16px;
`;
const Button6 = styled.button`
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    opacity: 70%;
  }
`;
const Text4 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 14px;
  white-space: nowrap;
`;
const Text22 = styled.div`
  color: #858caa;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  white-space: nowrap;
`;
const FlexRow2 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const Icons2 = styled.img`
  width: 0;
  height: 0;
`;
const RemoveButton = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f6ff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 2px;
`;
const ButtonURL4 = styled.button`
  width: 369px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    opacity: 70%;
  }
`;
const FlexRow4 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 7px 15px;
`;
const TemplatePersFlexRow4 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 7px 15px;
`;
const TemplatePersScreen3 = styled.div`
  width: 369px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersText10 = styled.button`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
`;
const TemplatePersWhiteFlexColumn1 = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  background-color: #eaeeff;
  border-radius: 20px;
`;
const TemplatePersTextbox5 = styled.input`
  font-size: 12px;
  width: 160px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
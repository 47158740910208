import React from "react";
import { useState, useEffect } from "react";
import "./createOnboard.css";
import "../../index.css";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "reactjs-popup/dist/index.css";  
import { FinishOnboarding } from "./finishOnboarding"; 
import { InputCard } from "./inputCard"; 
import { OnboardUtils } from "./onboardUtils";

import {
  Text1,
  Text5,
  WhiteFlexRowNoCenter,
  Body,
  Paragraph,
  FlexColumn,
  FlexColumnLittle,
  Divider,
  LastWeek,
} from "./styles";
 
const OnboardPage = (props) => {
  const location = useLocation();
  const [usage, setUsage] = useState("");
  const [role, setRole] = useState("");
  const [size, setSize] = useState("");
  const [website, setWebsite] = useState("");
  const [referred, setReferred] = useState("");

  const [user, setUser] = useState({}); // User data objec
  const [step, setStep] = useState(1);
  const [completed, setCompleted] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    updateAuth();
    if (location.state) {
      const { usage, role, size, website, referred, step, completed } = location.state;
      setUsage(usage);
      setRole(role);
      setSize(size);
      setWebsite(website);
      setReferred(referred);
      setStep(step);
      setCompleted(completed);
    }
  }, [location]);

  async function updateAuth() {
    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        console.log("user", user); 
        if (user?.uid?.toString) {
          OnboardUtils.getProfileInfo(user.uid.toString())
            .then((res) => {
                // console.log('CUSTOMER DASHBOARD GOT BACK: ',Object.keys(res), {res})
              if (Object.keys(res)) { 

                const { usage, role, size, website, referred, completed } = res
                console.log( 'User Profile: ', { usage, role, size, website, referred, completed } )
                usage && setUsage(usage);
                role && setRole(role);
                size && setSize(size);
                website && setWebsite(website);
                referred && setReferred(referred);
                completed && setCompleted(completed);
                completed && setStep(1);
              }
            })
            .catch((err) => {
              console.error('ERROR: ',err);
            });
        }
      } else {
        // No user redirect to sign in
        navigate("/login");
      }
    });
  }

  function changeStep() {
    if (step == 5) { 
      submit();
    }
    if (step == 6) { 
      navigate("/");
    }
    setStep(step + 1);
  }

  function changeStepBack() {
    setStep(step - 1);
  }

  function skipStep() {
    console.log('SKIP STEP', step)
    setStep(6);
    submit();
  }

  function submit() {
    console.log('SUBMIT callUpdateProfile:', {usage, role, size, website, referred, completed:true})
    OnboardUtils.callUpdateProfile(user.uid.toString(), {
      ownerUID: user.uid.toString(), usage, role, size, website, referred, completed: true
    }).then(async (response) => {if (response) {console.log("Submit Success");} else {}}).catch(async () => {});
  }

  function renderStep() {
    switch (step) {
      case 1:
        return (
          <InputCard 
            type={'radio'}
            title={"How do you plan to use Addy AI?"} 
            options={[
              { title: "Customer Service", description: "" },
              { title: "Sales", description: "" },
              { title: "Personal", description: "" },
              { title: "Other", description: "" },
            ]}
            fwdBtn={changeStep}
            skipBtn={skipStep} 
            setState={setUsage}
            stateVal={usage} 
            step={step - 1}
            totalSteps={4}
          />
        );
        case 2:
          return (
            <InputCard 
              type={'radio'}
              title={"What's your role?"} 
              options={[
                { title: "Business Owner", description: "" },
                { title: "Customer Service Agent", description: "" },
                { title: "Sales and Marketing", description: "" },
                { title: "Other", description: "" },
              ]}
              fwdBtn={changeStep}
              backBtn={changeStepBack}
              setState={setRole}
              stateVal={role}
              step={step - 1}
              totalSteps={4}
            />
          );
        //   case 3:
        //     return (
        //       <InputCard 
        //         type={'radio'}
        //         title={"What's your company size?"} 
        //         options={[
        //           { title: "1-10 employees", description: "" },
        //           { title: "11- 20 employees", description: "" },
        //           { title: "21 - 50 employees", description: "" },
        //           { title: "51 - 100 employees", description: "" },
        //           { title: "101 - 499 employees", description: "" },
        //           { title: "500+ employees", description: "" },
        //         ]}
        //         fwdBtn={changeStep}
        //         backBtn={changeStepBack}
        //         setState={setSize}
        //         stateVal={size}
        //         step={step - 1}
        //         totalSteps={4}
        //       />
        //     );
    //   case 3:
    //     return (
    //       <InputCard    
    //         type={'text'}
    //         title={"What's your website url"}
    //         fwdBtn={changeStep}
    //         backBtn={changeStepBack}
    //         setState={setWebsite}
    //         stateVal={website}
    //         step={step - 1}
    //         totalSteps={4}
    //       />
    //     ); 
        case 3:
          return (
            <InputCard 
              type={'radio'}
              title={"How did you hear about Addy AI?"} 
              options={[
                { title: "Google Search", description: "" },
                { title: "Twitter", description: "" },
                { title: "LinkedIn", description: "" },
                { title: "Facebook", description: "" },
                { title: "Referral", description: "" },
                { title: "Other", description: "" },
              ]}
              fwdBtn={changeStep}
              backBtn={changeStepBack}
              setState={setReferred}
              stateVal={referred}
              step={step - 1}
              totalSteps={4}
            />
          );
      case 4:
        return ( <FinishOnboarding fwdBtn={changeStep} step={step - 1} /> );
      default:
        //return;
        return (
          <div>
            <Body>
              <Text1>User Profile</Text1>

              <Paragraph>These settings will be used by your assistant</Paragraph>
            </Body>

            <FlexColumn>
              <FlexColumnLittle>
                <WhiteFlexRowNoCenter>
                  <label>Use:</label>
                  <Text5>
                    {/*<OccupationSelection
                      setUsage={setUsage}
                      usage={usage}
                      setOccupation={setOccupation}
                      occupation={occupation}
        />*/}
                  </Text5>
                </WhiteFlexRowNoCenter>
              </FlexColumnLittle>

              <FlexColumnLittle>
                <WhiteFlexRowNoCenter>
                  <label>Assistance Selection:</label>
                  <Text5>
                  {/*<AssistanceSelection setAssist={setAssist} assist={assist} />*/}
                  </Text5>
                </WhiteFlexRowNoCenter>
              </FlexColumnLittle>

              <FlexColumnLittle>
                <WhiteFlexRowNoCenter>
                  <label>Tone:</label>
                  <Text5>
                    {/*<select value={tone} onChange={(event) => setTone(event.target.value)}>
                      <option value="friendly">Friendly</option>
                      <option value="formal">Formal</option>
                      <option value="informal">Informal</option>
                      <option value="funny">Funny</option>
                      <option value="interested">Interested</option>
                      <option value="not interested">Not Interested</option>
                      <option value="excited">Excited</option>
                      <option value="thankful">Thankful</option>
                      <option value="angry">Angry</option>
                      <option value="surprised">Surprised</option>
                    </select>*/}
                  </Text5>
                </WhiteFlexRowNoCenter>
              </FlexColumnLittle>

              <FlexColumnLittle>
                <WhiteFlexRowNoCenter>
                  <label>Note:</label>
                  <Text5>
                  {/*<input type="text" value={note} onChange={(event) => setNote(event.target.value)} />*/}
                  </Text5>
                </WhiteFlexRowNoCenter>
              </FlexColumnLittle>
            </FlexColumn>

            <Body>
              <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
              <LastWeek onClick={submit}>Update</LastWeek>
            </Body>
          </div>
        );
    }
  }

  return (
    <>
      <div className="section" style={{ height: "100vh", justifyContent: props.alone ? "center" : "" }}>
        {renderStep()}
      </div>
    </>
  );
};

export default OnboardPage;

import React, { useEffect, useState } from 'react'
import './home.css'
import "../index.css";
import styled from "styled-components";
import OrganizationUtils from '../services/organization/OrganizationUtils';
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner"; 
import { useLocation } from "react-router-dom"; 
import CreateOrganization from './organization/createOrganization';

import { ProfileUtils } from "./profile/profileUtils";

const orgBackgroundColors = [
    "#9E8FEF",
    // "#F87462",
    // "#60C6D2",
    // "#579CFF",
];

const Home = (props) => {
    const [loadOrgs, setLoadOrgs] = useState(true);
    const [orgs, setOrgs] = useState([]);
    // const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        setLoadOrgs(true)
        // Get user
        getUser(); 
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewBusinessButton = () => {
        return <div className="flex flex-row gap-10">
            <CreateButtonRootRoot onClick={() => navigate("/createOrganization")}>
                <AddIconDiv>
                    <AddIcon
                        src="https://i.imgur.com/1vFxnRz.png"
                    />
                    <WorkflowText1Create>Setup New Business</WorkflowText1Create>
                    <HeroTextOnLigh2t>to train your custom AI assistant</HeroTextOnLigh2t>

                </AddIconDiv>

            </CreateButtonRootRoot>
            <></>
        </div>
    }

    const navigateOrganization = (id) => {
        navigate(`/organization/${id}/apps`);
    }

    const organizationList = () => {
        let list = [];
        for (let i = 0; i < orgs.length; i += 4) {

            list.push(
                <div key={i} className="flex flex-row gap-10">
                    {orgs[i] !== undefined && orgs[i].createButton ?
                        createNewBusinessButton()
                        : orgs[i] !== undefined && orgs[i].organizationId !== undefined ?
                            <WorkflowTypeworkflowsRootRoot onClick={() => navigateOrganization(orgs[i].organizationId)}>
                                <WorkflowGraphic style={{
                                    backgroundColor: `${orgBackgroundColors[Math.floor(Math.random() * orgBackgroundColors.length)]}`
                                }}
                                    src={orgs[i].organizationPhotoUrl}
                                />
                                <WorkflowBodyCTA>
                                    <WorkflowBody>
                                        <WorkflowText1>{orgs[i].organizationName}</WorkflowText1>
                                    </WorkflowBody>
                                </WorkflowBodyCTA>
                            </WorkflowTypeworkflowsRootRoot>
                            :
                            <></>
                    }

                    {orgs[i + 1] !== undefined && orgs[i + 1].organizationId !== undefined ?
                        <WorkflowTypeworkflowsRootRoot onClick={() => navigateOrganization(orgs[i + 1].organizationId)}>
                            <WorkflowGraphic style={{
                                backgroundColor: `${orgBackgroundColors[Math.floor(Math.random() * orgBackgroundColors.length)]}`
                            }}
                                src={orgs[i + 1].organizationPhotoUrl}
                            />
                            <WorkflowBodyCTA>
                                <WorkflowBody>
                                    <WorkflowText1>{orgs[i + 1].organizationName}</WorkflowText1>
                                </WorkflowBody>
                            </WorkflowBodyCTA>
                        </WorkflowTypeworkflowsRootRoot>
                        :
                        <></>
                    }

                    {orgs[i + 2] !== undefined && orgs[i + 2].organizationId !== undefined ?
                        <WorkflowTypeworkflowsRootRoot onClick={() => navigateOrganization(orgs[i + 2].organizationId)}>
                            <WorkflowGraphic style={{
                                backgroundColor: `${orgBackgroundColors[Math.floor(Math.random() * orgBackgroundColors.length)]}`
                            }}
                                src={orgs[i + 2].organizationPhotoUrl}
                            />
                            <WorkflowBodyCTA>
                                <WorkflowBody>
                                    <WorkflowText1>{orgs[i + 2].organizationName}</WorkflowText1>
                                </WorkflowBody>
                            </WorkflowBodyCTA>
                        </WorkflowTypeworkflowsRootRoot>
                        :
                        <></>
                    }

                    {orgs[i + 3] !== undefined && orgs[i + 3].organizationId !== undefined ?
                        <WorkflowTypeworkflowsRootRoot onClick={() => navigateOrganization(orgs[i + 3].organizationId)}>
                            <WorkflowGraphic style={{
                                backgroundColor: `${orgBackgroundColors[Math.floor(Math.random() * orgBackgroundColors.length)]}`
                            }}
                                src={orgs[i + 3].organizationPhotoUrl}
                            />
                            <WorkflowBodyCTA>
                                <WorkflowBody>
                                    <WorkflowText1>{orgs[i + 3].organizationName}</WorkflowText1>
                                </WorkflowBody>
                            </WorkflowBodyCTA>
                        </WorkflowTypeworkflowsRootRoot>
                        :
                        <></>
                    }
                </div>
            )
        }
        return list;
    }

    async function getUser() {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                OrganizationUtils.callGetOrganizations(user.uid.toString()).then(p => {
                    let orgsList = [];
                    orgsList.push({
                        createButton: true
                    });

                    orgsList = orgsList.concat(p);
                    setOrgs(orgsList);
                    setLoadOrgs(false);
                });


                // if (user?.uid?.toString) {
                //     ProfileUtils.getProfileInfo(user.uid.toString())
                //         .then((res) => {
                //             console.log('CUSTOMER DASHBOARD GOT BACK: ', Object.keys(res), { res })
                //             const { usage, occupation, tone, note, assist, completed } = res
                //             if (Object.keys(res) && completed) {
                //                 console.log({ usage, occupation, tone, note, assist, completed })
                //             }
                //             else {
                //                 console.log('REDIRECTING TO PROFILE FOR ONBOARDNG')
                //                 navigate(`/profile`, {
                //                     state: {
                //                         user: user,
                //                     },
                //                 });
                //             }
                //         })
                //         .catch((err) => {
                //             console.error('ERROR: ', err);
                //         });
                // }


            }
            else {
                setLoadOrgs(false);
                navigate("/login");
            }
        });
    }

    return (
        <div className="home-container">
            <div className="home-cover-div" style={{

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
                marginTop: "50px",
                marginLeft: "50px",
                marginRight: "50px",
                borderRadius: "30px",
                // backgroundColor: "#EBF0F8",
                // backgroundColor: "#003C64",
                marginBottom: "50px",
                backgroundSize: "cover",
                backgroundPosition: "center",
                // backgroundImage: "url('https://i.imgur.com/7zBajsN.png')",
                backgroundImage: "url('https://i.imgur.com/0LnBPa4.png')",
                
            }}>
                {/* <div style={{
                    position: "absolute",
                    marginTop: "65px",
                    paddingLeft: "30px"
                }}>
                    <HeroText>Create a personalized AI</HeroText>
                    <HeroText>for your business</HeroText>
                </div> */}

                <div>
                    <HeroText2>Create and train custom AI Assistants</HeroText2>
                    <HeroTextDescription>Train AI assistants for your business to help with emails, answers and tasks 24/7</HeroTextDescription>

                </div>
            </div>
            <div className="flex flex-col items-center grow gap-20">
                {/* <h1 className="text-5xl text-slate-800 dark:text-slate-100 w-[900px] text-center font-semibold">
                    Addy AI for Business
                </h1> */}

                <div className="flex flex-row items-center gap-10" style={{
                    paddingLeft: "25px",
                    paddingRight: "25px"
                }}>


                    {loadOrgs
                        ?
                        <>
                            {createNewBusinessButton()}
                            Loading ...
                            <InfinitySpin
                                color="#0E1221"
                                width="100"
                            />
                        </>
                        :
                        orgs.length > 0
                            ?
                            <div className="flex flex-col items-center gap-10 pb-4">
                                <div style={{
                                    width: "100%",
                                    marginBottom: "15px"
                                }}>
                                    <TitleText>Your businesses</TitleText>
                                    <HeroTextOnLight>Welcome! Your AI assistants will be part of a business. When you create a new business, it gets listed here</HeroTextOnLight>
                                </div>

                                {/* <div className="text-lg text-slate-800 dark:text-slate-100 w-[900px] text-center font-semibold">Your Business AIs</div> */}
                                <div className="flex flex-col gap-10">
                                    {organizationList()}
                                </div>
                            </div>
                            :
                            // <div>
                            //     You haven't created an AI for your business
                            // </div>

                            <div className="flex flex-col items-center gap-10">
                                <div className="text-lg text-slate-800 dark:text-slate-100 w-[900px] text-center font-semibold">Setup your first business</div>
                                <div className="flex flex-row gap-10">
                                    <CreateOrganization />
                                </div>
                            </div>

                        // <div className="flex flex-col items-center gap-10">
                        //     <div className="text-lg text-slate-800 dark:text-slate-100 w-[900px] text-center font-semibold">No Organizations</div>
                        //     <div className="flex flex-row gap-10">
                        //         <CreateAI />
                        //     </div>
                        // </div>
                    }
                </div>
            </div>
        </div>
    )
}


const WorkflowTypeworkflowsRootRoot = styled.div`
            cursor: pointer;
            gap: 24px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            border-style: solid;
            border-color: #eaeeff;
            background-color: #f9faff;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            padding: 14px;
            border-width: 2px;
            min-width: 200px;
            `;
const CreateButtonRootRoot = styled.div`
            cursor: pointer;
            gap: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-style: solid;
            border-color: #eaeeff;
            background-color: #f9faff;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            padding: 14px;
            border-width: 2px;
            `;
const WorkflowGraphic = styled.img`
            width: 193px;
            height: 193px;
            border-radius: 10px;
            object-fit: cover;
            `;
const AddIconDiv = styled.div`
    width: 193px;
    height: 193px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const AddIcon = styled.img`
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
`;
const WorkflowBodyCTA = styled.div`
            gap: 14px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-self: stretch;
            align-items: flex-start;
            `;
const WorkflowBody = styled.div`
            gap: 8px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            `;
const WorkflowText1 = styled.div`
            color: #0d1121;
            font-size: 16px;
            font-weight: 600;
            font-family: Poppins;
            line-height: 22.4px;
            white-space: nowrap;
            `;
const HeroText2 = styled.p`
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    // color: #1D344B;
    color: #FFFFFF;
`;
const HeroTextDescription = styled.p`
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    text-align: center;
    // color: #1D344B;
    color: rgba(255, 255, 255, 0.8);
`;
const HeroTextOnLight = styled.p`
    font-size: 15px;
    font-weight: normal;
    width: 100%;
    text-align: left;
    // color: #1D344B;
    color: rgba(0, 0, 0, 0.5);
`;
const HeroTextOnLigh2t = styled.p`
    font-size: 13px;
    font-weight: normal;
    width: fit-content;
    text-align: center;
    padding-top: 5px;
    // color: #1D344B;
    color: rgba(0, 0, 0, 0.8);
`;
const TitleText = styled.p`
    font-size: 25px;
    font-weight: 600;
    width: 100%;
    letter-spacing: 0.1px;
    text-align: left;
    color: #1D344B;
`;
const WorkflowText1Create = styled.div`
    color: #735DDE;
    font-size: 16px;
    font-weight: 600;
    font-family: Poppins;
    line-height: 22.4px;
    white-space: nowrap;
`;

const LastWeekContain = styled.button`
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 7px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

export default Home

import OrganizationUtils from "../../services/organization/OrganizationUtils";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import add from "../../assets/icons/add.png";


export const KnowledgeBaseTab = (props) => {
    const [organizationData, setOrganizationData] = useState({});

    useEffect(() => {
        // Get user
        getOrganizationName(props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getOrganizationName(props) {
        if (!(props && props.projectData && props.projectData.organizationId)) return "";
        OrganizationUtils.callGetOrganization(props.user.uid, props.projectData.organizationId, props.user.email)
            .then(data => {
                if (data) {
                    setOrganizationData(data);
                }
            })
    }

    function renderKnowledgeBases(knowledgeBases, user, appId) {
        return (

            <div>
                <div>
                    {knowledgeBases && knowledgeBases.length > 0 && (
                        <FlexColumn>
                            <div>
                                {knowledgeBases.map((item) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "350px",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            paddingTop: "5px",
                                            paddingBottom: "5px",
                                            // borderBottom: "0.5px solid rgba(0, 0, 0, 0.2)"
                                        }}>
                                        <div>
                                            <TextKb className="tooltip-container cursor-pointer">
                                                {item.title.length > 28 ? item.title.slice(0, 35) + "..." : item.title}
                                                <span className="tooltip-content">{item.title}</span>
                                            </TextKb>
                                        </div>

                                        <div>
                                            <Text2 onClick={() => props.deleteKnowledgeBase(appId, item.kbID, user.uid)}>
                                                <IconSmall src={`https://i.imgur.com/nSjYcVD.png`} />{" "}
                                                <span id={`kb-del-btn${item.kbID}`}>Delete</span>
                                            </Text2>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </FlexColumn>
                    )}
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "0.5px solid #485175",
                        marginTop: "15px",
                        // width: "fit-content",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "10px",
                        borderRadius: "7px",
                        cursor: "pointer",
                    }}
                    onClick={() => props.openAddKbModal()}>
                    <img alt="link" src={add} className="w-4 h-4 mr-1" />
                    <TextAdd>Add new source</TextAdd>
                </div>
            </div>

        )
    }

    return (
        <div style={{
            width: "100%"
        }}>

            <div style={{
                marginBottom: "30px",
                paddingBottom: "5px",
                borderBottom: "1px solid #eaeeff",
            }}>
                <HeaderText>
                    Knowledge Base
                </HeaderText>

                <SectionTitle1>
                    Train your AI by connecting your website or uploading files
                </SectionTitle1>

            </div>


            <div>
                {renderKnowledgeBases(props.knowledgeBases,
                    props.user,
                    props.appId)}
            </div>

        </div>
    )
}

const HeaderText = styled.div`
  color: #0d1121;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  white-space: nowrap;
`;

const SectionTitle1 = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 5px;
  margin-top: 4px;
`;

const FlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const TextKb = styled.div`
  color: #0d1121;
  font-size: 13px;
  font-family: Poppins;
  white-space: nowrap;
`;

const Text2 = styled.button`
  color: #485175;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const IconSmall = styled.img`
  width: 16px;
  height: 16px;
`;

const TextAdd = styled.button`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
  align-items: center;
`;
import React, { useState, useEffect } from "react";
import "./menuLeftOnboarding.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css"; 
import { MutatingDots } from "react-loader-spinner";

import { blue } from "tailwindcss/colors";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html"; 
import styled from "styled-components";
import { BaseTemplatePersonalization } from "../templatePersonalization/baseTemplatePersonalization"; 
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage"; 

const MenuLeftOnboarding = (props) => {
  // const TEMPLATE = 'template'
  const [back, setBack] = useState(false);
  const CONDITION_TEMPLATE = "condition";
  const TEMPLATE = "template";
  const TEMPLATE_WELCOME = "welcome";
  const TEMPLATE_BUTTONS1 = "buttons1";
  const TEMPLATE_BUTTONS2 = "buttons2";
  const TEMPLATE_BUTTONSURL = "buttonsurl";
  const TEMPLATE_BUTTONSC = "buttonsC";
  const TEMPLATE_CONNECT_WALLET = "connect";
  const TEMPLATE_EMBED = "embed";
  const TEMPLATE_TEXT = "text";
  const TEMPLATE_INPUT = "input";
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [title, setTitle] = useState("Example title");
  const [subtitle, setSubtitle] = useState("example subtitle");
  const [contractAddress, setContractAddress] = useState("");
  const [numberOfNeededTokens, setNumberOfNeededTokens] = useState(0);
  const [chain, setChain] = useState("eth");
  const [text, setText] = useState("example text");
  const [buttonBack, setButtonBack] = useState("Back");
  const [buttonNext, setButtonNext] = useState("Next");
  const [buttonWallet, setButtonWallet] = useState("Connect");
  const [embed, setEmbed] = useState("https://www.youtube.com/embed/xuYRyef-xf4");
  const [buttonColor, setButtonColor] = useState("#0d1121");
  const [logo, setLogo] = useState("");
  const [littleLogo, setLittleLogo] = useState("");
  const [buttons1, setButtons1] = useState([{ title: "example", subtitle: "example", url: "#", img: "" }]);
  const [inputs, setInputs] = useState([{ title: "example-1", value: "example-1", type: "text", order: 1 }]);
  const [formTitle, setFormTitle] = useState(props.selected);

  const [templateTitle, setTemplateTitle] = useState("");
  const [projectId, setProjectId] = useState("");
  const [screenKey, setScreenKey] = useState("");
  var [numberOfButtons1, setNumberOfButtons1] = useState(1);
  const [loadingSave, setLoadingSave] = useState(false);

  const [info, setInfo] = useState({});
  const closeModal = () => props.setIsOpen(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const templatesSelection = [
    { img: "", title: "Welcome", best: "Best for Welcome" },
    { img: "", title: "Button Selection", best: "Best for get Info" },
    { img: "", title: "Button with external Link", type: "", best: "Best to send people to other pages" },
    { img: "", title: "Wallet", best: "Best for validate for NFT" },
    { img: "", title: "Inputs", best: "Best for asking for Information" },
    // , { img: connectWalletTemplate, title: 'Connect Wallet', type: TEMPLATE_CONNECT_WALLET, best: 'Best for Connect Wallet' }
    { img: "", title: "Embed", best: "Best for Embed Content" },
    { img: "", title: "Text", best: "Best for Text" },
  ];

  const conditionTemplatesSelection = [
    { img: "", title: "Button Selection Vertical", type: TEMPLATE_BUTTONSC, best: "Best for Decision" },
  ];

  //   const closeModalD = () => setIsOpenD(false);

  useEffect(() => {
    function getStartInfo() {
      console.log("getStartInfo");
      if (
        props.onboardingInfo !== undefined &&
        Object.keys(props.onboardingInfo).length !== 0 &&
        props.onboardingInfo.template !== "" &&
        props.onboardingInfo.template !== undefined &&
        !back
      ) {
        setSelectedTemplate(props.onboardingInfo.template === undefined ? "" : props.onboardingInfo.template);
      }
      if (props.onboardingInfo.info?.title !== undefined && (selectedTemplate !== "" || props.selected !== "")) {
        setTemplateTitle(props.onboardingInfo.templateTitle === undefined ? "" : props.onboardingInfo.templateTitle);
        if (selectedTemplate === "") {
          setSelectedTemplate(props.onboardingInfo.template === undefined ? "" : props.onboardingInfo.template);
        }
        setProjectId(props.projectId === undefined ? "" : props.projectId);
        setScreenKey(props.screenKey === undefined ? "" : props.screenKey);
        setTitle(props.onboardingInfo.info?.title === undefined ? "Example Title" : props.onboardingInfo.info.title);
        setSubtitle(
          props.onboardingInfo.info?.subtitle === undefined ? "Example Subtitle" : props.onboardingInfo.info.subtitle
        );
        setEmbed(
          props.onboardingInfo.info?.embed === undefined
            ? "https://www.youtube.com/embed/xuYRyef-xf4"
            : props.onboardingInfo.info.embed
        );
        setText(props.onboardingInfo.info?.text === undefined ? "Example Text" : props.onboardingInfo.info.text);
        setLogo(props.onboardingInfo.info?.img === undefined ? "" : props.onboardingInfo.info.img);
        setButtonColor(
          props.onboardingInfo.info?.buttonColor === undefined ? "#0d1121" : props.onboardingInfo.info.buttonColor
        );
        setChain(props.onboardingInfo.info?.chain === undefined ? "eth" : props.onboardingInfo.info.chain);
        setNumberOfNeededTokens(
          props.onboardingInfo.info?.numberOfNeededTokens === undefined
            ? 0
            : props.onboardingInfo.info.numberOfNeededTokens
        );
        setContractAddress(
          props.onboardingInfo.info?.contractAddress === undefined ? "" : props.onboardingInfo.info.contractAddress
        );
        if (props.selected === CONDITION_TEMPLATE) {
          setNumberOfButtons1(2);
          setButtons1(
            props.onboardingInfo.info?.buttons === undefined
              ? [
                  { title: "example", subtitle: "example", url: "#", img: "", type: "text" },
                  { title: "example", subtitle: "example", url: "#", img: "", type: "text" },
                ]
              : props.onboardingInfo.info.buttons
          );
        } else {
          setNumberOfButtons1(1);
          setButtons1(
            props.onboardingInfo.info?.buttons === undefined
              ? [{ title: "example", subtitle: "example", url: "#", img: "", type: "text" }]
              : props.onboardingInfo.info.buttons
          );
        }
        setInputs(
          props.onboardingInfo.info?.inputs === undefined
            ? [{ title: "example-1", value: "example-1", type: "text", order: 1 }]
            : props.onboardingInfo.info.inputs
        );
        setFormTitle(
          props.onboardingInfo.info?.formTitle === ""
            ? selectedTemplate + "_" + props.selectedRow + "_" + props.selectedCol
            : props.onboardingInfo.info.formTitle
        );
      }
      if (props.onboardingInfo.template === undefined) {
        setSelectedTemplate(props.onboardingInfo.template === undefined ? "" : props.onboardingInfo.template);
      }
    }
    getStartInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onImageChange = async (e, i = false, img) => {
    if (i) {
      setLogo(img);
    } else {
      // const [file] = e.target.files;
      // setLogo(URL.createObjectURL(file));

      return new Promise(async (resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(e);

        reader.onloadend = async function () {
          var base64data = reader.result;
          // Upload base64 to firebase storage and get image url
          var getFirebaseImageURL = await uploadBase64ToFirebaseAndGetImageURL(
            base64data,
            `projectData/${projectId}/${screenKey}/logo.png`
          );
          var finalImage =
            !getFirebaseImageURL || !getFirebaseImageURL.length || getFirebaseImageURL.length < 1
              ? base64data
              : getFirebaseImageURL;
          setLogo(finalImage);
          resolve("finalImage");
        };
      });
    }
  };

  const onLogoFunction = async (e, d = false, img) => {
    if (d) {
      setLittleLogo(img);
    } else {
      // const [file] = e.target.files;
      // setLogo(URL.createObjectURL(file));
      return new Promise(async (resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onloadend = async function () {
          var base64data = reader.result;
          // Upload base64 to firebase storage and get image url
          var getFirebaseImageURL = await uploadBase64ToFirebaseAndGetImageURL(
            base64data,
            `projectData/${projectId}/${screenKey}/little_logo.png`
          );
          var finalImage =
            !getFirebaseImageURL || !getFirebaseImageURL.length || getFirebaseImageURL.length < 1
              ? base64data
              : getFirebaseImageURL;
          setLittleLogo(finalImage);
          resolve("finalImage");
        };
      });
    }
  };

  const onButtonImageChange = async (e, i, d = false, img) => {
    if (d) {
      let newArr = [...buttons1];
      setLogo(img);
      newArr[i].img = img;
      setButtons1(newArr);
    } else {
      let newArr = [...buttons1];
      // newArr[i].img = URL.createObjectURL(file); // replace e.target.value with whatever you want to change it to
      // setButtons1(newArr); // ??

      return new Promise(async (resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onloadend = async function async() {
          var base64data = reader.result;
          var buttonID = "button_" + i.toString();
          // Upload base64 to firebase storage and get image url
          var getFirebaseImageURL = await uploadBase64ToFirebaseAndGetImageURL(
            base64data,
            `projectData/${projectId}/${screenKey}/${buttonID}.png`
          );
          var finalImage =
            !getFirebaseImageURL || !getFirebaseImageURL.length || getFirebaseImageURL.length < 1
              ? base64data
              : getFirebaseImageURL;
          newArr[i].img = finalImage;
          setButtons1(newArr);
          resolve("finalImage");
        };
      });
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  function addMoreButtons() {
    setNumberOfButtons1((number) => number + 1);
    setButtons1((prev) => [...prev, { title: "example", subtitle: "example", url: "#", img: "", type: "text" }]);
  }

  function removeSpecificButton(index) {
    setNumberOfButtons1((number) => (number <= 1 ? number : number - 1));
    setButtons1((previousArr) => previousArr.splice(index, 1));
  }

  function addMoreInputs() {
    setInputs((prev) => [
      ...prev,
      {
        title: "example-" + (prev.length + 1),
        value: "example-" + (prev.length + 1),
        type: "text",
        order: prev.length + 1,
      },
    ]);
  }

  function addMoreDrop(index) {
    let newArr = [...inputs]; // copying the old datas array
    if (newArr[index].options === undefined) {
      newArr[index].options = [];
      newArr[index].options.push(""); // replace e.target.value with whatever you want to change it to
    } else {
      newArr[index].options.push(""); // replace e.target.value with whatever you want to change it to
    }
    setInputs(newArr); // ??
  }

  function removeSpecificInput(index) {
    setInputs((previousArr) => previousArr.filter((input, i) => i !== index));
  }

  function removeDrop(index, i) {
    let newArr = [...inputs];
    newArr[index].options.splice(i, 1);
    setInputs(newArr);
  }

  async function selectTemplate(template) { 
    setSelectedTemplate(template);
    setTitle("Example Title");
    setSubtitle("Example Subtitle");
    setEmbed("https://www.youtube.com/embed/xuYRyef-xf4");
    setText("Example Text");
    setLogo("");
    setButtonColor("#0d1121");

    if (props.selected === CONDITION_TEMPLATE) {
      setNumberOfButtons1(2);
      setButtons1([
        { title: "example", subtitle: "example", url: "#", img: "", type: "text" },
        { title: "example", subtitle: "example", url: "#", img: "", type: "text" },
      ]);
    } else {
      setNumberOfButtons1(1);
      setButtons1([{ title: "example", subtitle: "example", url: "#", img: "" }]);
    }

    await delay(500);
  }

  async function uploadBase64ToFirebaseAndGetImageURL(base64, uploadPath) {
    const storage = getStorage();
    const storageRef = ref(storage, uploadPath);
    const getImageDownloadURL = await uploadString(storageRef, base64, "data_url")
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((downloadURL) => {
        return downloadURL;
      })
      .catch((error) => {
        return "";
      });
    return getImageDownloadURL;
  }

  async function storeTemplateInfo() {
    setLoadingSave(true);

    switch (selectedTemplate) {
      case TEMPLATE_WELCOME:
        setInfo({
          title: title,
          subtitle: subtitle,
          text: text,
          img: logo,
          buttonColor: buttonColor,
          buttonNext: buttonNext,
        });
        break;
      case TEMPLATE_BUTTONS1:
        setInfo({
          selection: selectedTemplate === TEMPLATE_BUTTONS1,
          text: text,
          title: title,
          subtitle: subtitle,
          img: logo,
          buttons: buttons1,
          buttonColor: buttonColor,
          buttonBack: buttonBack,
          buttonNext: buttonNext,
        });
        break;
      case TEMPLATE_BUTTONS2:
        setInfo({
          title: title,
          text: text,
          subtitle: subtitle,
          img: logo,
          buttons: buttons1,
          buttonColor: buttonColor,
          buttonBack: buttonBack,
          buttonNext: buttonNext,
        });
        break;
      case TEMPLATE_BUTTONSURL:
        setInfo({
          title: title,
          text: text,
          subtitle: subtitle,
          img: logo,
          buttons: buttons1,
          buttonColor: buttonColor,
          buttonBack: buttonBack,
          buttonNext: buttonNext,
        });
        break;
      case TEMPLATE_BUTTONSC:
        setInfo({
          title: title,
          text: text,
          subtitle: subtitle,
          img: logo,
          buttons: buttons1,
          buttonColor: buttonColor,
          buttonBack: buttonBack,
          buttonNext: buttonNext,
        });
        break;
      case TEMPLATE_CONNECT_WALLET:
        setInfo({
          title: title,
          text: text,
          subtitle: subtitle,
          img: logo,
          buttons: buttons1,
          buttonColor: buttonColor,
          buttonBack: buttonBack,
          buttonNext: buttonNext,
          chain: chain,
          contractAddress: contractAddress,
          numberOfNeededTokens: numberOfNeededTokens,
          littleLogo: littleLogo,
        });
        break;
      case TEMPLATE_EMBED:
        let embedHTML = undefined;
        setInfo({
          title: title,
          subtitle: subtitle,
          text: text,
          embed: embed,
          img: logo,
          buttonColor: buttonColor,
          buttonBack: buttonBack,
          buttonNext: buttonNext,
          embedHTML: embedHTML,
        });
        break;
      case TEMPLATE_TEXT:
        setInfo({
          title: title,
          subtitle: subtitle,
          text: stateToHTML(editorState.getCurrentContent()),
          img: logo,
          buttonColor: buttonColor,
          buttonBack: buttonBack,
          buttonNext: buttonNext,
        });
        break;
      case TEMPLATE_INPUT:
        setInfo({
          title: title,
          subtitle: subtitle,
          text: text,
          img: logo,
          buttonColor: buttonColor,
          buttonBack: buttonBack,
          buttonNext: buttonNext,
          inputs: inputs,
          formTitle: formTitle,
        });
        break;
      default:
        break;
    } 
  }

  useEffect(() => {
    props.setTemplateFlowInfo(props.selectedRow, props.selectedCol, selectedTemplate, info, templateTitle);
    resetTemplateInfo();
    closeModal();
    setLoadingSave(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const templateFlowInfo = async () => {};

  function resetTemplateInfo() {
    setSelectedTemplate("");
    setBack(false);
  }

  // const buttonPersonalizationTemplate =
  //   <div className="">
  //     <div>
  //       <label htmlFor="name" className="block font-bold text-lg dark:text-white mb-2">
  //         Onboarding personalization
  //       </label>
  //       <div className="mb-6">
  //         <div className="mb-6">
  //           <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Title</label>
  //           <input value={title} onChange={e => setTitle(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Hey Bernie 👋" required />
  //         </div>
  //         {props.selected === TEMPLATE &&
  //           <>
  //             <label htmlFor="name" className="block font-bold text-sm dark:text-white mb-2">
  //               Buttons Content
  //             </label>
  //             <button onClick={() => { selectedTemplate === TEMPLATE_BUTTONS2 ? (numberOfButtons1 < 4 ? addMoreButtons() : console.log("No more buttons")) : props.selected === CONDITION_TEMPLATE ? (numberOfButtons1 < 2 ? addMoreButtons() : console.log("No more buttons")) : addMoreButtons(); }} type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
  //               +
  //             </button>
  //             <button onClick={() => { selectedTemplate === TEMPLATE_BUTTONS1 ? (numberOfButtons1 === 2 ? console.log("No more buttons") : removeButtons()) : removeButtons() }} type="button" className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
  //               -
  //             </button>
  //           </>
  //         }
  //         {[...Array(numberOfButtons1)].map((e, i) => (

  //           <div key={i}>
  //             <div className="grid gap-6 mb-6 md:grid-cols-2">
  //               <div>
  //                 <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button title</label>
  //                 <input value={buttons1[i].title} onChange={e => {
  //                   let newArr = [...buttons1]; // copying the old datas array
  //                   newArr[i].title = e.target.value; // replace e.target.value with whatever you want to change it to
  //                   setButtons1(newArr); // ??
  //                 }} type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Uniswap" required />
  //               </div>
  //               <div>
  //                 <label htmlFor="subtitle" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button Subtitle</label>
  //                 <input value={buttons1[i].subtitle} onChange={e => {
  //                   let newArr = [...buttons1]; // copying the old datas array
  //                   newArr[i].subtitle = e.target.value; // replace e.target.value with whatever you want to change it to
  //                   setButtons1(newArr); // ??
  //                 }} type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="https://uniswap.org/" required />
  //               </div>
  //             </div>
  //             <div className="grid gap-6 mb-6 md:grid-cols-2">
  //               <div className="flex flex-col py-[5px] justify-between items-left mb-2 gap-2">
  //                 <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="file_input">Upload Button Image</label>
  //                 <input onChange={e => {
  //                   onButtonImageChange(e, i)
  //                 }}
  //                   className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" type="file" />
  //                 <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG (MAX. 800x400px).</p>
  //               </div>
  //               <div className="flex flex-col py-[5px] mb-2 gap-2">
  //                 <label htmlFor="url" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button URL</label>
  //                 <input value={buttons1[i].url} onChange={e => {
  //                   let newArr = [...buttons1]; // copying the old datas array
  //                   newArr[i].url = e.target.value; // replace e.target.value with whatever you want to change it to
  //                   setButtons1(newArr); // ??
  //                 }} type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="https://uniswap.org/" required />

  //                 <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help"></p>
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //         <div className="grid gap-6 mb-6 md:grid-cols-2">
  //           <div className="flex flex-col py-[5px] justify-between items-left mb-2 gap-2">
  //             <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="file_input">Upload top Image</label>
  //             <input onChange={onImageChange} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" type="file" />
  //             <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG (MAX. 800x400px).</p>
  //           </div>
  //           <div className="flex flex-col items-left mb-2 gap-3">
  //             <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Buttons Colors</label>
  //             <button type="button" style={{ backgroundColor: buttonColor, borderWidth: '1px', color: buttonColor === 'FFFFFF' ? 'black' : 'black' }} onClick={e => setDisplayColorPicker(!displayColorPicker)} className="accordion-title focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
  //               Pick Color
  //             </button>
  //             {
  //               displayColorPicker
  //                 ?
  //                 <div style={styles.popover}>
  //                   <div style={styles.cover} onClick={e => setDisplayColorPicker(!displayColorPicker)} />
  //                   <ChromePicker color={buttonColor} onChange={e => setButtonColor(e.hex)} />
  //                 </div>
  //                 :
  //                 null
  //             }
  //           </div>
  //         </div>
  //         <div className="grid gap-6 mb-6 md:grid-cols-2">
  //           <div className="flex flex-col items-left gap-3">
  //             <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button Back Text</label>
  //             <input value={buttonBack} onChange={e => setButtonBack(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Back" required />
  //           </div>
  //           <div className="flex flex-col justify-between items-left mb-2 gap-2">
  //             <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button Next Text</label>
  //             <input value={buttonNext} onChange={e => setButtonNext(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Next" required />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="accordion">
  //       <div className="accordion-item">
  //         <h2 id="accordion-collapse-heading-1">
  //           <button type="button" onClick={() => setIsActive(!isActive)} className="accordion-title text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
  //             Preview
  //           </button>
  //         </h2>
  //       </div>
  //       {isActive && <div className="accordion-content"> {
  //         selectedTemplate === TEMPLATE_BUTTONS1
  //           ?
  //           <ButtonSelectionTemplate preview={'preview-class'} buttonColor={buttonColor} img={logo} title={title} buttons={buttons1} buttonNext={buttonNext} buttonBack={buttonBack} />
  //           :
  //           <ButtonSelectionVerticalTemplate preview={'preview-class'} buttonColor={buttonColor} img={logo} title={title} buttons={buttons1} buttonBack={buttonBack} buttonNext={buttonNext} />}</div>}
  //     </div>
  //   </div>;

  // const connectWalletPersonalizationTemplate =
  //   <div className="">
  //     <div>
  //       <label htmlFor="name" className="block font-bold text-lg dark:text-white mb-2">
  //         Onboarding personalization
  //       </label>
  //       <div className="mb-6">
  //         <div className="mb-6">
  //           <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Title</label>
  //           <input value={title} onChange={e => setTitle(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Connect wallet" required />
  //         </div>
  //         <div className="mb-6">
  //           <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subtitle</label>
  //           <input value={subtitle} onChange={e => setSubtitle(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Or buy our token ..." required />
  //         </div>
  //         <div className="grid gap-6 mb-6 md:grid-cols-3">
  //           <div>
  //             <label htmlFor="contractAddress" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Contract Adress</label>
  //             <input value={contractAddress} onChange={e => setContractAddress(e.target.value)} type="text" id="contractAddress" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0x0..." required />
  //           </div>
  //           <div>
  //             <label htmlFor="numberOfNeededTokens" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Number of needed tokens</label>
  //             <input value={numberOfNeededTokens} pattern="[0-9]*" onChange={e => {
  //               const re = /^[0-9\b]+$/;
  //               if (e.target.value === '' || re.test(e.target.value)) {
  //                 setNumberOfNeededTokens(e.target.value)
  //               }
  //             }} type="text" id="numberOfNeededTokens" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0x0..." required />
  //           </div>
  //           <div>
  //             <label htmlFor="chain" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Blockchain</label>
  //             <select onChange={e => setChain(e.target.value)} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
  //               <option selected>Choose a Blockchain</option>
  //               <option value="ETH">Ethereum</option>
  //               <option value="SOL">Solana</option>
  //               <option value="POL">Polygon</option>
  //             </select>
  //           </div>
  //         </div>
  //         <label htmlFor="name" className="block font-bold text-sm dark:text-white mb-2">
  //           Marketplaces content
  //         </label>
  //         <button onClick={() => { selectedTemplate === TEMPLATE_BUTTONS2 ? (numberOfButtons1 < 4 ? addMoreButtons() : console.log("No more buttons")) : addMoreButtons(); }} type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
  //           +
  //         </button>
  //         <button onClick={removeButtons} type="button" className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
  //           -
  //         </button>
  //         {[...Array(numberOfButtons1)].map((e, i) => (
  //           <div key={i}>
  //             <div className="grid gap-6 mb-6 md:grid-cols-2">
  //               <div>
  //                 <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button title</label>
  //                 <input value={buttons1[i].title} onChange={e => {
  //                   let newArr = [...buttons1]; // copying the old datas array
  //                   newArr[i].title = e.target.value; // replace e.target.value with whatever you want to change it to
  //                   setButtons1(newArr); // ??
  //                 }} type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Uniswap" required />
  //               </div>
  //               <div>
  //                 <label htmlFor="url" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button URL</label>
  //                 <input value={buttons1[i].url} onChange={e => {
  //                   let newArr = [...buttons1]; // copying the old datas array
  //                   newArr[i].url = e.target.value; // replace e.target.value with whatever you want to change it to
  //                   setButtons1(newArr); // ??
  //                 }} type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="https://uniswap.org/" required />
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //         <div className="grid gap-6 mb-6 md:grid-cols-2">
  //           <div className="flex flex-col py-[5px] justify-between items-left mb-2 gap-2">
  //             <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="file_input">Upload top Image</label>
  //             <input onChange={onImageChange} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" type="file" />
  //             <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG (MAX. 800x400px).</p>
  //           </div>
  //           <div className="flex flex-col items-left mb-2 gap-3">
  //             <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Buttons Colors</label>
  //             <button type="button" style={{ backgroundColor: buttonColor, borderWidth: '1px', color: buttonColor === 'FFFFFF' ? 'black' : 'black' }} onClick={e => setDisplayColorPicker(!displayColorPicker)} className="accordion-title focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
  //               Pick Color
  //             </button>
  //             {
  //               displayColorPicker
  //                 ?
  //                 <div style={styles.popover}>
  //                   <div style={styles.cover} onClick={e => setDisplayColorPicker(!displayColorPicker)} />
  //                   <ChromePicker color={buttonColor} onChange={e => setButtonColor(e.hex)} />
  //                 </div>
  //                 :
  //                 null
  //             }
  //           </div>
  //         </div>
  //         <div className="grid gap-6 mb-6 md:grid-cols-2">
  //           <div className="flex flex-col items-left gap-3">
  //             <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button Back Text</label>
  //             <input value={buttonBack} onChange={e => setButtonBack(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Back" required />
  //           </div>
  //           <div className="flex flex-col justify-between items-left mb-2 gap-2">
  //             <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button Next Text</label>
  //             <input value={buttonNext} onChange={e => setButtonNext(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Next" required />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="accordion">
  //       <div className="accordion-item">
  //         <h2 id="accordion-collapse-heading-1">
  //           <button type="button" onClick={() => setIsActive(!isActive)} className="accordion-title text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
  //             Preview
  //           </button>
  //         </h2>
  //       </div>
  //       {isActive && <div className="accordion-content"> <WalletConnectTemplate buttonColor={buttonColor} preview={'preview-class'} img={logo} title2={subtitle} title1={title} buttons={buttons1} buttonNext={buttonNext} buttonBack={buttonBack} /> </div>}
  //     </div>
  //   </div>;

  // const welcomePersonalizationTemplate = <div className="">
  //   <div>
  //     <label htmlFor="name" className="block font-bold text-lg dark:text-white mb-2">
  //       Onboarding personalization
  //     </label>
  //     <div className="mb-6">
  //       <div className="mb-6">
  //         <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Title</label>
  //         <input value={title} onChange={e => setTitle(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Hey Bernie 👋" required />
  //       </div>
  //       <div className="mb-6">
  //         <label htmlFor="subtitle" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subtitle</label>
  //         <input value={subtitle} onChange={e => setSubtitle(e.target.value)} type="text" id="subtitle" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Welcome to Vinci" required />
  //       </div>
  //       <div className="mb-6">
  //         <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Text</label>
  //         <textarea value={text} onChange={e => setText(e.target.value)} id="text" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Vinci is the #1 network ..."></textarea>
  //       </div>
  //       <div className="grid gap-6 mb-6 md:grid-cols-2">
  //         <div className="flex flex-col py-[5px] justify-between items-left mb-2 gap-2">
  //           <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="file_input">Upload Left Image</label>
  //           <input onChange={onImageChange} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input" type="file" />
  //           <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG (MAX. 800x400px).</p>
  //         </div>
  //         <div className="flex flex-col py-[5px] mb-2 gap-2">
  //           <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button Color</label>
  //           <button type="button" style={{ backgroundColor: buttonColor, borderWidth: '1px', color: buttonColor === 'FFFFFF' ? 'black' : 'black' }} onClick={e => setDisplayColorPicker(!displayColorPicker)} className="accordion-title focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
  //             Pick Color
  //           </button>
  //           {
  //             displayColorPicker
  //               ?
  //               <div style={styles.popover}>
  //                 <div style={styles.cover} onClick={e => setDisplayColorPicker(!displayColorPicker)} />
  //                 <ChromePicker color={buttonColor} onChange={e => setButtonColor(e.hex)} />
  //               </div>
  //               :
  //               null
  //           }
  //           <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help"></p>
  //         </div>
  //       </div>
  //       <div className="flex flex-col justify-between items-left mb-2 gap-2">
  //         <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Button Next Text</label>
  //         <input value={buttonNext} onChange={e => setButtonNext(e.target.value)} type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Next" required />
  //       </div>
  //     </div>
  //   </div>
  //   <div className="accordion">
  //     <div className="accordion-item">
  //       <h2 id="accordion-collapse-heading-1">
  //         <button type="button" onClick={() => setIsActive(!isActive)} className="accordion-title text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
  //           Preview
  //         </button>
  //       </h2>
  //     </div>
  //     {isActive && <div className="accordion-content" ref={componentRef}> <WelcomeTemplate buttonColor={buttonColor} preview={'preview-class'} title={title} subtitle={subtitle} content1={text} img={logo} className={'welcomeTemplate-preview'} buttonNext={buttonNext} /> </div>}
  //   </div>
  // </div>;
  const welcomePersonalizationTemplate = (
    <BaseTemplatePersonalization
      setBack={setBack}
      logo={logo}
      setLogo={setLogo}
      title={title}
      setTitle={setTitle}
      subtitle={subtitle}
      setSubtitle={setSubtitle}
      text={text}
      setText={setText}
      buttonColor={buttonColor}
      setButtonColor={setButtonColor}
      buttonNext={buttonNext}
      setButtonNext={setButtonNext}
      numberOfButtons={numberOfButtons1}
      addButton={addMoreButtons}
      removeButton={removeSpecificButton}
      storeTemplateInfo={storeTemplateInfo}
      resetTemplateInfo={resetTemplateInfo}
      imageFunction={onImageChange}
      info={info}
      loadingSave={loadingSave}
      templateFlowInfo={templateFlowInfo}
      setSelectedTemplate={setSelectedTemplate}
      selectedTemplate={selectedTemplate}
      welcome={true}
      template={<Text1>Select Template</Text1>}></BaseTemplatePersonalization>
  );
  const inputPersonalizationTemplate = (
    <BaseTemplatePersonalization
      setBack={setBack}
      logo={logo}
      setLogo={setLogo}
      title={title}
      setTitle={setTitle}
      subtitle={subtitle}
      setSubtitle={setSubtitle}
      text={text}
      setText={setText}
      buttonColor={buttonColor}
      setButtonColor={setButtonColor}
      buttonNext={buttonNext}
      setButtonNext={setButtonNext}
      addMoreInputs={addMoreInputs}
      addMoreDrop={addMoreDrop}
      removeSpecificInput={removeSpecificInput}
      removeDrop={removeDrop}
      inputs={inputs}
      setInputs={setInputs}
      storeTemplateInfo={storeTemplateInfo}
      resetTemplateInfo={resetTemplateInfo}
      imageFunction={onImageChange}
      info={info}
      loadingSave={loadingSave}
      templateFlowInfo={templateFlowInfo}
      setSelectedTemplate={setSelectedTemplate}
      selectedTemplate={selectedTemplate}
      formTitle={formTitle}
      setFormTitle={setFormTitle}
      input={true}
      template={<Text1>Select Template</Text1>}></BaseTemplatePersonalization>
  );

  const buttonPersonalizationTemplate = (
    <BaseTemplatePersonalization
      logo={logo}
      setLogo={setLogo}
      title={title}
      setTitle={setTitle}
      subtitle={subtitle}
      setSubtitle={setSubtitle}
      text={text}
      setText={setText}
      buttonColor={buttonColor}
      setButtonColor={setButtonColor}
      buttonNext={buttonNext}
      setButtonNext={setButtonNext}
      buttonBack={buttonBack}
      setButtonBack={setButtonBack}
      buttonsL={buttons1}
      setButtonsL={setButtons1}
      onButtonImageChange={onButtonImageChange}
      numberOfButtons={numberOfButtons1}
      addButton={addMoreButtons}
      removeButton={removeSpecificButton}
      storeTemplateInfo={storeTemplateInfo}
      resetTemplateInfo={resetTemplateInfo}
      imageFunction={onImageChange}
      info={info}
      loadingSave={loadingSave}
      templateFlowInfo={templateFlowInfo}
      setSelectedTemplate={setSelectedTemplate}
      selectedTemplate={selectedTemplate}
      condition={props.selected === CONDITION_TEMPLATE}
      externalURL={selectedTemplate === TEMPLATE_BUTTONSURL}
      backButton={true}
      buttons={true}
      template={
        props.selected === CONDITION_TEMPLATE ? <Text1>Select Template</Text1> : <Text1>Select Template</Text1>
      }></BaseTemplatePersonalization>
  );

  const embedPersonalizationTemplate = (
    <BaseTemplatePersonalization
      logo={logo}
      setLogo={setLogo}
      title={title}
      setTitle={setTitle}
      subtitle={subtitle}
      setSubtitle={setSubtitle}
      text={text}
      setText={setText}
      buttonColor={buttonColor}
      setButtonColor={setButtonColor}
      buttonNext={buttonNext}
      setButtonNext={setButtonNext}
      buttonBack={buttonBack}
      setButtonBack={setButtonBack}
      buttonsL={buttons1}
      setButtonsL={setButtons1}
      onButtonImageChange={onButtonImageChange}
      numberOfButtons={numberOfButtons1}
      addButton={addMoreButtons}
      removeButton={removeSpecificButton}
      storeTemplateInfo={storeTemplateInfo}
      resetTemplateInfo={resetTemplateInfo}
      imageFunction={onImageChange}
      info={info}
      loadingSave={loadingSave}
      templateFlowInfo={templateFlowInfo}
      setSelectedTemplate={setSelectedTemplate}
      selectedTemplate={selectedTemplate}
      backButton={true}
      embed={selectedTemplate === TEMPLATE_EMBED}
      textB={selectedTemplate === TEMPLATE_TEXT}
      embedV={embed}
      setEmbed={setEmbed}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      template={
        selectedTemplate === TEMPLATE_EMBED ? <Text1>Select Template</Text1> : <Text1>Select Template</Text1>
      }></BaseTemplatePersonalization>
  );

  const connectWalletPersonalizationTemplate = (
    <BaseTemplatePersonalization
      logo={logo}
      setLogo={setLogo}
      title={title}
      setTitle={setTitle}
      subtitle={subtitle}
      setSubtitle={setSubtitle}
      text={text}
      setText={setText}
      buttonColor={buttonColor}
      setButtonColor={setButtonColor}
      buttonNext={buttonNext}
      setButtonNext={setButtonNext}
      buttonBack={buttonBack}
      setButtonBack={setButtonBack}
      buttonsL={buttons1}
      setButtonsL={setButtons1}
      buttonWallet={buttonWallet}
      setButtonWallet={setButtonWallet}
      onButtonImageChange={onButtonImageChange}
      numberOfButtons={numberOfButtons1}
      addButton={addMoreButtons}
      removeButton={removeSpecificButton}
      storeTemplateInfo={storeTemplateInfo}
      resetTemplateInfo={resetTemplateInfo}
      imageFunction={onImageChange}
      info={info}
      loadingSave={loadingSave}
      templateFlowInfo={templateFlowInfo}
      setSelectedTemplate={setSelectedTemplate}
      selectedTemplate={selectedTemplate}
      chain={chain}
      setChain={setChain}
      setContractAddress={setContractAddress}
      numberOfNeededTokens={numberOfNeededTokens}
      contractAddress={contractAddress}
      setNumberOfNeededTokens={setNumberOfNeededTokens}
      logoFunction={onLogoFunction}
      walletC={true}
      backButton={false}
      template={<Text1>Select Template</Text1>}></BaseTemplatePersonalization>
  );

  return (
    <div className="flex flex-col columns-2">
      <ControlsTabRootRoot>
        <CardssetupPayments>
          <FlexRow>
            <LastWeek
              onClick={() => {
                window.open(props.getPreviewDomain());
              }}>
              Preview
            </LastWeek>
            {/* <LastWeek disabled={!props.enabled || props.loadSaveProject} onClick={async () => {
                            await props.saveProject();
                        }}>
                            {props.loadSaveProject ? 'Saving..' : 'Save'}
                        </LastWeek> */}
            <LastWeek1
              disabled={!props.enabled}
              onClick={() => {
                props.publishWebsite();
              }}>
              Publish
            </LastWeek1>
          </FlexRow>
        </CardssetupPayments>
      </ControlsTabRootRoot>

      <Screentemomplate2>
        <Popup open={props.isOpen} closeOnDocumentClick onClose={closeModal} className={`my my1`} modal nested>
          <>
            {false ? (
              <div className="flex flex-col place-items-center mt-5">
                <MutatingDots color={blue[400]} secondaryColor={blue[400]} visible={true} height={100} width={100} />
              </div>
            ) : (
              <div className={` `}>
                {selectedTemplate === "" ? (
                  <TemplatePickerRootRoot>
                    <FlexRowSelectTemplate>
                      <Text1>Select Template</Text1>
                      <Icons onClick={closeModal} src={`https://file.rendit.io/n/Kd28FmOdtEyZ7AQAx0Zw.svg`} />
                    </FlexRowSelectTemplate>
                    <Divider src={`https://file.rendit.io/n/mhk9mNaoIE6oj4vrE6Ia.svg`} />
                    {(() => {
                      let td = [];
                      if (props.selected === TEMPLATE) {
                        for (let i = 0; i <= templatesSelection.length - 1; i += 2) {
                          td.push(
                            <FlexRow1 key={i}>
                              <WhiteFlexColumn>
                                <FlexRow2>
                                  <FlexColumn>
                                    <Text2>{templatesSelection[i].title}</Text2>
                                    <FlexRow3>
                                      <Text3>{templatesSelection[i].best}</Text3>
                                    </FlexRow3>
                                  </FlexColumn>
                                  <SelectButton
                                    onClick={() => {
                                      selectTemplate(templatesSelection[i].type);
                                    }}>
                                    Select
                                  </SelectButton>
                                </FlexRow2>
                                <TemplateScreens>
                                  <Templates src={templatesSelection[i].img} />
                                </TemplateScreens>
                              </WhiteFlexColumn>
                              {templatesSelection[i + 1] && (
                                <WhiteFlexColumn>
                                  <FlexRow2>
                                    <FlexColumn>
                                      <Text2>{templatesSelection[i + 1].title}</Text2>
                                      <FlexRow3>
                                        <Text3>{templatesSelection[i + 1].best}</Text3>
                                      </FlexRow3>
                                    </FlexColumn>
                                    <SelectButton
                                      onClick={() => {
                                        selectTemplate(templatesSelection[i + 1].type);
                                      }}>
                                      Select
                                    </SelectButton>
                                  </FlexRow2>
                                  <TemplateScreens>
                                    <Templates src={templatesSelection[i + 1].img} />
                                  </TemplateScreens>
                                </WhiteFlexColumn>
                              )}
                            </FlexRow1>
                          );
                        }
                      } else if (props.selected === CONDITION_TEMPLATE) {
                        for (let i = 0; i <= conditionTemplatesSelection.length - 1; i += 2) {
                          td.push(
                            <FlexRow1 key={i}>
                              <WhiteFlexColumn>
                                <FlexRow2>
                                  <FlexColumn>
                                    <Text2>{conditionTemplatesSelection[i].title}</Text2>
                                    <FlexRow3>
                                      <Text3>{conditionTemplatesSelection[i].best}</Text3>
                                    </FlexRow3>
                                  </FlexColumn>
                                  <SelectButton
                                    onClick={() => {
                                      selectTemplate(conditionTemplatesSelection[i].type);
                                    }}>
                                    Select
                                  </SelectButton>
                                </FlexRow2>
                                <TemplateScreens>
                                  <Templates src={conditionTemplatesSelection[i].img} />
                                </TemplateScreens>
                              </WhiteFlexColumn>
                              {conditionTemplatesSelection[i + 1] && (
                                <WhiteFlexColumn>
                                  <FlexRow2>
                                    <FlexColumn>
                                      <Text2>{conditionTemplatesSelection[i + 1].title}</Text2>
                                      <FlexRow3>
                                        <Text3>{conditionTemplatesSelection[i + 1].best}</Text3>
                                      </FlexRow3>
                                    </FlexColumn>
                                    <SelectButton
                                      onClick={() => {
                                        selectTemplate(conditionTemplatesSelection[i + 1].type);
                                      }}>
                                      Select
                                    </SelectButton>
                                  </FlexRow2>
                                  <TemplateScreens>
                                    <Templates src={conditionTemplatesSelection[i + 1].img} />
                                  </TemplateScreens>
                                </WhiteFlexColumn>
                              )}
                            </FlexRow1>
                          );
                        }
                      }
                      return td;
                    })()}
                  </TemplatePickerRootRoot>
                ) : (
                  <div className="onboarding-personalization">
                    {selectedTemplate === TEMPLATE_WELCOME ? (
                      welcomePersonalizationTemplate
                    ) : selectedTemplate === TEMPLATE_BUTTONS1 ||
                      selectedTemplate === TEMPLATE_BUTTONS2 ||
                      selectedTemplate === TEMPLATE_BUTTONSC ||
                      selectedTemplate === TEMPLATE_BUTTONSURL ? (
                      buttonPersonalizationTemplate
                    ) : selectedTemplate === TEMPLATE_CONNECT_WALLET ? (
                      connectWalletPersonalizationTemplate
                    ) : selectedTemplate === TEMPLATE_EMBED || selectedTemplate === TEMPLATE_TEXT ? (
                      embedPersonalizationTemplate
                    ) : selectedTemplate === TEMPLATE_INPUT ? (
                      inputPersonalizationTemplate
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        </Popup>
      </Screentemomplate2>
    </div>
  );
};

const SelectButton = styled.button`
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const Templates = styled.img`
  width: 350px;
  height: 200px;
`;
const FlexColumn = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Icons = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;
const TemplateScreens = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #c9d1ef;
  overflow: hidden;
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
`;
const FlexRow2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 15px;
`;
const FlexRow3 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const FlexRow1 = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const TemplatePickerRootRoot = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 20px;
  padding: 22px 14px 14px 14px;
  border-width: 2px;
`;

const Screentemomplate2 = styled.div`
  width: 0px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
`;
const ControlsTabRootRoot = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 41px 0px 42px;
`;
const CardssetupPayments = styled.div`
  width: auto;
  gap: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  padding: 14px;
`;
const Divider = styled.img`
  height: 1px;
  align-self: stretch;
`;
const FlexRow = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const FlexRowSelectTemplate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 0px 11px;
`;
const LastWeek = styled.button`
  width: 85px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const LastWeek1 = styled.button`
  width: 85px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #0d1121;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
const Text2 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;
const Text1 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
const Text3 = styled.div`
  color: #0d1121;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 14px;
  white-space: nowrap;
`;
const WhiteFlexColumn = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  background-color: #f4f6ff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 11px;
`;

export default MenuLeftOnboarding;

import "./App.css";
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig, firebaseConfigDev } from "../src/core/config/firebaseConfig";
import { VinciSetup1 } from "./components/login/LoginPage";
import Logout from "./views/login/logout"; 
import Home from "./views/home";  
import OnboardPage from "./views/onboarding/onboard";  
import CreateAI from "./views/organization/createAI";
import { Overview } from "./views/organization/overview"; 
import { getAuth } from "firebase/auth"; 
import Feedback from "./views/feedback/feedback";
import SetUpAI from "./views/createOnboarding/setupAI";
import CreateOrganization from "./views/organization/createOrganization";
import { Menu } from "./components/menu/menu"; 

// Initialize Firebase
const app = initializeApp(process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_DEV === "true" ? firebaseConfigDev : firebaseConfig);
getAnalytics(app);

function App() {
    const [darkMode, setDarkMode] = React.useState(localStorage.getItem("darkMode") === "true");
    const [authentication, setAuthState] = useState({
        authenticated: false, //whether the user is allowed to access the protected routes
        initialized: true, //if firebase is still being nitalized
    });
    const [user, setUser] = useState(undefined); // User data object

    React.useEffect(
        () =>
            getAuth().onAuthStateChanged((user) => {
                if (user) {
                    //the user has been logged in
                    setAuthState({
                        authenticated: true, //the user is now authenticated
                        initialized: false,
                    });
                    setUser(user);
                } else {
                    //the user has been logged out
                    setAuthState({
                        authenticated: false, //the user is no longer authenticated
                        initialized: false,
                    });
                }
            }),
        [setAuthState]
    );

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        localStorage.setItem("darkMode", !darkMode);
    };

    const toggleOverlay = (open) => {
        // Get overlay open
        const overlayDiv = document.getElementById("home-overlay-parent");

        if (open) {
            // Close it
            if (overlayDiv) overlayDiv.style.display = "none";
        } else {
            // Open it
            if (overlayDiv) overlayDiv.style.display = "block";
        }
        
    }; 

    if (authentication.initialized) {
        //if firebase is still being initialized, return a Loading component
        return <></>;
    }

    return (
        <ChakraProvider>
            <div className={`${darkMode ? "dark bg-slate-900" : ""} flex-container`}>
                <Router>
                    <div className={`flex-items`}>
                        <Menu darkMode={darkMode} onToggleDarkMode={toggleDarkMode} onToggleOverlay={toggleOverlay}/>
                    </div>
                    <div className={`pl-20 home-container1 ${darkMode ? "dark bg-slate-900" : ""} flex-items`}> 
                        <div
                            className={`home-container1 ${darkMode ? "dark bg-slate-900" : ""} flex-items`}>
                            <div id="home-overlay-parent" className="overlay-div-container" style={{
                                display: "none"
                            }}>

                            </div>
                            <Routes>
                                {/* Homepage */}
                                <Route path="/test" element={<Home />} />
                                <Route path="" element={<Home />} />

                                {/* Log In Out */}
                                <Route path="/login" index element={<VinciSetup1 />} />
                                <Route path="/logout" index element={<Logout />} />

                                {/* Onboarding - profile is not implemented. */}
                                <Route path="/profile" index element={<OnboardPage />} />
                                <Route path="/feedback" element={<Feedback />} />

                                {/* Org Basics */}
                                <Route path="/createOrganization" element={<CreateOrganization alone={true} />} />
                                <Route path="/createdOrganizationSuccess" element={<Home />} />
                                <Route path="/deletedOrganizationSuccess" element={<Home />} />

                                {/* List the organizations */}
                                <Route path="/organization" element={<Overview user={user}/>} /> 
                                <Route path="/organization/:organizationId" element={<Overview user={user}/>} /> 
                                <Route path="/organization/:organizationId/apps" element={<Overview user={user}/>} />

                                {/* App Basics - Create AI assistant - Customer inquiry chatbot */} 
                                <Route path="/new-ai-created/:appId" element={<SetUpAI newApp={true} />} /> 
                                <Route path="/organization/:organizationId/create-ai" element={<CreateAI alone={true} />} />
                                
                                {/* Manage Apps, aka 'projects' - Multi channel apps for customer service solutions.*/}
                                <Route path="/organization/:organizationId/apps/:appId" element={<SetUpAI newApp={false} />} />

                            </Routes>
                        </div>
                    </div>
                </Router>
            </div>
        </ChakraProvider>
    );
}

export default App;

import React from "react";
import styled from "styled-components";
import "./imageRadio.css";

export const CardssetupWorkspace1 = (props) => {
    if (!props.aiTypes || !props.aiTypes.length) return;

    const appTypes = props.aiTypes;
    const renderAITypesHTML = [];
    for (let i = 0; i < appTypes.length; i++) {
        const app = appTypes[i];
        renderAITypesHTML.push(
            <div style={{
                padding: "10px",
            }} onClick={() => {
                if ((app.id).includes("email")) {
                    alert("Email Assistant is disabled for now. \nChoose 'Customer Inquiry Bot'")
                }
            }} >
                <input
                    type="radio" name="ai-type"
                    id={app.id} class="input-hidden"
                    disabled={(app.id).includes("email") ? true : false}
                    />
                <label for={app.id}>
                    <div className="radio-label-div" onClick={() =>
                        props.onAITypeSelected(app.id)}>
                        <img
                            style={{
                                borderRadius: "7px",
                            }}
                            src={app.imageURL}
                            alt={app.title} />
                        <Title>{app.title}</Title>
                    </div>
                </label>
            </div>
        )
    }

    return (
        <CardssetupWorkspaceRoot>
            <Paragraph1>
                Step {props.step}/{props.totalSteps}
            </Paragraph1>

            {/* <Graphic src={`https://file.rendit.io/n/0MsglFDyEvrcpGVwkng3.svg`} /> */}
            <BodyCTA>
                <Body>
                    <Text1>{props.title}</Text1>
                    <Paragraph>
                        {props.subtitle}
                    </Paragraph>
                </Body>

                <div style={{
                    width: "100%",
                    height: "fit-content",
                    borderRadius: "10px",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "horizontal",
                    justifyContent: "center",
                    backgroundColor: "#F5F7FF",
                }}>

                    {renderAITypesHTML}

                </div>

                <DividerCTA>
                    <Divider src={`https://file.rendit.io/n/MyvlevhyFNnV3ACZQYwd.svg`} />
                    <LastWeek onClick={props.function} disabled={!(props.aiTypeSelected.length > 3)}>
                        {props.button}
                    </LastWeek>
                </DividerCTA>
            </BodyCTA>
        </CardssetupWorkspaceRoot>
    );
};

export const SetupBusinessStep1 = (props) => {
    return (
      <CardssetupWorkspaceRoot>
        <Graphic src={`https://file.rendit.io/n/0MsglFDyEvrcpGVwkng3.svg`} />
        <BodyCTA>
          <Body>
            <Text1>{props.title}</Text1>
            <Paragraph>
              {props.subtitle}
            </Paragraph>
          </Body>
          <DividerCTA>
            <Divider src={`https://file.rendit.io/n/MyvlevhyFNnV3ACZQYwd.svg`} />
            <LastWeek onClick={props.function}>
              {props.button}
            </LastWeek>
          </DividerCTA>
        </BodyCTA>
      </CardssetupWorkspaceRoot>
    );
  };

const CardssetupWorkspaceRoot = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px;
  border-width: 2px;
  border-radius: 20px;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
`;
const Graphic = styled.img`
  width: 376px;
  height: 193px;
`;
const BodyCTA = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const Body = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Text1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const Paragraph = styled.div`
  width: 376px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;

const Title = styled.div`
  width: 376px;
  color: #485175;
  font-size: 13px;
  font-family: Poppins;
  font-weight: 600;
  margin-top: 10px;
`;
const Paragraph1 = styled.div`    
  justify-content: right;
  display: flex;
  width: 376px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const DividerCTA = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const Divider = styled.img`
  height: 1px;
  align-self: stretch;
`;
const LastWeek = styled.button`
  width: 344px;
  padding: 13.2px 15.2px;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-width: 0.8px;
  border-radius: 8px;
  border-style: solid;
  border-color: #eaeeff;
  box-sizing: content-box;
  background-color: #745DDE;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

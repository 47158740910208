import styled from "styled-components";

const BoldText = styled.div`
    font-weight: bold;
`
const NormalText = styled.div`

`;
 

const ActionButton = styled.button` 
  width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 8px;
  padding: 10.2px 10.2px;
  border-width: 0.8px;
  box-sizing: content-box; 
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const IconSmall = styled.img`
  width: 16px;
  height: 16px;
`;

export { 
    BoldText,
    ActionButton,
    NormalText,
    IconSmall,
};
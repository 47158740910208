import OrganizationUtils from "../../services/organization/OrganizationUtils";
import styled from "styled-components";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { QuickPrompts } from "./quickPrompts";
import ProjectUtils from "../../services/project/ProjectUtils";
import { AI_APP_API_URL } from "../../globalVariables";


export const QuickPromptsMain = (props) => {
    const [quickPrompts, setQuickPrompts] = useState((props.config &
        props.config.quickPrompts && props.config.quickPrompts.length) ?
        props.config.quickPrompts : []);

    const [addQuickPromptLoading, setAddQuickPromptLoading] = useState(false);

    
    async function addQuickPrompt(quickPrompt) {
        const newPrompts = quickPrompts;

        setQuickPrompts(prevPrompts => [...prevPrompts, quickPrompt]);
        // TODO: Should also update in Chat Preview
        newPrompts.push(quickPrompt);
        updateQuickPrompt(props.appId, props.user.uid, newPrompts);
    }

    async function removeQuickPrompt(quickPromptId) {
        // Filter out the QuickPrompt object which has the given id
        setQuickPrompts(prevPrompts => prevPrompts.filter(prompt => prompt.id !== quickPromptId));
    }

    async function updateQuickPrompt(projectId, requesterUserId, newPrompts) {
        setAddQuickPromptLoading(true);

        const requestURL = `${AI_APP_API_URL}/application/application`;
        await axios
            .get(requestURL, {
                params: {
                    appId: projectId,
                    uid: requesterUserId,
                },
            })
            .then(async (response) => {
                if (response.data.success) {
                    const project = response.data.data;
                    // Get config
                    if (!(project["config"])) {
                        props.showErrorToast("Sorry something went wrong", 6000);
                        setAddQuickPromptLoading(false);
                        return;
                    }

                    const config = project["config"];
                    config["quickPrompts"] = newPrompts;
                    // Update quick prompts
                    updateAppConfig(config);
                    setAddQuickPromptLoading(false);
                    
                } else {
                    props.showErrorToast("Sorry something went wrong", 6000);
                    setAddQuickPromptLoading(false);
                    return;
                }
            })
            .catch((error) => {
                props.showErrorToast("Error", 6000);
                setAddQuickPromptLoading(false);
                return;
            });
    }
    

    async function updateAppConfig(config) {
        ProjectUtils.callUpdateAppInfo(props.user.uid, props.appId, "config", config)
            .then(async (response) => {
                if (response) {
                    props.showSuccessToast(`Updated Successfully!`, 6000);
                } else {
                    props.showErrorToast("Failed to update", 6000);
                }
                return;
            })
            .catch((error) => {
                props.showErrorToast("Failed to update", 6000);
            });
    }


    return (
        <div style={{
            width: "100%"
        }}>

            <div style={{
                marginBottom: "30px",
                paddingBottom: "5px",
                borderBottom: "1px solid #eaeeff",
            }}>
                <HeaderText>
                    Quick Prompts
                </HeaderText>

                <SectionTitle1>
                    Give your customers quick answers to common queries
                </SectionTitle1>

            </div>

            <div style={{
                marginBottom: "30px"
            }}>
                <QuickPrompts config={props.config}
                    addQuickPromptLoading={addQuickPromptLoading}
                    addQuickPrompt={addQuickPrompt}/>
            </div>

        </div>
    )
}

const HeaderText = styled.div`
  color: #0d1121;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  white-space: nowrap;
`;

const LastWeek1 = styled.button`
  width: 150px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const SectionTitle1 = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 5px;
  margin-top: 4px;
`;

import axios from "axios";
import { AI_APP_API_URL, apiURL } from '../../globalVariables';


const ProjectUtils = {
    callGetProject: async function (projectId, userId) {
        const requestURL = `${apiURL}/project/getproject`;
        var data = null;
        await axios.get(requestURL, {
            params: {
                projectId: projectId,
                requesterUserId: userId,
            }
        }).then(async (response) => {
            if (response.data.success) {
                data = response.data.data;
                return data
                // Navigate to
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            console.log(error)
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return false;
            }
            return false;
        })
        return data
    },


    callGetOnboardView: async function () {
        const requestURL = `${apiURL}/public/onboardingview`
        return await axios.get(requestURL, {
            params: {
                url: "https://vinci-onboarding-repos.github.io/123123-c5cf9c9c-ab44-49b2-9060-/",
                API_KEY: '123123-c5cf9c9c-ab44-49b2-9060-',
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetProjects: async function (requesterUserId) {
        const requestURL = `${apiURL}/project/getprojects`
        return await axios.get(requestURL, {
            params: {
                requesterUserId: requesterUserId
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetonboardingbyorganization: async function (requesterUserId, orgId) {
        const requestURL = `${apiURL}/project/getonboardingbyorganization`
        return await axios.get(requestURL, {
            params: {
                requesterUserId: requesterUserId,
                organizationID: orgId,
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetAllAppsByOrganizationIdAndUserId: async function (uid, orgId) {
        const requestURL = `${AI_APP_API_URL}/application/get-ai-applications`;
        return await axios.get(requestURL, {
            params: {
                uid: uid,
                organizationId: orgId,
            }
        }).then(async (response) => {

            if (response.data.success) {
                if (response.data && response.data.apps) {
                    return response.data.apps;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callUpdateAppInfo: async function(requesterUserId, appId, attribute, value) {
        const requestURL = `${AI_APP_API_URL}/application/update-app-info`
        return await axios.post(requestURL, {
            requesterUserId: requesterUserId,
            appId: appId,
            attribute: attribute,
            value: value,
        }).then((response) => {
            return response.data.success;
        }).catch((error) => {
            // TODO: Show error
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetonboardingusers: async function (requesterUserId, projId) {
        const requestURL = `${apiURL}/project/getonboardingusers`
        return await axios.get(requestURL, {
            params: {
                requesterUserId: requesterUserId,
                projectId: projId,
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetonboardingviews: async function (requesterUserId, projId) {
        const requestURL = `${apiURL}/project/getonboardingviews`
        return await axios.get(requestURL, {
            params: {
                requesterUserId: requesterUserId,
                projectId: projId,
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callSetProjects: async function (projectId, projectData) {
        const requestURL = `${apiURL}/project/updateprojectinfo`
        return await axios.post(requestURL, {
            projectData: projectData,
            projectId: projectId,
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callNewOnboarding: async function (projectID, onboardingName, user) {
        const requestURL = `${apiURL}/project/createnewonboarding`

        await axios.post(requestURL, {
            projectId: projectID,
            onboardingName: onboardingName,
            creatorUserId: user,
        }).then((response) => {
            if (response.data.success) {
                return response;
            } else {
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
        })
    },

    callCreateProject: async function (projectID, projectName
        , featureManagement, featureOnboarding, featurePayments
        , organizationID, user, projImage) {
        const requestURL = `${apiURL}/project/createnewproject`

        return await axios.post(requestURL, {
            projectId: projectID,
            projectName: projectName,
            creatorUserId: user,
            onboarding: featureOnboarding,
            management: featureManagement,
            payments: featurePayments,
            organizationID: organizationID,
            profilePhotoUrl: projImage,
        }).then((response) => {
            if (response.data.success) {
                return response.data.success;
            } else {
                // TODO: Show error
                return false
            }
        }).catch((error) => {
            // TODO: Show error
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return;
            }
            return;
        })
    }
}

export default ProjectUtils;
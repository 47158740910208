import { getAuth, signOut } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in
        signOut(auth)
          .then(() => {
            // Sign-out successful.
            console.log("User Signed Out!");
            navigate("/"); // Redirect to the root path
          })
          .catch((error) => {
            // An error happened.
            console.log("Error signing out:", error);
          });
      }
    });
  }, [navigate]);

  return null; // Render nothing in the component
};

export default Logout;

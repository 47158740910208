import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { getAuth } from "firebase/auth";
import OrganizationUtils from "../../services/organization/OrganizationUtils";
import axios from "axios";
import './overview.css'
import { InfinitySpin } from "react-loader-spinner";
import { ORGANIZATION_API_URL } from "../../globalVariables";
import {
  TextEmail,
  Text2Delete,
  IconSmall,
  SectionTitle1,
  TextDescription,
  Text5,
  FlexColumnLittle,
  FlexRow3,
  FeedBackText,
  LastWeekContain,
  Textbox,
  PublishingCardsprojectsRootRoot,
  PublishingBody,
  PublishingText1,
  PublishingText2,
  ScrollviewRootRoot,
  FlexColumn,
  SectionContainer
} from "./organizationMembers-styled.js";

export const OrganizationMembers = props => {
    const [user, setUser] = useState(undefined); // User data object 
    const { organizationId } = useParams(); 

    const [addMemberEmail, setAddMemberEmail] = useState("");
    const [canAddMember, setCanAddMember] = useState(false);
    const [members, setMembers] = useState([]);


    useEffect(() => {

        const auth = getAuth();
        auth.onAuthStateChanged(async (user) => {
            setUser(user);
            getOrganizationMembers(organizationId, user.uid, user.email);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[organizationId]);

    function getOrganizationMembers(organizationId, userId, requesterEmail) {
        OrganizationUtils.callGetOrganizationMembers(userId, organizationId,
            requesterEmail)
            .then(async response => {
                if (response) {
                    setMembers(response);
                } else {
                    setMembers([]);
                }
            }).catch((error) => {
                setMembers([]);
            });
    }


    function deleting() {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Deleting</PublishingText1>
                    <PublishingText2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <InfinitySpin
                                color="#0E1221"
                            />
                        </div>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        )
    }

    function membersList(members, user) {
        const membersRender = [];
        // Add this user (who's admin)
        for (let i = 0; i < members.length; i++) {
            const member = members[i];
            membersRender.push(
                <div className="flex flex-row items-center">
                    <div style={{
                        minWidth: "100px",
                        maxWidth: "260px",
                        paddingBottom: "10px",
                        width: "250px"
                    }}>
                        <TextEmail className="tooltip-container">{(member.email).length > 28 ?
                            (member.email).slice(0, 35) + "..." : member.email}
                            {/* <span className="tooltip-content">{member.email}</span> */}
                        </TextEmail>
                    </div>
                    <div>
                        <Text2Delete onClick={() => props.deleteMember(member)}>
                            <IconSmall src={`https://i.imgur.com/nSjYcVD.png`} /> <span>Remove</span>
                        </Text2Delete>
                    </div>
                </div>
            )
        }
        return membersRender;

    }

    function isValidEmail(email) {
        // Regular expression for validating an email address pattern
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Test the given email against the regular expression and return the result
        return emailRegex.test(email);
    }

    const handleAddMemberEmailChange = event => {
        setAddMemberEmail(event.target.value);
        // set if URL is valid
        if ((event.target.value).length > 3) {
            setCanAddMember(isValidEmail(event.target.value)
                && !(memberIsAlreadyAdded(event.target.value)));

            if (memberIsAlreadyAdded(event.target.value)) {
                addFeedback(`${event.target.value} already added`,
                    "add-member-feedback-text",
                    3000, "grey");
            }
        } else {
            setCanAddMember(false);
        }

    };

    function memberIsAlreadyAdded(email) {
        return members.some(obj => obj.email === email);
    }
    const addFeedback = (text, idSelector, duration, color) => {
        const element = document.getElementById(idSelector);
        element.innerHTML = text;
        element.style.color = color;
        setTimeout(() => {
            // Set back to what it was
            element.style.color = "transparent";
            element.innerHTML = "";
        }, duration);
    }

    async function handleAddMember(email) {

        addFeedback("Adding member...", "add-member-feedback-text",
            4000, "grey");
        const requestURL = `${ORGANIZATION_API_URL}/organization/addmember`
        await axios.post(requestURL, {
            requesterUserId: user.uid,
            organizationId: props.organizationId,
            memberEmail: email,
        }).then(async (response) => {
            if (response.data.success) {
                setAddMemberEmail("");
                addFeedback(`Successfully sent invitation to ${email}.`, "add-member-feedback-text",
                    10000, "green");
                return;
            } else {
                addFeedback("Failed to add email", "add-member-feedback-text",
                    3000, "red");
                return;
            }
        }).catch((error) => {
            console.error(error);
            addFeedback("Error adding email", "add-member-feedback-text",
                3000, "red");
            return;
        })
    }

    return (
        <ScrollviewRootRoot>
            <FlexColumn>
                <SectionTitle1>
                    Members
                </SectionTitle1>

                <SectionContainer>

                    {user && user.email &&
                        <div className="flex flex-row items-center">
                            <div style={{
                                minWidth: "100px",
                                maxWidth: "260px",
                                width: "250px",
                                paddingBottom: "10px",
                            }}>
                                <TextEmail className="tooltip-container cursor-pointer">{(user.email).length > 28 ?
                                    (user.email).slice(0, 35) + "..." : user.email}
                                    <span className="tooltip-content">{user.email}</span>
                                </TextEmail>
                            </div>
                            {/* <div>
                            <Text2Delete onClick={() => props.deleteMember(user)}>
                                <IconSmall src={`https://i.imgur.com/nSjYcVD.png`} /> <span>Remove</span>
                            </Text2Delete>
                        </div> */}
                        </div>

                    }

                    {members && members.length > 0 ? membersList(members, user) :

                        <div style={{
                            marginTop: "10px"
                        }}>
                            <TextDescription>
                                You're the only member.
                            </TextDescription>
                            <TextDescription>
                                You can add your business emails as members below to enable
                                them to use Addy AI within this business.
                            </TextDescription>
                        </div>
                    }

                    <div style={{
                        width: "100%",
                        marginTop: "25px",
                        marginBottom: "15px",
                        borderBottom: "1px solid #eaeeff"
                    }}></div>

                    <FlexColumnLittle>

                        <Text5>Invite member</Text5>
                        <Text2Delete style={{width: "55%", textAlign: "left",
                            fontSize: "11px", marginBottom: "15px"}}>
                        An email invite will be sent.
                        Membership is confirmed once the invite is accepted.
                        If not received, please ask them to check their spam folder.
                        </Text2Delete>
                        <FlexRow3>
                            {/* <WhiteText>Code:</WhiteText> */}
                            <Textbox
                                value={addMemberEmail}
                                onChange={(e) => handleAddMemberEmailChange(e)}
                                placeholder="Enter email address">
                            </Textbox>
                            <LastWeekContain disabled={!canAddMember}
                                onClick={() => handleAddMember(addMemberEmail)}>
                                Invite
                            </LastWeekContain>
                        </FlexRow3>
                        <FeedBackText id="add-member-feedback-text">&nbsp;</FeedBackText>
                    </FlexColumnLittle>

                </SectionContainer>






            </FlexColumn>



        </ScrollviewRootRoot>
    );
};
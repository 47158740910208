import React from "react";
import styled from "styled-components";

export const EmbedTemplatePersonalization = props => {
  return (
    <TemplatePersWhiteFlexColumn1>
      <TemplatePersScreen3 style={{ flexDirection: "column" }}>
        <TemplatePersText10>
          Embed
        </TemplatePersText10>
        <TemplatePersTextbox>
          <TemplatePersSetupPayments
            value={props.embed}
            onChange={e => props.setEmbed(e.target.value)}
            placeholder={`Placeholder`} />
        </TemplatePersTextbox>
      </TemplatePersScreen3>
    </TemplatePersWhiteFlexColumn1>
  );
};

const TemplatePersTextbox = styled.div`
  height: 26px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  background-color: #f4f6ff;
`;
const TemplatePersSetupPayments = styled.input`
  width: 335px;
  gap: 2px;
  display: inline-block;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  color: #c9d1ef;
  white-space: nowrap;
  outline-width: 0px;
  border-width: 0px;
  background: none;
  color: black;
  :: placeholder {
    color: #c9d1ef;
  }
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const TemplatePersScreen3 = styled.div`
  width: 329px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersText10 = styled.button`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
`;
const TemplatePersWhiteFlexColumn1 = styled.div`
  gap: 16px;
  margin-left: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  background-color: #eaeeff;
  border-radius: 20px;
`;
import React from 'react'
import { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import './createOrgOrAI.css'
import "../../index.css";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { AI_APP_API_URL, apiURL, KNOWLEDGE_BASE_API_URL, ORGANIZATION_API_URL,
    DEFAULT_ORGANIZATION_COVER_PHOTO} from '../../globalVariables';
import 'reactjs-popup/dist/index.css';
import OrganizationUtils from "../../services/organization/OrganizationUtils";
import ProjectUtils from '../../services/project/ProjectUtils';
import UserUtils from '../../services/user/UserUtils';
import { CardssetupWorkspace1, SetupBusinessStep1 } from '../../components/simpleComponents';
import {
    CardsSetupOrganization2,
    CardsSetupBusiness2,
    CardsSetupBusinessStep3Configure,
    CardsConnectYourSupportInbox1,
    CardsPromptGmailAppPassword,
    CardSuccessPromptTrainPopUp,
    CardsSetupBusinessPhoto
} from './createOrganizationStep2';
import { CreateOrganizationStepLoading } from './createOrganizationStepLoading';
import { CardsSetupOrganization3 } from './createOrganizationStep3'; 
import styled from "styled-components";
import Modal from "react-modal";

function isValidUrl(str) {
    const pattern = /^https?:\/\/[\w.-]+\.[a-z]{2,}(\/[\w.-]+)*$/i;
    return pattern.test(str) && str.length >= 7;
}

const CreateOrganization = (props) => {

    const [user, setUser] = useState({}); // User data object
    const [projectName, setProjectName] = useState('');
    const [projectID, setProjectID] = useState("my-awesome-business-id");
    const [organizationName, setOrganizationName] = useState('');
    const [organizationID, setOrganizationID] = useState("my-awesome-organization-id");
    const [appId, setAppId] = useState("my-awesome-organization-id");
    const [orgImage, setOrgImage] = useState(null);
    const [projImage, setProjImage] = useState("");
    const [addKbModalIsOpen, setAddKbModalIsOpen] = React.useState(false);
    const [knowledgeBases, setKnowledgeBases] = useState([]); // Array of objects
    const [emailAddress, setEmailAddress] = useState("");
    const [twoFactorState, setTwoFactorState] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [emailClient, setEmailClient] = useState("");
    const [emailAddressValid, setEmailAddressValid] = useState(false);
    const [verificationLoading, setVerificationLoading] = useState(true); // At the start; kbs are loading
    const [verificationState, setVerificationState] = useState(false);
    const [verifyModalIsOpen, setVerifyModalIsOpen] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationCodeFormatValid, setVerificationCodeFormatValid] = useState(false);
    const [AIType, setAIType] = useState("");
    const [currentURL, setCurrentURL] = useState("");
    const [validURL, setValidURL] = useState(false);
    const [knowledgeBaseIDs, setKnowledgeBaseIDs] = useState([]);
    const [existingKbs, setExistingKbs] = useState([]);
    const [kbsLoading, setKbsLoading] = useState(true); // At the start; kbs are loading
    const [step, setStep] = useState(1);

    const [upgradePremiumModalOpen, setUpgradePremiumModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorReason, setErrorReason] = useState('');

    const navigate = useNavigate();

    const typesOfAIApps = [{
        title: "AI Email Assistant",
        imageURL: "https://i.imgur.com/DxuBLsu.png",
        id: "ai-email-assistant",
    }, {
        title: "Customer Inquiry Bot",
        imageURL: "https://i.imgur.com/8T2G64S.png",
        id: "customer-inquiry-bot",
    }]


    function showErrorToast() {
        document.getElementById('toast-error').style.setProperty('display', 'block', 'important');

        setTimeout(() => {
            document.getElementById('toast-error').style.setProperty('display', 'none', 'important');
        }, 3000);
    }

    function showCreatedToast() {
        document.getElementById('toast-created').style.setProperty('display', 'block', 'important');

        setTimeout(() => {
            document.getElementById('toast-created').style.setProperty('display', 'none', 'important');
        }, 3000);
    }

    function showHTTPFeedbackToast() {
        document.getElementById('toast-http-feedback').style.setProperty('display', 'block', 'important');

        setTimeout(() => {
            document.getElementById('toast-http-feedback').style.setProperty('display', 'none', 'important');
        }, 5000);
    }

    function showSuccessToast(message, duration = 3000) {
        const element = document.getElementById("success-toast");
        if (!element) {
          return;
        }
    
        element.style.setProperty("display", "block", "important");
        element.innerHTML = message;
        setTimeout(() => {
          // Set back to what it was
          element.style.setProperty("display", "none", "important");
          element.innerHTML = "";
        }, duration);
      }
    
      function showErrorToast(message = "Error", duration = 3000) {
        const element = document.getElementById("error-toast");
        if (!element) return;
    
        element.style.setProperty("display", "block", "important");
        element.innerHTML = message;
        setTimeout(() => {
          // Set back to what it was
          element.style.setProperty("display", "none", "important");
          element.innerHTML = "";
        }, duration);
      }

    function getKnowledgeBasesForThisApp(appId, uid) {
        OrganizationUtils.callGetKnowledgeBasesForAppID(appId, uid)
          .then(async (response) => {
            if (response) {
              setKnowledgeBases(response);
              return;
            }
            // No response
            setKnowledgeBases([]);
          })
          .catch((error) => {
            console.error(error);
            setKnowledgeBases([]);
            showErrorToast("Error: Failed to get Knowledge Bases", 6000);
          });
      }

    useEffect(() => {
        // Get user
        updateAuth();

        async function hideToasts() {
            document.getElementById('toast-save').style.setProperty('display', 'none', 'important');
            document.getElementById('toast-error').style.setProperty('display', 'none', 'important');
            document.getElementById('toast-created').style.setProperty('display', 'none', 'important');
            document.getElementById('success-toast').style.setProperty('display', 'none', 'important')
            document.getElementById('error-toast').style.setProperty('display', 'none', 'important');
            document.getElementById('toast-http-feedback').style.setProperty('display', 'none', 'important');
        }

        hideToasts()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeOrganization = event => {
        setOrganizationName(event.target.value);
        // if user has not chosen to edit ID, generate ID for them
        if (event.target.value.length > 3) {
            // find an unused project ID
            findAndSetUnusedIDOrganization(event.target.value);
            findAndSetUnusedIDApp(`app-${event.target.value}`)
        }
    };

    function isValidEmail(email) {
        // Regular expression for validating an email address pattern
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Test the given email against the regular expression and return the result
        return emailRegex.test(email);
    }
    function verificationCodeFormatIsValid(code) {
        // Regular expression for validating a 6-digit number pattern
        const codeRegex = /^\d{6}$/;

        // Test the given code against the regular expression and return the result
        return codeRegex.test(code);
    }

    const handleEmailAddressChange = event => {
        setEmailAddress(event.target.value);
        setEmailAddressValid(isValidEmail(event.target.value));
    };

    const handleTwoFactorAuthSelected = event => {
        setTwoFactorState(event.target.value);
    }

    const manuallyChangeTwoFactorAuth = (val) => {
        setTwoFactorState(val);
    }

    const handlePasswordChange = event => {
        setEmailPassword(event.target.value);
    };

    const handleEmailClientChange = client => {
        setEmailClient(client);
    }

    const handleVerificationCodeChange = code => {
        setVerificationCode(code.target.value);
        // Set if code format valid
        setVerificationCodeFormatValid(verificationCodeFormatIsValid(
            code.target.value
        ));
    }
    
    function openUpgradePremiumModal() {
        setUpgradePremiumModalOpen(true);
    }

    const closeUpgradePremiumModal = () => {
        setUpgradePremiumModalOpen(false);
    }

    function handleCloseUpgradeModal() {
        setUpgradePremiumModalOpen(false);
        setStep(6);
    }

    async function getExistingKnowledgeBaseForUID(uid) {
        OrganizationUtils.callGetExistingKnowledgeBases(uid)
            .then(async response => {
                if (response) {
                    setExistingKbs(response);
                    setKbsLoading(false);
                }
                setKbsLoading(false);
            }).catch(async () => {
                setKbsLoading(false);
                await showErrorToast()
            });
    }

    async function IDExists(endpoint, typeId, id) {
        const requestURL = endpoint
        const exists = await axios.get(requestURL, {
            params: {
                [typeId]: id,
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.data.exists) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            // TODO: Show error
            return false;
        })
        return exists;
    }

    async function findAndSetUnusedIDOrganization(organizationName) {
        let numberOfGenerations = 0;
        let generatedID = generateRandomID(organizationName);
        const exists = await IDExists(`${ORGANIZATION_API_URL}/organization/organizationexistsbyid`, 'organizationID', generatedID);
        if (exists) {
            // try 5 more times to find one that does not exist
            while (numberOfGenerations < 5) {
                // Generate new ID
                const newGeneratedID = generateRandomID(organizationName);
                const existsToo = await IDExists(`${ORGANIZATION_API_URL}/organization/organizationexistsbyid`, 'organizationID', newGeneratedID);
                if (existsToo && numberOfGenerations === 4) {
                    // Just set a random one, final iteration
                    setOrganizationID(`organization-${uuidv4()}`);
                    break;
                } else if (!existsToo) {
                    setOrganizationID(newGeneratedID);
                    break;
                }
                numberOfGenerations += 1;
            }
        } else {
            // Does not exists
            setOrganizationID(generatedID);
        }
    }

    async function findAndSetUnusedIDApp(appName) {
        let numberOfGenerations = 0;
        let generatedID = generateRandomID(appName);
        const exists = await IDExists(`${AI_APP_API_URL}/application/exists`, 'appID', generatedID);
        if (exists) {
            // try 5 more times to find one that does not exist
            while (numberOfGenerations < 5) {
                // Generate new ID
                const newGeneratedID = generateRandomID(appName);
                const existsToo = await IDExists(`${AI_APP_API_URL}/application/exists`, 'appID', newGeneratedID);
                if (existsToo && numberOfGenerations === 4) {
                    // Just set a random one, final iteration
                    setAppId(`app-${uuidv4()}`);
                    break;
                } else if (!existsToo) {
                    setAppId(newGeneratedID);
                    break;
                }
                numberOfGenerations += 1;
            }
        } else {
            // Does not exists
            setAppId(generatedID);
        }
    }

    async function findAndSetUnusedID(projectName) {
        let numberOfGenerations = 0;
        let generatedID = generateRandomID(projectName);
        const exists = await IDExists('/project/projectexistsbyid', 'projectID', generatedID);
        if (exists) {
            // try 5 more times to find one that does not exist
            while (numberOfGenerations < 5) {
                // Generate new ID
                const newGeneratedID = generateRandomID(projectName);
                const existsToo = await IDExists('/project/projectexistsbyid', 'projectID', newGeneratedID);
                if (existsToo && numberOfGenerations === 4) {
                    // Just set a random one, final iteration
                    setProjectID(`project-${uuidv4()}`);
                    break;
                } else if (!existsToo) {
                    setProjectID(newGeneratedID);
                    break;
                }
                numberOfGenerations += 1;
            }
        } else {
            // Does not exists
            setProjectID(generatedID);
        }
    }

    function generateRandomID(name) {
        // Replace all white spaces with hyphens
        const formattedOrganizationName = name.trim().toLowerCase()
            .replace(/\s+/g, '-');
        const proposedIDLength = 30;
        const formattedLength = formattedOrganizationName.length;

        const remainingLength = proposedIDLength - formattedLength;
        if (remainingLength > 0) {
            // there's room to add an extra UUID
            const randomID = uuidv4();
            if (randomID.length > remainingLength) {
                // take substring of randomID
                const randomIDSubstring = randomID.substring(0, remainingLength);
                const generatedID = `${formattedOrganizationName}-${randomIDSubstring}`;
                return generatedID;
            } else {
                // remaining length > random ID length. use all random ID
                const generatedID = `${formattedOrganizationName}-${randomID}`;
                return generatedID;
            }
        } else {
            return formattedOrganizationName;
        }
    }

    function getTitleById(id) {
        const app = typesOfAIApps.find(app => app.id === id);
        return app ? app.title : "n/a";
    }

    function haveSameElements(array1, array2) {
        if (array1.length !== array2.length) {
            return false;
        }

        const sortedArray1 = array1.slice().sort();
        const sortedArray2 = array2.slice().sort();

        for (let i = 0; i < sortedArray1.length; i++) {
            if (sortedArray1[i] !== sortedArray2[i]) {
                return false;
            }
        }

        return true;
    }

    async function hideRenderAddNewKnowledgeBase() {
        closeAddKbModal();
    }

    const closeAddKbModal = () => {
        setAddKbModalIsOpen(false);
    };

    async function updateKnowledgeBase() {
        // Get all the knowledge base ids.
        // send to update API
        const oldKnowkedgeBaseIds = knowledgeBases.map((obj) => obj.id);
        const newKnowledgeBaseIDs = knowledgeBaseIDs.map((obj) => obj.id);

        if (haveSameElements(oldKnowkedgeBaseIds, newKnowledgeBaseIDs)) {
            // It's the exact same, no change. just close modal
            closeAddKbModal();
            return;
        }
        // it's different call update
        OrganizationUtils.callUpdateKnowledgeBaseForAppID(appId, newKnowledgeBaseIDs, user.uid)
            .then(async (response) => {
                if (response) {
                    // Set the new knowledge base
                    getKnowledgeBasesForThisApp(appId, user.uid);
                    if (response.status === 402)  {
                        // Payment required
                        setErrorMessage(response.data.message);
                        setErrorReason(response.data.reason);
                        setUpgradePremiumModalOpen(true);
                        return;
                    } else {
                        closeAddKbModal(); // Close modal
                        showSuccessToast("Successfully updated sources!!", 6000);
                        //  Navigate to the organization
                        navigate(`/organization/${organizationID}`, {
                            state: {
                                newBusiness: true,
                            }
                        });
                        return;
                    }
                }
                // Failed to update
                showErrorToast("Error: Failed to update sources", 6000);
            })
            .catch((error) => {
                showErrorToast("Error: Failed to update sources", 6000);
            });
    }

    async function createNewBusiness() {
        if (!user.uid) {
            // TODO: Show error & return
            showErrorToastNew("No user found", 6000);
            return;
        } 

        let organizationPhotoURL = DEFAULT_ORGANIZATION_COVER_PHOTO;

        if (orgImage) {
            const appImagePath = `userData/${user.uid}/${organizationID}/app_image.png`;
            const finalImageOrg = await addImageTo(appImagePath, orgImage);

            if (!finalImageOrg) {
                showErrorToast()
                return;
            }
            organizationPhotoURL = finalImageOrg;
        }
        

        const newBusiness = {
            organizationName: organizationName,
            organizationId: organizationID,
            creatorUserId: user.uid.toString(),
            organizationPhotoUrl: organizationPhotoURL,
        }

        OrganizationUtils.callCreateOrganization(newBusiness).then(response => {
            if (response) {
                // Show success message, create email assistant application under org
                if (emailAddress.length && emailPassword.length && emailClient.length) {
                    createNewAI();
                } else {
                    createNewAI();
                }

                setStep(5);


            }
        }).catch((error) => {
            console.log(error)
            showErrorToast()
            setStep(0)
        });
    }

    async function createNewAI() {
        const kbIds = knowledgeBaseIDs.map(obj => obj.id);

        // if (!kbIds.length) {
        //     return;
        // }

        const newEmailAI = {
            ownerUID: user.uid.toString(),
            appName: `${organizationName}`,
            appId: appId,
            organizationId: organizationID,
            knowledgeBaseIds: kbIds,
            type: AIType,
            appTitle: getTitleById(AIType),
            profileImageURL: "https://i.imgur.com/rYazWWq.png",
            emailAuth: AIType.includes("email") ? {
                emailAddress: emailAddress,
                emailPassword: emailPassword,
                emailClient: emailClient,
            } : null
        }
        OrganizationUtils.callCreateApplicatipon(newEmailAI).then(async response => {
            if (response) {
                // Show success message
                // showCreatedToast();

                // navigate(`/new-ai-created/${response.appId}`, {
                //     state: {
                //         newApp: true,
                //         publicId: response.publicId,
                //         response: response,
                //     }
                // })
                return true;
                // setStep(0)
            } else {
                await showErrorToast()
                // setStep(1)
            }
        }).catch(async () => {
            await showErrorToast()
            // setStep(1)
        });
    }

    async function addImageTo(path, file) {
        // Upload base64 to firebase storage and get image url
        let getFirebaseImageURL = await uploadBase64ToFirebaseAndGetImageURL(
            file, path
        )
        let finalImage = (!getFirebaseImageURL || !getFirebaseImageURL.length
            || getFirebaseImageURL.length < 1) ? file : getFirebaseImageURL;

        setOrgImage(finalImage)
        return finalImage
    }

    async function updateAuth() {
        const auth = getAuth();
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in
                const uid = user.uid.toString();
                getExistingKnowledgeBaseForUID(uid);
                setUser(user);

                // Seting the default AI to ai email assistant for now
                chooseAIType("ai-email-assistant");
            } else {
                // No user redirect to sign in
                navigate("/login")
            }
        });
    }

    const handleChange = event => {
        setProjectName(event.target.value);
        // if user has not chosen to edit ID, generate ID for them
        if (event.target.value.length > 3) {
            // find an unused project ID
            findAndSetUnusedID(event.target.value);
        }
    };

    const handleURLTextChange = event => {
        const websiteURL = event.target.value;

        // Remove trailing /    
        const websiteURLNoTrail = websiteURL.endsWith('/') ? websiteURL.slice(0,-1) : websiteURL;

        setCurrentURL(websiteURL);
        // set if URL is valid
        if ((websiteURL).length > 7 && !((websiteURL).slice(0, 8).includes("http"))){
            showHTTPFeedbackToast();
        }
        setValidURL(isValidUrl(websiteURLNoTrail));
    };

    const clearCurrentURLValue = () => {
        setCurrentURL("");
        setValidURL(false);
    }

    const addKnowledgeBaseID = (kbObject) => {
        // KbObject coontains the kb id, and url identifier
        setKnowledgeBaseIDs([...knowledgeBaseIDs, kbObject]);
    }

    function chooseAIType(AITypeID) {
        setAIType(AITypeID);
    }

    const removeItemFromKnowledgeBaseIDs = (itemID) => {
        // Match the item ID and remove it from knowledgebase ids
        setKnowledgeBaseIDs(knowledgeBaseIDs.filter((i) => i.id !== itemID));
    }

    function changeStep() {
        setStep(step + 1);
    }

    function changeStepBack() {
        setStep(step - 1);
    }

    async function handleImageChange(file) {
        // console.log(step);
        setOrgImage(file);
    }

    const newBusinessRedirect = () => {
        navigate(`/organization/${organizationID}`, {
            state: {
                newBusiness: true,

            }
        })
    }

    async function createFileKnowledgeBase(file, fileName, textContent, fileType) {
        // PDF files are read as base64 so we can upload them to firebase
        const fileURL = (fileType === "txt") ? "n/a" :
            await uploadBase64ToFirebaseAndGetImageURL(file,
                `userData/${user.uid}/files/${uuidv4()}`);
        const requestURL = `${KNOWLEDGE_BASE_API_URL}/knowledge-base/knowledge-base`;
        const requestBody = {
            url: fileURL,
            source: "file",
            fileContent: textContent, // string content in file
            uid: user.uid,
            fileType: fileType,
            title: fileName,
            organizationId: organizationID,
            scrapedKb: "n/a",
        }

        const createKb = await axios.post(requestURL, requestBody)
            .then(async (response) => {
                if (response.data.success) {
                    return response.data.id;
                }
                // no successful
                return undefined;
            }).catch((error) => {
                console.error(error);
                return undefined;
            });
        return createKb;
    }

    async function uploadBase64ToFirebaseAndGetImageURL(base64, uploadPath) {
        const storage = getStorage();
        const storageRef = ref(storage, uploadPath);
        const getImageDownloadURL = await uploadString(storageRef, base64, "data_url")
            .then((snapshot) => {
                return getDownloadURL(snapshot.ref);
            }).then((downloadURL) => {
                return downloadURL;
            }).catch((error) => {
                return "";
            });
        return getImageDownloadURL;
    }


    const verifyEmailCredentials = () => {
        const appId = organizationID; // One email assistant per org; we'll see
        // how this goes
        if (appId === undefined) {
            showErrorToast();
            return;
        }
        // App id is available
        showVerifyLoadingView("loading");
        setVerificationCode("");
        OrganizationUtils.callVerifyEmailClientAuthCredentials(
            user.uid, appId, emailAddress, emailPassword, emailClient
        ).then(async response => {
            if (response) {
                // setVerificationState("enter-code");
                // setVerificationState("success-prompt-training")
                // Create new business
                createNewBusiness();
            } else {
                setVerificationLoading(false);
                hideVerifyLoadingView("loading");
                showErrorToastNew("Error: Credentials may not be valid", 6000);
            }
        }).catch(async () => {
            setVerificationLoading(false);
            showErrorToast();
            hideVerifyLoadingView("loading");
        });
    }

    const validateVerificationCode = () => {
        const appId = organizationID;
        if (appId === undefined) {
            showErrorToast();
            return;
        }
        // Get verification code
        const code = verificationCode;
        const uid = user.uid;

        // Show loading
        setVerificationState("loading");
        OrganizationUtils.callVerifyEmailVerificationCode(
            uid, appId, code
        ).then(async response => {
            if (response) {
                // Code is correct
                setVerificationState("enter-code");
                hideVerifyLoadingView("loading");
                showSuccessToastNew("Code Verified Successfully!!", 6000);
                // Move to next step
                changeStep();
                return;
            } else {
                setVerificationState("enter-code");
                showErrorToastNew("Error: Code may not be correct", 6000);
            }
        }).catch(async () => {
            setVerificationLoading(false);
            showErrorToast();
            hideVerifyLoadingView("loading");
        });

    }

    function showErrorToastNew(message = "Error", duration = 3000) {
        const element = document.getElementById("error-toast");
        if (!element) return;

        element.style.setProperty('display', 'block', 'important');
        element.innerHTML = message;
        setTimeout(() => {
            // Set back to what it was
            element.style.setProperty('display', 'none', 'important');
            element.innerHTML = "";
        }, duration);
    }

    function showSuccessToastNew(message, duration = 3000) {
        const element = document.getElementById("success-toast");
        if (!element) {
            return;
        };

        element.style.setProperty('display', 'block', 'important');
        element.innerHTML = message;
        setTimeout(() => {
            // Set back to what it was
            element.style.setProperty('display', 'none', 'important');
            element.innerHTML = "";
        }, duration);
    }

    const showVerifyLoadingView = (state) => {
        setVerifyModalIsOpen(true);
        setVerificationState(state);
    }

    const hideVerifyLoadingView = (state) => {
        setVerifyModalIsOpen(false);
        setVerificationState(state);
    }

    const closeTrainingView = () => {
        // Go back to previous step
        navigate(`/organization/${organizationID}`, {
            state: {
                newBusiness: true,

            }
        })
    }

    function renderStep() {
        switch (step) {
            case 1:
                return <CardsSetupBusiness2
                    text1={"Business Name"}
                    text2={"Enter a name for your business"}
                    info={[{ html: 'text3', text: 'Org. Info' }, { html: 'text1', text: 'Proj. Info' }]}
                    function2={changeStep}
                    buttonText={"Continue"}
                    imageFunction={handleImageChange}
                    function1={changeStepBack}
                    totalSteps={4}
                    step={step - 1}
                    onChange={handleChangeOrganization}
                    value={organizationName} />;
            case 2:
                return <CardsSetupBusinessPhoto
                text1={`Add a thumbnail for "${organizationName}" (optional)`}
                text2={"Adding an optional thumbnail makes it easier to recognize your business, especially if you have multiple businesses."}
                info={[{ html: 'text3', text: 'Org. Info' }, { html: 'text1', text: 'Proj. Info' }]}
                function2={changeStep}
                skip={changeStep}
                buttonText={"Continue"}
                imageFunction={handleImageChange}
                function1={changeStepBack}
                totalSteps={4}
                step={step - 1}
                onChange={handleChangeOrganization}
                value={organizationName} />;
            case 3:
                return <CardsConnectYourSupportInbox1
                    text1={"Connect Your Support Inbox"}
                    text2={"Give your AI permission to your support email account"}
                    info={[{ html: 'text1', text: 'Org. Info' }, { html: 'text3', text: 'Proj. Info' }]}
                    function2={changeStep}
                    skip={createNewBusiness}
                    buttonText={"Continue"}
                    function1={changeStepBack}
                    onChange={handleChange}
                    handleTwoFactorAuthSelected={handleTwoFactorAuthSelected}
                    manuallyChangeTwoFactorAuth={manuallyChangeTwoFactorAuth}
                    twoFactorState={twoFactorState}
                    totalSteps={4}
                    step={step - 1}
                    emailClient={emailClient}
                    emailAddress={emailAddress}
                    emailPassword={emailPassword}
                    handleEmailClientChange={handleEmailClientChange}
                    handleEmailAddressChange={handleEmailAddressChange}
                    handlePasswordChange={handlePasswordChange}
                    emailAddressValid={emailAddressValid}
                    verificationLoading={verificationLoading}
                    verifyModalIsOpen={verifyModalIsOpen}
                    verificationState={verificationState}
                    value2={emailPassword}
                    hideVerifyLoadingView={hideVerifyLoadingView}
                    handleVerificationCodeChange={handleVerificationCodeChange}
                    verificationCode={verificationCode}
                    verificationCodeFormatValid={verificationCodeFormatValid}
                    validateVerificationCode={validateVerificationCode}
                    value={emailAddress} />;

            case 4:
                return <CardsPromptGmailAppPassword
                    text1={`Create a GMail App Password for ${emailAddress}`}
                    text2={"An app password allows your AI support agent to access your support inbox securely"}
                    info={[{ html: 'text1', text: 'Org. Info' }, { html: 'text3', text: 'Proj. Info' }]}
                    buttonText={"Let's do it"}
                    function1={changeStepBack}
                    onChange={handleChange}
                    verifyPasswordClick={verifyEmailCredentials}
                    emailPassword={emailPassword}
                    emailAddress={emailAddress}
                    handlePasswordChange={handlePasswordChange}
                    totalSteps={4}
                    step={step - 1}
                    verificationLoading={verificationLoading}
                    verifyModalIsOpen={verifyModalIsOpen}
                    verificationState={verificationState}
                    value2={emailPassword}
                    hideVerifyLoadingView={hideVerifyLoadingView}
                    handleVerificationCodeChange={handleVerificationCodeChange}
                    verificationCode={verificationCode}
                    verificationCodeFormatValid={verificationCodeFormatValid}
                    validateVerificationCode={validateVerificationCode}
                    value={emailAddress} />;

            case 5:
                return <CardSuccessPromptTrainPopUp
                    title={`Successfully created new business`}
                    subtitle={`You can now train your AI support agent for ${organizationName}`}
                    organizationId={organizationID}
                    maybeLaterClicked={newBusinessRedirect}
                    trainAIClicked={changeStep}
                    emailAddress={emailAddress}

                />
            case 6:
                return <CardsSetupOrganization3
                user={user}
                text1={"Add Knowledge Base"}
                text2={"Add data to train your AI. You can add a website link or upload text files (pdf, txt, pptx)."}
                info={[
                    { html: "text1", text: "Org. Info" },
                    { html: "text3", text: "Proj. Info" },
                ]}
                createApplication={updateKnowledgeBase}
                buttonText={"Finish"}
                closeView={changeStepBack}
                function1={hideRenderAddNewKnowledgeBase}
                onChange={handleURLTextChange}
                knowledgeBaseIDs={knowledgeBaseIDs}
                addKbToState={addKnowledgeBaseID}
                removeItemFromKnowledgeBaseIDs={removeItemFromKnowledgeBaseIDs} 
                createFileKnowledgeBase={createFileKnowledgeBase}
                selectableExistingKBs={false}
                existingKbs={[]}
                kbsLoading={kbsLoading}
                step={7}
                clearCurrentURLValue={clearCurrentURLValue}
                currentURLValue={currentURL}
                isCurrentURLValid={validURL}
                uid={user.uid}
                organizationId={organizationID}
                totalSteps={6}
            />

            // case 3:
            //     return <CardsSetupBusinessStep3Configure
            //         text1={"Email Authentication"}
            //         text2={"Connect your customer support email account"}
            //         info={[{ html: 'text1', text: 'Org. Info' }, { html: 'text3', text: 'Proj. Info' }]}
            //         function2={verifyEmailCredentials}
            //         buttonText={"Verify"}
            //         function1={changeStepBack}
            //         onChange={handleChange}
            //         totalSteps={3}
            //         step={step - 1}
            //         emailClient={emailClient}
            //         emailAddress={emailAddress}
            //         emailPassword={emailPassword}
            //         handleEmailClientChange={handleEmailClientChange}
            //         handleEmailAddressChange={handleEmailAddressChange}
            //         handlePasswordChange={handlePasswordChange}
            //         emailAddressValid={emailAddressValid}
            //         verificationLoading={verificationLoading}
            //         verifyModalIsOpen={verifyModalIsOpen}
            //         verificationState={verificationState}
            //         value2={emailPassword}
            //         hideVerifyLoadingView={hideVerifyLoadingView}
            //         handleVerificationCodeChange={handleVerificationCodeChange}
            //         verificationCode={verificationCode}
            //         verificationCodeFormatValid={verificationCodeFormatValid}
            //         validateVerificationCode={validateVerificationCode}
            //         value={emailAddress} />;


            // case 4:
            //     return <CardsSetupOrganization3
            //         text1={"Train Your AI"}
            //         text2={"Add data to train your Email AI. You can add a website link or upload text files (pdf, txt)"}
            //         info={[{ html: 'text1', text: 'Org. Info' }, { html: 'text3', text: 'Proj. Info' }]}
            //         createApplication={createNewBusinessAndFirstApp}
            //         buttonText={"Create AI"}
            //         function1={changeStepBack}
            //         onChange={handleURLTextChange}
            //         knowledgeBaseIDs={knowledgeBaseIDs}
            //         addKbToState={addKnowledgeBaseID}
            //         removeItemFromKnowledgeBaseIDs={removeItemFromKnowledgeBaseIDs} 
            //         createFileKnowledgeBase={createFileKnowledgeBase}
            //         existingKbs={existingKbs}
            //         existingKbs={knowledgeBases}
            //         kbsLoading={kbsLoading}
            //         step={step - 1}
            //         clearCurrentURLValue={clearCurrentURLValue}
            //         currentURLValue={currentURL}
            //         isCurrentURLValid={validURL}
            //         uid={user.uid}
            //         organizationId={organizationId}
            //         totalSteps={3} />;
            case 8:
                return <CreateOrganizationStepLoading />
            case 9:
                navigate(`/organization/${organizationID}`, {
                    state: {
                        newBusiness: true,
                    }
                });
                return;
            default:
                navigate('/createdOrganizationSuccess');
                return;
        }
    }

    return (
        <>
            <div className="section" style={{ justifyContent: props.alone ? "center" : "", height: "100vh" }}>
                {
                    renderStep()
                }
            </div>
            <Modal
                id="upgrade-modal"
                isOpen={upgradePremiumModalOpen}
                onRequestClose={closeUpgradePremiumModal}
                style={modalStyle3}
                contentLabel="Upgrade Modal">
                <CardssetupPaymentsRootRoot >
                    <FlexRow style={{ fontSize: "14px", fontWeight: "bold" }}>{errorMessage}</FlexRow>
                    <FlexRow style={{ fontSize: "12px", wordWrap: "break-word" }}>{errorReason}</FlexRow>
                    <FlexRow style={{ justifyContent: "flex-end" }}>
                        <Button onClick={handleCloseUpgradeModal}>No, thanks</Button>
                        <Button onClick={() => {
                            window.open(`https://addy.so/pricing?uid=${encodeURIComponent(user.uid)}&src=pricing-upgrade-button-from-dashboard-training`, "_blank");
                            setStep(9);
                        }}>
                            Upgrade to premium
                        </Button>
                    </FlexRow>

                </CardssetupPaymentsRootRoot>
            </Modal>
            <div id="toast-created" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-400" style={{ zIndex: '100' }} role="alert">
                <div className="text-sm font-normal">Created with success</div>
            </div>

            <div id="toast-save" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-400" style={{ zIndex: '100' }} role="alert">
                <div className="text-sm font-normal">Saved</div>
            </div>

            <div id="toast-error" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400" style={{ zIndex: '100' }} role="alert">
                <div className="text-sm font-normal">Error</div>
            </div>

            <div id="error-toast" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400" style={{ zIndex: '100' }} role="alert">
            </div>

            <div id="success-toast" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-400" style={{ zIndex: '100' }} role="alert">

            </div>
            <div id="toast-http-feedback" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400" style={{ zIndex: '100' }} role="alert">
                <div className="text-sm font-normal">URL must start with "http" or "https"</div>
            </div>
        </ >
    )
}
const FlexRow = styled.div`
margin-top: -15px;
gap: 18px;
display: flex;
flex-direction: row;
justify-content: center;
align-self: stretch;
align-items: center;
`; 

const CardssetupPaymentsRootRoot = styled.div`
gap: 24px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
border-style: solid;
border-color: #eaeeff;
background-color: #f9faff;
box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
border-radius: 20px;
padding: 22px 14px 14px 14px;
border-width: 2px;
`;

const modalStyle3 = {
content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "40%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderColor: "dark:bg-gray-700",
    zIndex: "100",
},
};

const Button = styled.button`
width: fit-content;
padding-left: 3px;
padding-right: 3px;
gap: 2px;
display: block;
justify-content: center;
align-items: center;
color: #eaeeff;
font-size: 12px;
font-weight: 500;
font-family: Poppins;
line-height: 20px;
white-space: nowrap;
border-style: solid;
border-color: #eaeeff;
background-color: #745dde;
border-radius: 7px;
padding: 13.2px 15.2px;
border-width: 0.8px;
box-sizing: content-box;
cursor: pointer;
&: hover {
box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
}
`;

export default CreateOrganization

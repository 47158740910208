import React from "react";
import styled from "styled-components";
import { InfinitySpin } from "react-loader-spinner";
import './createOrganizationStep2.css'

export const CreateOrganizationStepLoading = props => {
  return (
    <CardssetupWorkspaceRootRoot>
      <InfinitySpin
        width='200'
        color="#0E1221"
      />
    </CardssetupWorkspaceRootRoot>
  );
};

const CardssetupWorkspaceRootRoot = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
  background-color: transparent;
  border: 0px;
`;
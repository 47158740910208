export const firebaseConfig = {
    apiKey: "AIzaSyCDBO1NWURhFlBZpd1Bxc2pxUPhV8k8LMQ",
    authDomain: "hey-addy-chatgpt.firebaseapp.com",
    databaseURL: "https://hey-addy-chatgpt-default-rtdb.firebaseio.com",
    projectId: "hey-addy-chatgpt",
    storageBucket: "hey-addy-chatgpt.appspot.com",
    messagingSenderId: "284266859441",
    appId: "1:284266859441:web:9d4598d5872ea944410b90",
    measurementId: "G-C1K8WJ7R6F"
};
export const firebaseConfigDev = {
    apiKey: "AIzaSyBdxQLKuIkI-NXzL6QHzgG0iPcru_ozuZs",
    authDomain: "addy-ai-dev.firebaseapp.com",
    databaseURL: "https://addy-ai-dev-default-rtdb.firebaseio.com",
    projectId: "addy-ai-dev",
    storageBucket: "addy-ai-dev.appspot.com",
    messagingSenderId: "111911035666",
    appId: "1:111911035666:web:8595059ca5e750528209cb",
    measurementId: "G-LXRYQ67R2R"
};

import React from "react";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";

export const TextTemplatePersonalization = props => {
  return (
    <TemplatePersWhiteFlexColumn1>
      <TemplatePersFlexRow4 style={{ flexDirection: "column" }}>
        <TemplatePersScreen3 style={{ flexDirection: "column" }}>
          <TemplatePersText10>
            Text
          </TemplatePersText10>
          <EditrT>
            <Editor
              editorState={props.editorState}
              onEditorStateChange={props.onEditorStateChange}
              toolbar={{
                options: ['inline'],
              }} />
          </EditrT>
        </TemplatePersScreen3>
      </TemplatePersFlexRow4>
    </TemplatePersWhiteFlexColumn1>
  );
};


const EditrT = styled.div`
border-radius: 8px;
  width: 335px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 7px 15px;
  background-color: #f4f6ff;
`;
const TemplatePersFlexRow4 = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 7px 15px;
`;
const TemplatePersScreen3 = styled.div`
  width: 369px;
  gap: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
`;
const TemplatePersText10 = styled.button`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
`;
const TemplatePersWhiteFlexColumn1 = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  background-color: #eaeeff;
  border-radius: 20px;
`;
import OrganizationUtils from "../../services/organization/OrganizationUtils";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { SketchPicker } from 'react-color';
import namer from 'color-namer';


export const ChatbotInterface = (props) => {
    const [colorPickerModalIsOpen, setColorPickerModalIsOpen] = useState(false);
    const [color, setColor] = useState(props.config.primaryColor || "#745DDE");
    
    const [colorName, setColorName] = useState("");

    useEffect(() => {
        // Get user
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setColor(props.config.primaryColor);
        if (props.config.primaryColor && props.config.primaryColor.length > 3) {
            setColorName(namer(props.config.primaryColor).pantone[0].name);
        }
        
    }, [props.config]);

    const closeColorPickerModal = () => {
        setColorPickerModalIsOpen(false);
    };

    const handleColorChange = (updatedColor) => {
        setColor(updatedColor.hex);
        setColorName(namer(updatedColor.hex).pantone[0].name);

        props.updateNewConfigState("primaryColor", updatedColor.hex);
    };

    const modalStyle2 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
            borderColor: "transparent",
        },
    };

    return (
        <Container>
            <SectionTitle1 style={{
                marginBottom: "3px"
            }}>
                Chat Interface
            </SectionTitle1>

            <SectionTitle2>
                Change colors, fonts and more.
            </SectionTitle2>

            <div style={{
                marginBottom: "25px",
                marginTop: "5px"
            }}>
                <Title>Primary Color</Title>
                <div className="horizontal-flex" style={{
                    cursor: "pointer",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    padding: "5px",
                    borderRadius: "8px",
                    gap: "10px",
                    width: "fit-content",
                    minWidth: "150px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }} onClick={() => setColorPickerModalIsOpen(!colorPickerModalIsOpen)}>
                    <div style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "5px",
                        backgroundColor: color,

                    }}></div>
                    <p style={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.7)"
                    }}>{colorName}</p>

                </div>
            </div>

            <Modal
                id="add-knowledgebase-modal"
                isOpen={colorPickerModalIsOpen}
                onRequestClose={closeColorPickerModal}
                shouldCloseOnOverlayClick={true}
                style={modalStyle2}
                contentLabel="Publish">
                <div style={{
                    padding: "20px",
                    width: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: "20px",
                    gap: "15px"
                }} className="bg-white dark:bg-gray-700 z-100 relative m-auto">
                    <div className="horizontal-flex" style={{
                        justifyContent: "flex-end",
                        width: "100%"
                    }}>
                        <div style={{
                            width: "fit-content",
                            cursor: "pointer"
                        }} onClick={closeColorPickerModal}>
                            <img src="https://i.imgur.com/RWLar5E.png" alt="close"
                                width="12" height="12" />
                        </div>
                    </div>
                    <SketchPicker color={color} onChange={handleColorChange} />
                    <p style={{
                        fontSize: "12px"
                    }}>{color}, {colorName}</p>
                </div>
            </Modal>

        </Container>
    )
}

// Styled Components 
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeeff;
`;

const Title = styled.h1`
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 13px;
  margin-bottom: 8px;
  ::after {
   content: " *";
   color: red;
  }
`;

const InputOneLine = styled.input`
  width: 100%;
  height: auto;
  line-height: 20px;
  padding: 10px;
  background-color: #EAEEFE;
`;

const InputTwoLine = styled.textarea`
   width: 100%;
   background-color: #EAEEFE;
   line-height: 20px;
   padding: 10px;
   height: ${4 * parseFloat(getComputedStyle(document.documentElement).fontSize)}px;
   max-height: ${4 * parseFloat(getComputedStyle(document.documentElement).fontSize)}px;
   overflow-y: auto;
`;

const SectionTitle1 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 12px;
`;

const SectionTitle2 = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 12px;
`;

const LastWeek1 = styled.button`
  width: 85px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
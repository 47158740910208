import axios from "axios";
import { apiURL, AI_APP_API_URL, ORGANIZATION_API_URL } from "../../globalVariables";
import { PROFILE_URL } from "../../globalVariables";

export const ProfileUtils = { 
  callUpdateProfile: async function (profileId, profileData) {
    const requestURL = `${PROFILE_URL}/profile/updateprofile/${profileId}`;
    console.log('callUpdateProfile: ', {requestURL, profileId, profileData})

    try {
      const response = await axios.put(requestURL, profileData);
      console.log('callUpdateProfile response: ', response)
      if (response.data.success) { return response.data; } else { return false; }
    } catch (error) { console.error(error); return false; }
  },

  getProfileInfo: async function (profileId) {
    const requestURL = `${PROFILE_URL}/profile/${profileId}`;
    
    console.log('UPDATING PROFILE: ', requestURL)
    try {
      const response = await axios.get(requestURL);
      if (response.data.success) { return response.data.data; } 
      else { console.log("REQUEST ERROR"); return null; }
    } catch (error) { console.error(error); return null; }
  },

};

export default ProfileUtils;

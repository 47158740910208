import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import logoSideBar from "../../assets/images/logoSideBar.png"; // relative path to image
import logoSideBarC from "../../assets/images/logoSideBarC.png"; // relative path to image
import add from "../../assets/icons/add.png"; // relative path to image
import info from "../../assets/icons/info.png"; // relative path to image
import address from "../../assets/icons/address.svg"; // relative path to image
import exit from "../../assets/icons/exit.png"; // relative path to image
import logout from "../../assets/icons/type=logout, filled=false.png"; // relative path to image
import chrome from "../../assets/icons/chrome.png"; // relative path to image
import home from "../../assets/icons/home.png"; // relative path to image
import homeF from "../../assets/icons/homeF.png"; // relative path to image
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import "./menu.css";
import { twMerge } from "tailwind-merge";
import { getAuth } from "firebase/auth";
import OrganizationUtils from "../../services/organization/OrganizationUtils";
import { InfinitySpin } from "react-loader-spinner";
import UserUtils from "../../services/user/UserUtils";

import { useState, useEffect } from "react";
import { DEFAULT_ORGANIZATION_COVER_PHOTO } from "../../globalVariables";

const orgBackgroundColors = [
    "#9E8FEF",
    // "#F87462",
    // "#60C6D2",
    // "#579CFF",
];

export const Menu = (props) => {
    const location = useLocation();
    const { pathname } = useLocation();
    const [loadOrgs, setLoadOrgs] = useState(true);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [menuOpen, setMenuOpen] = useState(false);
    const [orgs, setOrgs] = useState([]);
    const [first, setFirst] = useState(true);
    // const navigate = useNavigate();

    const closeModal = () => {
        props.onToggleOverlay(menuOpen);
        setMenuOpen((o) => !o);
    };

    const openModal = () => {
        props.onToggleOverlay(menuOpen);
        setMenuOpen((o) => !o);
        
    };

    const closeMenu = () => {
        
        setMenuOpen(false);
        props.onToggleOverlay(true);
    };

    useEffect(() => {
        setLoadOrgs(true);
        function CheckPath() {
            if (first) {
                setFirst(false);
            }
        }
        getUser();
        CheckPath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function CheckPath() {
            if (pathname.indexOf("createdOrganizationSuccess") > -1 || pathname.indexOf("deletedOrganizationSuccess") > -1) {
                setLoadOrgs(true);
                getUser();
            }

            if (pathname === "/") {
                getUser();
            }
        }
        CheckPath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeMenu();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    async function getUser() {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                OrganizationUtils.callGetOrganizations(user.uid.toString()).then((p) => {
                    setOrgs(p);
                    setLoadOrgs(false);
                });
            } else {
                setLoadOrgs(false);
            }
        });
    }

    const organizationNotClicked = (org) => (
        <ProjectAvatarNotSelected style={{
            backgroundColor: `${orgBackgroundColors[Math.floor(Math.random() * orgBackgroundColors.length)]}`
        }}
            onClick={() => {
                closeMenu();
                return;
            }}>
            <Link style={{
                padding: org.organizationPhotoUrl === DEFAULT_ORGANIZATION_COVER_PHOTO ? "20px" : "0px",
            }} to={`/organization/${org.organizationId}/apps`}>
                {org.organizationPhotoUrl === DEFAULT_ORGANIZATION_COVER_PHOTO ? 
                    org.organizationName.charAt(0).toUpperCase() :
                    <Image3 alt={org.organizationName} src={org.organizationPhotoUrl} />}
            </Link>
        </ProjectAvatarNotSelected>
    );

    const organizationClicked = (org) => (
        <ProjectAvatar style={{
            backgroundColor: `${orgBackgroundColors[Math.floor(Math.random() * orgBackgroundColors.length)]}`
        }}
            onClick={() => {
                closeMenu();
                return;
            }}>
            <Link style={{
                padding: org.organizationPhotoUrl === DEFAULT_ORGANIZATION_COVER_PHOTO ? "20px" : "0px",
            }} to={`/organization/${org.organizationId}/apps`}>
                {org.organizationPhotoUrl === DEFAULT_ORGANIZATION_COVER_PHOTO ? 
                    org.organizationName.charAt(0).toUpperCase() :
                    <Image3 alt={org.organizationName} src={org.organizationPhotoUrl} />}
            </Link>
        </ProjectAvatar>
    );

    const organizationListClosed = () => {
        let list = [];
        for (let i = 0; i < 5; i += 1) {
            list.push(
                <OrganizationRC key={i}>
                    {orgs[i] !== undefined ? (
                        pathname.includes(orgs[i].organizationId) ? (
                            organizationClicked(orgs[i])
                        ) : (
                            organizationNotClicked(orgs[i])
                        )
                    ) : (
                        <></>
                    )}
                </OrganizationRC>
            );
        }
        return list;
    };

    const organizationList = () => {
        let list = [];
        for (let i = 0; i < 10; i += 3) {
            list.push(
                <OrganizationR key={i}>
                    {orgs[i] !== undefined ? (
                        pathname.includes(orgs[i].organizationId) ? (
                            organizationClicked(orgs[i])
                        ) : (
                            organizationNotClicked(orgs[i])
                        )
                    ) : (
                        <></>
                    )}

                    {orgs[i + 1] !== undefined ? (
                        pathname.includes(orgs[i + 1].organizationId) ? (
                            organizationClicked(orgs[i + 1])
                        ) : (
                            organizationNotClicked(orgs[i + 1])
                        )
                    ) : (
                        <></>
                    )}

                    {orgs[i + 2] !== undefined ? (
                        pathname.includes(orgs[i + 2].organizationId) ? (
                            organizationClicked(orgs[i + 2])
                        ) : (
                            organizationNotClicked(orgs[i + 2])
                        )
                    ) : (
                        <></>
                    )}
                </OrganizationR>
            );
        }
        return list;
    };
    const getExtension = () => {
        window.open(
            "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
            "_blank"
        );
    };
    return (
        <>
            {location.pathname.includes("/login") ? (
                <></>
            ) : (
                <>
                    <button
                        onClick={() => menuOpen ? closeModal() : openModal()}
                        style={{
                            borderRadius: "50%",
                            width: "25px",
                            backgroundColor: "white",
                            bottom: "5px",
                            borderWidth: "1px",
                            position: "absolute",
                            left: menuOpen ? "240px" : "70px",
                            zIndex: "102",
                        }}>
                        {menuOpen ? "<<" : ">>"}
                    </button>
                    <div
                        ref={wrapperRef}
                        style={{ width: menuOpen ? "250px" : "80px" }}
                        data-role="Menu"
                        id="menu"
                        className="h-screen flex flex-col justify-between	 relative gap-[12px] font-bold items-stretch p-[15px] pt-[40px] overflow-auto overflow-x-hidden border-r-2 border-slate-100 dark:border-slate-800">
                        <div>
                            {/* <LogoButton closeMenu={closeMenu} menuOpen={menuOpen} darkMode={props.darkMode} text="Logo" exact page="/" /> */}
                        </div>
                        {/* <NavButton text="Experience" isDisabled="isDisabled" className="disabled-link" page="/experience" icon={faDiagramProject} /> */}
                        {/* <NavButton text="Dashboard" isDisabled="isDisabled" page="/dashboard" icon={faChartLine} /> */}
                        {/* <NavButton text="Aggregator" page="/aggregator" icon={faCommentRegular} activeIcon={faCommentSolid} /> */}

                        {/* <NavButton closeMenu={closeMenu} menuOpen={menuOpen} text="Organizations" page="/organization" icon={home} activeIcon={homeF} /> */}
                        <div>
                            <NavButtonGrey text="Businesses" exact page="/" icon={home} activeIcon={homeF} />
                            {loadOrgs ? (
                                <div style={{ marginLeft: "-45px" }}>
                                    <InfinitySpin color="#0E1221" width="100" height="50" />
                                </div>
                            ) : menuOpen ? (
                                organizationList()
                            ) : (
                                organizationListClosed()
                            )}
                            <NavButtonGrey
                                closeMenu={closeMenu}
                                menuOpen={menuOpen}
                                page="/createOrganization"
                                icon={add}
                                text="New Business"></NavButtonGrey>
                        </div>
                        {/*
            <div>
              <NavButtonGrey
                closeMenu={closeMenu}
                menuOpen={menuOpen}
                page="/profile"
                icon={address}
                text="Profile"></NavButtonGrey>
            </div>
            */}
                        <div>
                            <NavButtonGrey
                                closeMenu={closeMenu}
                                menuOpen={menuOpen}
                                onclick={getExtension}
                                icon={chrome}
                                text="Chrome Extension"></NavButtonGrey>
                        </div>
                        <div>

                            {/* <ProfileButton closeMenu={closeMenu} darkMode={props.darkMode} menuOpen={menuOpen} text="Profile" exact page="/profile" className="mt-auto" /> */}
                        </div>
                        <div>
                            <NavButtonGrey
                                closeMenu={closeMenu}
                                menuOpen={menuOpen}
                                page="/feedback"
                                icon={info}
                                text="Send Feedback"></NavButtonGrey>

                            <NavButtonGrey
                                closeMenu={closeMenu}
                                menuOpen={menuOpen}
                                page="/logout"
                                icon={logout}
                                text="Log Out"></NavButtonGrey>
                            {/* <ProfileButton closeMenu={closeMenu} darkMode={props.darkMode} menuOpen={menuOpen} text="Profile" exact page="/profile" className="mt-auto" /> */}
                        </div>

                        {/* <NavButton menuOpen={menuOpen} text="Logout" page="/logout" icon={faLongArrowAltUp} /> */}
                        {/* <NavBut mton text={props.darkMode ? "Light Mode" : "Dark Mode"} onClick={props.onToggleDarkMode} icon={props.darkMode ? faSun : faMoon} className="" /> */}
                    </div>
                </>
            )}
        </>
    );
};

const NavButtonGrey = (props) => {
    const { pathname } = useLocation();
    return (
        <Link
            onClick={() => {
                props.closeMenu();
                props.onclick();
                return;
            }}
            key={Math.random().toString(36).substr(2, 9) || "key"}
            to={props.page || "#"}
            className={twMerge(`navButton flex py-[12px] pl-[13px] cursor-pointer items-center gap-[10px] overflow-hidden transition-all
        ${(props.exact ? pathname === props.page : pathname.indexOf(props.page) > -1) ? "navButtonS" : ""} ${props.className
                } ${props.isDisabled}`)}
            width={{ width: props.menuOpen ? "100%" : "52px" }}>
            {(props.exact ? pathname === props.page : pathname.indexOf(props.page) > -1) ? (
                <img alt="link" src={props.activeIcon ? props.activeIcon : props.icon} className={`mr-2 w-6 h-6`} />
            ) : (
                <img alt="link" src={props.icon} className={` w-6 h-6`} />
            )}
            <span className={`navButton ${props.className}`}>{props.text}</span>
        </Link>
    );
};

const ProfileButton = (props) => {
    const { pathname } = useLocation();
    // const [web3UserAvailable, setWeb3UserAvailable] = useState(false);
    // const [web2UserAvailable, setWeb2UserAvailable] = useState(false);
    // const [user, setUser] = useState({}); // User data object
    const [profile, setProfile] = useState({}); // User data object

    useEffect(() => {
        // Get user
        getUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getUser() {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in

                UserUtils.callGetProfile(user.uid.toString()).then((p) => {
                    setProfile(p);
                });
            }
        });
    }

    return (
        <Link
            to={props.page || "#"}
            onClick={props.closeMenu}
            width={{ width: props.menuOpen ? "100%" : "52px" }}
            className={twMerge(`navButton flex py-[12px] pl-[8px] cursor-pointer items-center gap-[10px] overflow-hidden transition-all profile"} ${props.className
                } ${props.isDisabled}
        ${(props.exact ? pathname === props.page : pathname.startsWith(props.page)) ? "navButtonS " : ""} 
            `)}>
            {profile.name ? (
                // Show user profile photo
                <img className="user-profile-icon-image" alt="User" src={profile.profilePhotoUrl} />
            ) : (
                <div className="profile-icon-div">
                    {pathname.startsWith(props.page) ? (
                        <FontAwesomeIcon icon={props.activeIcon ? props.activeIcon : faUser} />
                    ) : (
                        <FontAwesomeIcon icon={faUser} />
                    )}
                </div>
            )}

            <span className="navButton transition-all whitespace-nowrap ">
                {profile.name
                    ? // Render user view
                    profile.name?.substring(0, 10) + "..."
                    : // renderUserView(user, web3UserAvailable, web2UserAvailable)
                    props.text}
            </span>
        </Link>
    );
};

const LogoButton = (props) => {
    return (
        <Link
            to={props.page || "#"}
            onClick={props.closeMenu}
            width={{ width: props.menuOpen ? "100%" : "52px" }}
            className={twMerge(
                `flex min-h-[48px] pl-1 rounded-md cursor-pointer items-center gap-[10px] overflow-hidden transition-all ${props.className} ${props.isDisabled}`
            )}>
            {!props.menuOpen ? (
                <img alt="logo" src={logoSideBarC}></img>
            ) : (
                <img alt="logo" className="pl-5" src={logoSideBar}></img>
            )}
        </Link>
    );
};

const Image3 = styled.img`
  width: 52px;
  height: 52px;
`;
const OrganizationR = styled.div`
  width: 175px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 9px 0px;
`;
const OrganizationRC = styled.div`
  width: 57px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 3px 0px;
`;
const ProjectAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
//   border-color: #745dde;
  border-color: rgba(0, 0, 0, 1);
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 16px;
  border-width: 2px;
  width: 52px;
  height: 52px;
  color: rgba(255, 255, 255, 1);
`;
const ProjectAvatarNotSelected = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 16px;
  border-width: 2px;
  width: 52px;
  height: 52px;
  color: rgba(255, 255, 255, 1);
`;

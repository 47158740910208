import OrganizationUtils from "../../services/organization/OrganizationUtils";
import styled from "styled-components";
import React, { useEffect, useState } from "react";



export const TextContent = (props) => {
    const [organizationData, setOrganizationData] = useState({});
    
    const [chatbotName, setChatbotName] = useState(props.config.chatbotName);
    const [welcomeMessage, setWelcomeMessage] = useState(props.config.welcomeMessage);
    const [inputPlaceholder, setInputPlaceholder] = useState(props.config.inputPlaceholder);


    useEffect(() => {
        // Get user
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setChatbotName(props.config.chatbotName);
        setWelcomeMessage(props.config.welcomeMessage);
        setInputPlaceholder(props.config.inputPlaceholder);
        
    }, [props.config]);

    const handleChatbotNameChange = (e) => {
        setChatbotName(e.target.value);
        props.updateNewConfigState("chatbotName", e.target.value);
    }
    
    const handleWelcomeMessageChange = (e) => {
        setWelcomeMessage(e.target.value);
        props.updateNewConfigState("welcomeMessage", e.target.value);
    }
    
    const handleInputPlaceholderChange = (e) => {
        setInputPlaceholder(e.target.value);
        props.updateNewConfigState("inputPlaceholder", e.target.value);
    }

    return (
        <Container>
            <SectionTitle1 style={{
                marginBottom: "3px"
            }}>
                Text Content
            </SectionTitle1>

            <SectionTitle2>
                Change the default text content shown on your assistant
            </SectionTitle2>

            <div style={{
                marginBottom: "20px"
            }}>
                <Title>Chatbot Name</Title>
                <InputOneLine className="no-border-input2"
                    onChange={handleChatbotNameChange}
                    value={chatbotName} />
                
            </div>

            <div style={{
                marginBottom: "10px"
            }}>
                <Title2>Welcome Message</Title2>
                <InputOneLine className="no-border-input2"
                    onChange={handleWelcomeMessageChange}
                    value={welcomeMessage}/>
                <SectionTitle3>
                    This greeting is the first message your customers see in the chatbot.
                </SectionTitle3>
            </div>

            <div style={{
                marginBottom: "10px"
            }}>
                <Title2>Input Placeholder Text</Title2>
                <InputOneLine className="no-border-input2"
                    onChange={handleInputPlaceholderChange}
                    value={inputPlaceholder}/>
                <SectionTitle3>
                    Placeholder text that tells your customers what to do in the chatbot.
                </SectionTitle3>
            </div>


        </Container>
    )
}

// Styled Components 
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeeff;
`;

const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Poppins;
  font-size: 13px;
  margin-bottom: 8px;
  ::after {
   content: " *";
   color: red;
  }
`;

const Title2 = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Poppins;
  font-size: 13px;
  margin-bottom: 8px;
`;

const InputOneLine = styled.input`
  width: 100%;
  height: auto;
  line-height: 20px;
  padding: 10px;
  background-color: #EAEEFE;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
`;

const SectionTitle1 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 12px;
`;

const SectionTitle2 = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 15px;
`;

const SectionTitle3 = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 15px;
  margin-top: 5px;
`;

const LastWeek1 = styled.button`
  width: 85px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import { firebaseConfig, firebaseConfigDev } from "../../core/config/firebaseConfig";
import styled from "styled-components";
import "firebaseui/dist/firebaseui.css";
import axios from "axios";
import { AI_APP_API_URL } from "../../globalVariables";

firebase.initializeApp(process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_DEV === "true" ? firebaseConfigDev : firebaseConfig);

export const VinciSetup1 = (props) => {
    const [user, setUser] = useState({}); // User data object
    const [loadingW, setLoadingW] = useState(false);
    const [accessCodeValid, setAccessCodeValid] = useState(true); // Setting to true for now
    const [inviteCodeValue, setInviteCodeValue] = useState("");
    const [canCheckInviteCode, setCanCheckInviteCode] = useState(false);

    const navigate = useNavigate();

    async function updateAuth() {
        const auth = getAuth();
        setUser(user);
        auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in
                setUser(user);
                signInUser(user);
            } else {
                const authUI = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
                authUI.start(".firebase-auth-container", {
                    callbacks: {
                        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                            signInUser(authResult.user);
                        },
                    },
                    signInFlow: "popup",
                    signInOptions: [
                        {
                            // provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                        },
                    ],
                    tosUrl: "https://addy-ai.com/termsofservice",
                    privacyPolicyUrl: "https://addy-ai.com/privacypolicy",
                });
            }
        });
    }

    const handleInviteCodeTextChange = (event) => {
        setInviteCodeValue(event.target.value);
        // set if URL is valid
        if (event.target.value.length === 5) {
            setCanCheckInviteCode(true);
        } else {
            setCanCheckInviteCode(false);
        }
    };

    async function signInUser(user) {
        setLoadingW(false);
        navigate(`/`, {
            state: {
                user: user,
            },
        });
    }

    useEffect(() => {
        const headerElement = document.getElementById("addy-global-header");
        if (headerElement) headerElement.style.display = "none";

        updateAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addFeedback = (text, idSelector, duration, color) => {
        const element = document.getElementById(idSelector);
        element.innerHTML = text;
        element.style.color = color;
        setTimeout(() => {
            // Set back to what it was
            element.style.color = "transparent";
            element.innerHTML = "";
        }, duration);
    };

    async function handleInviteCode(code) {
        addFeedback("Checking invite code...", "invite-feedback-text", 4000, "grey");
        const requestURL = `${AI_APP_API_URL}/application/has-access`;
        await axios
            .get(requestURL, {
                params: {
                    "accessCode": code,
                },
            })
            .then(async (response) => {
                if (response.data.success && response.data.valid) {
                    addFeedback("Invite code valid", "invite-feedback-text", 3000, "green");
                    setAccessCodeValid(true);
                    return;
                } else {
                    addFeedback("Invite code is not valid", "invite-feedback-text", 3000, "red");
                    setAccessCodeValid(false);
                    return;
                }
            })
            .catch((error) => {
                console.error(error);
                addFeedback("Error checking invite code", "invite-feedback-text", 3000, "red");
                setAccessCodeValid(false);
                return;
            });
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4"
            style={{
                width: "100%",
                height: "100vh",
                backgroundColor: 'transparent'
            }}>

            <div className="p-4" style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
            }}>

                <HeroText>Create or sign in to your Addy AI account</HeroText>

                <br />
                <br />

                <div className="firebase-auth-container" style={{
                    backgroundColor: "white",
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    width: "80%",
                    borderRadius: "20px",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
                    id="firebase-auth-div">

                </div>


            </div>

            <div className="p-4"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#8EC5FC",
                    backgroundColor: "#EBF0F8",
                    backgroundImage: "linear-gradient(62deg, #0a57d0 0%, #745dde 100%",
                }}>


                <div>
                    <HeroText2>Automate your customer service with AI</HeroText2>
                    <br />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <img src="https://i.imgur.com/hkRFrVr.gif" width="80%" alt="demo"
                        style={{ borderRadius: "20px" }} />
                </div>

                <div className="horizontal-flex center-opposite-flex" style={{
                    paddingTop: "30px",
                    paddingBottom: "30px",
                }}>
                    <img alt="sample" width="160" height="40" src="https://i.imgur.com/xW3XP3n.png" />
                    <p className="footer-helper" style={{
                        width: "350px", marginLeft: "10px",
                        color: "rgba(255, 255, 255, 1)"
                    }}>Join 10,000+ people using Addy AI to automate their customer service</p>
                </div>
            </div>
        </div>
    );
};
const FeedBackText = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 10px;
`;

const LastWeekContain = styled.button`
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 7px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const WhiteText = styled.div`
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #858caa;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #eaeeff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const Text5 = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  color: #485175;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
const FlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const FlexColumnLittle = styled.div`
  gap: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const HeroText = styled.p`
    font-size: 30px;
    width: 100%;
    font-weight: normal;
    text-align: center;
`;

const HeroText2 = styled.p`
    font-size: 50px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
`;

const FlexRow3 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Textbox = styled.input`
  width: 180px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #745dde;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 12px 15.2px;
  border-width: 2px;
  font-size: 15px;
`;

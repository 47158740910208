import axios from "axios";
import { apiURL } from '../../globalVariables';


const AuthUtils = {
    callAddAdminToOrganization: async function (userId, organizationId) {
        const requestURL = `${apiURL}/auth/addadmintoorganization`
        return await axios.post(requestURL, {
            userId: userId,
            organizationId: organizationId
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetTwitterLogin: async function () {
        const requestURL = `${apiURL}/routes/twitter/auth/getUserAuth`
        return await axios.get(requestURL, {
        }).then(async (response) => {
            console.log(response.data)
            return response.data;
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetDiscordBot: async function () {
        const requestURL = `${apiURL}/routes/discord/auth/bot`
        return await axios.get(requestURL, {
        }).then(async (response) => {
            console.log(response.data)
            return response.data;
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },
}


export default AuthUtils;
import React, { useRef } from "react";
import "./feedback.css";
import styled from "styled-components";
import { InlineWidget } from "react-calendly";

import emailjs from '@emailjs/browser';


const Feedback = (props) => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_nftwlh4', 'template_k627l88', form.current, 'i-vQscuIj6jpx6Jqc')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (

        <div className="form1" style={{
            height: "100vh",
        }}>



            <div className="text-lg text-slate-800 dark:text-slate-100 w-[900px] text-center font-semibold">Schedule a call with us</div>
           
            <InlineWidget styles={{
                width: '100%',
                minWidth: '620px',
                minHeight: "80vh",
                maxHeight: "80vh",
            }} url="https://calendly.com/michael-addy-ai/feedback-top-customers" />


            <div className="text-lg text-slate-800 dark:text-slate-100 w-[900px] text-center font-semibold">Schedule a meeting above or</div>
            <div className="text-lg text-slate-800 dark:text-slate-100 w-[900px] text-center font-semibold">Fill this feedback form</div>

            <div style={{
                marginTop: "10px",
            }}>
                <LastWeek onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLScR8AEfZepF93cyzC0929IrfK-ycNa2lqr6uZsgfet6_NQaBw/viewform?usp=sf_link", "_blank")}>
                    Go to Feedback Form
                </LastWeek>
            </div>
            
            {/* <div style={{
                width: "100%",
                height: "500px",
            }}>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScR8AEfZepF93cyzC0929IrfK-ycNa2lqr6uZsgfet6_NQaBw/viewform?usp=sf_link"
                    title="Addy AI For Business Feedback"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0">Loading…
                </iframe>
            
            </div> */}
            {/* <form className="form" ref={form} onSubmit={sendEmail}>
                <label>Name</label>
                <input type="text" name="user_name" />
                <label>Email</label>
                <input type="email" name="user_email" />
                <label>Message</label>
                <textarea name="message" />
                <input type="submit" style={{marginTop: '5px'}} value="Send" />
            </form> */}
        </div>
    );
}

const LastWeek = styled.button`
  width: 344px;
  height: 14px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

export default Feedback
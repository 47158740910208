import React from 'react'
import { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import './createOrgOrAI.css'
import "../../index.css";
import { getAuth } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { AI_APP_API_URL } from '../../globalVariables';
import { KNOWLEDGE_BASE_API_URL } from '../../globalVariables';
import 'reactjs-popup/dist/index.css';
import OrganizationUtils from "../../services/organization/OrganizationUtils";
import { CardssetupWorkspace1 } from '../../components/simpleComponents';
import { CardsSetupOrganization2 } from './createOrganizationStep2';
import { CreateOrganizationStepLoading } from './createOrganizationStepLoading';
import { CardsSetupOrganization3 } from './createOrganizationStep3';
import styled from "styled-components";
import Modal from 'react-modal';

function isValidUrl(str) {
    const pattern = /^https?:\/\/[\w.-]+\.[a-z]{2,}(\/[\w.-]+)*$/i;
    return pattern.test(str) && str.length >= 7;
}

const CreateAI = (props) => {
    const [user, setUser] = useState({}); // User data object
    const [projectName, setProjectName] = useState('');
    const [projectID, setProjectID] = useState("my-awesome-project-id");
    const [organizationName, setOrganizationName] = useState('');
    const [orgImage, setOrgImage] = useState("");
    const [projImage, setProjImage] = useState("");
    const [step, setStep] = useState(1);
    const [AIType, setAIType] = useState("");
    const [currentURL, setCurrentURL] = useState("");
    const [validURL, setValidURL] = useState(false);
    const [knowledgeBaseIDs, setKnowledgeBaseIDs] = useState([]);
    const [existingKbs, setExistingKbs] = useState([]);
    const [kbsLoading, setKbsLoading] = useState(true); // At the start; kbs are loading
    const navigate = useNavigate();

    const [upgradePremiumModalOpen, setUpgradePremiumModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorReason, setErrorReason] = useState('');

    let { organizationId } = useParams();
    console.log(organizationId);


    const typesOfAIApps = [{
        title: "Email Assistant",
        imageURL: "https://i.imgur.com/CpREN3D.png",
        id: "ai-email-assistant",
    }, {
        title: "Website Chat Bot",
        imageURL: "https://i.imgur.com/pKwApAz.png",
        id: "customer-inquiry-bot",
    }]

    function showErrorToast() {
        document.getElementById('toast-error').style.setProperty('display', 'block', 'important');

        setTimeout(() => {
            document.getElementById('toast-error').style.setProperty('display', 'none', 'important');
        }, 3000);
    }

    function showHTTPFeedbackToast() {
        document.getElementById('toast-http-feedback').style.setProperty('display', 'block', 'important');

        setTimeout(() => {
            document.getElementById('toast-http-feedback').style.setProperty('display', 'none', 'important');
        }, 5000);
    }

    function showCreatedToast() {
        document.getElementById('toast-created').style.setProperty('display', 'block', 'important');

        setTimeout(() => {
            document.getElementById('toast-created').style.setProperty('display', 'none', 'important');
        }, 3000);
    }

    function getTitleById(id) {
        const app = typesOfAIApps.find(app => app.id === id);
        return app ? app.title : "n/a";
    }

    async function getExistingKnowledgeBaseForUID(uid) {
        OrganizationUtils.callGetExistingKnowledgeBases(uid)
            .then(async response => {
                if (response) {
                    setExistingKbs(response);
                    setKbsLoading(false);
                }
                setKbsLoading(false);
            }).catch(async () => {
                setKbsLoading(false);
                await showErrorToast()
            });
    }

    useEffect(() => {
        // Get user
        updateAuth();

        async function getProjectList() {
            document.getElementById('toast-save').style.setProperty('display', 'none', 'important');
            document.getElementById('toast-error').style.setProperty('display', 'none', 'important');
            document.getElementById('toast-created').style.setProperty('display', 'none', 'important');
            document.getElementById('toast-http-feedback').style.setProperty('display', 'none', 'important');
        }

        getProjectList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeOrganization = event => {
        setOrganizationName(event.target.value);
        // if user has not chosen to edit ID, generate ID for them
    };

    const handleURLTextChange = event => {
        const websiteURL = event.target.value;

        // Remove trailing /    
        const websiteURLNoTrail = websiteURL.endsWith('/') ? websiteURL.slice(0, -1) : websiteURL;

        setCurrentURL(websiteURL);
        // set if URL is valid
        if ((websiteURL).length > 7 && !((websiteURL).slice(0, 8).includes("http"))) {
            showHTTPFeedbackToast();
        }
        setValidURL(isValidUrl(websiteURLNoTrail));
    };

    const clearCurrentURLValue = () => {
        setCurrentURL("");
        setValidURL(false);
    }

    const addKnowledgeBaseID = (kbObject) => {
        // KbObject coontains the kb id, and url identifier
        setKnowledgeBaseIDs([...knowledgeBaseIDs, kbObject]);
    }

    const removeItemFromKnowledgeBaseIDs = (itemID) => {
        // Match the item ID and remove it from knowledgebase ids
        setKnowledgeBaseIDs(knowledgeBaseIDs.filter((i) => i.id !== itemID));
    }

    async function createFileKnowledgeBase(file, fileName, textContent, fileType) {
        // PDF files are read as base64 so we can upload them to firebase
        const fileURL = (fileType === "txt") ? "n/a" :
            await uploadBase64ToFirebaseAndGetImageURL(file,
                `userData/${user.uid}/files/${uuidv4()}`);
        const requestURL = `${KNOWLEDGE_BASE_API_URL}/knowledge-base/knowledge-base`;
        const requestBody = {
            url: fileURL,
            source: "file",
            fileContent: textContent, // string content in file
            uid: user.uid,
            fileType: fileType,
            title: fileName,
            organizationId: organizationId,
            scrapedKb: "n/a",
        }

        const createKb = await axios.post(requestURL, requestBody)
            .then(async (response) => {
                if (response.data.success) {
                    return response.data.id;
                }
                // no successful
                return undefined;
            }).catch((error) => {
                console.error(error);
                return undefined;
            });
        return createKb;
    }

    async function IDExists(endpoint, typeId, id) {
        const requestURL = `${AI_APP_API_URL}` + endpoint
        const exists = await axios.get(requestURL, {
            params: {
                [typeId]: id,
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.data.exists) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            // TODO: Show error
            return false;
        })
        return exists;
    }


    async function createNewApplication() {
        setStep(4);
        if (!user.uid) {
            // TODO: Show error & return
            return;
        }

        // const appImagePath = `userData/${user.uid}/${organizationId}/app_image.png`;
        // const finalImageOrg = await addImageTo(appImagePath, orgImage);
        const finalImageOrg = "https://i.imgur.com/rYazWWq.png";

        const kbIds = knowledgeBaseIDs.map(obj => obj.id);

        if (!kbIds.length) {
            // TODO: Show error and return
            return;
        }
        // if (!finalImageOrg) {
        //     showErrorToast()
        //     return;
        // }
        const newApplication = {
            ownerUID: user.uid.toString(),
            appName: organizationName,
            appId: `app-${uuidv4()}`,
            organizationId: organizationId,
            knowledgeBaseIds: kbIds,
            type: AIType,
            appTitle: getTitleById(AIType),
            profileImageURL: finalImageOrg,
        }

        OrganizationUtils.callCreateApplicatipon(newApplication).then(async response => {
            if (response) {
                // Show success message
                if (response.status === 402) {
                    // Payment required
                    setErrorMessage(response.data.message);
                    setErrorReason(response.data.reason);
                    setUpgradePremiumModalOpen(true);
                    return;
                } else {
                    navigate(`/new-ai-created/${response.appId}`, {
                        state: {
                            newApp: true,
                            publicId: response.publicId,
                            response: response,
                        }
                    });
                    return;
                }
                // setStep(0)
            } else {
                await showErrorToast("Error creating AI. Please try again.");
                setStep(1);
            }
        }).catch(async () => {
            await showErrorToast("Error creating AI. Please try again.");
            setStep(1);
        });
    }

    async function updateAuth() {
        const auth = getAuth();
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in
                const uid = user.uid.toString();
                getExistingKnowledgeBaseForUID(uid);
                setUser(user);
            } else {
                // No user redirect to sign in
                navigate("/login")
            }
        });
    }

    async function uploadBase64ToFirebaseAndGetImageURL(base64, uploadPath) {
        const storage = getStorage();
        const storageRef = ref(storage, uploadPath);
        const getImageDownloadURL = await uploadString(storageRef, base64, "data_url")
            .then((snapshot) => {
                return getDownloadURL(snapshot.ref);
            }).then((downloadURL) => {
                return downloadURL;
            }).catch((error) => {
                return "";
            });
        return getImageDownloadURL;
    }

    function changeStep() {
        setStep(step + 1);
    }

    function chooseAIType(AITypeID) {
        setAIType(AITypeID)
    }

    function changeStepBack() {
        setStep(step - 1);
    }

    async function handleImageChange(file) {
        console.log(step);
        setOrgImage(file);
    }

    async function addImageTo(path, file) {
        // Upload base64 to firebase storage and get image url
        let getFirebaseImageURL = await uploadBase64ToFirebaseAndGetImageURL(
            file, path
        )
        let finalImage = (!getFirebaseImageURL || !getFirebaseImageURL.length
            || getFirebaseImageURL.length < 1) ? file : getFirebaseImageURL;

        setOrgImage(finalImage)
        return finalImage
    }

    
    function openUpgradePremiumModal() {
        setUpgradePremiumModalOpen(true);
    }

    function closeUpgradePremiumModal() {
        setUpgradePremiumModalOpen(false);
    }

    function handleCloseUpgradeModal() {
        setUpgradePremiumModalOpen(false);
        setStep(5);
    }
    const modalStyle3 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "40%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderColor: "dark:bg-gray-700",
            zIndex: "100",
        },
    };

    const Button = styled.button`
        width: fit-content;
        padding-left: 3px;
        padding-right: 3px;
        gap: 2px;
        display: block;
        justify-content: center;
        align-items: center;
        color: #eaeeff;
        font-size: 12px;
        font-weight: 500;
        font-family: Poppins;
        line-height: 20px;
        white-space: nowrap;
        border-style: solid;
        border-color: #eaeeff;
        background-color: #745dde;
        border-radius: 7px;
        padding: 13.2px 15.2px;
        border-width: 0.8px;
        box-sizing: content-box;
        cursor: pointer;
        &: hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
        }
    `;

    const FlexRow = styled.div`
        margin-top: -15px;
        gap: 18px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: stretch;
        align-items: center;
    `;

    const CardssetupPaymentsRootRoot = styled.div`
        gap: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-style: solid;
        border-color: #eaeeff;
        background-color: #f9faff;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 22px 14px 14px 14px;
        border-width: 2px;
    `;

    function renderStep() {
        switch (step) {
            case 1:
                return <CardssetupWorkspace1
                    title={"Where Should the AI work?"}
                    subtitle={"Select an operation platform for your AI customer service agent, e.g., Email or Website Chatbot."}
                    button={"Next"}
                    step={step}
                    totalSteps={3}
                    aiTypes={typesOfAIApps}
                    aiTypeSelected={AIType}
                    onAITypeSelected={chooseAIType}
                    function={changeStep} />;
            case 2:
                return <CardsSetupOrganization2
                    text1={"Name your AI"}
                    text2={"Enter a name for your new AI customer service agent"}
                    info={[{ html: 'text3', text: 'Org. Info' }, { html: 'text1', text: 'Proj. Info' }]}
                    function2={changeStep}
                    buttonText={"Continue"}
                    imageFunction={handleImageChange}
                    function1={changeStepBack}
                    onChange={handleChangeOrganization}
                    step={step}
                    totalSteps={3}
                    value={organizationName} />;
            case 3:
                return <CardsSetupOrganization3
                    user={user}
                    text1={"Train Your AI"}
                    text2={"Add data to train your AI. You can add a website link or upload text files (pdf, txt)."}
                    info={[{ html: 'text1', text: 'Org. Info' }, { html: 'text3', text: 'Proj. Info' }]}
                    createApplication={createNewApplication}
                    buttonText={"Finish"}
                    imageFunction={handleImageChange}
                    function1={changeStepBack}
                    onChange={handleURLTextChange}
                    knowledgeBaseIDs={knowledgeBaseIDs}
                    addKbToState={addKnowledgeBaseID}
                    removeItemFromKnowledgeBaseIDs={removeItemFromKnowledgeBaseIDs}
                    createFileKnowledgeBase={createFileKnowledgeBase}
                    selectableExistingKBs={true}
                    existingKbs={existingKbs}
                    kbsLoading={kbsLoading}
                    step={step}
                    clearCurrentURLValue={clearCurrentURLValue}
                    currentURLValue={currentURL}
                    isCurrentURLValid={validURL}
                    uid={user.uid}
                    organizationId={organizationId}
                    totalSteps={3} />;
            case 4:
                return <CreateOrganizationStepLoading />
            
            case 5:
                navigate(`/organization/${organizationId}/apps`);
                return;
            default:
                // navigate('/createdOrganizationSuccess');
                return;
        }
    }

    return (
        <>
            <div className={props.alone ? 'section' : 'section1'} style={{ justifyContent: props.alone ? "center" : "", height: props.alone ? '100vh' : 'auto', marginTop: "30px" }}>
                {
                    renderStep()
                }
            </div>
            <div id="toast-created" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-400" style={{ zIndex: '100' }} role="alert">
                <div className="text-sm font-normal">Created with success</div>
            </div>

            <div id="toast-save" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-400" style={{ zIndex: '100' }} role="alert">
                <div className="text-sm font-normal">Saved</div>
            </div>

            <div id="toast-error" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400" style={{ zIndex: '100' }} role="alert">
                <div className="text-sm font-normal">Error</div>
            </div>
            <div id="toast-http-feedback" className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400" style={{ zIndex: '100' }} role="alert">
                <div className="text-sm font-normal">URL must start with "http" or "https"</div>
            </div>

            <Modal
                id="upgrade-modal"
                isOpen={upgradePremiumModalOpen}
                onRequestClose={closeUpgradePremiumModal}
                style={modalStyle3}
                contentLabel="Upgrade Modal">
                <CardssetupPaymentsRootRoot >
                    <FlexRow style={{ fontSize: "14px", fontWeight: "bold" }}>{errorMessage}</FlexRow>
                    <FlexRow style={{ fontSize: "12px", wordWrap: "break-word" }}>{errorReason}</FlexRow>
                    <FlexRow style={{ justifyContent: "flex-end" }}>
                        {/* <p style={{width:"40%"}}></p> */}
                        <Button onClick={handleCloseUpgradeModal}>No, thanks</Button>
                        <Button onClick={() => {
                            window.open(`https://addy.so/pricing?uid=${encodeURIComponent(user.uid)}&src=pricing-upgrade-button-from-dashboard-training`, "_blank");
                            setStep(5);
                        }}>
                            Upgrade to premium
                        </Button>
                    </FlexRow>

                </CardssetupPaymentsRootRoot>
            </Modal>

        </ >
    )
}

export default CreateAI

/* eslint-disable no-unused-vars */
import React from "react";
import { v4 as uuidv4 } from "uuid";
import "./createOnboarding.css";
import "../../index.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import MenuLeftOnboarding from "../../components/menu/menuLeftOnboarding";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { blue, current, transparent } from "tailwindcss/colors";
import { faArrowDown, faCircle, faExternalLink, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import Modal from "react-modal";
import { ThreeDots, MutatingDots } from "react-loader-spinner";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import knowledgeBaseIconOutline from '../../assets/icons/files_icon_outline.png'
import knowledgeBaseIconFull from '../../assets/icons/files_icon_filled.png'
import settingsIconOutline from '../../assets/icons/settings_icon_outline.png'
import settingsIconFull from '../../assets/icons/settings_icon_full_purple.png'
import tagIconOutline from '../../assets/icons/tag_icon_outline.png'
import tagIconFull from '../../assets/icons/tag_icon_full.png'
import chromeIconOutline from '../../assets/icons/chrome_icon_outline.png'
import chromeIconFullPurple from '../../assets/icons/chrome_icon_full_purple.png'
// import { renderToString } from "react-dom/server";
// import WelcomeTemplate from "../../components/templates/welcomeTemplate";
// import ButtonSelectionTemplate from "../../components/templates/buttonSelectionTemplate";
// import ButtonSelectionVerticalTemplate from "../../components/templates/buttonSelectionVerticalTemplate";
import {
    AI_APP_API_URL,
    apiURL,
    baseHTML,
    CUSTOM_DOMAIN_A_RECORDS,
    KNOWLEDGE_BASE_API_URL,
} from "../../globalVariables";
import usePrompt from "../../components/prompts/usePrompt";
// import EmbeddedTemplate from "../../components/templates/embeddedTemplate";
// import TextTemplate from "../../components/templates/textTemplate";
import styled from "styled-components";
import { InfinitySpin } from "react-loader-spinner";
// import emptyTemplateImg from "../../components/menu/templatesImages/templatePlaceholder.png";
// import conditionPlaceholder from "../../components/menu/templatesImages/conditionPlaceholder.png";
// import ButtonSelectionConditionTemplate from "../../components/templates/buttonSelectionConditionTemplate";
// import { TemplateWelcome } from "../../components/templates/templateWelcome";
// import { TemplateText } from "../../components/templates/templateText";
// import { TemplateEmbed } from "../../components/templates/templateEmbed";
// import { TemplateButtonSelection } from "../../components/templates/templateButtonSelection";
// import Utils from "../../components/templates/utils";
// import { TemplateInput } from "../../components/templates/templateInput";
// import { TemplateWallet } from '../../components/templates/templateWallet';
import minus from "../../assets/icons/minus.png";
import CodeEditor, { SelectionText } from "@uiw/react-textarea-code-editor";
import OrganizationUtils from "../../services/organization/OrganizationUtils";
import { CardsSetupOrganization3 } from "../organization/createOrganizationStep3";
import add from "../../assets/icons/add.png";
import ProjectUtils from "../../services/project/ProjectUtils";
import { ChatbotPreview } from "./chatbotPreview";
import { AppViewHeader } from "./appViewHeader";
import { Settings } from "./settings";
import { KnowledgeBaseTab } from "./knowledgeBaseTab";
import { QuickPromptsMain } from "./QuickPromptsMain";
import { EmailAssistantPreview } from "./emailAssistantPreview";
import { FinishOnboarding } from "../onboarding/finishOnboarding";

Modal.setAppElement("#root");

const SetUpAI = (props) => {
    const integrations = ["discord", "twitter"];
    const TEMPLATE = "template";
    const CONDITION_TEMPLATE = "condition";
    const TEMPLATE_WELCOME = "welcome";
    const TEMPLATE_BUTTONS1 = "buttons1";
    const TEMPLATE_BUTTONS2 = "buttons2";
    const TEMPLATE_BUTTONSURL = "buttonsurl";
    const TEMPLATE_BUTTONSC = "buttonsC";
    const TEMPLATE_CONNECT_WALLET = "connect";
    const TEMPLATE_EMBED = "embed";
    const TEMPLATE_TEXT = "text";
    const TEMPLATE_INPUT = "input";
    const [loadV, setLoadV] = useState(false);
    const [loadCD, setLoadCD] = useState(false);
    const [loadPage, setLoadPage] = useState(false);
    const [customDomainVerify, setCustomDomainVerify] = useState(false);
    const [pageOnboardingContent2D, setPageOnboardingContent2D] = useState([]);
    const [pageOnboardingConfigJSON, setPageOnboardingConfigJSON] = useState({});
    const [pageOnboardingSourceFiles, setPageOnboardingSourceFiles] = useState({});
    const [publishConfetti, setPublishConfetti] = useState([]);
    const [defaultDomainPublish, setDefaultDomainPublish] = useState(true);
    const [customDomainPublish, setCustomDomainPublish] = useState(false);
    const [checkVinciDomain, setCheckVinciDomain] = useState(true);
    const [loadSaveProject, setLoadSaveProject] = useState(false);
    const [customDomains, setCustomDomains] = useState({});
    const [selectedBox, setSelectedBox] = useState("");
    const [selectedRow, setSelectedRow] = useState(0);
    const [selectedCol, setSelectedCol] = useState(0);
    const [publishModalIsOpen, setPublishModalOpen] = React.useState(false);
    const [addKbModalIsOpen, setAddKbModalIsOpen] = React.useState(false);
    const [conditionPuttedS, setConditionPuttedS] = useState(false);
    const [prevHighlited, setPrevHighlited] = useState(0);
    const [screen, setScreen] = useState({});
    const [enabled, setEnabled] = useState(false);
    const [domainNameValid, setDomainNameValid] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [step, setStep] = useState(1);
    const [customDomain, setCustomDomain] = useState("");
    const [vinciDomain, setVinciDomain] = useState("");
    const [projectT, setProjectT] = useState(null);
    const [knowledgeBases, setKnowledgeBases] = useState([]); // Array of objects
    const [kbsLoading, setKbsLoading] = useState(true); // At the start; kbs are loading
    const [existingKbs, setExistingKbs] = useState([]);
    const [currentURL, setCurrentURL] = useState("");
    const [validURL, setValidURL] = useState(false);
    const [backgroundEmailSignature, setBackgroundEmailSignature] = useState("");
    const [backgroundEmailToggle, setBackgroundEmailToggle] = useState("on");
    const [knowledgeBaseIDs, setKnowledgeBaseIDs] = useState([]);
    const [currentView, setCurrentView] = useState("settings");
    var oldPageContent = null;

    const [serverProjectData, setServerProjectData] = useState({});
    const [chatbotUIConfig, setChatbotUIConfig] = useState({});
    const [realTimeChatbotUIConfigRealTime, setRealTimeChatbotUIConfig] = useState({});
    const [publishState, setPublishState] = useState("chooseDestination");
    const [user, setUser] = useState({}); // User data object

    const scriptTagRef = useRef();
    const { windowWidth, windowHeight } = useWindowSize();
    const closeTooltip = () => console.log("close tooltip");

    const { state } = useLocation();
    let { projectId } = useParams();
    let { appId } = useParams();

    const navigate = useNavigate();
    const [errorText1, setErrorText1] = useState("");
    const [errorText2, setErrorText2] = useState("");
    const [loadedProject, setLoadedProject] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [newAppSuccessShown, setNewAppSuccessShown] = useState(false);
    const { organizationId } = useParams();
    const [upgradePremiumModalOpen, setUpgradePremiumModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorReason, setErrorReason] = useState('');

    useEffect(() => {
        // Get user
        updateAuth();

        document.getElementById("toast-save").style.setProperty("display", "none", "important");
        document.getElementById("toast-save-template").style.setProperty("display", "none", "important");
        document.getElementById("toast-error").style.setProperty("display", "none", "important");

        document.getElementById("success-toast").style.setProperty("display", "none", "important");
        document.getElementById("error-toast").style.setProperty("display", "none", "important");

        // Check if this is a new app
        if (props.newApp && props.newApp === true && !newAppSuccessShown) {
            showSuccessToast("New AI created successfully!!", 5000);
            setNewAppSuccessShown(true);
        }
    }, []);

    function getKnowledgeBasesForThisApp(appId, uid) {
        OrganizationUtils.callGetKnowledgeBasesForAppID(appId, uid)
            .then(async (response) => {
                if (response) {
                    setKnowledgeBases(response);
                    return;
                }
                // No response
                setKnowledgeBases([]);
            })
            .catch((error) => {
                console.error(error);
                setKnowledgeBases([]);
                showErrorToast("Error: Failed to get Knowledge Bases", 6000);
            });
    }

    function copy() {
        // Get the text field
        var copyText = document.getElementById("myInput");

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
    }

    const isBlocking = () => {
        if (loadedProject) {
            return oldPageContent !== pageOnboardingContent2D;
        } else {
            return false;
        }
    };

    function openModal() {
        setPublishModalOpen(true);
    }

    function openAddKbModal() {
        setKnowledgeBaseIDs(knowledgeBases);
        setAddKbModalIsOpen(true);
    }

    function openUpgradePremiumModal() {
        setUpgradePremiumModalOpen(true);
    }

    function renderARecords() {
        const aRecords = CUSTOM_DOMAIN_A_RECORDS;
        return (
            <div>
                {aRecords.map((record, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            navigator.clipboard.writeText(record);
                        }}
                        className="horizontal-layout items-center mb-2">
                        <div className="small-dot-blue mr-2 items-center">
                            <FontAwesomeIcon icon={faCircle} size="xs" />
                        </div>
                        <p className="small-title-text mr-2">{record}</p>
                        <div
                            onClick={() => {
                                navigator.clipboard.writeText(record);
                            }}>
                            <FontAwesomeIcon
                                onClick={() => {
                                    navigator.clipboard.writeText(record);
                                }}
                                icon={faCopy}
                                size="xs"
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    const handleChange = (event) => {
        setDomainName(event.target.value);
        setDomainNameValid(validDomainName(event.target.value));
    };

    /**
     * @desc Validates a domain both domains and subdomains
     * @param {String} domain - The domain string
     * @return {Boolean} true or false if domain is valid
     */
    function validDomainName(domain) {
        const regex = /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,}?$/i;
        const match = domain.match(regex);
        if (!match) return false;
        return true;
    }

    const closeModal = () => {
        setPublishModalOpen(false);
        setPublishConfetti([]);
        setPublishState("chooseDestination");
    };

    const closeAddKbModal = () => {
        setAddKbModalIsOpen(false);
    };

    const closeUpgradePremiumModal = () => {
        setUpgradePremiumModalOpen(false);
    }
    /**
     * @desc Adding a new element to the content tree from a condition block
     * @param {Object} clickedParams - The params of the condition that was clicked
     * @param {Object} content - The content of the new element to add
     * @param {String} state - Add the new content to the "true" state or "false" state
     */
    async function addNewPageOnboardingContentFromCondition(clickedParams, content, state) {
        // Get the row and col of the clicked params
        const currentPageOnboardingContent = pageOnboardingContent2D;
        const clickedRow = clickedParams.row;
        // When adding content from condition, we have to do more shifting
        // If there is a false state, use that row
        let falseState = false; // Assuming there's no false state
        let trueState = false;
        try {
            const falseStateElement = currentPageOnboardingContent[clickedParams.row + 1][clickedParams.col + 1];
            const trueStateElement = currentPageOnboardingContent[clickedParams.row + 1][clickedParams.col - 1];

            // False state exists
            if (falseStateElement !== undefined && falseStateElement.type !== "empty") {
                falseState = true;
            }
            if (trueStateElement !== undefined && trueStateElement.type !== "empty") {
                trueState = true;
            }
        } catch (error) { }
        if (state === "true") {
            const addOperation = falseState ? "replaceEmptyCell" : "addNewRow";
            if ((falseState && trueState) || (trueState && !falseState)) {
                // If true state?
                const newPageContent = addRowAndShiftAndReplaceColumnCell(
                    clickedParams.row + 1,
                    currentPageOnboardingContent.length,
                    clickedParams.col - 1,
                    "down",
                    content
                );
                const newOnboardingPageContent = updateExistingIndicesAfterAdd(newPageContent);
                // Update state
                setPageOnboardingContent2D(newOnboardingPageContent);
            } else if (falseState) {
                // Shift All top elements one space right
                shiftColumnPageOnboardingContent(0, clickedRow, "right");
                // Add new col for true state
                shiftColumnPageOnboardingContent(content.row, content.row, "right");
                // Then add the new element below
                addNewPageOnboardingContent2D(content.row, content.col, content, addOperation);
            } else if (!falseState && !trueState) {
                // Shift All top elements one space right
                shiftColumnPageOnboardingContent(0, clickedRow, "right");
                addNewPageOnboardingContent2D(content.row, content.col, content, addOperation);
            }
        } else if (state === "false") {
            const addOperation = trueState ? "replaceEmptyCell" : "addNewRow";
            if ((falseState && trueState) || (falseState && !trueState)) {
                const newPageContent = addRowAndShiftAndReplaceColumnCell(
                    clickedParams.row + 1,
                    currentPageOnboardingContent.length,
                    clickedParams.col + 1,
                    "down",
                    content
                );
                const newOnboardingPageContent = updateExistingIndicesAfterAdd(newPageContent);
                setPageOnboardingContent2D(newOnboardingPageContent);
            } else if (trueState) {
                // Shift all top elements one space left
                shiftColumnPageOnboardingContent(0, clickedRow, "left");
                // Add new col for false state
                shiftColumnPageOnboardingContent(content.row, content.row, "left");
                // // Then add the new element below
                addNewPageOnboardingContent2D(content.row, content.col, content, addOperation);
            } else if (!falseState && !trueState) {
                // Shift all top elements one space left
                shiftColumnPageOnboardingContent(0, clickedRow, "left");
                addNewPageOnboardingContent2D(content.row, content.col, content);
            }
        }
    }

    /**
     * @desc Adds a new element to the onboarding page content tree
     * from a page block
     * @param {Number} row The row to insert the new element in onboaring page tree
     * @param {Number} col The col to insert the new element in oboarding page tree
     * @param {Object} content The content configuration of the new element
     */
    async function addNewPageOnboardingContent2D(row, col, content, operation, end) {
        var currentPageOnboardingContent = pageOnboardingContent2D.slice();
        // Build out new row of content
        if (operation === "replaceEmptyCell") {
            // Replace empty cells, just replace the row.
            currentPageOnboardingContent[row][col] = content;
        } else {
            const newRow = [];
            // Add content at column in this new row
            const numElementsInPreviousRow = currentPageOnboardingContent[row - 1].length;
            for (let i = 0; i < numElementsInPreviousRow; i++) {
                if (i === col) {
                    // If col to insert, put content here
                    newRow.push(content);
                } else {
                    const emptyContent = {
                        key: `empty-${uuidv4()}`,
                        type: "empty",
                    };
                    newRow.push(emptyContent);
                }
            }
            currentPageOnboardingContent = currentPageOnboardingContent
                .slice(0, row)
                .concat([newRow])
                .concat(currentPageOnboardingContent.slice(row));
            // currentPageOnboardingContent.splice(row, 0, newRow);
        }
        currentPageOnboardingContent = currentPageOnboardingContent.filter((item) => item.length > 0);
        // Update row col of all other onboarding content
        const newOnboardingPageContent = updateExistingIndicesAfterAdd(currentPageOnboardingContent);

        // TODO: Update onboarding config
        // Update state
        setPageOnboardingContent2D(newOnboardingPageContent);
    }

    /**
     * @desc Shifts elements in a specific row by one indext to left or right
     * @param {Number} startRow - The row index to start shifting columns
     * @param {Number} endRow - The row to end shifting column (inclusive)
     * @param {String} shiftDirection - Which direction to shift left or right
     */
    function shiftColumnPageOnboardingContent(startRow, endRow, shiftDirection) {
        console.log(startRow, endRow, shiftDirection);
        const currentPageOnboardingContent = pageOnboardingContent2D.slice();
        if (shiftDirection === "right") {
            // Add empty content to the left side of rows which shifts everything to the right
            for (let row = startRow; row <= endRow; row++) {
                const emptyContent = {
                    type: "empty",
                };
                currentPageOnboardingContent[row].splice(0, 0, emptyContent);
            }
            setPageOnboardingContent2D(currentPageOnboardingContent);
        } else if (shiftDirection === "left") {
            // Add empty content to the last index which shifts everything to the left
            for (let row = startRow; row <= endRow; row++) {
                const emptyContent = {
                    type: "empty",
                };
                const rowLen = currentPageOnboardingContent[row].length;
                currentPageOnboardingContent[row].splice(rowLen, 0, emptyContent);
                console.log(currentPageOnboardingContent[row]);
            }
            setPageOnboardingContent2D(currentPageOnboardingContent);
        } else {
            throw new Error("InvalidShiftDirection");
        }
    }

    /**
     * @desc Add end elements in a specific row by one indext to left or right
     * @param {Number} startRow - The row index to start shifting columns
     * @param {Number} endRow - The row to end shifting column (inclusive)
     * @param {String} shiftDirection - Which direction to shift left or right
     */
    function addEndPageOnboardingContent(startRow, endRow, shiftDirection) {
        console.log(startRow, endRow, shiftDirection);
        const currentPageOnboardingContent = pageOnboardingContent2D.slice();
        if (shiftDirection === "right") {
            // Add empty content to the left side of rows which shifts everything to the right
            for (let row = startRow; row <= endRow; row++) {
                const emptyContent = {
                    type: "end",
                };
                currentPageOnboardingContent[row].splice(0, 0, emptyContent);
            }
            setPageOnboardingContent2D(currentPageOnboardingContent);
        } else if (shiftDirection === "left") {
            // Add empty content to the last index which shifts everything to the left
            for (let row = startRow; row <= endRow; row++) {
                const emptyContent = {
                    type: "empty",
                };
                const rowLen = currentPageOnboardingContent[row].length;
                currentPageOnboardingContent[row].splice(rowLen, 0, emptyContent);
                console.log(currentPageOnboardingContent[row]);
            }
            setPageOnboardingContent2D(currentPageOnboardingContent);
        } else {
            throw new Error("InvalidShiftDirection");
        }
    }

    function deleteColumnCell(startRow, endRow, col, shiftDirection) {
        let currentPageOnboardingContent = pageOnboardingContent2D.slice();
        if (shiftDirection === "down") {
            const emptyContent = {
                key: `empty-${uuidv4()}`,
                type: "empty",
            };
            //currentPageOnboardingContent[startRow][col] = emptyContent;
            // If there are elements below shift all below elements one place up
            if (currentPageOnboardingContent.length - 1 > startRow) {
                for (let row = startRow + 1; row < currentPageOnboardingContent.length; row++) {
                    // Swap empty element and non empty element
                    const temp = currentPageOnboardingContent[row - 1][col];
                    currentPageOnboardingContent[row - 1][col] = currentPageOnboardingContent[row][col];
                    currentPageOnboardingContent[row][col] = temp;
                }
            }
            currentPageOnboardingContent = currentPageOnboardingContent.slice(0, -1);
            return currentPageOnboardingContent;
        }
    }

    async function deleteConditionAndChildren(startRow) {
        let currentPageOnboardingContent = await pageOnboardingContent2D.slice(0, startRow);
        for (let i = 0; i < startRow; i++) {
            if (currentPageOnboardingContent[i].length > 1) {
                currentPageOnboardingContent[i] = currentPageOnboardingContent[i].slice(1, 2);
                currentPageOnboardingContent[i][0].col = 0;
            }
        }
        return currentPageOnboardingContent;
    }

    function addRowAndShiftAndReplaceColumnCell(startRow, endRow, col, shiftDirection, content) {
        const currentPageOnboardingContent = pageOnboardingContent2D.slice();
        if (shiftDirection === "down") {
            // Add one row at the bottom
            const numRows = currentPageOnboardingContent.length;
            const numberOfElementsInLastRow = currentPageOnboardingContent[numRows - 1].length;
            const newRow = [];
            for (let i = 0; i < numberOfElementsInLastRow; i++) {
                const emptyContent = {
                    key: `empty-${uuidv4()}`,
                    type: "empty",
                };
                newRow.push(emptyContent);
            }
            currentPageOnboardingContent.splice(numRows, 0, newRow);
            // Shift column down. start from bottom
            if (shiftDirection === "down") {
                for (let row = endRow; row >= startRow; row--) {
                    currentPageOnboardingContent[row][col] = currentPageOnboardingContent[row - 1][col];
                }
            }
            // Replace
            currentPageOnboardingContent[startRow][col] = content;
            return currentPageOnboardingContent;
        }
    }

    function updateExistingIndicesAfterAdd(onboardingPageContent) {
        // For now I am only shifting row, clarify with Jota
        for (let row = 0; row < onboardingPageContent.length; row++) {
            for (let col = 0; col < onboardingPageContent[row].length; col++) {
                onboardingPageContent[row][col].row = row;
                onboardingPageContent[row][col].col = col;
            }
        }
        return onboardingPageContent;
    }

    async function addMainAppPageContent(appData) {
        let sampleContent = [];
        let onboardingConfigJSON = {};
        for (let row = 0; row < 1; row++) {
            sampleContent.push([]); // Push an empty array
            for (let col = 0; col < 1; col++) {
                // Add initial content
                const key = `app-page-${uuidv4()}`; // Unique key for this element
                const newContent = {
                    type: "app",
                    row: row,
                    col: col,
                    key: key,
                    path: `${key}`,
                    info: appData,
                };
                sampleContent[row].push(newContent);
                // Add starting JSON config
                onboardingConfigJSON[key] = newContent;
            }
        }
        setPageOnboardingContent2D(sampleContent);
        setPageOnboardingConfigJSON(onboardingConfigJSON);

        addNewTemplate2D({ row: 0, col: 0, path: "" }, true, true);
    }

    async function addDummyContent() {
        let sampleContent = [];
        let onboardingConfigJSON = {};
        for (let row = 0; row < 1; row++) {
            sampleContent.push([]); // Push an empty array
            for (let col = 0; col < 1; col++) {
                // Add initial content
                const key = `start-${uuidv4()}`; // Unique key for this element
                const newContent = {
                    type: "start",
                    row: row,
                    col: col,
                    key: key,
                    path: `${key}`,
                };
                sampleContent[row].push(newContent);
                // Add starting JSON config
                onboardingConfigJSON[key] = newContent;
            }
        }
        setPageOnboardingContent2D(sampleContent);
        setPageOnboardingConfigJSON(onboardingConfigJSON);

        addNewTemplate2D({ row: 0, col: 0, path: "" }, true, true);
    }

    function renderRow(rowOfContent) {
        return (
            <div className="horizontal-layout">
                {rowOfContent.map((content, index) => (
                    <div key={index}>{renderContent(content)}</div>
                ))}
            </div>
        );
    }

    function renderContent(content) {
        var width = "105px";
        if (content.type === "empty" && content.col === 1) {
            const exists = pageOnboardingContent2D[content.row][content.col + 1];
            const exists2 = pageOnboardingContent2D[content.row][content.col - 1];
            if (exists !== undefined && exists2 !== undefined) {
                width = "105px";
            } else {
                width = "495px";
            }
            if (
                (pageOnboardingContent2D[content.row][content.col + 1]?.type === "empty" &&
                    pageOnboardingContent2D[content.row][content.col - 1]?.type === "screen") ||
                (pageOnboardingContent2D[content.row][content.col - 1]?.type === "empty" &&
                    pageOnboardingContent2D[content.row][content.col + 1]?.type === "screen")
            ) {
                width = "495px";
            }
        }
        switch (content.type) {
            case "condition":
                return conditionTemplate(content);
            case "screen":
                return screenTemplate(content);
            case "start":
                return startPoint2D(content);
            case "app":
                return appPageTemplate(content);
            case "end":
                return screenTemplate(content, true);
            case "empty":
                return emptyTemplate(content, width);
            default:
                return null;
        }
    }

    function getImagePlaceholder(params) { }

    function getImage(params) {
        if (params && params.appType) {
            switch (params.appType) {
                case "ai-email-assistant":
                    return "";
                case "customer-inquiry-bot":
                    return "";
                default:
                    break;
            }
        }
        return "";
    }

    function screenTemplate(params, end = false) {
        return (
            <div id={params.key} className="flex flex-col items-center card-container">
                <div className="h-6 w-px border-dashed border-2 border-[#eaeeff]"></div>

                <ScreenTemplate>
                    <div></div>
                    <button
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "end",
                            padding: "5px",
                        }}
                        onClick={() => deleteBlock(params)}>
                        <img style={{ width: "15px", height: "15px" }} src={minus} alt="delete"></img>
                    </button>

                    {getImage(params) !== null ? (
                        <div
                            style={{ pointerEvent: "none" }}
                            data-v-6cbfeb2a=""
                            className=" block w-[400px] h-[275px] overflow-hidden ">
                            {getImage(params)}
                        </div>
                    ) : (
                        <div data-v-6cbfeb2a="" className="w-[400px] h-[275px] overflow-hidden ">
                            <img alt="template" style={{ width: "400px", height: "275px" }} src={`${getImagePlaceholder(params)}`} />
                        </div>
                    )}

                    <span>
                        {params.info.templateTitle !== undefined
                            ? params.info.templateTitle
                            : !end
                                ? "Define template content"
                                : "End template content"}
                    </span>
                    <ScreenTemplateFlexColumn>
                        <ScreenTemplateLastWeek
                            onClick={() => {
                                changeSelected(TEMPLATE, params);
                                setIsOpen((o) => !o);
                            }}>
                            {params["info"]["template"] !== "" ? "Edit Template" : "Pick Template"}
                        </ScreenTemplateLastWeek>
                    </ScreenTemplateFlexColumn>
                </ScreenTemplate>
                {!end ? plusWithLine(params) : <></>}
            </div>
        );
    }

    function appPageTemplate(params, end = false) {
        if (!params || params == null) return;
        return (
            <div id={params.key ? params.key : "default"} className="flex flex-col items-center card-container">
                {/* <div className="h-6 w-px border-dashed border-2 border-[#eaeeff]"></div> */}

                <AppPageTemplate>
                    {/* <div></div> */}
                    {/* <button
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
              padding: '5px',
            }} onClick={() => deleteBlock(params)}><img style={{ width: '15px', height: '15px' }} src={minus} alt="delete"></img></button> */}

                    {params.appImageURL ? (
                        <div data-v-6cbfeb2a="" className="w-[400px] h-[275px] overflow-hidden mb-2">
                            <img
                                alt="template"
                                style={{ width: "400px", height: "275px", objectFit: "cover" }}
                                src={`${params.appImageURL}`}
                            />
                        </div>
                    ) : (
                        <div data-v-6cbfeb2a="" className="w-[400px] h-[275px] overflow-hidden ">
                            <img
                                alt="template"
                                style={{ width: "400px", height: "275px", objectFit: "cover" }}
                                src={`${getImagePlaceholder(params)}`}
                            />
                        </div>
                    )}

                    <AppTitleText>{params.appName}</AppTitleText>

                    <SubTitleText>{params.appTitle}</SubTitleText>

                    <br />

                    {/* <ScreenTemplateFlexColumn>
                        <ScreenTemplateLastWeek onClick={() => { changeSelected(TEMPLATE, params); setIsOpen(o => !o) }}>
                            {params['template'] ? 'Edit Template' : 'Pick Template'}
                        </ScreenTemplateLastWeek>
                    </ScreenTemplateFlexColumn> */}
                    <div
                        style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100%",
                            marginBottom: "12px",
                        }}>
                        {/* <FlexRow>
                            <LastWeek onClick={() => { window.open(getPreviewDomain()) }}>
                                Preview
                            </LastWeek>
                            <LastWeek1 disabled={false} onClick={() => publishWebsite()}>
                                Publish
                            </LastWeek1>
                        </FlexRow> */}
                    </div>
                </AppPageTemplate>
                {/* {!end ? plusWithLine(params) : <></>} */}
            </div>
        );
    }

    function emptyTemplate(params, width) {
        return (
            <div id={params.key} className="flex flex-col items-center" style={{ width: width }}>
                <div className=" group dark:bg-gray-700 rounded-md  border-opacity-75 px-8 py-5 text-black dark:text-white text-opacity-75 text-sm text-center w-64 cursor-pointer transition duration-75 ease-in-out relative overflow-hidden mx-5 ring-opacity-25"></div>
            </div>
        );
    }

    function conditionTemplate(params) {
        return (
            <div id={params.key} className="flex flex-col items-center card-container">
                <div className="h-6 w-px border-dashed border-2 border-[#eaeeff]"></div>

                <ScreenTemplate>
                    <div></div>
                    <button
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "end",
                            padding: "5px",
                        }}
                        onClick={() => deleteBlock(params)}>
                        <img style={{ width: "15px", height: "15px" }} src={minus} alt="delete"></img>
                    </button>
                    {getImage(params) !== null ? (
                        <div
                            style={{ pointerEvent: "none" }}
                            data-v-6cbfeb2a=""
                            className="block w-[400px] h-[275px] overflow-hidden ">
                            {getImage(params)}
                        </div>
                    ) : (
                        <div data-v-6cbfeb2a="" className="w-[400px] h-[275px] overflow-hidden ">
                            <img alt="template" style={{ width: "400px", height: "275px" }} src={`${getImagePlaceholder(params)}`} />
                        </div>
                    )}

                    <span>{params.info.templateTitle !== undefined ? params.info.templateTitle : "Define template content"}</span>
                    <ScreenTemplateFlexColumn>
                        <ScreenTemplateLastWeek
                            onClick={() => {
                                changeSelected(CONDITION_TEMPLATE, params);
                                setIsOpen((o) => !o);
                            }}>
                            Pick Condition Template
                        </ScreenTemplateLastWeek>
                    </ScreenTemplateFlexColumn>
                </ScreenTemplate>
                {conditionAction(params)}
            </div>
        );
    }

    function startPoint2D(content) {
        return (
            <li>
                <CardssetupPaymentsRootRoot onClick={() => changeSelected("")} className="flex flex-col items-center">
                    <Body>
                        <Text1>Member enters the onboarding</Text1>
                        <Text1></Text1>
                    </Body>
                </CardssetupPaymentsRootRoot>
                {plusWithLine(content)}
            </li>
        );
    }

    /**
     * @desc Adds a new Screen template to the onboarding content tree
     * @param {*} clickedElementParams The params of the element that was clicked
     * to add new template to the bottom
     */
    async function addNewTemplate2D(clickedElementParams, state, end = false) {
        console.log("addNewTemplate2D", clickedElementParams, state, end);
        // Mark the first screen.
        const prefix = clickedElementParams.row === 0 ? "screen-index" : "screen";
        let lastPath = "";
        if (prefix === "screen-index") {
            pageOnboardingContent2D.forEach((element, rowIndex) => {
                element.forEach((element1, columnIndex) => {
                    if (element1.key !== undefined) {
                        if (element1.key.includes("screen-index")) {
                            element1.key = `screen-${uuidv4()}`;
                        }
                        if (element1.key.includes("condition-index")) {
                            element1.key = `condition-${uuidv4()}`;
                        }
                        if (element1.key.includes("start")) {
                            lastPath = element1.key;
                        } else {
                            lastPath = lastPath + "/" + element1.key;
                            element1.path = lastPath;
                        }
                    }
                });
            });
        }

        const key = `${prefix}-${uuidv4()}`;
        const newContent = {
            type: end ? "end" : "screen",
            row: clickedElementParams.row + 1,
            col: clickedElementParams.col,
            key: key,
            path: `${clickedElementParams.path}/${key}`, // Needed for JSON config
            info: {},
        };

        if (clickedElementParams.type === "condition" && state) {
            // Then we are adding this at a condition
            if (state === "true") {
                // Add this element one space to the left
                let newCol = newContent.col - 1 < 0 ? 0 : newContent.col - 1;
                newContent.col = newCol;
                newContent.state = state;
                addNewPageOnboardingContentFromCondition(clickedElementParams, newContent, "true");
            } else if (state === "false") {
                // Add this element one space to the right
                let newCol = newContent.col + 1;
                newContent.col = newCol;
                newContent.state = state;
                addNewPageOnboardingContentFromCondition(clickedElementParams, newContent, "false");
            }
            addOnboardingConfigJSON(clickedElementParams, newContent);
        } else {
            // Not adding at condition, add directly below
            // If below element is empty, just replace
            if (conditionPuttedS) {
                let newCol = newContent.col - 1 < 0 ? 0 : newContent.col - 1;
                newContent.col = newCol;
            } else {
            }
            let emptyElementBelow = false;
            try {
                const emptyElement = pageOnboardingContent2D[clickedElementParams.row + 1][clickedElementParams.col];

                // False state exists
                if (emptyElement !== undefined && emptyElement.type === "empty") {
                    emptyElementBelow = true;
                }
            } catch (error) { }
            const addOperation = emptyElementBelow ? "replaceEmptyCell" : "addNewRow";
            addNewPageOnboardingContent2D(
                clickedElementParams.row + 1,
                clickedElementParams.col,
                newContent,
                addOperation,
                end
            );

            // Update page onboarding JSON config
            addOnboardingConfigJSON(clickedElementParams, newContent);
        }
        hightlightElement(0);
        changeSelected("", { row: 0, col: 0 });

        closeTooltip();
    }

    async function insertConditionInMatrix(clickedElementParams, state, newContent) {
        const thereAreElementsBelow = pageOnboardingContent2D.length - 1 > clickedElementParams.row;
        const rowToAdd = clickedElementParams.row + 1;
        if (thereAreElementsBelow) {
            console.log("There are elements below");
            // Ask Where to move below elements
            const whereToMoveElementsBelowCondition = "true";
            // Where to move the elements below the condition
            if (whereToMoveElementsBelowCondition === "true") {
                // Shift below elements left which Essentially means shifting top elements right
                shiftColumnPageOnboardingContent(0, clickedElementParams.row, "right");
                const numberOfRows = pageOnboardingContent2D.length;
                shiftColumnPageOnboardingContent(clickedElementParams.row + 1, numberOfRows - 1, "left");
                addNewPageOnboardingContent2D(rowToAdd, clickedElementParams.col + 1, newContent);
            } else {
                // Shift below elements right.
                // TODO: Figure out row to add + 1, or + 2
                shiftColumnPageOnboardingContent(rowToAdd + 1, pageOnboardingContent2D.length - 1, "right");
            }
        } else {
            if (clickedElementParams.type === "condition" && state === "true") {
                // Choose whether to add to left or right
                let newCol = newContent.col - 1 < 0 ? 0 : newContent.col - 1;
                newContent.col = newCol;
                addNewPageOnboardingContentFromCondition(clickedElementParams, newContent, state);
            } else if (clickedElementParams.type === "condition" && state === "false") {
                let newCol = newContent.col + 1;
                newContent.col = newCol;
                addNewPageOnboardingContentFromCondition(clickedElementParams, newContent, "false");
            } else if (clickedElementParams.type !== "condition") {
                // No elements below just add condition here
                addNewPageOnboardingContent2D(clickedElementParams.row + 1, clickedElementParams.col, newContent);
            }
        }
    }

    /**
     * @desc Adds a new Condition template to the onboarding content tree
     * @param {Object} clickedElementParams The params of the element that was clicked
     * @param {String} whereToMoveElementsBelowCondition - Where to move the elements
     * below the condition. If no elements below, it's the string "null"
     * to add new condition to the bottom
     */
    async function addNewConditionTemplate2D(clickedElementParams, state) {
        setConditionPuttedS(true);
        const prefix = clickedElementParams.row === 0 ? "condition-index" : "condition";

        let lastPath = "";
        if (prefix === "condition-index") {
            pageOnboardingContent2D.forEach((element, rowIndex) => {
                element.forEach((element1, columnIndex) => {
                    if (element1.key !== undefined) {
                        if (element1.key.includes("screen-index")) {
                            element1.key = `screen-${uuidv4()}`;
                        }
                        if (element1.key.includes("condition-index")) {
                            element1.key = `condition-${uuidv4()}`;
                        }
                        if (element1.key.includes("start")) {
                            lastPath = element1.key;
                        } else {
                            lastPath = lastPath + "/" + element1.key;
                            element1.path = lastPath;
                        }
                    }
                });
            });
        }

        const key = `${prefix}-${uuidv4()}`;
        const rowToAdd = clickedElementParams.row + 1;
        const newContent = {
            type: "condition",
            row: rowToAdd,
            col: clickedElementParams.col,
            key: key,
            path: `${clickedElementParams.path}/${key}`, // Needed for JSON config
            info: {},
        };
        if (clickedElementParams.type === "condition" && state) {
            if (state === "true") {
                // Add this to the left side of condition
                insertConditionInMatrix(clickedElementParams, state, newContent);
            } else if (state === "false") {
                // Add this to the right side of condition
                insertConditionInMatrix(clickedElementParams, state, newContent);
            }
        } else {
            // Not adding from condition
            insertConditionInMatrix(clickedElementParams, state, newContent);
        }

        addOnboardingConfigJSON(clickedElementParams, newContent);
        hightlightElement(0);
        changeSelected("", { row: 0, col: 0 });
        closeTooltip();
    }

    async function addOnboardingConfigJSON(clickedElementParams, newContent) {
        // Get current object in config
        const currentConfigJSON = cloneJSON(pageOnboardingConfigJSON);
        // Delete info object from newContent if exists. New info contains
        // base 64 which is very very large
        const jsonContent = cloneJSON(newContent);
        delete jsonContent["info"];
        // Get the object at this path of the clicked element
        const clickedConfigJSON = getValueAtPathJSON(currentConfigJSON, clickedElementParams.path);
        // Then check if this path has a next screen
        const keys = Object.keys(clickedConfigJSON);
        const screenMatchRegex = /^screen.*$/;
        const conditionMatchRegex = /^condition.*$/;
        const hasNextScreen = matchExistsInArray(keys, screenMatchRegex);
        const hasNextCondition = matchExistsInArray(keys, conditionMatchRegex);

        if (!hasNextScreen && !hasNextCondition) {
            // No next screen or condition, we can add
            const newConfigJSON = addNewObjectToJSON(
                clickedElementParams.path,
                newContent.key,
                jsonContent,
                currentConfigJSON
            );
            setPageOnboardingConfigJSON(newConfigJSON);
        } else {
            // There's a next screen or condition.
            if (hasNextScreen) {
            }
            if (hasNextCondition) {
            }
        }
    }
    /**
     * @desc Adds an object to the current onboarding configuration
     * @param {String} path - The path of the parent object to add new object to
     * @param {String} newKey - The key of the new child to add to the onboarding flow
     * @param {Object} config - The config object of the new object to add to the flow
     * @param {Object} JSONObject - The full JSON object
     */
    const addNewObjectToJSON = (path, newKey, newObjet, JSONObject) => {
        // If path ends with "/" remove it
        const formattedPath = path.slice(-1) === "/" ? path.slice(0, -1) : path;
        // Add the new key to the complete path
        const completePath = `${formattedPath}/${newKey}`;
        // Get onboarding state config
        let currentObj = JSONObject; // Moving ref pointing to the current object
        const listOfObjectsInPath = completePath.split("/");
        const lengthOfPath = listOfObjectsInPath.length;
        // Iterate through the list of Objects in the path until we get to the
        // Location to insert the new object config
        for (let i = 0; i < lengthOfPath - 1; i++) {
            const key = listOfObjectsInPath[i];
            // If currentObj[key] is undefined
            if (!currentObj[key]) {
                // Add it with an empty value. We will update this empty value later
                currentObj[key] = {};
            }
            // If currentObj[key] is defined, update current[object] to point to the next
            // Child for the next iteration
            currentObj = currentObj[key];
        }
        // At the end of the iteration, currentObj will be pointing to the new key
        // We want to add. All we have to do is update it
        currentObj[newKey] = newObjet;
        return JSONObject;
    };

    /**
     * @desc gets a value at specific path in object
     * @param {String} objectJSON - The JSON object
     * @param {String} path - The path to get
     */
    function getValueAtPathJSON(objectJSON, path) {
        const formattedPath = path.slice(-1) === "/" ? path.slice(0, -1) : path;
        var value = formattedPath.split("/").reduce((r, k) => r[k], objectJSON);
        return value;
    }

    function matchExistsInArray(array, regex) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].match(regex)) return true;
        }
        return false;
    }

    function hightlightElement(elementID) {
        const prevElement = document.getElementById(prevHighlited);
        if (prevElement !== undefined && prevElement !== null && prevHighlited) {
            prevElement.style.backgroundColor = transparent;
        }
        const element = document.getElementById(elementID);
        if (element) {
            element.style.backgroundColor = blue[100];
            element.style.paddingBottom = "10px";
            element.style.borderRadius = "8px";
            setPrevHighlited(elementID);
        }
    }

    function changeSelected(selected, index) {
        // hightlightElement(index.key);
        setSelectedBox(selected);
        setSelectedCol(index.col);
        setSelectedRow(index.row);
    }

    async function deleteBlock(params) {
        setSelectedBox("");
        setSelectedCol(0);
        setSelectedRow(0);
        /**
         * row, col, type, key
         */
        // alert(JSON.stringify(params.row));
        const currentPageOnboardingContent = pageOnboardingContent2D;
        if (params.type === "screen") {
            // Delete from screen
            // Delete this cell by adding empty cell at the bottom of column
            // & Shift this column one place up
            const newPageContent = deleteColumnCell(params.row, currentPageOnboardingContent.length, params.col, "down");
            const newOnboardingPageContent = updateExistingIndicesAfterAdd(newPageContent);
            setPageOnboardingContent2D(newOnboardingPageContent);
            setPrevHighlited(0);
        } else if (params.type === "condition") {
            setConditionPuttedS(false);
            // Delete from condition. This should delete everything below.
            // Delete all the rows from here
            const newPageContent = await deleteConditionAndChildren(params.row);
            const newOnboardingPageContent = updateExistingIndicesAfterAdd(newPageContent);
            setPageOnboardingContent2D(newOnboardingPageContent);
            setPrevHighlited(0);
        }
    }

    function setTemplateFlowInfo(row, col, template, info, templateTitle) {
        if (
            pageOnboardingContent2D !== undefined &&
            pageOnboardingContent2D.length > 0 &&
            pageOnboardingContent2D[row][col] !== undefined
        ) {
            pageOnboardingContent2D[row][col].info = { template: template, info: info, templateTitle: templateTitle };
            setPageOnboardingContent2D((prev) => [...prev]);
            showSaveTemplateToast();
            saveProject(false);
        }
        // onboardingConfigDict[row + '/' + col] = { template: template, info: info, templateTitle: templateTitle }
    }

    function showSaveToast() {
        document.getElementById("toast-save").style.setProperty("display", "block", "important");

        setTimeout(() => {
            document.getElementById("toast-save").style.setProperty("display", "none", "important");
        }, 3000);
    }

    function showSuccessToast(message, duration = 3000) {
        const element = document.getElementById("success-toast");
        if (!element) {
            return;
        }

        element.style.setProperty("display", "block", "important");
        element.innerHTML = message;
        setTimeout(() => {
            // Set back to what it was
            element.style.setProperty("display", "none", "important");
            element.innerHTML = "";
        }, duration);
    }

    function showErrorToast(message = "Error", duration = 3000) {
        const element = document.getElementById("error-toast");
        if (!element) return;

        element.style.setProperty("display", "block", "important");
        element.innerHTML = message;
        setTimeout(() => {
            // Set back to what it was
            element.style.setProperty("display", "none", "important");
            element.innerHTML = "";
        }, duration);
    }

    function showSaveTemplateToast() {
        document.getElementById("toast-save-template").style.setProperty("display", "block", "important");

        setTimeout(() => {
            document.getElementById("toast-save-template").style.setProperty("display", "none", "important");
        }, 3000);
    }

    //   function showErrorToast() {
    //     document.getElementById('toast-error').style.setProperty('display', 'block', 'important');

    //     setTimeout(() => {
    //       document.getElementById('toast-error').style.setProperty('display', 'none', 'important');
    //     }, 3000);
    //   }

    function plusWithLine(params, state) {
        return (
            <div className="flex flex-col items-center">
                <div className="h-6 w-px border-dashed border-2 border-[#eaeeff]"></div>
                <ScreenRootRoot>
                    <button
                        onClick={() => {
                            addNewTemplate2D(params, state);
                        }}>
                        <Body11>
                            <Icons src={`https://file.rendit.io/n/VoukbtqrDTWLmDdFP6LF.svg`} />
                            <Text1>Screen</Text1>
                        </Body11>
                    </button>
                    <FlexRow>
                        <Line src={`https://file.rendit.io/n/U65rQRRUKo4xcepSRpGF.svg`} />
                        {conditionPuttedS ? (
                            <button
                                onClick={() => {
                                    addNewConditionTemplate2D(params, state);
                                }}
                                style={{ pointerEvents: "none" }}>
                                <Body1>
                                    <Icons src={`https://file.rendit.io/n/RhyLOjyxmtk7QiRrSp21.svg`} />
                                    <Text1>Condition</Text1>
                                </Body1>
                            </button>
                        ) : (
                            <button
                                onClick={() => {
                                    addNewConditionTemplate2D(params, state);
                                }}
                                style={{ pointerEvents: "auto" }}>
                                <Body1>
                                    <Icons src={`https://file.rendit.io/n/RhyLOjyxmtk7QiRrSp21.svg`} />
                                    <Text1>Condition</Text1>
                                </Body1>
                            </button>
                        )}
                    </FlexRow>
                </ScreenRootRoot>
            </div>
        );
    }

    function conditionAction(params) {
        return (
            <div className="flex flex-col items-center">
                <div className="h-6 w-px border-dashed border-2 border-[#eaeeff]"></div>
                <div className="flex items-start justify-center add-condition relative">
                    <div className="flex flex-col items-center relative condition-horizontal-line">
                        <div className="h-px w-1/2 absolute top-0 right-0 border-dashed border-2 border-[#eaeeff]"></div>

                        <div className="h-6 w-px border-dashed border-2 border-[#eaeeff]"></div>
                        {plusWithLine(params, "true")}
                    </div>
                    <div className="flex flex-col items-center relative condition-horizontal-line">
                        <div className="h-px w-1/2 absolute top-0 left-0 border-dashed border-2 border-[#eaeeff]"></div>
                        <div className="h-6 w-px border-dashed border-2 border-[#eaeeff]"></div>
                        {plusWithLine(params, "false")}
                    </div>
                </div>
            </div>
        );
    }

    async function callGetProjectAPI(projectId, requesterUserId, requestURL) {
        await axios
            .get(requestURL, {
                params: {
                    appId: projectId,
                    uid: requesterUserId,
                },
            })
            .then(async (response) => {
                if (response.data.success) {
                    const project = response.data.data;
                    setProjectT(project);
                    if (project["config"] && project["config"]["domains"] !== undefined) {
                        Object.keys(project["onboardingConfig"]["domains"]).forEach((domain) => {
                            if (project["onboardingConfig"]["domains"][domain].verified) {
                                setCustomDomain({ domain: project["onboardingConfig"]["domains"][domain] });
                            }
                        });
                        setVinciDomain(project["url"]);
                    } else {
                        setVinciDomain(project["url"]);
                    }

                    // Set project data
                    setServerProjectData(project);
                    // Set config state
                    setChatbotUIConfig(project.config ? project.config : {});
                    setRealTimeChatbotUIConfig(project.config ? project.config : {});

                    // Set signature
                    if (project["appType"].includes("email")) {
                        // Get email signature
                        const backgroundSignature = project.hasOwnProperty("signature")
                            ? project["signature"]
                            : "Best regards,\nOur Team via Addy AI";

                        const backgroundToggle = project["backgroundResponse"] ? "on" : "off";
                        setBackgroundEmailSignature(backgroundSignature);
                        setBackgroundEmailToggle(backgroundToggle);
                    }
                    setVinciDomain(`addy-ai.github.io/customer-inquiry-bot/?publicId=${project.publicId}`);


                    setLoadPage(false);
                } else {
                    showErrorToast("Error: Project may not exist or you don't have access", 6000);
                    setLoadPage(false);
                    return;
                }
            })
            .catch((error) => {
                // TODO: error
                console.error(error);
                showErrorToast("Error");
                setLoadPage(false);
                return;
            });
    }

    async function getProjectDataFromAPI() {
        const requestURL = `${AI_APP_API_URL}/application/application`;
        if (user.uid === undefined) {
            const auth = getAuth();
            auth.onAuthStateChanged(async (user) => {
                if (user) {
                    // User is signed in
                    const uid = user.uid.toString();
                    await callGetProjectAPI(appId, uid, requestURL);
                    setLoadedProject(true);
                } else {
                    navigate("/login");
                    return null;
                }
            });
        } else {
            await callGetProjectAPI(appId, user.uid, requestURL);
            setLoadedProject(true);
        }
    }

    useEffect(() => {
        function createStart() {
            setLoadPage(true);
            if (state && state["projectData"] !== undefined) {
                getProjectDataFromAPI();
            } else {
                getProjectDataFromAPI();
            }
            document.getElementById("toast-http-feedback").style.setProperty("display", "none", "important");
        }
        createStart();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (customDomain !== "") {
            setCustomDomainPublish(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customDomain]);

    useEffect(() => {
        function getStartInfo() {
            var issues = false;
            var condition = false;
            var conditionRow = 0;
            var conditionsOutput = 0;
            pageOnboardingContent2D.forEach((element) => {
                element.forEach((innerElement) => {
                    if (innerElement.type !== "empty" && innerElement.type !== "start") {
                        if (Object.keys(innerElement.info).length === 0) {
                            issues = true;
                        }
                        if (innerElement.type === "condition") {
                            condition = true;
                            conditionRow = innerElement.row;
                        }
                        if (condition && innerElement.row === conditionRow + 1) {
                            conditionsOutput++;
                        }
                    }
                });
            });
            if (condition && conditionsOutput < 2) {
                issues = true;
            }
            if (pageOnboardingContent2D.length < 2) {
                issues = true;
            }
            if (issues) {
                setEnabled(false);
            } else {
                setEnabled(true);
            }
        }
        getStartInfo();
    }, [pageOnboardingContent2D]);

    function cloneJSON(obj) {
        // basic type deep copy
        if (obj === null || obj === undefined || typeof obj !== "object") {
            return obj;
        }
        // array deep copy
        if (obj instanceof Array) {
            var cloneA = [];
            for (var i = 0; i < obj.length; ++i) {
                cloneA[i] = cloneJSON(obj[i]);
            }
            return cloneA;
        }
        // object deep copy
        var cloneO = {};
        for (var x in obj) {
            cloneO[x] = cloneJSON(obj[x]);
        }
        return cloneO;
    }

    async function publishWebsite() {
        const repositoryName = serverProjectData.publicId;
        if (!repositoryName || !repositoryName.length) {
            // Show error and return;
            return;
        }
        // Source Strings
        openModal();
        // parts[i] = renderToString(<MyComponent key={i}>{parts[i]}</MyComponent>)
    }

    async function updateAuth() {
        const auth = getAuth();
        const getUser = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in
                const uid = user.uid.toString();
                setUser(user);
                // Get knowledge bases
                getKnowledgeBasesForThisApp(appId, uid);
                return user;
            } else {
                return null;
            }
        });
        return getUser;
    }

    async function postPublishWebsite() {
        setStep(1);
        // TODO: Check if domains are selected, Use button disabled logic
        if (Object.keys(pageOnboardingSourceFiles).length < 1) {
            // No source files TODO: Throw error
        }
        // Show publishing loading
        setPublishState("publishing");

        // Previous implementation was to publish by
        // Updating github repo, then call deploy
        // see how this would be useful in the future

        await axios
            .post(`${AI_APP_API_URL}/application/publish`, {
                requesterUserId: user.uid,
                appId: appId,
            })
            .then(async () => {
                // Call deployment function
                // TODO: GET actual repo name not project-id

                // await deployWebsite(serverProjectData.projectId);
                showConfetti();
                setPublishState("success");
                // await saveProject();
                setEnabled(false);
            })
            .catch(() => {
                // TODO: Code error handler
                setPublishState("chooseDestination");
            });
    }

    async function saveProject(confetti = true) {
        setLoadSaveProject(true);
        let inputs = [];
        const inputsF = [];
        pageOnboardingContent2D.forEach((element) => {
            element.forEach((innerElement) => {
                if (innerElement.info && innerElement.info.info.inputs) {
                    innerElement.info.info.inputs.forEach((input) => {
                        if (!integrations.includes(input.type)) {
                            inputs.push({ value: input.value, type: input.type, order: input.order, title: input.title });
                        } else {
                            inputs.push({ value: input.type + "U", type: input.type, order: input.order, title: input.title });
                        }
                    });
                }
                if (inputs.length > 0) {
                    inputsF.push({ inputs: inputs, key: innerElement.key, formTitle: innerElement.info.info.formTitle });
                }
                inputs = [];
            });
        });
        await axios
            .post(`${apiURL}/project/updateproject`, {
                projectId: serverProjectData.projectId,
                pageOnboardingContent2D: pageOnboardingContent2D,
                pageOnboardingConfigJSON: pageOnboardingConfigJSON,
                inputs: inputsF,
            })
            .then(async () => {
                //await deployWebsite("demo");
                showSaveToast();
                if (confetti) {
                    showConfetti();
                }
                setLoadSaveProject(false);
                return true;
            })
            .catch(() => {
                // TODO: Code error handler
                setPublishState("chooseDestination");
                showErrorToast();
                setLoadSaveProject(false);
                return false;
            });
    }

    async function deployWebsite(repositoryName) {
        await axios
            .post(`${AI_APP_API_URL}/application/deploy`, {
                repositoryName: serverProjectData.publicId,
            })
            .then(() => {
                // Monitor deployment.
                // Show confetti
                setPublishState("success");
            })
            .catch(() => {
                // TODO: Code error handler
                setPublishState("chooseDestination");
                showErrorToast();
            });
    }

    function showConfetti() {
        var confetti = [];
        confetti.push(
            <Confetti
                className="confetti-publish"
                width={windowWidth}
                height={windowHeight}
                numberOfPieces={200}
                recycle={false}
            />
        );
        setPublishConfetti(confetti);
        // After 5 seconds remove the confetti
    }

    const handleCustomDomainChange = (domain) => {
        if (!validDomainName(domainName)) {
            setErrorText1("Invalid domain format");
            return;
        }
        setCustomDomains(customDomains);
        if (customDomains[domain]) {
            customDomains[domain] = !customDomains[domain];
        } else {
            // Doesn't exist create it
            customDomains[domain] = true;
        }
        setCustomDomainPublish(!customDomainPublish);
    };

    async function addCustomDomain() {
        setLoadCD(true);
        if (!validDomainName(domainName)) {
            setLoadCD(false);
            // setErrorText1("Invalid domain format");
            return;
        }
        // Everything OK. Make post request
        const addDomainURL = `${apiURL}/deployment/addcustomdomain`;
        await axios
            .post(addDomainURL, {
                requesterUserId: user.uid,
                projectId: projectId,
                domain: domainName,
            })
            .then((response) => {
                setStep(2);
            })
            .catch((error) => {
                // TODO: Show error
                console.error(error);
            });
        setLoadCD(false);
    }

    async function verifyCustomDomain() {
        setLoadV(true);
        if (!validDomainName(domainName)) {
            setErrorText1("Invalid domain format");
            return;
        }
        // Everything OK. Make post request
        const verifyURL = `${apiURL}/deployment/verifydomainownership`;
        await axios
            .post(verifyURL, {
                requesterUserId: user.uid,
                projectId: projectId,
                domain: domainName,
            })
            .then((response) => {
                setCustomDomainVerify(true);
                setCustomDomainPublish(true);
                setLoadV(false);
                if (response.data.data.domain) {
                    if (response.data.data.domain.verified) {
                        setCustomDomain(response.data.data);
                    }
                }
                setErrorText2(`Custom domain ${domainName} has been verified.`);
            })
            .catch((error) => {
                // TODO: Show error
                if (error.response) {
                    setCustomDomainVerify(false);
                    const notFoundARecords = error.response.data.data?.notFoundARecords;
                    if (notFoundARecords && notFoundARecords.length > 0) {
                        setErrorText2(`The following A Records were not found on your domain (${notFoundARecords.join(", ")})`);
                    } else {
                        setErrorText2(`Failed while calling the backend`);
                    }
                }

                console.error(error);
                setLoadV(false);
            });
    }

    function getPreviewDomain() {
        return `https://${vinciDomain}`;
    }

    function renderAllNewPublishedToDomains() {
        const renderDomains = [];
        if (defaultDomainPublish) {
            renderDomains.push(
                <PublishedOptions key={serverProjectData.projectId + "vinci"}>
                    <a style={{ display: "flex" }} target="_blank" href={`https://${vinciDomain}`} rel="noreferrer">
                        <PublishedText2>{vinciDomain?.slice(0, 31) + "..."}</PublishedText2>
                        <PublishedIcons src={`https://file.rendit.io/n/hddUV8HMkhiBueMekh32.svg`} />
                    </a>
                </PublishedOptions>
            );
        }
        return renderDomains;
    }

    function renderCustomPublishedToDomains() {
        const renderDomains = [];
        if (customDomain !== "") {
            let customUrl = customDomain[Object.keys(customDomain)]["url"];
            if (!customUrl.includes("https://") || !customUrl.includes("http://")) {
                customUrl = "https://" + customUrl;
            }
            renderDomains.push(
                <PublishedOptions key={serverProjectData.projectId + "custom"}>
                    <a style={{ display: "flex" }} target="_blank" href={customUrl} rel="noreferrer">
                        <PublishedText2>{customUrl}</PublishedText2>
                        <PublishedIcons src={`https://file.rendit.io/n/hddUV8HMkhiBueMekh32.svg`} />
                    </a>
                </PublishedOptions>
            );
        }
        return renderDomains;
    }

    function step2() {
        return (
            <div id="step-1-project">
                <div className="relative flex justify-between items-center pt-5 mb-5">
                    <div>
                        <PublishProjectsFeatures>Verify ownership</PublishProjectsFeatures>

                        <div>
                            <PublishProjectsFeatures>A Records</PublishProjectsFeatures>
                            <PublishText3>
                                Add the following <strong>A</strong> records below to your DNS provider to verifty you own {domainName}.
                                Do not delete your A records
                            </PublishText3>
                        </div>
                    </div>
                </div>
                <div className="modal-content tracking-wide text-black dark:text-white text-sm text-opacity-75 whitespace-normal flex-1">
                    <PublishText4>{renderARecords()}</PublishText4>
                    <div>
                        <PublishProjectsFeatures>CNAME</PublishProjectsFeatures>
                        <PublishText3>
                            Next Configure CNAME in your DNS settings by creating the following CNAME record. If you already had a
                            CNAME “www“, replace it with the CNAME record below.
                            <div
                                className="horizontal-layout items-center mb-2"
                                onClick={() => {
                                    navigator.clipboard.writeText("vinci-onboarding-repos.github.io");
                                }}>
                                <div className="small-dot-blue mr-2 items-center">
                                    <FontAwesomeIcon icon={faCircle} size="xs" />
                                </div>
                                <PublishText4 className="text-sm">CNAME - “www“ - “vinci-onboarding-repos.github.io”</PublishText4>
                                <div
                                    onClick={() => {
                                        navigator.clipboard.writeText("vinci-onboarding-repos.github.io");
                                    }}>
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        size="xs"
                                        onClick={() => {
                                            navigator.clipboard.writeText("vinci-onboarding-repos.github.io");
                                        }}
                                    />
                                </div>
                            </div>
                        </PublishText3>
                    </div>

                    <p className="pt-2 lg:max-w-6xl whitespace-normal"></p>
                    <p className="pt-2 lg:max-w-6xl whitespace-normal"></p>
                </div>

                <div className="flex items-center pb-10 justify-end">
                    <div data-v-6037b6f3="" className="grid items-center grid-flow-col gap-3">
                        <LastWeek3 onClick={() => setStep(1)} type="button" data-v-6037b6f3="">
                            <span>Previous</span>
                        </LastWeek3>

                        {loadV ? (
                            <ThreeDots color={blue[400]} visible={true} height={60} width={60} />
                        ) : (
                            <PublishLastWeek2 onClick={() => verifyCustomDomain()} data-v-6037b6f3="" disabled={false}>
                                <span>Verify</span>
                            </PublishLastWeek2>
                        )}
                    </div>
                </div>
                {errorText2}
                {errorText1}
            </div>
        );
    }

    function onDefaultDomainCheckClick(event) {
        if (checkVinciDomain) {
            event.preventDefault();
        } else {
            setCheckVinciDomain(!checkVinciDomain);
        }
    }

    function publishPopUp() {
        return (
            <PublishCardsprojectsRootRoot>
                <PublishFlexRow>
                    <PublishFlexRow1>
                        <PublishIcons src={`https://file.rendit.io/n/0RMN6QVaMH70GeDuy5JM.svg`} />
                        <PublishText1 onClick={closeModal} style={{ cursor: "pointer" }}>
                            Back
                        </PublishText1>
                    </PublishFlexRow1>
                </PublishFlexRow>
                <PublishBody>
                    <PublishProjectsFeatures>Choose Publish Domain</PublishProjectsFeatures>
                    <PublishText2>Select a domain to publish your AI</PublishText2>
                </PublishBody>
                <PublishFlexColumn>
                    <PublishFlexColumn>
                        <PublishCheckboxOption>
                            <PublishFlexColumn1>
                                <PublishText3>Default Domain</PublishText3>
                                <a target="_blank" href={`https://${vinciDomain}`} rel="noreferrer">
                                    <PublishText4>{vinciDomain?.slice(0, 55) + "..."}</PublishText4>
                                </a>
                            </PublishFlexColumn1>
                            <PublishFlexColumn2 checked={checkVinciDomain} onClick={() => onDefaultDomainCheckClick()}>
                                <HiddenCheckbox checked={checkVinciDomain} onChange={() => onDefaultDomainCheckClick()} />
                                <PublishImage1
                                    src={checkVinciDomain ? "https://file.rendit.io/n/nsR2nUpJY0RdEhS2438C.svg" : ""}
                                    checked={checkVinciDomain}
                                />
                            </PublishFlexColumn2>
                        </PublishCheckboxOption>
                    </PublishFlexColumn>
                    <PublishFlexColumn>
                        <PublishCheckboxOption>
                            <PublishFlexColumn1>
                                <PublishText3>Custom Domain (Coming soon)</PublishText3>
                                {customDomain !== "" && (
                                    <a
                                        target="_blank"
                                        href={
                                            !customDomain[Object.keys(customDomain)]["url"].includes("http") ||
                                                !customDomain[Object.keys(customDomain)]["url"].includes("https")
                                                ? "https://" + customDomain[Object.keys(customDomain)]["url"]
                                                : customDomain[Object.keys(customDomain)]["url"]
                                        }
                                        rel="noreferrer">
                                        <PublishText4>{customDomain[Object.keys(customDomain)]["url"]}</PublishText4>
                                    </a>
                                )}
                            </PublishFlexColumn1>
                            <PublishFlexColumn2
                                checked={customDomainPublish}
                                disabled={!customDomainPublish}
                                onClick={() => handleCustomDomainChange(customDomainPublish)}>
                                <HiddenCheckbox
                                    label={`Custom Domain`}
                                    value={customDomainPublish}
                                    onChange={() => handleCustomDomainChange(customDomainPublish)}
                                />
                                <PublishImage1
                                    src={customDomainPublish ? "https://file.rendit.io/n/nsR2nUpJY0RdEhS2438C.svg" : ""}
                                    checked={customDomainPublish}
                                />
                            </PublishFlexColumn2>
                        </PublishCheckboxOption>
                    </PublishFlexColumn>
                    <PublishFlexColumn>
                        <PublishCheckboxOption>
                            <PublishTextbox
                                placeholder="Enter your custom domain"
                                required="required"
                                autoFocus="autofocus"
                                disabled={true}
                                onChange={handleChange}
                                value={domainName}></PublishTextbox>
                            {loadCD ? (
                                <ThreeDots color={blue[400]} visible={true} height={35} width={90} />
                            ) : (
                                <PublishLastWeek2 disabled={!validDomainName(domainName)} onClick={() => addCustomDomain()}>
                                    Add custom domain
                                </PublishLastWeek2>
                            )}
                        </PublishCheckboxOption>
                    </PublishFlexColumn>
                    {step === 2 ? step2() : null}
                    <PublishBody>
                        <PublishDivider src={`https://file.rendit.io/n/iNEkLe1Yuxc10KefLvIz.svg`} />
                        <PublishLastWeek
                            onClick={() => postPublishWebsite()}
                            disabled={!defaultDomainPublish && !(customDomains.length > 0)}>
                            Publish to selected domain
                        </PublishLastWeek>
                    </PublishBody>
                </PublishFlexColumn>
            </PublishCardsprojectsRootRoot>
        );
    }

    function successPopUp() {
        return (
            <PublishedCardsprojectsRootRoot>
                <PublishedBody>
                    <PublishedText1>🎉 Published</PublishedText1>
                    <PublishedParagraph>
                        It might take up to 2 minutes for the changes to be reflected on your domain
                    </PublishedParagraph>
                </PublishedBody>
                <PublishedFlexColumn>
                    {renderAllNewPublishedToDomains()}
                    {renderCustomPublishedToDomains()}
                    <PublishedBody>
                        <PublishedDivider src={`https://file.rendit.io/n/qdIeiv025zJAN6hxnsBQ.svg`} />
                        <PublishedLastWeek
                            onClick={() => {
                                closeModal();
                                setPublishConfetti([]);
                                setPublishState("chooseDestination");
                            }}>
                            Close
                        </PublishedLastWeek>
                    </PublishedBody>
                </PublishedFlexColumn>
            </PublishedCardsprojectsRootRoot>
        );
    }

    function publishingPopUp() {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Publishing</PublishingText1>
                    <PublishingText2>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <InfinitySpin color="#0E1221" />
                        </div>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        );
    }

    function getScriptTagForProj(params) {
        if (!params) return "";
        return `<script src="https://cdn.jsdelivr.net/gh/addy-ai/customer-inquiry-bot@latest/js/bubble.min.js" id="${params.publicId}" title="embed"></script>`;
    }

    function copyScriptTextToClipboard(text, buttonID, buttonText) {
        const buttonClicked = document.getElementById(buttonID);
        // Copy the text inside the text field
        navigator.clipboard.writeText(text);

        // Show text copied
        buttonClicked.style.textDecoration = "none";
        buttonClicked.style.color = "#04A96D";
        buttonClicked.innerHTML = "Copied!";

        // Set back to original after 3 seconds
        setTimeout(() => {
            buttonClicked.style.textDecoration = "underline";
            buttonClicked.style.color = "#0d1121";
            buttonClicked.innerHTML = buttonText;
        }, 3000);
    }

    function deleteKnowledgeBase(appId, knowledgeBaseId, userID) {
        const deleteButtonElementID = `kb-del-btn${knowledgeBaseId}`;
        const deleteButtonElement = document.getElementById(deleteButtonElementID);
        if (deleteButtonElement) {
            deleteButtonElement.innerHTML = "Deleting...";
        }
        const currentKnowledgeBases = knowledgeBases;
        OrganizationUtils.callDeleteKnowledgeBaseForAppID(appId, knowledgeBaseId, userID)
            .then(async (response) => {
                if (response) {
                    // Set the new knowledge base
                    const newKnowledgeBases = currentKnowledgeBases.filter((item) => item.id !== knowledgeBaseId);
                    setKnowledgeBases(newKnowledgeBases);
                    showSuccessToast("Successfully deleted source!!", 5000);
                    return;
                }
                // Failed to delete
                showErrorToast("Error: Failed to delete source", 6000);
                if (deleteButtonElement) {
                    deleteButtonElement.innerHTML = "Delete";
                }
            })
            .catch((error) => {
                showErrorToast("Error: Failed to delete source", 6000);
                if (deleteButtonElement) {
                    deleteButtonElement.innerHTML = "Delete";
                }
            });
    }

    async function createFileKnowledgeBase(file, fileName, textContent, fileType) {
        // PDF files are read as base64 so we can upload them to firebase
        const fileURL =
            fileType === "txt"
                ? "n/a"
                : await uploadBase64ToFirebaseAndGetImageURL(file, `userData/${user.uid}/files/${uuidv4()}`);
        const requestURL = `${KNOWLEDGE_BASE_API_URL}/knowledge-base/knowledge-base`;
        const requestBody = {
            url: fileURL,
            source: "file",
            fileContent: textContent, // string content in file
            uid: user.uid,
            fileType: fileType,
            title: fileName,
            organizationId: organizationId,
            scrapedKb: "n/a",
        };

        const createKb = await axios
            .post(requestURL, requestBody)
            .then(async (response) => {
                if (response.data.success) {
                    return response.data.id;
                }
                // no successful
                return undefined;
            })
            .catch((error) => {
                console.error(error);
                return undefined;
            });
        return createKb;
    }

    async function uploadBase64ToFirebaseAndGetImageURL(base64, uploadPath) {
        const storage = getStorage();
        const storageRef = ref(storage, uploadPath);
        const getImageDownloadURL = await uploadString(storageRef, base64, "data_url")
            .then((snapshot) => {
                return getDownloadURL(snapshot.ref);
            })
            .then((downloadURL) => {
                return downloadURL;
            })
            .catch((error) => {
                return "";
            });
        return getImageDownloadURL;
    }

    const handleURLTextChange = (event) => {
        const websiteURL = event.target.value;

        // Remove trailing /    
        const websiteURLNoTrail = websiteURL.endsWith('/') ? websiteURL.slice(0, -1) : websiteURL;

        setCurrentURL(websiteURL);
        // set if URL is valid
        if ((websiteURL).length > 7 && !((websiteURL).slice(0, 8).includes("http"))) {
            showHTTPFeedbackToast();
        }
        setValidURL(isValidUrl(websiteURLNoTrail));
    };

    const handleSignatureTextChange = (event) => {
        const saveSignatureButton = document.getElementById("save-signature-btn");
        setBackgroundEmailSignature(event.target.value);
        if (saveSignatureButton) {
            saveSignatureButton.style.display = "flex";
        }
    };

    function isValidUrl(str) {
        const pattern = /^https?:\/\/[\w.-]+\.[a-z]{2,}(\/[\w.-]+)*$/i;
        return pattern.test(str) && str.length >= 7;
    }

    function showHTTPFeedbackToast() {
        document.getElementById("toast-http-feedback").style.setProperty("display", "block", "important");

        setTimeout(() => {
            document.getElementById("toast-http-feedback").style.setProperty("display", "none", "important");
        }, 5000);
    }

    const clearCurrentURLValue = () => {
        setCurrentURL("");
        setValidURL(false);
    };

    async function getExistingKnowledgeBaseForUID(uid) {
        OrganizationUtils.callGetExistingKnowledgeBases(uid)
            .then(async (response) => {
                if (response) {
                    setExistingKbs(response);
                    setKbsLoading(false);
                }
                setKbsLoading(false);
            })
            .catch(async () => {
                setKbsLoading(false);
                await showErrorToast();
            });
    }

    async function updateKnowledgeBase() {
        // Get all the knowledge base ids.
        // send to update API


        const oldKnowkedgeBaseIds = knowledgeBases.map((obj) => obj.id);
        const newKnowledgeBaseIDs = knowledgeBaseIDs.map((obj) => obj.id);

        if (haveSameElements(oldKnowkedgeBaseIds, newKnowledgeBaseIDs)) {
            // It's the exact same, no change. just close modal
            closeAddKbModal();
            return;
        }
        // it's different call update
        OrganizationUtils.callUpdateKnowledgeBaseForAppID(appId, newKnowledgeBaseIDs, user.uid)
            .then(async (response) => {
                if (response) {
                    // Set the new knowledge base
                    getKnowledgeBasesForThisApp(appId, user.uid);
                    if (response.status === 402) {
                        // Payment required
                        setErrorMessage(response.data.message);
                        setErrorReason(response.data.reason);
                        setUpgradePremiumModalOpen(true);
                        return;
                    } else {
                        closeAddKbModal(); // Close modal
                        showSuccessToast("Successfully updated sources!!", 6000);
                        return;
                    }
                }
                // Failed to delete
                showErrorToast("Error: Failed to update sources", 6000);
            })
            .catch((error) => {
                showErrorToast("Error: Failed to update sources", 6000);
            });
    }

    function haveSameElements(array1, array2) {
        if (array1.length !== array2.length) {
            return false;
        }

        const sortedArray1 = array1.slice().sort();
        const sortedArray2 = array2.slice().sort();

        for (let i = 0; i < sortedArray1.length; i++) {
            if (sortedArray1[i] !== sortedArray2[i]) {
                return false;
            }
        }

        return true;
    }

    function setViewState(view) {
        if (view) setCurrentView(view);
    }

    async function hideRenderAddNewKnowledgeBase() {
        closeAddKbModal();
    }

    const addKnowledgeBaseID = (kbObject) => {
        // KbObject coontains the kb id, and url identifier
        setKnowledgeBaseIDs([...knowledgeBaseIDs, kbObject]);
    };

    const removeItemFromKnowledgeBaseIDs = (itemID) => {
        // Match the item ID and remove it from knowledgebase ids
        setKnowledgeBaseIDs(knowledgeBaseIDs.filter((i) => i.id !== itemID));
    };

    function updateBackgroundResponseToggle(event) {
        const selectedValue = event.target.value;
        const backgroundToggle = selectedValue === "on" ? true : false;

        setBackgroundEmailToggle(backgroundToggle);
        // Call api to update

        ProjectUtils.callUpdateAppInfo(user.uid, appId, "backgroundResponse", backgroundToggle)
            .then(async (response) => {
                if (response) {
                    setBackgroundEmailToggle(selectedValue);
                    showSuccessToast(`Background responses ${selectedValue}`, 6000);
                } else {
                    showErrorToast("Failed to update", 6000);
                }
                return;
            })
            .catch((error) => {
                showErrorToast("Failed to update", 6000);
            });
    }

    async function saveSignatureClick() {
        // Show save loading
        const saveSignatureButton = document.getElementById("save-signature-btn");

        const saveSignatureButtonText = document.getElementById("save-signature-btn-text");
        if (saveSignatureButtonText) {
            saveSignatureButtonText.innerHTML = "Saving...";
        }
        // Call API to update app
        ProjectUtils.callUpdateAppInfo(user.uid, appId, "signature", backgroundEmailSignature)
            .then(async (response) => {
                if (response) {
                    if (saveSignatureButtonText) saveSignatureButtonText.innerHTML = "Saved!";
                } else {
                    if (saveSignatureButtonText) saveSignatureButtonText.innerHTML = "Error";
                }
                // Remove signature button afer a while
                setTimeout(() => {
                    if (saveSignatureButton) saveSignatureButton.style.display = "none";
                }, 6000);
            })
            .catch((error) => {
                if (saveSignatureButtonText) saveSignatureButtonText.innerHTML = "Error";
                // Remove signature button afer a while
                setTimeout(() => {
                    if (saveSignatureButton) saveSignatureButton.style.display = "none";
                }, 6000);
            });
    }

    function trainingStatus(status) {
        if (status === "processing") {
            return (
                <div
                    style={{
                        marginBottom: "15px",
                        paddingBottom: "15px",
                        borderBottom: "1px solid #eaeeff",
                    }}>
                    <SectionTitle1>Training Status</SectionTitle1>

                    <div
                        className="flex flex-row items-center"
                        style={{
                            backgroundColor: "#FFF8EE",
                            width: "200px",
                            borderRadius: "10px",
                            height: "40px",
                            paddingLeft: "20px",
                            marginBottom: "10px",
                        }}>
                        <Dot style={{ backgroundColor: "#F29949" }}></Dot>
                        <p style={{ marginLeft: "10px", fontSize: "14px" }}>Processing</p>
                    </div>

                    <ParagraphSubTitle>
                        This may take up to 5 minutes. We will send you an email when it's complete
                    </ParagraphSubTitle>
                </div>
            );
        } else if (status === "complete") {
            return (
                <div
                    style={{
                        marginBottom: "15px",
                        paddingBottom: "15px",
                        borderBottom: "1px solid #eaeeff",
                    }}>
                    <SectionTitle1>Training Status</SectionTitle1>

                    <div
                        className="flex flex-row items-center"
                        style={{
                            backgroundColor: "#EAF7EE",
                            width: "200px",
                            borderRadius: "10px",
                            height: "40px",
                            paddingLeft: "20px",
                            marginBottom: "10px",
                        }}>
                        <Dot style={{ backgroundColor: "#47B678" }}></Dot>
                        <p style={{ marginLeft: "10px", fontSize: "14px" }}>Complete</p>
                    </div>

                    <ParagraphSubTitle>Your AI is now ready to respond to your customer emails 24/7.</ParagraphSubTitle>
                </div>
            );
        } else if (status === "failed") {
            return (
                <div
                    style={{
                        marginBottom: "15px",
                        paddingBottom: "15px",
                        borderBottom: "1px solid #eaeeff",
                    }}>
                    <SectionTitle1>Training Status</SectionTitle1>

                    <div
                        className="flex flex-row items-center"
                        style={{
                            backgroundColor: "#FFEDEB",
                            width: "200px",
                            borderRadius: "10px",
                            height: "40px",
                            paddingLeft: "20px",
                            marginBottom: "10px",
                        }}>
                        <Dot style={{ backgroundColor: "#F87462" }}></Dot>
                        <p style={{ marginLeft: "10px", fontSize: "14px" }}>Failed</p>
                    </div>

                    <ParagraphSubTitle>There was a problem training your AI. Please contact support for help.</ParagraphSubTitle>
                </div>
            );
        } else {
            return null;
        }
    }

    function renderAddNewKnowledgeBase() {
        return (
            <CardsSetupOrganization3
                user={user}
                text1={"Add Knowledge Base"}
                text2={"Add data to train your AI. You can add a website link or upload text files (pdf, txt, pptx)."}
                info={[
                    { html: "text1", text: "Org. Info" },
                    { html: "text3", text: "Proj. Info" },
                ]}
                createApplication={updateKnowledgeBase}
                buttonText={"Update"}
                function1={hideRenderAddNewKnowledgeBase}
                onChange={handleURLTextChange}
                closeView={closeAddKbModal}
                knowledgeBaseIDs={knowledgeBaseIDs}
                addKbToState={addKnowledgeBaseID}
                removeItemFromKnowledgeBaseIDs={removeItemFromKnowledgeBaseIDs}
                createFileKnowledgeBase={createFileKnowledgeBase}
                selectableExistingKBs={true}
                existingKbs={existingKbs}
                kbsLoading={kbsLoading}
                step={1}
                clearCurrentURLValue={clearCurrentURLValue}
                currentURLValue={currentURL}
                isCurrentURLValid={validURL}
                uid={user.uid}
                organizationId={organizationId}
                totalSteps={1}
            />
        );
    }

    const modalStyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
        },
    };

    const updateChatbotPreview = (newConfig) => {
        setRealTimeChatbotUIConfig(newConfig);
    }

    const modalStyle2 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
            borderColor: "transparent",
        },
    };

    const modalStyle3 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "40%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderColor: "dark:bg-gray-700",
            zIndex: "100",
        },
    };

    const Button = styled.button`
        width: fit-content;
        padding-left: 3px;
        padding-right: 3px;
        gap: 2px;
        display: block;
        justify-content: center;
        align-items: center;
        color: #eaeeff;
        font-size: 12px;
        font-weight: 500;
        font-family: Poppins;
        line-height: 20px;
        white-space: nowrap;
        border-style: solid;
        border-color: #eaeeff;
        background-color: #745dde;
        border-radius: 7px;
        padding: 13.2px 15.2px;
        border-width: 0.8px;
        box-sizing: content-box;
        cursor: pointer;
        &: hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
        }
    `;
    function handleCloseUpgradeModal() {
        setUpgradePremiumModalOpen(false);
    }

    // function handleUpgrade(user) {
    //     var link = `https://addy.so/pricing?uid=${encodeURIComponent(user.uid)}&src=pricing-upgrade-button-from-dashboard-training`;
    //     window.open(link, '_blank');
    // }
    return (
        <div
            className="container bg-transparent dark:bg-gray-800 bg"
            style={{
                paddingTop: "50px",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "column"
            }}>

            <div style={{
                marginBottom: "40px",
                width: "100%",
            }}>
                <AppViewHeader projectData={serverProjectData} user={user} />

                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderBottom: "1px solid #eaeeff",
                    marginTop: "25px"
                }}>
                    <FlexRow>
                        {
                            currentView === "settings" ? <MenuOptionsSelectedNew style={{
                                borderBottom: "2px solid #745dde"
                            }} onClick={() => setViewState("settings")}>
                                <Icons1
                                    src={currentView === "settings" ? settingsIconFull : settingsIconOutline}
                                />
                                <TextViewChange style={{
                                    color: "#745dde",
                                    fontWeight: "500"
                                }}>Settings</TextViewChange>
                            </MenuOptionsSelectedNew> :
                                <MenuOptions4 onClick={() => setViewState("settings")}>
                                    <Icons1
                                        src={currentView === "settings" ? settingsIconFull : settingsIconOutline}
                                    />
                                    <TextViewChange>Settings</TextViewChange>
                                </MenuOptions4>
                        }
                        {
                            currentView === "knowledgeBase" ? <MenuOptionsSelectedNew style={{
                                borderBottom: "2px solid #745dde"
                            }} onClick={() => setViewState("knowledgeBase")}>
                                <Icons1
                                    src={currentView === "knowledgeBase" ? knowledgeBaseIconFull : knowledgeBaseIconOutline}
                                />
                                <TextViewChange style={{
                                    color: "#745dde",
                                    fontWeight: "500"
                                }}>Knowledge Base</TextViewChange>
                            </MenuOptionsSelectedNew> : <MenuOptions4 onClick={() => setViewState("knowledgeBase")}>
                                <Icons1
                                    src={currentView === "knowledgeBase" ? knowledgeBaseIconFull : knowledgeBaseIconOutline}
                                />
                                <TextViewChange>Knowledge Base</TextViewChange>
                            </MenuOptions4>
                        }

                        {
                            serverProjectData["appType"] && (!serverProjectData["appType"].includes("email")) && currentView === "quickprompts" ? <MenuOptionsSelectedNew style={{
                                borderBottom: "2px solid #745dde"
                            }} onClick={() => setViewState("quickprompts")}>
                                <Icons1
                                    src={currentView === "quickprompts" ? tagIconFull : tagIconOutline}
                                />
                                <TextViewChange style={{
                                    color: "#745dde",
                                    fontWeight: "500"
                                }}>Quick prompts</TextViewChange>
                            </MenuOptionsSelectedNew> : serverProjectData["appType"] && (!serverProjectData["appType"].includes("email")) && <MenuOptions4 onClick={() => setViewState("quickprompts")}>
                                <Icons1
                                    src={currentView === "quickprompts" ? tagIconFull : tagIconOutline}
                                />
                                <TextViewChange>Quick prompts</TextViewChange>
                            </MenuOptions4>
                        }

                        {
                            serverProjectData["appType"] && (serverProjectData["appType"].includes("email")) && currentView === "chrome" ? <MenuOptionsSelectedNew style={{
                                borderBottom: "2px solid #745dde"
                            }} onClick={() => setViewState("chrome")}>
                                <Icons1
                                    src={currentView === "chrome" ? chromeIconFullPurple : chromeIconOutline}
                                />
                                <TextViewChange style={{
                                    color: "#745dde",
                                    fontWeight: "500"
                                }}>Add to Chrome </TextViewChange>
                            </MenuOptionsSelectedNew> : serverProjectData["appType"] && (serverProjectData["appType"].includes("email")) && <MenuOptions4 onClick={() => setViewState("chrome")}>
                                <Icons1
                                    src={currentView === "chrome" ? chromeIconFullPurple : chromeIconOutline}
                                />
                                <TextViewChange>Add to Chrome</TextViewChange>
                            </MenuOptions4>
                        }


                    </FlexRow>

                </div>

            </div>

            <div className="row">


                <div className="col-md-7" style={{
                    paddingBottom: "60px"
                }}>

                    {
                        currentView === "knowledgeBase" ?
                            <KnowledgeBaseTab knowledgeBases={knowledgeBases}
                                deleteKnowledgeBase={deleteKnowledgeBase}
                                appId={appId} user={user}
                                openAddKbModal={openAddKbModal}
                            /> : <></>
                    }
                    {
                        serverProjectData["appType"] && (!serverProjectData["appType"].includes("email")) && currentView === "settings" ?
                            <Settings user={user} appId={appId} config={chatbotUIConfig}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                                updateChatbotPreview={updateChatbotPreview}
                            /> : <></>
                    }

                    {
                        serverProjectData["appType"] && (!serverProjectData["appType"].includes("email")) && currentView === "quickprompts" ?
                            <QuickPromptsMain user={user} appId={appId} config={serverProjectData &&
                                serverProjectData.config ?
                                serverProjectData.config : {}}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                            /> : <></>
                    }

                    {
                        serverProjectData["appType"] && (serverProjectData["appType"].includes("email")) && currentView === "chrome" ?
                            <div style={{ paddingBottom: "50px", width: "fit-content" }}>
                                <FinishOnboarding
                                    paragraphWidth="450px"
                                    subTitle="Install the Chrome extension to quickly draft or respond to customer emails within your email client. Once your AI is trained, it will use the training data to enhance its responses to customers."
                                    disableMaybeButton={true}
                                />
                            </div> : <></>
                    }

                    {serverProjectData["appType"] && serverProjectData["appType"].includes("email")
                        && currentView === "settings" ? (
                        <div
                            style={{
                                width: "80%",
                                paddingBottom: "20px",
                                borderBottom: "1px solid #eaeeff",
                            }}>
                            {trainingStatus(projectT["trainingStatus"])}

                            <SectionTitle1>Background responses</SectionTitle1>

                            <ParagraphSubTitle>
                                AI writes draft email responses for you and saves them in your drafts folder. You can check and
                                review before sending.
                            </ParagraphSubTitle>

                            <SelectClient onChange={(e) => updateBackgroundResponseToggle(e)} value={backgroundEmailToggle}>
                                <option value="on">On</option>
                                <option value="off">Off</option>
                            </SelectClient>

                            <ParagraphSubTitle>Signature</ParagraphSubTitle>

                            <SignatureText
                                rows="3"
                                value={backgroundEmailSignature}
                                onChange={(e) => handleSignatureTextChange(e)}
                            />
                            <SignatureSaveButton id="save-signature-btn" onClick={() => saveSignatureClick()}>
                                <SignatureSaveFloatingBubble>
                                    <IconSmall src={`https://i.imgur.com/9VcPgAZ.png`} />{" "}
                                    <span id="save-signature-btn-text">Save</span>
                                </SignatureSaveFloatingBubble>
                            </SignatureSaveButton>
                        </div>
                    ) : (
                        <></>
                    )}




                </div>

                <div className="col-md-5" style={{
                    backgroundColor: "#EDF2FA",
                    borderRadius: "20px",
                    padding: "15px",
                    height: "fit-content",
                    paddingBottom: "60px"
                }}>

                    {loadPage ? (
                        <div className="flex flex-col justify-center items-center">Loading actions...</div>
                    ) : (
                        <div className="flex flex-col justify-center items-center">
                            <div
                                className="bg-gray-0 dark:bg-gray-800 h-screen w-full"
                                style={{
                                    // boxShadow: "rgba(9, 30, 66, 0.1) 0px 1px 1px, rgba(9, 30, 66, 0.1) 0px 0px 1px 1px",
                                    borderRadius: "0px",
                                    marginTop: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}>

                                {
                                    loadPage ? (
                                        <div className="flex flex-col justify-center items-center">
                                            <MutatingDots color="#0E1221" secondaryColor="#0E1221" visible={true} height={100} width={100} />
                                        </div>
                                    ) : (
                                        // <div className="flex flex-col justify-center items-center">{appPageTemplate(projectT)}</div>
                                        <></>
                                    )

                                }

                                {serverProjectData["appType"] && serverProjectData["appType"].includes("email") ? (
                                    <></>
                                ) : (
                                    <ChatbotPreview data={projectT} realtimeConfig={realTimeChatbotUIConfigRealTime} />
                                )}

                                {serverProjectData["appType"] && serverProjectData["appType"].includes("email") ? (
                                    <></>
                                ) : (
                                    <div
                                        style={{
                                            width: "80%",
                                            // paddingBottom: "15px",
                                            borderBottom: "1px solid #eaeeff",
                                            marginTop: "30px"
                                        }}>
                                        <SectionTitle1>Embed on your website</SectionTitle1>

                                        <div data-color-mode="dark">
                                            <CodeEditor
                                                value={getScriptTagForProj(projectT)}
                                                ref={scriptTagRef}
                                                language="js"
                                                placeholder="Please enter JS code."
                                                readOnly={true}
                                                padding={15}
                                                style={{
                                                    borderRadius: "8px",
                                                    fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                                                    fontSize: 12,
                                                }}
                                            />

                                            <p
                                                style={{
                                                    fontSize: "12px",
                                                    marginTop: "3px",
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                    color: "#0d1121",
                                                }}
                                                id="script-copy-button"
                                                onClick={() =>
                                                    copyScriptTextToClipboard(
                                                        getScriptTagForProj(projectT),
                                                        "script-copy-button",
                                                        "Click to copy code"
                                                    )
                                                }>
                                                Click to copy code
                                            </p>

                                            <div style={{
                                                borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
                                                width: "100%",
                                                marginTop: "25px",
                                                marginBottom: "7px"
                                            }}> </div>
                                        </div>

                                        <br />
                                    </div>
                                )}

                                {serverProjectData["appType"] && serverProjectData["appType"].includes("email") ? (
                                    <></>
                                ) : (
                                    <div
                                        style={{
                                            width: "80%",
                                        }}>

                                        <CardssetupPayments>
                                            <FlexRow>
                                                <LastWeek style={{
                                                    width: "135px",
                                                    border: "1px solid rgba(0, 0, 0, 0.2)"
                                                }}
                                                    onClick={() => {
                                                        window.open(getPreviewDomain());
                                                    }}>
                                                    Preview
                                                </LastWeek>

                                                <LastWeek1 style={{
                                                    width: "135px"
                                                }} disabled={false} onClick={() => publishWebsite()}>
                                                    Publish
                                                </LastWeek1>
                                            </FlexRow>
                                        </CardssetupPayments>

                                        <br />
                                    </div>
                                )}

                                {serverProjectData["appType"] && serverProjectData["appType"].includes("email") ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            paddingBottom: "20px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center"
                                        }}>
                                        <SectionTitle1 style={{
                                            fontSize: "14px",
                                        }}>Test your email AI by Chatting with it</SectionTitle1>
                                        <ParagraphSubTitle style={{
                                            paddingBottom: "15px",
                                        }}>
                                            Ask your AI to write any email related to its training data. 
                                            For instance you can ask it to "Write a sales email for {serverProjectData["appName"]}."
                                        </ParagraphSubTitle>

                                        {/* <EmailAssistantPreview user={user} showErrorToast={showErrorToast} /> */} 
                                        <ChatbotPreview data={projectT} realtimeConfig={realTimeChatbotUIConfigRealTime} /> 
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {serverProjectData["appType"] && serverProjectData["appType"].includes("email") ? (
                                    <div
                                        style={{
                                            width: "80%",
                                            paddingTop: "15px"
                                        }}>

                                        <CardssetupPayments>
                                            <FlexRow>
                                                <LastWeek1 style={{
                                                    width: "fit-content",
                                                    paddingLeft: "30px",
                                                    paddingRight: "30px"
                                                }} disabled={false} onClick={() => window.open("https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn", "_blank")}>
                                                    Click to Install AI in Your Email Client
                                                </LastWeek1>
                                            </FlexRow>
                                        </CardssetupPayments>

                                        <br />
                                    </div>
                                ) : (
                                    <></>
                                )}

                            </div>
                        </div>
                    )}
                </div>



            </div>

            <Modal
                id="publish-modal"
                isOpen={publishModalIsOpen}
                onRequestClose={closeModal}
                style={modalStyle}
                contentLabel="Publish">
                <div>
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">
                        {publishState === "chooseDestination" ? publishPopUp() : null}
                        {publishState === "publishing" ? publishingPopUp() : null}
                        {publishState === "success" ? successPopUp() : null}
                    </div>
                </div>
            </Modal>

            <Modal
                id="add-knowledgebase-modal"
                isOpen={addKbModalIsOpen}
                onRequestClose={closeAddKbModal}
                shouldCloseOnOverlayClick={false}
                style={modalStyle2}
                contentLabel="Publish">
                <div>
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">{renderAddNewKnowledgeBase()}</div>
                </div>
            </Modal>
            <Modal
                id="upgrade-modal"
                isOpen={upgradePremiumModalOpen}
                onRequestClose={closeUpgradePremiumModal}
                style={modalStyle3}
                contentLabel="Upgrade Modal">
                <CardssetupPaymentsRootRoot >
                        <FlexRow style={{ fontSize: "14px", fontWeight: "bold"}}>{errorMessage}</FlexRow>    
                        <FlexRow style={{fontSize: "12px", wordWrap: "break-word"}}>{errorReason}</FlexRow>
                        <FlexRow style={{justifyContent: "flex-end"}}>
                            {/* <p style={{width:"40%"}}></p> */}
                            <Button onClick={handleCloseUpgradeModal}>No, thanks</Button>
                            <Button onClick={() => {
                                    window.open(`https://addy.so/pricing?uid=${encodeURIComponent(user.uid)}&src=pricing-upgrade-button-from-dashboard-training`, "_blank");
                                }}>
                                Upgrade to premium
                            </Button>
                        </FlexRow>
                    
                </CardssetupPaymentsRootRoot>
            </Modal>



            {publishConfetti}

            <div
                id="toast-save"
                className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-400"
                style={{ zIndex: "100" }}
                role="alert">
                <div className="text-sm font-normal">Project successfully saved</div>
            </div>

            <div
                id="success-toast"
                className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-400"
                style={{ zIndex: "100" }}
                role="alert"></div>

            <div
                id="toast-save-template"
                className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-green-800"
                style={{ zIndex: "100" }}
                role="alert">
                <div className="text-sm font-normal">Template successfully saved</div>
            </div>

            <div
                id="toast-error"
                className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400"
                style={{ zIndex: "100" }}
                role="alert">
                <div className="text-sm font-normal">Error</div>
            </div>

            <div
                id="error-toast"
                className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400"
                style={{ zIndex: "100" }}
                role="alert"></div>

            <div
                id="toast-http-feedback"
                className="flex absolute right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 bg-red-400"
                style={{ zIndex: "100" }}
                role="alert">
                <div className="text-sm font-normal">URL must start with "http" or "https"</div>
            </div>
        </div>
    );
};

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
`;
const SignatureText = styled.textarea`
  resize: none;
  word-wrap: break-word;
  display: flex;
  font-size: 13px;
  white-space: pre-wrap;
  overflow-y: scroll;
  line-height: 20px;
  height: fit-content;
  width: 100%;
  background-color: #f4f6ff;
  border-color: #eaeeff;
  border-radius: 10px;
  margin-top: 8px;
  padding: 10px;
`;
const SelectClient = styled.select`
  border-radius: 8px;
  border: 1px solid gray;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  width: 100%;
  background-color: #f4f6ff;
  border-color: #eaeeff;
  margin-top: 8px;
  margin-bottom: 20px;
`;

const SignatureSaveButton = styled.div`
  cursor: pointer;
  display: none;
  position: relative;
  right: 0px;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
const SignatureSaveFloatingBubble = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  width: fix-content;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 5px;
`;

const FlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const TextKb = styled.div`
  color: #0d1121;
  font-size: 13px;
  font-family: Poppins;
  white-space: nowrap;
`;

const ParagraphSubTitle = styled.div`
  width: 100%;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;

const Text2 = styled.button`
  color: #485175;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const TextViewChange = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;

const MenuOptions3 = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  background-color: #f9faff;
  border-radius: 16px;
  padding: 15.2px 15.2px 15.2px 11.2px;
  cursor: pointer;
`;

const MenuOptions4 = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
//   padding: 15.2px 15.2px 15.2px 11.2px;
  cursor: pointer;
  padding-bottom: 10px;
`;

const MenuOptionsSelected = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 15.2px 15.2px 15.2px 11.2px;
  border-width: 2.8px;
  cursor: pointer;
`;

const MenuOptionsSelectedNew = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
//   padding: 15.2px 15.2px 15.2px 11.2px;
    padding-bottom: 10px;
  cursor: pointer;
`;

const Icons1 = styled.img`
  width: 24px;
  height: 24px;
`;

const TextAdd = styled.button`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const ControlsTabRootRoot = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  gap: 12px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const CardssetupPayments = styled.div`
  width: auto;
  gap: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
`;

const AppTitleText = styled.div`
  width: 376px;
  color: #0d1121;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
  white-space: nowrap;
`;

const SubTitleText = styled.div`
  width: 376px;
  color: #485175;
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins;
`;

const LastWeek = styled.button`
  width: 85px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const LastWeek1 = styled.button`
  width: 85px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const PublishedBody = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const PublishedCardsprojectsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
`;
const PublishedText1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;

const HeaderText = styled.div`
  color: #0d1121;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  white-space: nowrap;
`;

const PublishedParagraph = styled.div`
  width: 500px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const PublishedFlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const PublishedOptions = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  background-color: #f4f6ff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 23px 11px;
`;
const PublishedText2 = styled.div`
  color: #0d1121;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
const PublishedIcons = styled.img`
  width: 20px;
  height: 20px;
`;
const PublishedDivider = styled.img`
  width: 500px;
  height: 1px;
`;
const PublishedLastWeek = styled.button`
  width: auto;
  height: 14px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const PublishingCardsprojectsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 52px 14px;
  border-width: 2px;
`;
const PublishingBody = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PublishingText1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const PublishingText2 = styled.div`
  width: 500px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  text-align: center;
`;
const PublishBody = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const PublishFlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const PublishCheckboxOption = styled.div`
  gap: 10px;
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const PublishText3 = styled.div`
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const PublishCardsprojectsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
  width: 525px;
`;
const PublishFlexRow = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
`;
const PublishFlexRow1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const PublishIcons = styled.img`
  width: 14px;
  height: 14px;
`;
const PublishText1 = styled.div`
  color: #485175;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  white-space: nowrap;
`;
const PublishProjectsFeatures = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const PublishText2 = styled.div`
  width: 500px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const PublishFlexColumn1 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const PublishText4 = styled.div`
  color: #0d1121;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;

const SectionTitle1 = styled.div`
  color: #0d1121;
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const PublishFlexColumn2 = styled.div`
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: ${(props) => (props.disabled ? "lightgrey" : "#745DDE")};
  background-color: ${(props) => (props.disabled ? "lightgrey" : "transparent")};
  border-radius: 8px;
  padding: 0px 4.67px 0px 4px;
  border-width: 2px;
`;
const PublishImage1 = styled.img`
  width: 13.33px;
  height: 9.55px;
  src: ${(props) => (props.checked ? "https://file.rendit.io/n/nsR2nUpJY0RdEhS2438C.svg" : "white")}
  background: ${(props) => (props.checked ? "https://file.rendit.io/n/nsR2nUpJY0RdEhS2438C.svg" : "white")}
`;
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 5px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 5px;
`;
const PublishTextbox = styled.input.attrs({ type: "url" })`
  gap: 2px;
  width: 75%;
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  font-size: 12px;
  border-width: 0.8px;
`;
const PublishDivider = styled.img`
  width: 500px;
  height: 1px;
`;
const PublishLastWeek2 = styled.button`
  gap: 2px;
  display: flex;
  flex-grow: 1;
  width: 25%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #0d1121;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const LastWeek3 = styled.button`
  width: 85px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const PublishLastWeek = styled.button`
  width: 94%;
  height: 14px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745dde;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const CardssetupPaymentsRootRoot = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 22px 14px 14px 14px;
  border-width: 2px;
`;
const Body = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const Text1 = styled.div`
  color: #0d1121;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 19.6px;
  white-space: nowrap;
`;

const Icons = styled.img`
  width: 32px;
  height: 32px;
`;
const IconSmall = styled.img`
  width: 16px;
  height: 16px;
`;
const ScreenRootRoot = styled.div`
  height: 110px;
  width: 250px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 20px;
  border-width: 2px;
`;
const Body11 = styled.div`
  margin-top: -15px;
  width: 80px;
  height: 62px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 11px;
`;
const FlexRow = styled.div`
  margin-top: -15px;
  gap: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
`;
const Line = styled.img`
  margin-left: 15px;
  width: 1px;
  height: 56px;
`;
const Body1 = styled.div`
  height: 62px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 11px;
`;

const ScreenTemplate = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 20px;
  border-width: 2px;
`;

const AppPageTemplate = styled.div`
  gap: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 20px;
  border-width: 2px;
`;

const ScreenTemplateBody = styled.img`
  width: 400px;
  height: 275px;
`;
const ScreenTemplateFlexColumn = styled.div`
  height: 50px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 11px;
`;
const ScreenTemplateLastWeek = styled.button`
  margin-bottom: 20px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #0d1121;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

export default SetUpAI;

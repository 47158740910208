import axios from "axios";
import { apiURL, AI_APP_API_URL, KNOWLEDGE_BASE_API_URL, ORGANIZATION_API_URL } from '../../globalVariables';


const OrganizationUtils = {
    callGetOrganization: async function (userId, organizationId, userEmail) {

        const requestURL = `${ORGANIZATION_API_URL}/organization/getorganization`
        return await axios.get(requestURL, {
            params: {
                requesterUserId: userId,
                organizationId: organizationId,
                userEmail: userEmail
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetOrganizationMembers: async function (userId, organizationId, requesterEmail) {

        const requestURL = `${ORGANIZATION_API_URL}/organization/get-organization-members`
        return await axios.get(requestURL, {
            params: {
                requesterUserId: userId,
                organizationId: organizationId,
                requesterEmail: requesterEmail
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callRemovemember: async function (requesterUserId, organizationId, userEmail) {
        const requestURL = `${ORGANIZATION_API_URL}/organization/removemember`
        return await axios.post(requestURL, {
            requesterUserId: requesterUserId,
            organizationId: organizationId,
            memberEmail: userEmail,
        }).then(async (response) => {
            return response.data.success;
        }).catch((error) => {
            console.error(error);
            return false;
        })
    },

    callGetorganizationwithadmininfo: async function (userId, organizationId) {
        const requestURL = `${apiURL}/organization/getorganizationwithadmininfo`
        return await axios.get(requestURL, {
            params: {
                requesterUserId: userId,
                organizationId: organizationId
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetKnowledgeBasesForOrg: async function (organizationId,
        requesterUserId, userEmail) {
        const requestURL = `${ORGANIZATION_API_URL}/organization/get-knowledgebases`
        return await axios.get(requestURL, {
            params: {
                uid: requesterUserId,
                organizationId: organizationId,
                userEmail: userEmail,
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.knowledgeBases;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetKnowledgeBasesForAppID: async function (appID, requesterUserId) {
        const requestURL = `${AI_APP_API_URL}/application/get-knowledgebases`
        return await axios.get(requestURL, {
            params: {
                uid: requesterUserId,
                appId: appID,
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.knowledgebases;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callDeleteKnowledgeBaseForAppID: async function (appId, knowledgeBaseId, uid) {
        const requestURL = `${AI_APP_API_URL}/application/delete-knowledge-base`

        return await axios.post(requestURL, {
            appId: appId,
            knowledgeBaseId: knowledgeBaseId,
            requesterUserId: uid,
        }).then(async (response) => {
            if (response.data.success) {
                return response.data.success
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return false;
            }
            return false;
        })
    },

    callDeleteKnowledgeBaseFromOrganization: async function (orgId, knowledgeBaseId, uid) {
        const requestURL = `${ORGANIZATION_API_URL}/organization/delete-knowledge-base`

        return await axios.post(requestURL, {
            orgId: orgId,
            knowledgeBaseId: knowledgeBaseId,
            requesterUserId: uid,
        }).then(async (response) => {
            if (response.data.success) {
                return response.data.success
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return false;
            }
            return false;
        })
    },

    callUpdateKnowledgeBaseCheck: async function (orgId, knowledgeBaseId, uid) {
        const requestURL = `${ORGANIZATION_API_URL}/organization/check-knowledge-base-new-content`

        return await axios.post(requestURL, {
            orgId: orgId,
            knowledgeBaseId: knowledgeBaseId,
            requesterUserId: uid,
        }).then(async (response) => {
            if (response.data.success) {
                return response.data
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return false;
            }
            return false;
        })
    },

    callUpdateKnowledgeBaseFields: async function (orgId, knowledgeBaseId, uid, updatedKnowledgeBase) {
        const requestURL = `${ORGANIZATION_API_URL}/organization/update-knowledge-base-fields`

        return await axios.post(requestURL, {
            orgId: orgId,
            knowledgeBaseId: knowledgeBaseId,
            requesterUserId: uid,
            updatedKnowledgeBase: updatedKnowledgeBase
        }).then(async (response) => {
            if (response.data.success) {
                return response.data
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return false;
            }
            return false;
        })
    },

    callUpdateKnowledgeBaseForAppID: async function (appId, newKnowledgeBaseIds, uid) {
        const requestURL = `${AI_APP_API_URL}/application/update-knowledge-base`
        return await axios.post(requestURL, {
            appId: appId,
            newKnowledgeBaseIds: newKnowledgeBaseIds,
            requesterUserId: uid,
        }).then(async (response) => {
            if (response.data.success) {
                return response.data.success
            }
            else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return false;
            } else if (error.response.status === 402 || error.response.data.message === "Upgrade to premium") {
                return error.response;
            }
            return false;
        })
    },

    callVerifyEmailClientAuthCredentials: async function (
        requesterUserId, appId, email, password, client) {
        const requestURL = `${AI_APP_API_URL}/application/verify-email-credentials`
        const requestBody = {
            requesterUserId: requesterUserId,
            appId: appId,
            email: email,
            password: password,
            client: client,
        }
        return await axios.post(requestURL, requestBody)
            .then(async (response) => {
                if (response.data.success) {
                    return response.data.success
                } else {
                    return false;
                    // TODO: Show error
                }
            }).catch((error) => {
                // TODO: Show error
                // console.error(error);
                if (error.response.data && error.response.data.reason &&
                    error.response.data.reason === "Unauthorized") {
                    // TODO: Show error or navigate. 
                    // navigate("/onboarding");
                    return false;
                }
                return false;
            })
    },

    callVerifyEmailVerificationCode: async function (
        requesterUserId, appId, code) {
        const requestURL = `${AI_APP_API_URL}/application/verify-auth-code`
        const requestBody = {
            requesterUserId: requesterUserId,
            appId: appId,
            code: code,
        }
        return await axios.post(requestURL, requestBody)
            .then(async (response) => {
                console.log(response.data);
                if (response.data.success) {
                    return response.data.success
                } else {
                    return false;
                    // TODO: Show error
                }
            }).catch((error) => {
                // TODO: Show error
                // console.error(error);
                if (error.response.data && error.response.data.reason &&
                    error.response.data.reason === "Unauthorized") {
                    // TODO: Show error or navigate. 
                    // navigate("/onboarding");
                    return false;
                }
                return false;
            })
    },

    callGetOrganizations: async function (requesterUserId) {
        console.log('callGetOrganizations', ORGANIZATION_API_URL)
        const requestURL = `${ORGANIZATION_API_URL}/organization/getorganizations`
        return await axios.get(requestURL, {
            params: {
                requesterUserId: requesterUserId
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callGetAppsForUID: async function (uid) {
        const requestURL = `${AI_APP_API_URL}/application/applications-for-uid`
        return await axios.get(requestURL, {
            params: {
                uid: uid
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.apps) {
                    return response.data.apps;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callSetOrganizations: async function (organizationId, organization) {
        const requestURL = `${apiURL}/organization/updateorganizationinfo`
        return await axios.post(requestURL, {
            organizationId: organizationId,
            description: organization.description,
            organizationName: organization.organizationName,
            organizationPhotoUrl: organization.organizationPhotoUrl,
            website: organization.website,
            company: organization.company,
            companyWebsite: organization.companyWebsite,
            timezone: organization.timezone,
            location: organization.location,
            experience: organization.experience,
            twitter: organization.twitter,
            linkedIn: organization.linkedIn
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callDeleteOrganization: async function (organizationId, user) {
        const requestURL = `${ORGANIZATION_API_URL}/organization/deleteorganization`

        return await axios.post(requestURL, {
            organizationId: organizationId,
            requesterUserId: user,
        }).then(async (response) => {
            if (response.data.success) {
                return response.data.success
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return;
            }
            return;
        })
    },

    callCreateOrganization: async function (newOrganization) {
        const requestURL = `${ORGANIZATION_API_URL}/organization/createneworganization`
        return await axios.post(requestURL, {
            organizationName: newOrganization.organizationName,
            organizationId: newOrganization.organizationId,
            creatorUserId: newOrganization.creatorUserId,
            organizationPhotoUrl: newOrganization.organizationPhotoUrl,
        }).then(async (response) => {
            if (response.data.success) {
                return response.data.success
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            if (error.response.data && error.response.data.reason &&
                error.response.data.reason === "Unauthorized") {
                // TODO: Show error or navigate. 
                // navigate("/onboarding");
                return;
            }
            return;
        })
    },

    callPublishApp: async function (repositoryName) {
        const requestURL = `${AI_APP_API_URL}/application/deploy`
        return await axios.post(requestURL, {
            identifier: repositoryName,
            identifierType: "publicId"
        }).then(async (response) => {
            if (response.data.success) {
                return response.data
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            throw new Error(error);
        })
    },

    callDeployAppByAppId: async function (appId) {
        const requestURL = `${AI_APP_API_URL}/application/deploy`
        return await axios.post(requestURL, {
            identifier: appId,
            identifierType: "appId",
        }).then(async (response) => {
            if (response.data.success) {
                return response.data
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            throw new Error(error);
        })
    },

    callPublishAppByAppId: async function (appId, uid) {
        const requestURL = `${AI_APP_API_URL}/application/publish`
        return await axios.post(requestURL, {
            requesterUserId: uid,
            appId: appId,
        }).then(async (response) => {
            if (response.data.success) {
                return response.data
            } else {
                return false;
                // TODO: Show error
            }
        }).catch((error) => {
            // TODO: Show error
            throw new Error(error);
        })
    },

    callCreateApplicatipon: async function (appObject) {
        const requestURL = `${AI_APP_API_URL}/application/create`
        return await axios.post(requestURL, appObject)
            .then(async (response) => {
                if (response.data.success) {
                    return response.data
                } else {
                    return false;
                    // TODO: Show error
                }
            }).catch((error) => {
                // TODO: Show error
                if (error.response.status === 402 || error.response.data.message === "Upgrade to premium") {

                    return error.response;
                }
                console.error(error);
                return false;
            })
    },

    callGetExistingKnowledgeBases: async function (uid) {
        const requestURL = `${KNOWLEDGE_BASE_API_URL}/knowledge-base/get-all-knowledgebases-for-uid`
        const knowledgeBases = await axios.get(requestURL, {
            params: {
                "uid": uid,
            }
        }).then(async (response) => {
            if (response.data.success) {
                return response.data.knowledgeBases;
            }
            // no successful
            return undefined;
        }).catch((error) => {
            // TODO: Show error
            console.error(error);
            return undefined;
        })
        return knowledgeBases;
    }

}

export default OrganizationUtils;
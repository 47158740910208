/* eslint-disable array-callback-return */
import React from "react";
import {
  Text1,
  Text5,
  WhiteFlexRowNoCenter,
  CardsSetupWorkspaceRoot,
  Body,
  FlexRow,
  FlexRow1,
  Text2,
  Icons,
  Paragraph1,
  Text4,
  Paragraph,
  FlexColumn,
  FlexColumnLittle,
  Divider,
  LastWeek,
  SkipButton,
  ActionButton
} from "./styles";

export const TextInput = (props) => {
  return (
    <div
      style={{
        height: "auto",
        paddingLeft: "10px",
        display: "flex",
        flexDirection: "column",
      }}>
      <input
        type="text"
        placeholder="https://example.com"
        value={props.stateVal}
        onChange={(event) => {
          console.log(event.target.value);
          props.setState(event.target.value);
        }}
      />
      {/*props.stateVal && <Text5>{props.stateVal}</Text5>*/}
    </div>
  );
};

export const DropdownInput = (props) => {
  return (
    <div
      style={{
        height: "auto",
        paddingLeft: "10px",
        display: "flex",
        flexDirection: "column",
      }}>
      <select
        value={props.stateVal}
        onChange={(event) => {
          const selectedItem = props.options.find(item => item.title === event.target.value);
          console.log(selectedItem, props.stateVal);
          props.setState(selectedItem.title);
        }}>
        {props.options.map((item, index) => (
          <option key={index} value={item.title}>
            {item.title}
          </option>
        ))}
      </select>
      {props.stateVal && props.options.map((item, index) => (
        <div key={index}>
          {item.title === props.stateVal && item.description && <Text5>{item.description}</Text5>}
        </div>
      ))}
    </div>
  );
};

export const CheckBoxInput = (props) => {
  return (
    <div
      style={{
        height: "auto",
        paddingLeft: "10px",
        display: "flex",
        flexDirection: "column",
      }}>
      {props.options.map((item, index) => (
        <div>
          <label key={index} style={{ padding: "3px" }}>
            <input
              type="checkbox"
              value={item.title}
              onChange={(event) => {
                console.log(item, props.stateVal);
                if (event.target.checked) {
                  let valueList = props.stateVal.split(",");
                  valueList.push(item.title);
                  props.setState(valueList.join(","));
                } else {
                  let valueList = props.stateVal.split(",");
                  valueList = valueList.filter((value) => value !== item.title);
                  let value = valueList.join(",");
                  props.setState(value);
                }
              }}
              checked={props.stateVal && props.stateVal.includes(item.title)}
            />
            &nbsp;&nbsp;&nbsp;{item.title}
          </label>
          {props.stateVal && props.stateVal.includes(item.title) && item.description && <Text5>{item.description}</Text5>}
        </div>
      ))}
    </div>
  );
}; 

export const RadioInput = (props) => {
  return (
    <div
      style={{
        height: "auto",
        paddingLeft: "10px",
        display: "flex",
        flexDirection: "column",
      }}>
      {props.options.map((item, index) => (
        <div key={index}>
          <label style={{ padding: "3px" }}>
            <input
              type="radio"
              value={item.title}
              onChange={(event) => {
                console.log(item, props.stateVal);
                props.setState(event.target.value);
              }}
              checked={props.stateVal === item.title}
            />
            &nbsp;&nbsp;&nbsp;{item.title}
          </label>
          {props.stateVal === item.title && item.description && <Text5>{item.description}</Text5>}
        </div>
      ))}
    </div>
  );
};

export const InputCard = (props) => {
  return (
    <CardsSetupWorkspaceRoot>
      <FlexRow>
        <FlexRow1>
          <Paragraph1>
            Step {props.step}/{props.totalSteps}
          </Paragraph1>
          {
            props.backBtn && <Text2 onClick={props.backBtn}>
            <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span>
          </Text2>
          }
        </FlexRow1>
      </FlexRow>
      <Body>
        <Text1>{props.title}</Text1>
      </Body>
      {props.subtitle || props.caption && (
        <Body>
          { props.subtitle &&  <Text4>{props.subtitle}</Text4> } 
          { props.caption &&  <Paragraph>{props.caption}</Paragraph> }
        </Body>
      )}

      <FlexColumn>
        <FlexColumnLittle>
          <WhiteFlexRowNoCenter>
            {props.type==='text' && <TextInput {...props} />}
            {props.type==='dropdown' && <DropdownInput {...props} />}
            {props.type==='checkbox' && <CheckBoxInput {...props} />}
            {props.type==='radio' && <RadioInput {...props} />}

          </WhiteFlexRowNoCenter>
        </FlexColumnLittle>
        <Body>
          <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
          {props.skipBtn ? (
            <FlexRow> 
              <SkipButton onClick={props.skipBtn}>Skip</SkipButton>
              <ActionButton onClick={props.fwdBtn}>Continue</ActionButton> 
            </FlexRow> 
          ) : ( <LastWeek onClick={props.fwdBtn}>Continue</LastWeek> )}
        </Body>
      </FlexColumn>
    </CardsSetupWorkspaceRoot>
  );
};
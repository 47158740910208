import OrganizationUtils from "../../services/organization/OrganizationUtils";
import styled from "styled-components";
import React, { useEffect, useState } from "react";

export const AppViewHeader = (props) => {
    const [organizationData, setOrganizationData] = useState({});

    useEffect(() => {
        // Get user
        getOrganizationName(props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getOrganizationName(props) {
        if (!(props && props.projectData.organizationId)) return "";
        OrganizationUtils.callGetOrganization(props.user.uid, props.projectData.organizationId, props.user.email)
            .then(data => {
                if (data) {
                    setOrganizationData(data);
                }
            })
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            // alignItems: "center",
            width: "100%",
            paddingBottom: "10px",
        }}>
           
            <div className="horizontal-flex">
                {/* <HeaderText>
                    {organizationData.organizationName ? organizationData.organizationName : ""}
                </HeaderText> */}

                {/* <HeaderText style={{
                    paddingLeft: "10px",
                    paddingRight: "10px"
                }}>
                    {greaterThanSymbols}
                </HeaderText> */}
                    
                <HeaderText>
                    {props.projectData.appName ? props.projectData.appName : ""}
                </HeaderText>
            </div>

            <div>
                <SectionTitle1>
                    {props.projectData && props.projectData.appTitle ?
                        props.projectData.appTitle : ""}
                </SectionTitle1>


            </div>
            
            
            
        </div>
    )
}

const SectionTitle1 = styled.div`
  color: #495275;
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const HeaderText = styled.div`
  color: #0d1121;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  white-space: nowrap;
`;

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import Modal from "react-modal";
import './createOrganizationStep2.css'

export const CardsSetupOrganization2 = props => {
    useEffect(() => {
        setImage(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [image, setImage] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF"];
    const handleChange = (file) => {
        setImage(URL.createObjectURL(file));

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            props.imageFunction(e.target.result, props.text1);
        }
    };
    return (
        <CardssetupWorkspaceRootRoot>
            <FlexRow>
                <FlexRow1>
                    <Text2 onClick={props.function1}>
                        <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span>
                    </Text2>
                    <Paragraph1>
                        Step {props.step}/{props.totalSteps}
                    </Paragraph1>
                </FlexRow1>
                {/* <FlexRow2>
          {props.info.map((item, index) => {
            item.html === "text3" ?
              (
                <Text3>{item.text}</Text3>
              ) : (
                <Text1>{item.text}</Text1>
              )
            index < props.info.length - 1 ?
              <Line src={`https://file.rendit.io/n/ciQViPxUYf694yKxEyXY.svg`} />
              :
              <></>
          })}

        </FlexRow2> */}
            </FlexRow>
            <Body>
                <Text4>{props.text1}</Text4>
                <Paragraph>{props.text2}</Paragraph>
            </Body>
            <FlexColumn>
                <FlexColumn>
                    <FlexRow3>
                        <WhiteText>Name:</WhiteText>
                        <Textbox
                            value={props.value}
                            onChange={props.onChange}
                            placeholder={'My Awesome AI'}>
                        </Textbox>
                    </FlexRow3>
                </FlexColumn>
                {/* <FlexColumn>
                    <Text5>Choose a photo for you new AI</Text5>
                    <WhiteFlexRow>
                        <Text6>
                            <>
                                <FileUploader classes="files" style={{ border: '0px' }} handleChange={handleChange} name="file" types={fileTypes} />
                                {image
                                    && <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "fit-content"
                                    }}>
                                        <img style={{
                                            width: "120px",
                                            height: "120px",
                                            objectFit: "contain",

                                        }} src={image} alt="Organization" />
                                    </div>

                                }
                            </>
                        </Text6>
                    </WhiteFlexRow>
                </FlexColumn> */}
                <Body>
                    <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
                    <LastWeek onClick={props.function2} disabled={!(props.value.length > 3)}>
                        {props.buttonText}
                    </LastWeek>
                </Body>
            </FlexColumn>
        </CardssetupWorkspaceRootRoot>
    );
};

export const CardsSetupBusiness2 = props => {
    const [image, setImage] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF"];
    const handleChange = (file) => {
        setImage(URL.createObjectURL(file));

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            props.imageFunction(e.target.result, props.text1);
        }
    };
    return (
        <CardssetupWorkspaceRootRoot>
            <FlexRow>
                <FlexRow1>
                    <Text2 onClick={props.function1}>
                        <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span>
                    </Text2>
                    <Paragraph1>
                        Step {props.step}/{props.totalSteps}
                    </Paragraph1>
                </FlexRow1>
                {/* <FlexRow2>
            {props.info.map((item, index) => {
              item.html === "text3" ?
                (
                  <Text3>{item.text}</Text3>
                ) : (
                  <Text1>{item.text}</Text1>
                )
              index < props.info.length - 1 ?
                <Line src={`https://file.rendit.io/n/ciQViPxUYf694yKxEyXY.svg`} />
                :
                <></>
            })}
  
          </FlexRow2> */}
            </FlexRow>
            <Body>
                <Text4>{props.text1}</Text4>
                <Paragraph>{props.text2}</Paragraph>
            </Body>
            <FlexColumn>
                <FlexColumn>
                    <FlexRow3>
                        <WhiteText>Name:</WhiteText>
                        <Textbox
                            value={props.value}
                            onChange={props.onChange}
                            placeholder={'My Awesome Business'}>
                        </Textbox>
                    </FlexRow3>
                </FlexColumn>
                {/* <FlexColumn>
                    <Text5>Photo:</Text5>
                    <WhiteFlexRow>
                        <Text6>
                            <>
                                <FileUploader classes="files" style={{ border: '0px' }} handleChange={handleChange} name="file" types={fileTypes} />
                                {image
                                    && <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "fit-content"
                                    }}>
                                        <img style={{
                                            width: "120px",
                                            height: "120px",
                                            objectFit: "contain",

                                        }} src={image} alt="Organization" />
                                    </div>

                                }
                            </>
                        </Text6>
                    </WhiteFlexRow>
                </FlexColumn> */}
                <Body>
                    <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
                    <LastWeek onClick={props.function2} disabled={!(props.value.length > 3)}>
                        {props.buttonText}
                    </LastWeek>
                </Body>
            </FlexColumn>

        </CardssetupWorkspaceRootRoot>
    );
};


export const CardsSetupBusinessPhoto = props => {
    const [image, setImage] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF"];
    const handleChange = (file) => {
        setImage(URL.createObjectURL(file));

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            props.imageFunction(e.target.result, props.text1);
        }
    };
    return (
        <CardssetupWorkspaceRootRoot>
            <FlexRow>
                <FlexRow1>
                    <Text2 onClick={props.function1}>
                        <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span>
                    </Text2>
                    <Paragraph1>
                        Step {props.step}/{props.totalSteps}
                    </Paragraph1>
                </FlexRow1>
                
            </FlexRow>
            <Body>
                <Text4>{props.text1}</Text4>
                <Paragraph>{props.text2}</Paragraph>
            </Body>
            <FlexColumn>
                
                <FlexColumn>
                    <Text5>Thumbnail Photo:</Text5>
                    <WhiteFlexRow>
                        <Text6>
                            <>
                                <FileUploader classes="files" style={{ border: '0px' }} handleChange={handleChange} name="file" types={fileTypes} />
                                {image
                                    && <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "fit-content"
                                    }}>
                                        <img style={{
                                            width: "120px",
                                            height: "120px",
                                            objectFit: "contain",

                                        }} src={image} alt="Organization" />
                                    </div>

                                }
                            </>
                        </Text6>
                    </WhiteFlexRow>
                </FlexColumn>
                <Body>
                    <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "20px",
                        width: "100%"
                    }}>

                        <LastWeekOutline onClick={props.skip}>
                            Skip
                        </LastWeekOutline>


                        <LastWeekHalf onClick={props.function2}>
                            {props.buttonText}
                        </LastWeekHalf>

                    </div>
                   
                </Body>
            </FlexColumn>

        </CardssetupWorkspaceRootRoot>
    );
};



export const CardsSetupBusinessStep3Configure = props => {
    const [image, setImage] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF"];
    const [showPassword, setShowPassword] = useState(false);

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    const emailClientSelected = (event) => {
        const selectedValue = event.target.value;
        const gmailInstructions = document.getElementById("gmail-instructions");
        if (selectedValue === "gmail") {
            gmailInstructions.style.display = "flex";
        } else {
            gmailInstructions.style.display = "none";
        }
        props.handleEmailClientChange(selectedValue);
    }

    const closeModal = () => {
        props.hideVerifyLoadingView("loading");
    }
    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            border: "1px solid transparent",
        },
    };

    function loadingPopUp() {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Verifying...</PublishingText1>
                    <PublishingText2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <InfinitySpin
                                color="#0E1221"
                            />
                        </div>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        )
    }

    function enterCodePopUp(email) {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Enter verification code</PublishingText1>
                    <PublishingText2>Enter the verification code sent to: {email} below</PublishingText2>
                    <PublishingText2>
                        <FlexRow3>
                            <div style={{
                                display: 'flex',
                                flexDirection: "row", width: "100%",
                                gap: "15px", justifyContent: 'center',
                                paddingLeft: "20px", paddingRight: "20px",
                                marginTop: "30px"
                            }}>
                                <TextboxCode
                                    value={props.verificationCode}
                                    onChange={props.handleVerificationCodeChange}
                                    placeholder="Enter Verification Code">
                                </TextboxCode>
                                <LastWeekContain disabled={!props.verificationCodeFormatValid}
                                    onClick={props.validateVerificationCode}>
                                    Submit
                                </LastWeekContain>
                            </div>
                        </FlexRow3>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        )
    }

    return (
        <CardssetupWorkspaceRootRoot>
            <FlexRow>
                <FlexRow1>
                    <Text2 onClick={props.function1}>
                        <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span>
                    </Text2>
                    <Paragraph1>
                        Step {props.step}/{props.totalSteps}
                    </Paragraph1>
                </FlexRow1>
            </FlexRow>
            <Body>
                <Text4>{props.text1}</Text4>
                <Paragraph>{props.text2}</Paragraph>
            </Body>
            <FlexColumn>
                <Text5>Select Email Client:</Text5>

                <SelectClient onChange={(e) => emailClientSelected(e)}>
                    <option value="">Select client:</option>
                    <option value="gmail">Gmail</option>
                    <option value="outlook">Outlook</option>
                </SelectClient>

            </FlexColumn>
            <FlexColumn>
                <FlexColumn>
                    <FlexRow3>
                        <WhiteTextFixed>Email Address:</WhiteTextFixed>
                        <TextboxFields
                            value={props.value}
                            onChange={props.handleEmailAddressChange}
                            placeholder={'john@example.com'}>
                        </TextboxFields>
                    </FlexRow3>

                    <WhiteFlexRowInfo id="gmail-instructions" style={{
                        display: "none"
                    }}>
                        <InfoParagraph>For Gmail, you must use an <a style={{
                            textDecoration: "underline",
                            color: "blue",
                        }} target="_blank" href="https://myaccount.google.com/apppasswords" rel="noreferrer">App Password</a></InfoParagraph>
                        <InfoParagraph>Learn how to <a style={{
                            textDecoration: "underline",
                            color: "blue",
                        }} target="_blank" href="https://www.getmailbird.com/gmail-app-password/" rel="noreferrer">create a Gmail App Password</a></InfoParagraph>
                    </WhiteFlexRowInfo>

                    <FlexRow3>
                        <WhiteTextFixed>Password:</WhiteTextFixed>
                        <TextboxFieldsPassword
                            type={showPassword ? "text" : "password"}
                            value={props.value2}
                            onChange={props.handlePasswordChange}
                            placeholder={'Password for email above'}>
                        </TextboxFieldsPassword>
                        <PasswordToggle onClick={toggleShowPassword}>
                            {showPassword ? "👁️" : "👁️‍🗨️"}
                        </PasswordToggle>
                    </FlexRow3>

                </FlexColumn>

                <Body>
                    <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
                    <LastWeek onClick={props.function2} disabled={!(props.emailAddressValid && props.emailPassword.length > 4 && props.emailClient.length > 0)}>
                        {props.buttonText}
                    </LastWeek>
                </Body>
            </FlexColumn>
            <Modal
                id="training-modal"
                isOpen={props.verifyModalIsOpen}
                onRequestClose={closeModal}
                style={modalStyle}
                contentLabel="Training">
                <div >
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">
                        {props.verificationState === "loading" ? loadingPopUp() : null}
                        {props.verificationState === "enter-code" ? enterCodePopUp(props.emailAddress) : null}
                    </div>

                </div>
            </Modal>
        </CardssetupWorkspaceRootRoot>
    );
};


export const CardsConnectYourSupportInbox1 = props => {

    const [openTwoFactorInstructionsModal, setOpenTwoFactorInstructionsModal] = useState(false);

    const emailClientSelected = (event) => {

        const selectedValue = event.target.value;
        props.handleEmailClientChange(selectedValue);
    }


    const close2FAModal = () => {
        setOpenTwoFactorInstructionsModal(false);
    }

    const open2FAModal = () => {
        setOpenTwoFactorInstructionsModal(true);
    }

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            border: "1px solid transparent",
        },
    };


    function twoFactorAuthInstructionsPopUp() {
        return (
            <PublishingCardsprojectsRootRoot>
                <FlexColumn>



                    <div style={{
                        width: "800px",
                        height: "320px"
                    }}>

                        <FlexRow>
                            <div style={{ width: "350px", height: "250px", marginRight: "30px" }}>
                                <FlexRow>
                                    <Text4>Turn on two factor authentication in GMail</Text4>
                                </FlexRow>

                                <div style={{
                                    marginTop: "20px",
                                    marginBottom: "50px"
                                }}>
                                    <ParagraphContain>Two factor authentication must be turned on in GMail to connect your support inbox with Addy AI</ParagraphContain>
                                </div>



                                <div style={{
                                    marginBottom: "35px"
                                }}>
                                    <Title2>Click below to turn on two step verification</Title2>
                                    <p style={{
                                        height: "5px"
                                    }}>&nbsp;</p>
                                    <LastWeekContain onClick={() => { window.open("https://myaccount.google.com/signinoptions/two-step-verification", '_blank'); }}>
                                        Turn on two factor authentication
                                    </LastWeekContain>
                                </div>

                                <br />

                                <div>
                                    <ParagraphContain>When you’re done setting up two factor authentication, click the “Completed” button below</ParagraphContain>
                                </div>
                            </div>
                            <div style={{ height: "250px", width: "450px" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    height: "100%",
                                    padding: "10px"
                                }}>
                                    <video src="https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FcustomerDashboard%2F2fa.mp4?alt=media&token=f66231fc-8e8a-4b24-acb3-4273826f7167"
                                        loop="true" autoPlay="true" muted={true}
                                        style={{
                                            // border: "3px solid #eaeeff",
                                            width: "450px",
                                            borderRadius: "15px",
                                            boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
                                        }}>
                                    </video>
                                </div>
                            </div>
                        </FlexRow>
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "100%",
                        marginTop: "10px"
                    }}>
                        <div style={{
                            width: "415px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end"
                        }}>
                            <LastWeek onClick={() => {
                                props.manuallyChangeTwoFactorAuth("yes")
                                close2FAModal();
                            }}>
                                Completed
                            </LastWeek>
                        </div>

                    </div>

                </FlexColumn>
            </PublishingCardsprojectsRootRoot>
        )
    }

    return (
        <CardssetupWorkspaceRootRoot>
            <FlexRow>
                <FlexRow1>
                    <Text2 onClick={props.function1}>
                        <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span>
                    </Text2>
                    <Paragraph1>
                        Step {props.step}/{props.totalSteps}
                    </Paragraph1>
                </FlexRow1>
            </FlexRow>
            <Body>
                <Text4>{props.text1}</Text4>
                <Paragraph>{props.text2}</Paragraph>
            </Body>
            <FlexColumn>
                <Title2>Select email client:</Title2>

                <SelectClient onChange={(e) => emailClientSelected(e)}>
                    <option value="">Select client:</option>
                    <option value="gmail">Gmail</option>
                    <option value="outlook">Outlook</option>
                </SelectClient>

            </FlexColumn>


            {/* <div></div> */}

            <FlexColumn>

                <Title2>Do you have two factor authentication turned on?</Title2>

                <label>
                    <input
                        type="radio"
                        value="yes"
                        checked={props.twoFactorState === "yes"}
                        onChange={props.handleTwoFactorAuthSelected}
                    />
                    &nbsp;&nbsp;Yes
                </label>

                <label>
                    <input
                        type="radio"
                        value="no"
                        checked={props.twoFactorState === "no"}
                        onChange={props.handleTwoFactorAuthSelected}
                    />
                    &nbsp;&nbsp;No
                </label>

            </FlexColumn>


            <FlexColumn>
                <Title2>Enter your support email address:</Title2>

                <FlexRow3>
                    <TextboxFieldsEmail
                        value={props.value}
                        onChange={props.handleEmailAddressChange}
                        placeholder={'john@example.com'}>
                    </TextboxFieldsEmail>
                </FlexRow3>


                <Body>
                    <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "20px",
                        width: "100%"
                    }}>

                        <LastWeekOutline onClick={props.skip}>
                            Skip
                        </LastWeekOutline>


                        <LastWeekHalf onClick={props.twoFactorState === "yes" || props.emailClient === "outlook" ? props.function2
                        : open2FAModal} disabled={!(props.emailAddressValid && props.emailClient.length > 0 &&
                            (props.twoFactorState === "yes" || props.twoFactorState === "no"))}>
                        {props.buttonText}
                        </LastWeekHalf>


                    </div>
                   
                </Body>

            </FlexColumn>
            <Modal
                id="2fa-modal"
                isOpen={openTwoFactorInstructionsModal}
                onRequestClose={close2FAModal}
                style={modalStyle}
                shouldCloseOnOverlayClick={false}
                contentLabel="2 Factor Authentication Instructions">
                <div >
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">
                        {twoFactorAuthInstructionsPopUp()}
                    </div>

                </div>
            </Modal>
        </CardssetupWorkspaceRootRoot>
    );
};


export const CardsPromptGmailAppPassword = props => {
    const [openTwoFactorInstructionsModal, setOpenTwoFactorInstructionsModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const closeAppPasswordPromptModal = () => {
        setOpenTwoFactorInstructionsModal(false);
    }

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    const openAppPasswordPromptModal = () => {
        setOpenTwoFactorInstructionsModal(true);
    }

    const closeModal = () => {
        props.hideVerifyLoadingView("loading");
    }

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            border: "1px solid transparent",
        },
    };

    function loadingPopUp() {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Verifying...</PublishingText1>
                    <PublishingText2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <InfinitySpin
                                color="#0E1221"
                            />
                        </div>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        )
    }

    function enterCodePopUp(email) {
        return (
            <PublishingCardsprojectsRootRoot>
                <PublishingBody>
                    <PublishingText1>Enter verification code</PublishingText1>
                    <PublishingText2>Enter the verification code sent to: {email} below</PublishingText2>
                    <PublishingText2>
                        <FlexRow3>
                            <div style={{
                                display: 'flex',
                                flexDirection: "row", width: "100%",
                                gap: "15px", justifyContent: 'center',
                                paddingLeft: "20px", paddingRight: "20px",
                                marginTop: "30px"
                            }}>
                                <TextboxCode
                                    value={props.verificationCode}
                                    onChange={props.handleVerificationCodeChange}
                                    placeholder="Enter Verification Code">
                                </TextboxCode>
                                <LastWeekContain disabled={!props.verificationCodeFormatValid}
                                    onClick={props.validateVerificationCode}>
                                    Submit
                                </LastWeekContain>
                            </div>
                        </FlexRow3>
                    </PublishingText2>
                </PublishingBody>
            </PublishingCardsprojectsRootRoot>
        )
    }



    function promptAppPasswordPopUp() {
        return (
            <PublishingCardsprojectsRootRoot>
                <FlexRow>
                    <FlexRow1>
                        <Text2 onClick={closeAppPasswordPromptModal}>
                            <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span>
                        </Text2>
                    </FlexRow1>
                </FlexRow>

                <FlexColumn>



                    <div style={{
                        width: "800px",
                        height: "320px"
                    }}>

                        <FlexRow>
                            <div style={{ width: "300px", height: "250px", marginRight: "30px" }}>
                                <FlexRow>
                                    <Text4>Generate a GMail App Password</Text4>
                                </FlexRow>

                                <div style={{
                                    marginTop: "20px",
                                    marginBottom: "30px"
                                }}>
                                    <ParagraphContain>Follow the  tutorial on the right to generate an app password. Then paste the app password below</ParagraphContain>
                                </div>


                                <LastWeekContain onClick={() => {window.open("https://myaccount.google.com/apppasswords", "_blank")}}>
                                    Generate app password
                                </LastWeekContain>



                                <div style={{
                                    marginBottom: "30px",
                                    marginTop: "35px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px"
                                }}>
                                    <Title2>Paste your generated App Password below</Title2>

                                    <FlexRow3>

                                        <TextboxFieldsPassword
                                            type={showPassword ? "text" : "password"}
                                            value={props.value2}
                                            onChange={props.handlePasswordChange}
                                            placeholder={'Password'}>
                                        </TextboxFieldsPassword>
                                        <PasswordToggle onClick={toggleShowPassword}>
                                            {showPassword ? "👁️" : "👁️‍🗨️"}
                                        </PasswordToggle>
                                    </FlexRow3>

                                    <LastWeekFull onClick={props.verifyPasswordClick}
                                        disabled={!(props.emailPassword.length > 4)}>
                                        Verify
                                    </LastWeekFull>
                                </div>

                            </div>

                            <div style={{ height: "250px", width: "450px" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    height: "100%",
                                    padding: "10px"
                                }}>
                                    <video src="https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FcustomerDashboard%2Fapp_password.mp4?alt=media&token=f300b620-5dcb-4454-af3f-e7ae2eecbe37"
                                        loop="true" autoPlay="true" muted="true"
                                        style={{
                                            width: "450px",
                                            borderRadius: "15px",
                                            boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
                                        }}>
                                    </video>
                                </div>
                            </div>
                        </FlexRow>
                    </div>

                </FlexColumn>
            </PublishingCardsprojectsRootRoot>
        )
    }


    

    return (
        <CardssetupWorkspaceRootRoot>
            <FlexRow>
                <FlexRow1>
                    <Text2 onClick={props.function1}>
                        <Icons src={`https://file.rendit.io/n/X8BJzNAi4He5eButStzx.svg`} /> <span>Back</span>
                    </Text2>
                    <Paragraph1>
                        Step {props.step}/{props.totalSteps}
                    </Paragraph1>
                </FlexRow1>
            </FlexRow>
            <Body>
                <div style={{
                    width: "400px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}>
                    <Text4Break>{props.text1}</Text4Break>
                    <Paragraph>{props.text2}</Paragraph>
                </div>



            </Body>

            <FlexColumn>
                <Body>
                    <Divider src={`https://file.rendit.io/n/AXdmgDjdrvYGxa9TBYLl.svg`} />
                    <LastWeekFull onClick={openAppPasswordPromptModal}>
                        {props.buttonText}
                    </LastWeekFull>
                </Body>
            </FlexColumn>
            <Modal
                id="2fa-modal"
                isOpen={openTwoFactorInstructionsModal}
                onRequestClose={closeAppPasswordPromptModal}
                should
                style={modalStyle}
                shouldCloseOnOverlayClick={false}
                contentLabel="2 Factor Authentication Instructions">
                <div >
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">
                        {promptAppPasswordPopUp()}
                    </div>

                </div>
            </Modal>

            <Modal
                id="training-modal"
                isOpen={props.verifyModalIsOpen}
                shouldCloseOnOverlayClick={false}
                onRequestClose={closeModal}
                style={modalStyle}
                contentLabel="Training">
                <div >
                    <div className="bg-white dark:bg-gray-700 z-100 flex relative m-auto">
                        {props.verificationState === "loading" ? loadingPopUp() : null}
                        {props.verificationState === "enter-code" ? enterCodePopUp(props.emailAddress) : null}
                    </div>

                </div>
            </Modal>

        </CardssetupWorkspaceRootRoot>
    );
};

export const CardSuccessPromptTrainPopUp = props => {
    // closeAppPasswordPromptModal();
    return (
        <PublishingCardsprojectsRootRoot>
            <div style={{
                width: "100%",
                height: "100px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "30px",
                justifyContent: "center"
            }}>

                <img alt="success" src="https://i.imgur.com/XDc1kBB.png" width="100" height="100" />

            </div>

            <FlexColumn>

                <div style={{
                    width: "500px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "25px"
                }}>

                    <div style={{ width: "100%", marginRight: "10px" }}>
                        <FlexRow>
                            <Text4Success>{props.title}</Text4Success>
                        </FlexRow>
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <Title2>{props.subtitle}</Title2>

                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "20px"
                    }}>

                        <LastWeekOutline onClick={props.maybeLaterClicked}>
                            Maybe Later
                        </LastWeekOutline>


                        <LastWeekHalf onClick={props.trainAIClicked}>
                            Train AI
                        </LastWeekHalf>

                    </div>


                </div>

            </FlexColumn>


        </PublishingCardsprojectsRootRoot>
    )
}


// const Text1 = styled.div`
//   color: #485175;
//   font-size: 10px;
//   font-weight: 500;
//   font-family: Poppins;
//   line-height: 14px;
//   white-space: nowrap;
// `;
// const Text3 = styled.div`
//   color: #c9d1ef;
//   font-size: 10px;
//   font-weight: 500;
//   font-family: Poppins;
//   line-height: 14px;
//   white-space: nowrap;
// `;
// const FlexRow2 = styled.div`
//   gap: 4px;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
//   border-style: solid;
//   border-color: #eaeeff;
//   border-radius: 8px;
//   padding: 9.2px 15.2px;
//   border-width: 0.8px;
// `;
// const Line = styled.img`
//   width: 16px;
//   height: 1px;
// `;
const LastWeekContain = styled.button`
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 7px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
const PublishingCardsprojectsRootRoot = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 52px 14px;
  border-width: 2px;
`;
const PublishingBody = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PublishingText1 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const PublishingText2 = styled.div`
  width: 500px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  text-align: center;
`;
const SelectClient = styled.select`

    border-radius: 8px;
    border: 1px solid gray;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    width: 200px;
    background-color: #f4f6ff;
    border-color: #eaeeff;
`;

const Paragraph1 = styled.div`    
  justify-content: right;
  display: flex;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const Text2 = styled.button`
  color: #485175;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  flex-direction: row;
  display: flex;
`;
const Body = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Paragraph = styled.div`
  width: 376px;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;

const ParagraphContain = styled.div`
  width: 100%;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
`;
const FlexColumn = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const FlexRow3 = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
const Textbox = styled.input`
  width: 268px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;

const TextboxCode = styled.input`
  width: 268px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  font-size: 18px;
`;

const TextboxFields = styled.input`
  width: 245px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const TextboxFieldsEmail = styled.input`
  width: 100%;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
//   border-color: rgba(0, 0, 0, 0.1);
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
//   border-width: 0.8px;
    border-width: 3px;
`;

const TextboxFieldsPassword = styled.input`
  width: 245px;
  gap: 2px;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  padding-right: 40px;
  border-width: 3px;
`;

const PasswordToggle = styled.p`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 0px;
  right: 35px;
  cursor: pointer;
`;

const CardssetupWorkspaceRootRoot = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f9faff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 14px;
  border-width: 2px;
`;
const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
`;
const FlexRowSpaceDeleted = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
`;
const FlexRow1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Icons = styled.img`
  width: 14px;
  height: 14px;
`;
const Text4 = styled.div`
  color: #0d1121;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  white-space: nowrap;
`;
const Text4Break = styled.div`
  color: #0d1121;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 22.4px;
  overflow-wrap: break-word;
`;
const Text4Success = styled.div`
  color: #0d1121;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 30.4px;
`;
const WhiteText = styled.div`
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #858caa;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #eaeeff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const WhiteTextFixed = styled.div`
  gap: 2px;
  width: 125px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #858caa;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #eaeeff;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
`;
const Text5 = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;

const Title2 = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  color: #485175;
  font-size: 15px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
`;
const WhiteFlexRow = styled.div`
  width: 374px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-color: #d8e0ff;
  background-color: #eaeeff;
  overflow: hidden;
  border-radius: 16px;
  padding: 34px 0px 33px 0px;
  border-width: 1px;
`;
const WhiteFlexRowInfo = styled.div`
  width: 374px;
  display: flex;
  flex-direction: column;
  border-style: dashed;
  border-color: #745DDE;
  background-color: #eaeeff;
  overflow: hidden;
  border-radius: 10px;
  font-size: 15px;
  padding: 10px 10px 10px 10px;
  border-width: 1px;
`;
const InfoParagraph = styled.p`
  font-size: 12px;
`
const Text6 = styled.div`
  color: #485175;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 14px;
  text-align: center;
`;
const Divider = styled.img`
  width: 376px;
  height: 1px;
`;
const LastWeek = styled.button`
  width: 344px;
  height: 14px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const LastWeekFull = styled.button`
  width: 100%;
  height: 14px;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const LastWeekHalf = styled.button`
  width: 50%;
  gap: 2px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #eaeeff;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #745DDE;
  border-radius: 8px;
  padding: 13.2px 15.2px;
  border-width: 0.8px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const LastWeekOutline = styled.button`
  width: 50%;
  gap: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #485175;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 20px;
  white-space: nowrap;
  border-style: solid;
  border-color: #eaeeff;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 13px 15px;
  border-width: 1px;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;
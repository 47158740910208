import axios from "axios";
import { apiURL } from '../../globalVariables';


const UserUtils = {
    callGetProfile: async function (userId) {
        const requestURL = `${apiURL}/user/getuserinfo`
        return await axios.get(requestURL, {
            params: {
                userId: userId
            }
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callSetProfile: async function (userId, profile) {
        const requestURL = `${apiURL}/user/updateuserinfo`
        return await axios.post(requestURL, {
            userId: userId,
            description: profile.description,
            name: profile.name,
            profilePhotoUrl: profile.profilePhotoUrl,
            website: profile.website,
            company: profile.company,
            companyWebsite: profile.companyWebsite,
            timezone: profile.timezone,
            location: profile.location,
            workTime: profile.workTime,
            experience: profile.experience,
            twitter: profile.twitter,
            linkedIn: profile.linkedIn
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data && response.data.success) {
                    return response.data.data;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    },

    callAddOrganization: async function (userId, organizations) {
        const requestURL = `${apiURL}/user/updateuserorganizationinfo`
        return await axios.post(requestURL, {
            userId: userId,
            organizations: organizations,
        }).then(async (response) => {
            if (response.data.success) {
                if (response.data.success) {
                    return response.data.success;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            console.log(error)
            // TODO: Show error
            return false;
        })
    }
}

export default UserUtils;